import React, { FC, FocusEvent, useContext, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import cn from 'classnames';
import styles from './Search.module.scss';
import { collection, getDocs, query, where } from 'firebase/firestore/lite';
import { useLocation, useParams } from 'react-router-dom';
import { FirebaseContext, IFirebaseContext } from '../../../context/FirebaseContext';
import {
  CollectionReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
} from '@firebase/firestore/lite';
import { IPartnerContext, PartnerContext } from '../../../context/PartnerContext';
import { HeaderContext, IHeaderCampaignData } from '../../../context/HeaderContext';
import { ICampaign, ICampaignData } from '../../../types/Campaign';
import { ITemplate, ITemplateData } from '../../../types/Template';
import { DocumentData } from 'firebase/firestore/lite';

type ISetData = <T>(state: T) => void;

interface Props {
  customSearch?: (search: string) => void;
  setMethod?: ISetData;
}

const Search: FC<Props> = ({ customSearch, setMethod }): JSX.Element => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { headerObjectValue } = useContext(HeaderContext);
  const { campaignFirestore } = useContext<IFirebaseContext>(FirebaseContext);
  const { partnerData } = useContext<IPartnerContext>(PartnerContext);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const handleSearchVal = (e: FocusEvent<HTMLInputElement>): void => {
    setSearchValue(e?.target?.value);
  };

  const search = async (): Promise<void> => {
    // Comparison of filled fields with object fields
    const checkSearchFields = (obj: any) => {
      let check = false;
      const categoryData: IHeaderCampaignData = headerObjectValue.categoryData;
      if (obj) {
        Object.keys(categoryData)?.map((key) => {
          if (key === categoryData[key]?.toString()?.toLowerCase() || !categoryData[key]) {
            delete categoryData[key];
          }
          return key;
        });
        check = !Object.keys(categoryData)?.length;
        Object.keys(categoryData)?.map((key) => {
          let filterParam = categoryData[key];
          if (Array.isArray(categoryData[key])) {
            filterParam = '' + categoryData[key];
          }
          if (obj && Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === filterParam) {
            check = true;
          } else {
            if (
              'categoryData' in obj &&
              Object.prototype.hasOwnProperty.call(obj?.categoryData, key) &&
              obj?.categoryData?.[key] === filterParam
            ) {
              check = true;
            }
          }
          return check;
        });
      }

      return check;
    };

    const queryWithoutSearch = (colSnapshots: Array<CollectionReference>): Array<Query> => {
      return colSnapshots?.map((colSnapshot: CollectionReference) =>
        query(colSnapshot, where('partner_id', '==', partnerData?.partner_id)),
      );
    };

    const getData = (
      colSnapshots: Array<CollectionReference>,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      searchFields: Array<string>,
    ): Array<Query<DocumentData>> => {
      // if (searchValue && Object.entries(searchValue)?.length) {
      //   return queryWithSearch(colSnapshots, searchFields);
      // } else {
      return queryWithoutSearch(colSnapshots);
      // }
    };
    // End Query methods

    const getSnapshots = async (
      data: Array<Query<DocumentData>>,
    ): Promise<Awaited<QuerySnapshot<DocumentData>>[]> => {
      return await Promise.all(data.map(async (item: Query<DocumentData>) => await getDocs(item)));
    };

    const getSearchData = <ArrayType, DataType>(
      querySnapshot: Awaited<QuerySnapshot<DocumentData>>[],
      checkMethod: (data: DataType) => boolean,
    ): Array<ArrayType> => {
      const ids: Array<string> = [];
      const searchData: Array<ArrayType> = [];
      if (querySnapshot) {
        querySnapshot.forEach((snapshot: QuerySnapshot<DocumentData>) => {
          snapshot.forEach((doc: DocumentSnapshot<DocumentData>) => {
            if (!ids?.includes(doc.id)) {
              ids.push(doc.id);
              const obj = doc.data() as DataType;
              if (checkMethod(obj)) {
                const checkSearch: boolean = checkSearchFields(obj);
                if (checkSearch) {
                  searchData.push({ data: obj, id: doc.id } as unknown as ArrayType);
                }
              }
            }
          });
        });
      }

      return searchData;
    };

    const setSearchData = <DataType,>(setData: ISetData, data: DataType): void => {
      if (setData) {
        setData(data);
      }
    };

    const generateData = async <ArrayType, DataType>(
      colSnapshots: Array<CollectionReference>,
      searchFields: Array<string>,
      dataCheck: (data: DataType) => boolean,
      setMethod?: ISetData,
    ): Promise<void> => {
      if (setMethod) {
        const data: Array<Query<DocumentData>> = getData(colSnapshots, searchFields);
        const querySnapshot = await getSnapshots(data);
        const searchData = getSearchData<ArrayType, DataType>(querySnapshot, dataCheck);
        setSearchData(setMethod, searchData);
      }
    };

    // Start Check methods
    const templateDataCheck = (template: ITemplateData): boolean => {
      const isNotEmptySearch =
        searchValue &&
        (template?.templateName?.includes(searchValue) || template?.type?.includes(searchValue));
      const isEmptySearch = !searchValue || !Object?.entries(searchValue)?.length;
      const checkTemplateId: boolean = template?.partner_id === partnerData?.partner_id;

      return (isNotEmptySearch || isEmptySearch) && checkTemplateId;
    };

    const campaignDataCheck = (campaign: ICampaignData): boolean => {
      const isNotEmptySearch = searchValue && campaign?.setup?.name?.includes(searchValue);
      const isEmptySearch = !searchValue || !Object?.entries(searchValue)?.length;
      const isEqualId = campaign?.partner_id === partnerData?.partner_id;

      return (isNotEmptySearch || isEmptySearch) && isEqualId;
    };
    // End Check methods

    // Start Search methods
    const optimisationManagementSearch = (): void => {
      if (customSearch && searchValue) {
        customSearch(searchValue);
      }
    };

    const templatesManagementSearch = async (): Promise<void> => {
      if (campaignFirestore) {
        const colTemplatesSnapshot = collection(campaignFirestore, 'templates');
        const colExclusionsTemplatesSnapExclusions = collection(
          campaignFirestore,
          'exclusions_templates',
        );
        const colSnapshotsArr = [colTemplatesSnapshot, colExclusionsTemplatesSnapExclusions];
        const searchFields = ['name', 'type', 'templateName'];
        await generateData<ITemplate, ITemplateData>(
          colSnapshotsArr,
          searchFields,
          templateDataCheck,
          setMethod,
        );
      }
    };

    const campaignsSearch = async (): Promise<void> => {
      if (campaignFirestore) {
        const colSnapshot = collection(campaignFirestore, 'campaigns');
        const colSnapshotsArr = [colSnapshot];
        const searchFields = ['setup.name'];
        await generateData<ICampaign, ICampaignData>(
          colSnapshotsArr,
          searchFields,
          campaignDataCheck,
          setMethod,
        );
      }
    };
    // End Search methods

    switch (pathname) {
      case `/optimisation-management`: {
        optimisationManagementSearch();
        break;
      }
      case `/templates-management/${id}`: {
        await templatesManagementSearch();
        break;
      }
      default: {
        await campaignsSearch();
      }
    }
  };

  return (
    <InputGroup className={cn('flex-row', styles.searchInput)}>
      <FormControl aria-label="Search" onBlur={handleSearchVal} />
      <button className={cn('btn btn-outline-success h-100', styles.searchButton)} onClick={search}>
        Search
      </button>
    </InputGroup>
  );
};

export default Search;
