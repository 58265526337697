import { getAuth } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import firebase from 'firebase/compat/app';
// import 'react-toastify/dist/ReactToastify.css';

import { IFirebaseContext } from '../context/FirebaseContext';
//import { LoginFirebase }  from '../../helpers/helpers';

export const LoginFirebase = (firebaseContext: IFirebaseContext) => {
  const auth = getAuth(firebaseContext.firebaseGui);
  const ui = new firebaseui.auth.AuthUI(auth);
  ui.start('#firebaseui-auth-container', {
    callbacks: {
      signInSuccessWithAuthResult: function () {
        const email = (<HTMLInputElement>document.querySelector('#ui-sign-in-email-input')).value;
        const pwd = (<HTMLInputElement>document.querySelector('#ui-sign-in-password-input')).value;
        firebaseContext.email = email;
        firebaseContext.password = pwd;
        return false;
      },
    },
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        disableSignUp: {
          status: true,
        },
      },
    ],
  });
};
