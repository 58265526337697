import { gql } from '../__generated__';
import {
  CampaignSetInput,
  CampaignSetTouchpointInput,
  UpsertCampaignMutationVariables,
} from '../__generated__/graphql';
import { ICreateCampaignForm, ICreateCampaignSet } from '../../types/CreateCampaignForm';
import { getFrequencyInput } from '../helpers/getFrequencyInput';
import { convertToUTC } from '../../helpers/handleUTCTime';

export const UPSERT_CAMPAIGN_MUTATION = gql(`
    mutation UpsertCampaign($input: UpsertCampaignInput!) {
        upsertCampaign(input: $input) {
            campaign { 
                id 
            }
        }
    }
`);

export const getUpsertCampaignMutationVariables = (
  data: ICreateCampaignForm,
  updatedAt?: string,
  id?: string,
): UpsertCampaignMutationVariables => {
  //Required fields
  const result: UpsertCampaignMutationVariables = {
    input: {
      brand: data.categoryData.brand,
      budget: data.setup.budget,
      category: data.categoryData.category,
      division: data.categoryData.division,
      franchiseDescription: data.setup.franchiseDescription,
      franchiseName: data.setup.franchiseName,
      name: data.setup.name,
      poNumber: data.setup.po,
      product: data.categoryData.product,
      usersNamingSection: data.setup.scenarioName,
      endDate: convertToUTC(data.setup.endDate).toISOString(),
      startDate: convertToUTC(data.setup.startDate).toISOString(),
      sets: parseSets(data.sets),
      updatedAt: updatedAt,
      frequency: getFrequencyInput(data.setup.frequency),
    },
  };

  //Optional fields
  if (id) {
    result.input.id = id;
  }

  return result;
};

const parseSets = (input: ICreateCampaignSet[]): CampaignSetInput[] => {
  return input.map((set) => {
    const result: CampaignSetInput = {
      touchpoints: parseTouchpoints(set),
      creatives: set.creatives,
      name: set.name,
      goals: [set.goal],
    };

    if (set.uniqId !== undefined) {
      result.uniqId = set.uniqId;
    }

    return result;
  });
};

const parseTouchpoints = (input: ICreateCampaignSet): CampaignSetTouchpointInput[] => {
  return input.touchpoints.map((el) => {
    const result: CampaignSetTouchpointInput = {
      name: el.name,
      frequency: getFrequencyInput(el.frequency),
    };
    return result;
  });
};
