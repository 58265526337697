import React, { FC } from 'react';

interface Props {
  className: string;
}

const StopIcon: FC<Props> = ({ className }): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="48.000000pt"
      height="48.000000pt"
      viewBox="0 0 48.000000 48.000000"
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path d="M175 425 c-83 -29 -145 -132 -130 -214 14 -77 73 -142 146 -161 161 -44 300 132 224 281 -31 58 -116 110 -180 109 -11 -1 -38 -7 -60 -15z m165 -185 l0 -30 -100 0 -100 0 0 30 0 30 100 0 100 0 0 -30z" />
      </g>
    </svg>
  );
};

export default StopIcon;
