import React, { FC, useMemo } from 'react';
import styles from './GenerateSegments.module.scss';
import cn from 'classnames';
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { FormControl } from 'react-bootstrap';
import { ICreateCampaignForm } from '../../../../types/CreateCampaignForm';
import { useFormState, useWatch } from 'react-hook-form';
import { BudgetSplitSegmentsTypeEnumModel } from '../../../../graphql/__generated__/graphql';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form/dist/types/fieldArray';
import SegmentDataPickers from '../SegmentDataPickers/SegmentDataPickers';
import { handleFloatChange } from '../../../../helpers/handleInput';

interface Props {
  control: Control<ICreateCampaignForm>;
  selectedTouchpointIndex: number;
  register: UseFormRegister<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  removeSegment: UseFieldArrayRemove;
  segments: FieldArrayWithId<ICreateCampaignForm, `budgetSplits.${number}.segmentsList`>[];
}

const GenerateSegments: FC<Props> = ({
  control,
  selectedTouchpointIndex,
  register,
  getValues,
  setValue,
  segments,
  removeSegment,
}): JSX.Element => {
  const { errors } = useFormState({
    control,
    name: `budgetSplits.${selectedTouchpointIndex}.segmentsList`,
  });

  const watchedSegments = useWatch({
    control,
    name: `budgetSplits.${selectedTouchpointIndex}.segmentsList`,
  });

  const currBudget: number = useMemo(
    () =>
      watchedSegments.reduce(
        (prev, curr) => prev + (Number.isNaN(curr.budget) ? 0 : curr.budget ?? 0),
        0,
      ),
    [watchedSegments],
  );

  setValue(`budgetSplits.${selectedTouchpointIndex}.budget`, currBudget);

  const remove = (index: number) => {
    if (segments.length === 1) {
      return;
    }

    const segmentsType = getValues(`budgetSplits.${selectedTouchpointIndex}.segmentsType`);

    if (segmentsType === BudgetSplitSegmentsTypeEnumModel.Equal) {
      removeSegment(index);
    }
  };

  return (
    <div className={'row border col-9 ms-4 mt-5 pt-4 pb-4 zeroMargin'}>
      <p>Segments</p>
      <div className={'row gap-2 ms-4 mt-3'}>
        <div className={'row'}>
          <p className={'col-4'}>Start Date</p>
          <p className={'col-4'}>End Date</p>
          <p className={'col-4'}>Budget</p>
        </div>
        {segments.map((segment, segmentIndex) => (
          <div key={segment.id} className={'row'}>
            <SegmentDataPickers
              control={control}
              selectedTouchpointIndex={selectedTouchpointIndex}
              getValues={getValues}
              setValue={setValue}
              segmentId={segmentIndex}
            />
            <div className={'col-3'}>
              <FormControl
                {...register(
                  `budgetSplits.${selectedTouchpointIndex}.segmentsList.${segmentIndex}.budget`,
                  {
                    valueAsNumber: true,
                    onChange: (e) => {
                      handleFloatChange(
                        e,
                        setValue,
                        `budgetSplits.${selectedTouchpointIndex}.segmentsList.${segmentIndex}.budget`,
                      );
                    },
                  },
                )}
                className={styles.budget}
                type={'text'}
                inputMode={'decimal'}
                placeholder={'Budget'}
                isInvalid={
                  errors.budgetSplits?.[selectedTouchpointIndex]?.segmentsList?.[segmentIndex]
                    ?.budget?.message !== undefined
                }
              />
            </div>
            <img
              className={cn('d-flex align-self-center', styles.stop)}
              alt={'Remove segment'}
              onClick={() => remove(segmentIndex)}
              src={segments.length > 1 ? '/stop.png' : '/stopDisabled.png'}
            />
          </div>
        ))}
        <div className={'row'}>
          <div className={'col-4'} />
          <span className={'text-end col-3'}>Total: </span>
          <div className={'col-3'}>
            <FormControl placeholder={'budget'} disabled={true} value={currBudget.toFixed(2)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateSegments;
