import { gql, useFragment } from '../__generated__';
import {
  ICreateCampaignSetWithVariants,
  ICreateCampaignVariantTargetGroup,
} from '../../types/CreateCampaignForm';
import { CampaignSetVariantsFragment } from '../__generated__/graphql';
import {
  CAMPAIGN_SET_TABS_FOR_VARIANTS_FRAGMENT,
  campaignSetsTabsFragmentToSetWithTabsForVariants,
} from './CampaignSetTabsForVariantsFragment';
import { v4 as uuidv4 } from 'uuid';

export const CAMPAIGN_SET_VARIANTS_FRAGMENT = gql(`
    fragment CampaignSetVariants on CampaignSet {
        ...CampaignSetTabsForVariants
        variants {
            name
            targetGroups {
                type
                subType
                values
            }
        }
    }
`);

export const campaignSetVariantsFragmentToSetWithVariants = (
  campaignSets: CampaignSetVariantsFragment[],
): ICreateCampaignSetWithVariants[] => {
  const campaignSetTabsFragment = useFragment(
    CAMPAIGN_SET_TABS_FOR_VARIANTS_FRAGMENT,
    campaignSets,
  );

  const setWithTabs = campaignSetsTabsFragmentToSetWithTabsForVariants(campaignSetTabsFragment);

  return setWithTabs.map((set, index) => ({
    ...set,
    variants:
      campaignSets[index].variants?.map((variant) => {
        const tabs: ICreateCampaignVariantTargetGroup = {};

        // Set every key to false
        campaignSetTabsFragment[index].targetGroups?.forEach((setTargetGroup) => {
          if (tabs[setTargetGroup.type] === undefined) {
            tabs[setTargetGroup.type] = {};
          }

          tabs[setTargetGroup.type][setTargetGroup.subType] = setTargetGroup.values.map(
            (value) => ({ name: value, value: false }),
          );
        });

        // Set keys to true
        variant.targetGroups.forEach((variantTargetGroup) => {
          if (
            tabs[variantTargetGroup.type] === undefined ||
            tabs[variantTargetGroup.type][variantTargetGroup.subType] === undefined
          ) {
            return;
          }

          variantTargetGroup.values.forEach((variantValue) => {
            const index = tabs[variantTargetGroup.type][variantTargetGroup.subType].findIndex(
              (value) => value.name === variantValue,
            );
            if (index === -1) {
              return;
            }

            tabs[variantTargetGroup.type][variantTargetGroup.subType][index].value = true;
          });
        });
        return {
          uniqId: uuidv4(),
          name: variant.name,
          tabs: tabs,
        };
      }) ?? [],
  }));
};
