import styles from './Loader.module.scss';
import React from 'react';
import cn from 'classnames';

interface Props {
  isOrange?: boolean;
}

const Loader360 = ({ isOrange = false }: Props) => (
  <div className={cn(styles.loader360, { [styles.orangeLoader]: isOrange })}></div>
);

export default Loader360;
