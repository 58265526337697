import React, { FC, useContext } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { IDropdownItem, IOptions } from '../GetTabs';
import { Control, useWatch } from 'react-hook-form';
import cn from 'classnames';
import styles from '../PlatformsBrandsProductsSetup.module.scss';
import ShowTypes from '../ShowTypes';
import { PartnerContext } from '../../../context/PartnerContext';

interface Props {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  options: IOptions;
  getValues: UseFormGetValues<any>;
  removeItem: (name: string, index: number) => void;
}

const GetBrandSafety: FC<Props> = ({
  control,
  getValues,
  setValue,
  register,
  options,
}): JSX.Element => {
  const globalSafety = useWatch({ control, name: `global_safety` });
  const advertisersSafety = useWatch({ control, name: `advertisers_safety` });
  const { partnerData } = useContext(PartnerContext);

  const getGlobalSafety = (index: number) => {
    if (!getValues(`global_safety.${index}.advertiser`)) {
      setValue(
        `global_safety.${index}.advertiser`,
        partnerData?.platforms?.DV360?.default_sdf_version,
      );
    }

    return (
      <React.Fragment key={`global_safety_${index}`}>
        <div className={'row mt-4 mx-2'}>
          <div className={'row mt-2'}>
            <ShowTypes
              register={register}
              index={index}
              setValue={setValue}
              getValues={getValues}
              control={control}
              mainObj={globalSafety}
              name={`global_safety.${index}`}
              isGlobal={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getAdvertisersSafety = (index: number) => {
    return (
      <React.Fragment key={`advertisers_safety_${index}`}>
        <div className={'row mt-4 mx-2'}>
          <div className={'row'}>
            <div className={'col-4'}>
              <select
                {...register(`advertisers_safety.${index}.advertiserId`)}
                className={cn(styles.select_40, 'form-select col-3 gap-2')}
                aria-label="Advertiser"
                defaultValue="default"
                onChange={(e: any) => {
                  if (options?.advertisers) {
                    for (let i = 0; i < options?.advertisers?.length; i++) {
                      if (options?.advertisers[i]?.id === e?.target?.value) {
                        setValue(
                          `advertisers_safety.${index}.advertiser`,
                          options?.advertisers[i]?.version,
                        );
                      }
                    }
                  }
                  setValue(`advertisers_safety.${index}.advertiserId`, e?.target?.value);
                  setValue(`advertisers_safety.${index}.is_active`, true, { shouldValidate: true });
                }}>
                {options?.advertisers
                  ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
                  ?.map((adv: IDropdownItem, index2: number) => (
                    <option
                      key={`advertisers_safety_advertisers_${index}_${index2}`}
                      value={adv?.id}>
                      {adv?.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className={'col-4'} />
            <div className={'col-4'} />
          </div>
          <div className={'row mt-2'}>
            {/*<div className={'col-4'}>*/}
            {/*  <ShowTypes register={register} index={index} setValue={setValue} control={control} name={`advertisers_safety.${index}.advertiser`}/>*/}
            {/*</div>*/}
            {/*<div className={'col-4'}>*/}
            {/*  <select*/}
            {/*    {...register(`advertisers_safety.${index}.field_type`)}*/}
            {/*    className={cn(styles.select_40, "form-select col-3 gap-2")}*/}
            {/*    aria-label="Field type"*/}
            {/*    defaultValue='default'*/}
            {/*    onChange={(e: any) => {*/}
            {/*      setValue(`advertisers_safety.${index}.field_type`, e?.target?.value)*/}
            {/*      setValue(`advertisers_safety.${index}.is_active`, true, {shouldValidate: true})*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <option value="default">Field type</option>*/}
            {/*    {*/}
            {/*      FREQUENCY?.map((frq: string, index: number) =>*/}
            {/*        <option key={`advertisers_safety_Field_type${index}`} value={frq?.toLocaleLowerCase()}>{frq}</option>*/}
            {/*      )*/}
            {/*    }*/}
            {/*  </select>*/}
            {/*</div>*/}

            <ShowTypes
              register={register}
              index={index}
              setValue={setValue}
              getValues={getValues}
              control={control}
              mainObj={advertisersSafety}
              name={`advertisers_safety.${index}`}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className={'row'}>
        <p className="mt-3 col-12 fw-bold">Brand safety & sdfs predefinitions</p>
        <div className={'row mx-2'}>
          <p className="mt-3 col-12 fw-bold">Client level (global settings for all advertisers)</p>
          {globalSafety?.length
            ? globalSafety
                ?.filter((itemGlobalSafety: any) => itemGlobalSafety?.is_active)
                ?.map((item: any, index: number) => {
                  return getGlobalSafety(index);
                })
            : null}
          {getGlobalSafety(
            globalSafety?.filter(
              (itemGlobalSafety: any) =>
                itemGlobalSafety &&
                Object.prototype.hasOwnProperty.call(itemGlobalSafety, 'is_active') &&
                itemGlobalSafety.is_active,
            )?.length || 0,
          )}
          <p className="mt-3 col-12 fw-bold">Advertisers individual settings</p>
          {advertisersSafety?.length
            ? advertisersSafety
                ?.filter((itemAdvertisersSafety: any) => itemAdvertisersSafety?.is_active)
                ?.map((item: any, index: number) => {
                  return getAdvertisersSafety(index);
                })
            : null}
          {getAdvertisersSafety(
            advertisersSafety?.filter(
              (itemAdvertisersSafety: any) =>
                itemAdvertisersSafety &&
                Object.prototype.hasOwnProperty.call(itemAdvertisersSafety, 'is_active') &&
                itemAdvertisersSafety.is_active,
            )?.length || 0,
          )}
        </div>
      </div>
    </>
  );
};

export default GetBrandSafety;
