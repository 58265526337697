import React from 'react';
import { Form } from 'react-bootstrap';

interface AdsFilteringInputProps {
  placementChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AdsFilteringInput = ({ placementChangeHandler }: AdsFilteringInputProps) => {
  return (
    <div className="row mb-3">
      <div className="col-3">
        <Form.Control
          onChange={placementChangeHandler}
          type="text"
          name="filteringPlacement"
          id="filteringPlacement"
          placeholder="Placement Name"
        />
      </div>
    </div>
  );
};

export default AdsFilteringInput;
