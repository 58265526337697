export const flatten = (input: any, reference?: any, output?: any) => {
  output = output || {};
  for (let key in input) {
    const value = input[key];
    key = reference ? reference + '.' + key : key;
    if (typeof value === 'object' && value !== null) {
      flatten(value, key, output);
    } else {
      output[key] = value;
    }
  }
  return output;
};

export const sortObject = (obj: any) => {
  return Object.entries(obj)
    .sort(([, a]: any, [, b]: any) => a - b)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
};

// function sortFunction(a: any, b: any) {
//   if (a[1] === b[1]) {
//     return 0;
//   } else {
//     return a[1] < b[1] ? -1 : 1;
//   }
// }

export const removeUndefined = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter((v) => v != null));
};

export const removeEmpty = (obj: any): any => {
  const newObj: any = {};
  Object.keys(obj).forEach(function (k) {
    if (obj[k] && typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
      newObj[k] = removeEmpty(obj[k]);
    } else if (Array.isArray(obj[k])) {
      newObj[k] = obj[k]?.filter((item: any) => {
        const checkObj = removeEmpty(item);
        return Object?.keys(checkObj)?.length > 0;
      });
    } else if (obj[k] != null) {
      newObj[k] = obj[k];
    }
  });
  return newObj;
};
export const compareObjects = (obj1: any, obj2: any) => {
  const flatObj1 = removeUndefined(flatten(obj1));
  const flatObj2 = removeUndefined(flatten(obj2));

  const sortObj1: any = {};
  const sortObj2: any = {};
  Object.keys(flatObj1)?.map((key) => {
    sortObj1[key] = flatObj1[key];
    sortObj2[key] = flatObj2[key];
  });

  return JSON.stringify(sortObj1) === JSON.stringify(sortObj2);
};

export const compareDifferObjects = (obj1: any, obj2: any) => {
  const flatObj1 = removeUndefined(flatten(obj1));
  const flatObj2 = removeUndefined(flatten(obj2));

  const sortObj1: any = {};
  const sortObj2: any = {};
  Object.keys(flatObj1)?.map((key) => {
    sortObj1[key] = flatObj1[key];
  });
  Object.keys(flatObj2)?.map((key) => {
    sortObj2[key] = flatObj2[key];
  });

  return Object.keys(sortObj2)?.length === Object.keys(sortObj1)?.length;
};
