import { createContext } from 'react';
import { IPartner } from '../types/Partner';

export interface IPartnerContext {
  partnerData: IPartner | null;
  setPartnerData: (value: IPartner) => void;
}

const initialPartnerState: IPartnerContext = {
  partnerData: null,
  setPartnerData: () => {},
};

export const PartnerContext = createContext<IPartnerContext>(initialPartnerState);
