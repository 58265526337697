import 'firebaseui/dist/firebaseui.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

export const changeName = (name: string): string => {
  return name
    .toLowerCase()
    ?.split(' ')
    ?.join('_')
    ?.replace(/[^a-zA-Z0-9_.\- ]/g, '');
};

export const changeNameWithDot = (name: string): string => {
  return name
    .toLowerCase()
    ?.split(' ')
    ?.join('_')
    ?.replace(/[^a-zA-Z0-9_\- ]/g, '');
};

export const uniqueObjArray = (arr: any, filterField: string): Array<any> => {
  return Array.from(new Map(arr.map((item: any) => [item[filterField], item])).values());
};

export const diffDays = (d1: number | string | Date, d2: number | string | Date) => {
  const start: Date = new Date(d1);
  const end: Date = new Date(d2);
  let days = (end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24;
  days = days - (end.getTimezoneOffset() - start.getTimezoneOffset()) / (60 * 24);

  return Math.round(days);
};

export const diffMonths = (dateFrom: Date, dateTo: Date) => {
  return (
    dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

export const getCurrentDate = (d: any) => new Date(d).getDate();

export const normaliseDate = (date: string | number) => {
  if (date) {
    if (typeof date === 'string') {
      const splitDate = date?.split('-');
      return new Date(parseInt(splitDate[2]), parseInt(splitDate[1]) - 1, parseInt(splitDate[0]));
    } else {
      return new Date(date);
    }
  } else {
    return new Date();
  }
};

export const findNestedObj = (entireObj: any, keyToFind: string) => {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && Object.prototype.hasOwnProperty.call(nestedValue, keyToFind)) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

export const notifySuccess = () => toast.success('Login Success !');
export const notifyFailed = () => toast.error('Login Failed !!');
