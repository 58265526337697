import React, { FC, useContext, useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import CreateCampaign from '../../components/CreateCampaign/CreateCampaign';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore/lite';
// import useSetUser from '../../hooks/useSetUser';
import { FirebaseContext } from '../../context/FirebaseContext';
import { DocumentSnapshot, Query, QuerySnapshot } from '@firebase/firestore/lite';
import { DocumentData } from 'firebase/firestore/lite';
import { ITemplate, ITemplates } from '../../types/Template';
import {
  IExclusionTemplate,
  IExclusionTemplateData,
  IExclusionTemplates,
} from '../../types/ExclusionTemplate';
import TemplatesManagementSetup from '../../components/TemplatesManagementSetup/TemplatesManagementSetup';
import TemplatesTable from '../../components/TemplatesManagementSetup/TemplatesTable/TemplatesTable';
import { PartnerContext } from '../../context/PartnerContext';

const TemplatesM: FC = (): JSX.Element => {
  // const {setPartner} = useSetUser();
  const { id } = useParams();
  const { partnerData } = useContext(PartnerContext);
  const { campaignFirestore } = useContext(FirebaseContext);
  const [templates, setTemplates] = useState<Array<ITemplate | IExclusionTemplate>>([]);
  const { setValue, getValues, control } = useForm();

  const getTemplates = async (): Promise<ITemplates> => {
    if (id && campaignFirestore) {
      const colSnapshot = collection(campaignFirestore, 'templates');
      const stateQueryName: Query = query(colSnapshot, where('partner_id', '==', id));
      const docs: QuerySnapshot<DocumentData> = await getDocs(stateQueryName);
      const templates: any = [];
      docs.forEach((doc: any) => {
        templates.push({
          id: doc?.id,
          data: doc?.data(),
        });
      });
      return templates;
    }
    return [];
  };

  const getExclusionsTemplates = async (): Promise<IExclusionTemplates> => {
    if (campaignFirestore) {
      const colSnapshot = collection(campaignFirestore, 'exclusions_templates');
      const stateQueryName = query(colSnapshot, where('partner_id', '==', id));
      const docs: QuerySnapshot<DocumentData> = await getDocs(stateQueryName);
      const exclusionsTemplates: IExclusionTemplates = [];
      docs.forEach((doc: DocumentSnapshot<DocumentData>) => {
        exclusionsTemplates.push({
          id: doc?.id,
          data: doc.data() as IExclusionTemplateData,
        });
      });

      return exclusionsTemplates;
    }

    return [];
  };

  const getAllTemplates = async (): Promise<Array<ITemplate | IExclusionTemplate>> => {
    if (partnerData) {
      const templates = await getTemplates();
      const exclusionsTemplates = await getExclusionsTemplates();
      return [...templates, ...exclusionsTemplates];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (campaignFirestore) {
      getAllTemplates().then((res: Array<ITemplate | IExclusionTemplate>) => {
        setTemplates(res);
      });
    }
  }, [getAllTemplates]);

  // useEffect(() => {
  //   if (!campaignFirestore && masterFirestore) {
  //     setPartner();
  //   }
  // }, [campaignFirestore, masterFirestore])

  return (
    <div>
      <Header campaignSetupText={'Templates management'} withButtons={false} />
      <CreateCampaign
        control={control}
        setValue={setValue}
        isShowCreate={false}
        isShowStatus={false}
        getValues={getValues}
        setMethod={setTemplates}
      />
      <TemplatesManagementSetup />
      <TemplatesTable
        templates={templates}
        setTemplates={setTemplates}
        getAllTemplates={getAllTemplates}
      />
    </div>
  );
};

export default TemplatesM;
