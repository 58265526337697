import { gql } from '../__generated__';

export const CATEGORY_DIVISION_QUERY = gql(`
    query GetCategoryDivision($brandId: String!) {
        categories(brandId: $brandId) {
            id
            category
        }
    
        division(brandId: $brandId) {
            id
            division
        }

        scenarioNames(brand: $brandId)
    }
`);
