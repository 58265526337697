import { gql } from '../__generated__';

export const UPSERT_TARGET_GROUP_QUERY = gql(`
    query GetUpsertTargetGroup($campaignId: String!) {
        campaign(campaignId: $campaignId) {
            ...CampaignSetup
            sets {
                ...CampaignSet
                ...CampaignSetTabs
            }
            updatedAt
        }
    }
`);
