import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { ICreateCampaignForm, ICreateCampaignSet } from '../../../../types/CreateCampaignForm';
import MultipleGoalsSelect from '../../MultipleGoalsSelect/MultipleGoalsSelect';
import { ISetForm } from '../../../../types/SetForm';
import { Error } from '../../../Error/Error';
import {
  GoalDetailsFragment,
  InsertionFrequencyFragment,
} from '../../../../graphql/__generated__/graphql';
import { UseFieldArrayAppend } from 'react-hook-form/dist/types/fieldArray';

interface Props {
  append: UseFieldArrayAppend<ICreateCampaignForm, 'sets'>;
  matchedInsertionFreqTemplates: InsertionFrequencyFragment[];
  goals: GoalDetailsFragment[];
}

const MultipleSelectBlock: FC<Props> = ({
  goals,
  append,
  matchedInsertionFreqTemplates,
}): JSX.Element => {
  const defaultValue: ISetForm = {
    goal: '',
    touchpoints: [],
    creatives: [],
  };

  const {
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISetForm>({
    defaultValues: defaultValue,
  });

  const goal = useWatch({ control, name: 'goal' });
  const touchpoints = useWatch({ control, name: 'touchpoints' });
  const creatives = useWatch({ control, name: 'creatives' });

  const checkDisabled = (): boolean => {
    return !(goal !== '' && touchpoints.length && creatives.length);
  };

  const createSet = (data: ISetForm): void => {
    const set: ICreateCampaignSet = {
      ...data,
      name: '',
      touchpoints: data.touchpoints.map((el) => {
        const touchpointFrequency = matchedInsertionFreqTemplates.find(
          (template) => template.touchpointName === el,
        )?.frequency;
        return {
          name: el,
          frequency: {
            limit: touchpointFrequency?.limit?.toString(),
            per: touchpointFrequency?.per?.toString(),
            period: touchpointFrequency?.period ?? undefined,
          },
        };
      }),
    };

    append(set);

    setValue('goal', '');
  };

  const getErrors = (): JSX.Element | undefined => {
    if (Object.keys(errors).length === 0) {
      return undefined;
    }

    return <Error>Please select at least one goal, touchpoint and creative</Error>;
  };

  return (
    <div className="border rounded pb-3 mt-3 row zeroMargin align-items-center">
      <h6 className={'mt-2'}>Set of insertion orders</h6>
      <div className={'row'}>
        <div className={'col-10'}>
          <MultipleGoalsSelect
            goals={goals}
            control={control}
            getValues={getValues}
            setValue={setValue}
          />
        </div>
        <Button
          className="col-2 p-1"
          onClick={() => handleSubmit(createSet)()}
          variant="primary"
          disabled={checkDisabled()}>
          Create set
        </Button>
      </div>
      {getErrors()}
    </div>
  );
};

export default MultipleSelectBlock;
