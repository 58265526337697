import React, { FC, useEffect, useMemo } from 'react';
import FilterNavigation from '../FilterNavigation/FilterNavigation';
import { useLazyQuery } from '@apollo/client';
import { TABS_QUERY } from '../../../graphql/queries/TabsQuery';
import { useGraphQLError } from '../../../hooks/useGraphQLError';

interface Props {
  activeTabId: string | null;
  selectedSetUniqId: string | null;
  campaignId: string;
  setActiveTabId: (value: string) => void;
}

export interface TabType {
  id: string;
  tabName: string;
  tabOrder: number;
}

const GetTabs: FC<Props> = ({
  selectedSetUniqId,
  activeTabId,
  setActiveTabId,
  campaignId,
}): JSX.Element | null => {
  const [getTabs, { error, data }] = useLazyQuery(TABS_QUERY);

  useEffect(() => {
    if (selectedSetUniqId === null) {
      return;
    }

    getTabs({ variables: { campaignId: campaignId, uniqId: selectedSetUniqId } });
  }, [selectedSetUniqId]);

  useGraphQLError([error]);

  const tabs: TabType[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return (
      data.tabs
        ?.filter(
          (tab): tab is TabType => tab.id != null && tab.tabName != null && tab.tabOrder != null,
        )
        .sort((tab1, tab2) => tab1.tabOrder - tab2.tabOrder) ?? []
    );
  }, [data]);

  if (tabs.length === 0 || selectedSetUniqId === null) {
    return <></>;
  }

  return <FilterNavigation activeTab={activeTabId} setActiveTab={setActiveTabId} tabs={tabs} />;
};

export default GetTabs;
