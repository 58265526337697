import React, { FC } from 'react';
import {
  ICreateCampaignBudgetSplit,
  ICreateCampaignForm,
} from '../../../../../types/CreateCampaignForm';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import styles from './TouchpointTableItem.module.scss';
import cn from 'classnames';
import { FormControl } from 'react-bootstrap';
import { Error } from '../../../../Error/Error';
import TouchpointNameRow from './TouchpointNameRow/TouchpointNameRow';
import { handleFloatChange } from '../../../../../helpers/handleInput';

interface Props {
  register: UseFormRegister<ICreateCampaignForm>;
  errors: FieldErrors<ICreateCampaignForm>;
  touchpoint: ICreateCampaignBudgetSplit;
  touchpointIndex: number;
  selectedTouchpointIndex: null | number;
  setSelectedTouchpointIndex: (index: number) => void;
  campaignId: string;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  removeTouchpointHandler: (index: number) => any;
}

const TouchpointTableItem: FC<Props> = ({
  register,
  errors,
  selectedTouchpointIndex,
  setSelectedTouchpointIndex,
  touchpoint,
  touchpointIndex,
  campaignId,
  setValue,
  removeTouchpointHandler,
}): JSX.Element => {
  return (
    <div
      className={cn('col-12 row mx-0 gap-2 py-1 border-top', {
        [styles.set]: !touchpoint.partner,
        [styles.disabled]: touchpoint.isDisabled,
      })}
      onClick={() => setSelectedTouchpointIndex(touchpointIndex)}>
      <p
        className={cn(`col-6`, styles.tp, {
          [styles.selectTouchpoint]: selectedTouchpointIndex === touchpointIndex,
        })}>
        {`${touchpoint.setName} ${touchpoint.name} ${touchpoint.partner ?? ''}`}
      </p>
      {!touchpoint.isDisabled && (
        <>
          <div className={'col-2'}>
            <FormControl className={styles.value} defaultValue={touchpoint.KPI} disabled={true} />
          </div>
          <div className={'col-2'}>
            <FormControl
              {...register(`budgetSplits.${touchpointIndex}.kpiValue`, {
                setValueAs: (value) => {
                  return Number(value);
                },
                onChange: (e) => {
                  handleFloatChange(e, setValue, `budgetSplits.${touchpointIndex}.kpiValue`);
                },
              })}
              className={styles.value}
              type={'text'}
              inputMode={'decimal'}
              isInvalid={errors.budgetSplits?.[touchpointIndex]?.kpiValue?.message !== undefined}
            />
          </div>
        </>
      )}
      {touchpoint.isPartner && (
        <div className="col-1 d-flex justify-content-center align-items-center">
          <img
            alt="Remove variant"
            className={styles.stop}
            src={'/stop.png'}
            onClick={(event) => {
              event.stopPropagation();
              removeTouchpointHandler(touchpointIndex);
            }}
          />
        </div>
      )}

      {errors.budgetSplits?.[touchpointIndex]?.segmentsList !== undefined && (
        <Error>Please correct the segments</Error>
      )}
      <TouchpointNameRow
        campaignId={campaignId}
        setId={touchpoint.setUniqId}
        touchpointId={touchpoint.touchpointUniqId}
        partnerId={touchpoint.partner}
      />
    </div>
  );
};

export default TouchpointTableItem;
