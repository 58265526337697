import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface ModalWindowProps {
  isShowModal: boolean;
  description: string;
  subDescription?: string;
  title: string;
  setIsShow: (state: (prevState: boolean) => boolean) => void;
}

const ModalWindow: FC<ModalWindowProps> = ({
  isShowModal,
  setIsShow,
  description,
  subDescription,
  title,
}): JSX.Element => {
  const onClose = () => {
    setIsShow((prevState: boolean) => !prevState);
  };

  return (
    <Modal show={isShowModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className={'text-danger text-center text-break'}>
          {description}
          <span className={'fw-bold'}>{subDescription}</span>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWindow;
