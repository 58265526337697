import { gql } from '../__generated__';

export const VERIFY_CAMPAIGN_MUTATION = gql(`
    mutation VerifyCampaign($advertiserId: String!, $campaignId: String!, $insertionOrderIds: [String!]) {
        verifyCampaign(advertiserId: $advertiserId, campaignId: $campaignId, insertionOrderIds: $insertionOrderIds) {
            ok
            errors
        }
    }
`);
