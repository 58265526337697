import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './PlatformsBrandsProductsSetup.module.scss';
import FilterNavigation from '../FilterNavigation/FilterNavigation';
import { useForm } from 'react-hook-form';
import GetTabs from './GetTabs';
import { FirebaseContext } from '../../context/FirebaseContext';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore/lite';
import { removeEmpty } from '../../helpers/flatten';
import { PartnerContext } from '../../context/PartnerContext';
import useSetCampaign from '../../hooks/useSetCampaign';
import cn from 'classnames';
import { DocumentSnapshot } from '@firebase/firestore/lite';
// import { ICampaignData } from '../../types/Campaign';
import Loader from '../UI/Loader/Loader';
import { sha256 } from 'js-sha256';
import { DocumentData } from 'firebase/firestore/lite';
import CheckIcon from '../UI/icons/CheckIcon';
import ModalWindow from '../ModalWindow/ModalWindow';

const TABS = [
  'Client setup',
  'Reporting',
  'Optimisation settings',
  // 'Categories, brands, product',
  // 'Naming conventions',
  // 'Brand safety',
  // 'Touchpoints',
  // 'Campaign objectives',
  // 'Touchpoint compatibility',
  // 'Touchpoint types and formats',
  // 'Main tactics',
  // 'Creatives'
];

const PlatformsBrandsProductsSetup: FC = (): JSX.Element => {
  const { campaignFirestore, masterFirestore } = useContext(FirebaseContext);
  const { setCampaign } = useSetCampaign();
  const { partnerData } = useContext(PartnerContext);
  const [activeTab, setActiveTab] = useState<string>('');
  // const [modalSubtext, setModalSubtext] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saveDisables, setSaveDisables] = useState<boolean>(false);
  const [loadPage, setLoadPage] = useState<boolean>(true);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [saveTabsLoading, setSaveTabsLoading] = useState<boolean>(false);
  const { register, getValues, control, setValue, watch } = useForm();

  useEffect(() => {
    const prefill = async () => {
      let data: any = null;
      let dataNC: any = null;
      // let dataPlatforms: any = null;

      if (partnerData?.campaignName && masterFirestore && campaignFirestore) {
        const docSnap = await getDoc(
          doc(masterFirestore, 'clients-test', partnerData?.campaignName),
        );
        const docSnapNC = await getDoc(doc(campaignFirestore, 'naming-conventions', 'definitions'));
        const docSnapPlatforms = await getDoc(
          doc(campaignFirestore, 'platforms-setup-prefill', partnerData?.campaignName),
        );
        const variableTypesSnapshot = await getDoc(
          doc(campaignFirestore, 'variables_types', partnerData?.campaignName),
        );
        const variablesSnapshot = await getDoc(
          doc(campaignFirestore, 'variables', partnerData?.campaignName),
        );

        // const objectivesCol = collection(firestore, 'campaign-objectives');
        const objectivesCol = collection(campaignFirestore, 'campaign-objectives');
        const objectivesSnapshot = await getDocs(objectivesCol);

        const divisionsCol = collection(
          masterFirestore,
          'clients-test',
          partnerData?.campaignName,
          'divisions',
        );
        const divisionsSnapshot = await getDocs(divisionsCol);
        const divis = divisionsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
          nc_division: doc.id,
          division: doc.data()?.division,
          is_active: true,
        }));
        setValue(
          `divisions`,
          divis?.sort((a: any, b: any) => (a?.division > b?.division ? 1 : -1)),
        );

        // const globalSDfCol = collection(
        //   masterFirestore,
        //   'clients-test',
        //   partnerData?.campaignName,
        //   'sdf_default_settings',
        // );
        // const globalSDfSnapshot = await getDocs(globalSDfCol);

        const advertisersSafety: any = [];
        const globalSafety: any = [];
        // const globalSDF = globalSDfSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => {
        //   const id = doc?.id;
        //   const data = doc?.data();
        //   switch (id) {
        //     case 'Advertiser':
        //       const keys: any = Object.keys(data);
        //       keys?.map((item: any) => {
        //         if (data) {
        //           const currData = data[item];
        //           advertisersSafety?.push({
        //             advertiserId: item,
        //             values: currData?.values || '',
        //             SDF_type: currData?.SDF_type,
        //             field_name: currData?.field_name || '',
        //             advertiser: currData?.version,
        //             is_active: true,
        //           });
        //         }
        //       });
        //       break;
        //     case 'Global':
        //       const globalKeys: any = Object.keys(data);
        //       globalKeys?.map((item: any) => {
        //         if (data) {
        //           const globalKeysInside = Object.keys(data[item]);
        //           globalKeysInside?.map((key) => {
        //             const currData = data[item][key];
        //             globalSafety?.push({
        //               advertiserId: item,
        //               values: currData?.values || '',
        //               SDF_type: currData?.SDF_type,
        //               field_name: currData?.field_name || '',
        //               advertiser: currData?.version,
        //               is_active: true,
        //             });
        //           });
        //         }
        //       });
        //       // globalSafety;
        //       break;
        //   }
        // });

        setValue('advertisers_safety', advertisersSafety);
        setValue('global_safety', globalSafety);
        // setValue(`divisions`, divis?.sort((a: any, b: any) => a?.division > b?.division ? 1 : -1))

        const categoriesCol = collection(
          masterFirestore,
          'clients-test',
          partnerData?.campaignName,
          'categories',
        );
        const categoriesSnapshot = await getDocs(categoriesCol);
        const categories = categoriesSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
          ...doc.data(),
        }));
        setValue(
          `product_categories`,
          categories?.sort((a: any, b: any) => (a?.category > b?.category ? 1 : -1)),
        );

        const optimisationSettingsCol = collection(campaignFirestore, 'optimisation-settings');
        const optimisationSettingsSnapshot = await getDocs(optimisationSettingsCol);
        const optimisationSettings = optimisationSettingsSnapshot.docs.map(
          (doc: DocumentSnapshot<DocumentData>) => ({ ...doc.data(), is_active: true }),
        );
        setValue(`optimisation_settings`, optimisationSettings);

        const brandsCol = collection(
          masterFirestore,
          'clients-test',
          partnerData?.campaignName,
          'brands',
        );
        const brandsSnapshot = await getDocs(brandsCol);
        const brands = brandsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => {
          const data = doc?.data();
          const brandObj: any = {
            Brand: data?.Brand,
            division: data?.division,
            nc_brand: data?.nc_brand,
            is_active: true,
          };

          const brandsKeys = Object.keys(data);
          brandsKeys.map((key: any) => {
            if (key?.includes('_id') && data) {
              const brand_id = data[key];
              const Platform = key?.split('_id')[0];
              let currentPlatform = null;

              switch (Platform) {
                case 'dv360':
                  currentPlatform = 'DV360';
                  break;
                case 'adform':
                  currentPlatform = 'AdForm';
                  break;
                case 'doubleclick':
                  currentPlatform = 'DoubleClick';
                  break;
                default:
                  currentPlatform = 'Firebase';
              }
              brandObj.platforms = [];
              brandObj.platforms.push({
                Platform: currentPlatform,
                brand_id,
                is_active: true,
              });
            }
          });

          if (data?.product_categories_refs) {
            const categories: any = [];
            data?.product_categories_refs?.map((item: any) => {
              categories?.push({
                Category: item,
                is_active: true,
              });
            });

            brandObj.categories = categories;
          }

          return brandObj;
        });
        setValue('categories_brands', brands);
        // setValue(`product_categories`, categories?.sort((a: any, b: any) => a?.category > b?.category ? 1 : -1))

        const tps: any = [];
        const campaignObjectives: any = [];

        objectivesSnapshot.docs.map((doc: any) => {
          const data = doc.data();
          const id = doc.id;
          Object?.values(data)?.map((item: any) => {
            let findTpIndex = null;
            for (let i = 0; i < tps?.length; i++) {
              if (tps[i]?.name === item?.name) {
                findTpIndex = i;
                break;
              }
            }

            if (findTpIndex === null) {
              tps.push({
                ...item,
                creative_list: item?.creative_list?.map((crItem: any) => ({
                  ...crItem,
                  is_active: true,
                })),
                is_active: true,
                targeting_compatibility: item?.targeting_compatibility?.map((item: any) => ({
                  name: item,
                  is_active: true,
                })),
              });
            } else {
              tps[findTpIndex] = {
                ...tps[findTpIndex],
                // creative_list: [...tps[findTpIndex]?.creative_list, ...item?.creative_list?.map((crItem: any) => ({
                //   ...crItem,
                //   is_active: true
                // }))]
                // .filter((value: any, index: number, self: any) => {
                // return self.findIndex((v: any) => v.creative_name === value.creative_name) === index;
                // })
                // ,
                is_active: true,
                targeting_compatibility: [
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  ...tps[findTpIndex]?.targeting_compatibility,
                  item?.targeting_compatibility?.map((item: any) => ({
                    name: item,
                    is_active: true,
                  })),
                ],
              };
            }

            let findIndex = null;
            for (let i = 0; i < campaignObjectives?.length; i++) {
              if (campaignObjectives[i]?.campaign_objective === id) {
                findIndex = i;
                break;
              }
            }

            if (findIndex !== null) {
              campaignObjectives[findIndex] = {
                ...campaignObjectives[findIndex],
                creative_list: [
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  ...campaignObjectives[findIndex]?.creative_list,
                  {
                    creative_name: item?.name,
                    creative_name_prev: item?.name,
                    is_active: true,
                  },
                ],
              };
            } else {
              campaignObjectives?.push({
                campaign_objective: id,
                is_active: true,
                creative_list: [
                  {
                    creative_name: item?.name,
                    creative_name_prev: item?.name,
                    is_active: true,
                  },
                ],
              });
            }
          });
        });

        setValue('touchpoints', tps);
        setValue('campaign_objectives', campaignObjectives);

        if (docSnap?.exists()) {
          data = docSnap.data();
        }
        if (docSnapPlatforms?.exists()) {
          // dataPlatforms = docSnapPlatforms.data();
        }
        if (docSnapNC?.exists()) {
          dataNC = docSnapNC.data();
        }

        if (dataNC) {
          const conventions: any = [];
          Object.keys(dataNC)?.map((key) => {
            const separatorCharacter =
              dataNC[key] &&
              Object.prototype.hasOwnProperty.call(dataNC[key], 'Separator character')
                ? dataNC[key]['Separator character']
                : '';
            const separatorCharacterSubtitle =
              dataNC[key] &&
              Object.prototype.hasOwnProperty.call(dataNC[key], 'Separator character subtitle')
                ? dataNC[key]['Separator character subtitle']
                : '';

            if (separatorCharacter) {
              delete dataNC[key]['Separator character'];
              delete dataNC[key]['Separator character subtitle'];
            }

            const keys = Object?.keys(dataNC[key]);
            const campaignItemTypes = keys?.map((key2: any) => {
              if (key2 !== 'default') {
                return {
                  'Campaign Item Type': key2,
                  is_active: true,
                  reference:
                    dataNC[key][key2] && dataNC[key][key2] !== ''
                      ? dataNC[key][key2]?.map((item: any) => ({
                          ...item,
                          is_active: true,
                        }))
                      : [],
                };
              }
            });
            conventions.push({
              Platform: key,
              is_active: true,
              'Separator character': separatorCharacter,
              'Separator character subtitle': separatorCharacterSubtitle,
              campaignItemTypes,
            });
          });

          setValue(
            'conventions',
            conventions?.sort((a: any, b: any) => (a?.Platform > b?.Platform ? 1 : -1)),
          );
        }

        const variable_types: any = [];

        const variable_types_names: any = [];

        if (variableTypesSnapshot?.exists()) {
          const parseData = variableTypesSnapshot.data();
          Object.values(parseData)?.map((data: any) => {
            delete data?.id;
            variable_types_names.push(data?.variable_type);
            variable_types.push({
              ...data,
              is_active: true,
            });
          });
        }

        const variables: any = [];
        if (variablesSnapshot?.exists()) {
          const parseData = variablesSnapshot.data();
          Object.values(parseData)?.map((data: any) => {
            let isFind = false;
            let obj: any = variables?.find(
              (item: any) => item?.select_variable_value === data?.nc_variable_type,
            );

            if (!obj?.select_variable_value) {
              obj = {
                select_variable_value: data?.nc_variable_type,
              };
            } else {
              isFind = true;
            }

            delete data?.id;

            if (data?.parent_values) {
              obj.parent_variable_type = data?.parent_values?.nc_parent_type || 'default';
            }

            if (!data?.parent_values) {
              obj.variable_values = [
                ...(obj.variable_values ? obj.variable_values : []),
                {
                  nc_variable_name: data?.nc_variable_name,
                  variable_name: data?.variable_name,
                  is_active: true,
                },
              ];
            } else {
              obj.variable_values = [
                ...(obj.variable_values ? obj.variable_values : []),
                {
                  nc_variable_name: data?.nc_variable_name,
                  variable_name: data?.variable_name,
                  is_active: true,
                  children_values: data?.parent_values?.parent_values?.map((item: string) => ({
                    parentValue: item,
                    is_active: true,
                  })),
                  // parent_values:
                  //   data?.parent_values?.parent_values ?
                  //     data?.parent_values?.parent_values
                  //       ?.map((item: any) => ({
                  //         is_active: true,
                  //         parentValue: item
                  //       }))
                  //     : []
                },
              ];
            }

            if (!isFind) {
              variables.push({
                ...obj,
                is_active: true,
              });
            }
          });
        }

        const newVariableTypes: any = [];
        for (let i = 0; i < variable_types?.length; i++) {
          let check = false;
          for (let j = 0; j < variables?.length; j++) {
            if (variables[j]?.select_variable_value === variable_types[i]?.nc_variable_type) {
              newVariableTypes?.push(variables[j]);
              check = true;
              break;
            }
          }

          if (!check) {
            newVariableTypes.push({
              select_variable_value: variable_types[i]?.nc_variable_type,
              is_active: true,
            });
          }
        }

        if (variable_types?.length) {
          setValue('creative.variable_types', variable_types);
        }

        if (variables?.length) {
          setValue('creative.variable_values', newVariableTypes);
        }

        // if (dataPlatforms) {
        //   Object.keys(dataPlatforms)?.map((key: any) => {
        //     setValue(key, dataPlatforms[key])
        //   })
        // }

        let keys: any = [];
        if (data) {
          keys = Object.keys(data);
        }
        let platformKeys: any = [];
        if (data?.platforms) {
          platformKeys = Object.keys(data?.platforms);
        }

        keys?.map((key: any) => {
          if (typeof data[key] !== 'object') {
            setValue(key, data[key]);
          }
        });

        platformKeys?.map((key: any, index: number) => {
          delete data?.platforms[key]?.Reports;
          let scope_description = null;
          const setItem: any = {
            Platform: key,
            is_active: true,
          };
          if (Object.keys(data?.platforms[key])?.length) {
            scope_description = Object.keys(data?.platforms[key])[0];
            setItem.scope_description = Object.keys(data?.platforms[key])[0];
          }

          if (scope_description) {
            const scopeDescriptionData = data?.platforms[key][scope_description];
            setItem.client_platform_id = scopeDescriptionData?.name;
          }

          if (scope_description) {
            if (data?.platforms[key][scope_description]?.service_key) {
              setItem.credentials = data?.platforms[key][scope_description]?.service_key;
            }
            if (data?.platforms[key][scope_description]?.service_account) {
              setItem.service_account = data?.platforms[key][scope_description]?.service_account;
            }
            if (data?.platforms[key][scope_description]?.Additions) {
              setItem.Additions = data?.platforms[key][scope_description]?.Additions;
            }
          }
          setValue(`platforms.${index}`, setItem);
        });

        if (data?.reporting_mails) {
          const mailsKeys = Object.keys(data?.reporting_mails);
          const mails: any = [];
          mailsKeys?.map((key: any) => {
            if (typeof data?.reporting_mails[key] === 'object') {
              mails.push({
                ...data?.reporting_mails[key],
                Email: key,
                is_active: true,
              });
            } else {
              mails.push({
                Frequency: data?.reporting_mails[key],
                Email: key,
                is_active: true,
              });
            }
          });
          setValue(
            `reports`,
            mails.sort((a: any, b: any) => (a?.Email > b?.Email ? 1 : -1)),
          );
        }

        if (data?.categories) {
          data?.categories?.map((item: any, index: number) => {
            setValue(`product_categories.${index}`, {
              ...item,
              is_active: true,
            });
          });
        }
      }
    };

    if (partnerData && !campaignFirestore) {
      setCampaign();
    } else {
      prefill().then(() => {
        setLoadPage(false);
      });
    }
  }, [partnerData, campaignFirestore, masterFirestore]);

  useEffect(() => {
    if (saveTabsLoading) {
      const timer1 = setTimeout(() => {
        setSaveTabsLoading(false);
      }, 1500);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [saveTabsLoading]);

  // const checkTps = (): { check: boolean; difference?: string } => {
  //   const tpNames = getValues()?.touchpoints?.map((item: any) => item?.name);
  //   const selectTpNames = getValues()
  //     ?.campaign_objectives?.map((item: any) =>
  //       item?.creative_list?.map((item2: any) => item2?.creative_name),
  //     )
  //     ?.flat(Infinity);

  //   if (selectTpNames?.length !== tpNames?.length) {
  //     let largestArray: Array<string> = [];
  //     let lessArray: Array<string> = [];
  //     if (selectTpNames.length > tpNames?.length) {
  //       largestArray = selectTpNames;
  //       lessArray = tpNames;
  //     } else {
  //       largestArray = tpNames;
  //       lessArray = selectTpNames;
  //     }
  //     const difference = largestArray.filter(
  //       (item: string) => !lessArray.includes(item) && item !== '',
  //     );
  //     return {
  //       check: false,
  //       difference: difference?.join(', '),
  //     };
  //   }
  //   return {
  //     check: true,
  //   };
  // };

  const saveTabs = async (): Promise<void> => {
    // const check = checkTps();
    if (campaignFirestore) {
      setSaveTabsLoading(false);
      const newObj = JSON.parse(JSON.stringify(getValues()));
      newObj.categories_brands = getValues()
        ?.categories_brands?.filter((item: any) => item?.is_active)
        ?.map((item: any) => ({
          ...item,
          platforms: item?.platforms?.filter((item2: any) => item2?.is_active),
        }));
      newObj.divisions = getValues()?.divisions?.filter((item: any) => item?.is_active);
      newObj.platforms = getValues()?.platforms?.filter((item: any) => item?.is_active);
      newObj.creative = {
        variable_types: null,
        variable_values: null,
      };
      newObj.creative.variable_types = getValues()?.creative?.variable_types?.filter(
        (item: any) => item?.is_active,
      );
      newObj.campaign_objectives = getValues()?.campaign_objectives?.filter(
        (item: any) => item?.is_active,
      );
      newObj.touchpoints = getValues()?.touchpoints?.filter((item: any) => item?.is_active);
      newObj.creative.variable_values = getValues()?.creative?.variable_values?.filter(
        (item: any) => item,
      );
      newObj.product_categories = getValues()?.product_categories?.filter(
        (item: any) => item?.is_active,
      );
      newObj.optimisation_settings = getValues()?.optimisation_settings?.filter(
        (item: any) => item?.is_active,
      );
      newObj.reports = getValues()?.reports?.filter((item: any) => item?.is_active);
      newObj.advertisers_safety = getValues()?.advertisers_safety?.filter(
        (item: any) => item?.is_active,
      );
      newObj.global_safety = getValues()?.global_safety?.filter((item: any) => item?.is_active);
      newObj.conventions = getValues()
        ?.conventions?.filter((item: any) => item?.is_active)
        ?.map((item: any) => ({
          ...item,
          campaignItemTypes: item?.campaignItemTypes?.filter((item2: any) => item2?.is_active),
        }))
        ?.map((item: any) => ({
          ...item,
          campaignItemTypes: item?.campaignItemTypes?.map((item2: any) => ({
            ...item2,
            reference: item2?.reference?.filter((item3: any) => item3?.is_active),
          })),
        }));
      const clientObj: any = {
        business_model: newObj?.business_model,
        billable: newObj?.billable,
        fixed_revenue_value: newObj?.fixed_revenue_value,
        is_active: newObj?.is_active,
      };

      if (newObj?.platforms?.length) {
        clientObj.platforms = {};
        for (let i = 0; i < newObj?.platforms?.length; i++) {
          const currPlatform = newObj?.platforms[i];

          if (
            currPlatform?.Platform &&
            currPlatform?.Platform !== 'default' &&
            currPlatform?.scope_description
          ) {
            clientObj.platforms[currPlatform?.Platform] = {
              [currPlatform?.scope_description]: {
                Additions: currPlatform?.Additions || '',
                service_account: currPlatform?.service_account || '',
                name: currPlatform?.client_platform_id || '',
                service_key: {
                  ...(currPlatform?.credentials || {}),
                },
              },
            };
          }
        }
      }
      if (newObj?.reports) {
        clientObj.reporting_mails = {};
        for (let i = 0; i < newObj?.reports?.length; i++) {
          const currReport = JSON.parse(JSON.stringify(newObj?.reports[i]));
          if (currReport?.Email) {
            const email = currReport?.Email;
            delete currReport?.Email;
            clientObj.reporting_mails[email] = { ...currReport };
          }
        }
      }

      if (newObj?.product_categories?.length) {
        clientObj.categories = newObj?.product_categories?.map((item: any) => {
          // if (item?.nc_parent === 'default') {
          return {
            ...item,
            // nc_parent: null
          };
          // } else {
          //   return item
          // }
        });
      }

      const divisionsTest: any = {};

      clientObj.divisions = {};
      newObj?.divisions?.map((item: any) => {
        if (item?.nc_division) {
          const copyItem = JSON.parse(JSON.stringify(item));
          delete copyItem?.is_active;
          divisionsTest[copyItem?.nc_division] = copyItem;
          clientObj.divisions[copyItem?.nc_division] = copyItem;
        }
      });

      const categoriesTest: any = {};

      newObj?.product_categories?.map((item: any) => {
        if (item?.nc_category) {
          const copyItem = JSON.parse(JSON.stringify(item));
          delete copyItem?.is_active;
          categoriesTest[copyItem?.nc_category] = {
            ...copyItem,
            nc_parent: copyItem?.nc_parent,
          };
        }
      });

      const brandsTest: any = {};

      newObj?.categories_brands?.map((item: any) => {
        if (item?.nc_brand) {
          brandsTest[item?.nc_brand] = {};
          brandsTest[item?.nc_brand].Brand = item?.Brand;
          brandsTest[item?.nc_brand].nc_brand = item?.nc_brand;
          brandsTest[item?.nc_brand].division = item?.division;
          brandsTest[item?.nc_brand].sdf_file_type = item?.sdf_file_type;
          brandsTest[item?.nc_brand].sdf_version = item?.sdf_version;

          if (item?.platforms?.length) {
            const platform = item?.platforms[0];
            if (platform?.Platform) {
              brandsTest[item?.nc_brand][`${platform?.Platform?.toLowerCase()}_id`] =
                platform?.brand_id;
            }
          }

          const categories: any = [];
          item?.categories?.map((categItem: any) => {
            if (categItem?.is_active && categItem?.Category !== 'default') {
              categories?.push(categItem?.Category);
            }
          });
          brandsTest[item?.nc_brand].product_categories_refs = categories;
        }
      });

      clientObj.brands = brandsTest;

      const optimisationSettingsTest: any = {};

      newObj?.optimisation_settings?.map((item: any, index: any) => {
        const copyItem = JSON.parse(JSON.stringify(item));
        delete copyItem?.is_active;
        const default_variable_values = copyItem?.default_variable_values;
        const variable_description = copyItem?.variable_description;
        const variable_type = copyItem?.variable_type;
        const superName = `${default_variable_values}_${variable_description}_${variable_type}`;
        const keys = superName?.replace(/_{1,}/g, '_');
        const name = `${keys}_${index}`;
        optimisationSettingsTest[name] = copyItem;
        optimisationSettingsTest[name].variable_name = name;
      });

      const conventionsTest: any = {};
      newObj?.conventions?.map((item: any) => {
        if (item?.Platform) {
          const savePlatform = item?.Platform === 'default' ? 'Global' : item?.Platform;
          conventionsTest[savePlatform] = {
            'Separator character':
              item && Object.prototype.hasOwnProperty.call(item, 'Separator character')
                ? item['Separator character']
                : '',
            'Separator character subtitle':
              item && Object.prototype.hasOwnProperty.call(item, 'Separator character subtitle')
                ? item['Separator character subtitle']
                : '',
          };
          item?.campaignItemTypes?.map((item2: any) => {
            if (item2 && Object.prototype.hasOwnProperty.call(item2, 'Campaign Item Type')) {
              conventionsTest[savePlatform][item2['Campaign Item Type']] = [];
              const refer: any = [];
              item2?.reference?.map((reference: any, index: number) => {
                refer.push({
                  element_order: index + 1,
                  // 'Value reference name': reference?.hasOwnProperty('Value reference')
                  //   ? reference['Value reference'] : null,
                  Variable: reference?.Variable || null,
                  VariableValue: reference?.VariableValue || null,
                  'Default value':
                    reference && Object.prototype.hasOwnProperty.call(reference, 'Default value')
                      ? reference['Default value']
                      : null,
                  is_merge: reference?.is_merge || false,
                  campaign_item_type: item2['Campaign Item Type'],
                  'Allowed values list':
                    reference &&
                    Object.prototype.hasOwnProperty.call(reference, 'Allowed values list')
                      ? reference['Allowed values list']
                      : null,
                });
              });
              if (refer?.length) {
                conventionsTest[savePlatform][item2['Campaign Item Type']] = refer;
              }
            }
          });
        }
      });

      const sdf_default_settings: any = {};

      if (newObj?.advertisers_safety?.length) {
        sdf_default_settings.Advertiser = {};

        for (let i = 0; i < newObj?.advertisers_safety?.length; i++) {
          const item = JSON.parse(JSON.stringify(newObj?.advertisers_safety[i]));
          const id = item?.advertiserId;
          const version = item?.advertiser;
          delete item?.advertiserId;
          delete item?.is_active;
          delete item?.advertiser;
          sdf_default_settings.Advertiser[id] = {
            ...item,
            version,
          };
        }
      }

      if (newObj?.global_safety?.length) {
        // @ts-ignore
        const localName = partnerData?.platforms?.DV360.API?.name;
        sdf_default_settings.Global = {
          // @ts-ignore
          [localName]: {},
        };

        for (let i = 0; i < newObj?.global_safety?.length; i++) {
          const item = JSON.parse(JSON.stringify(newObj?.global_safety[i]));
          delete item?.is_active;
          sdf_default_settings.Global[localName][`${item?.SDF_type}_${i}`] = item;
        }
      }

      //WOOOOOOOORK

      const variablesTest: any = {};
      const variablesTypes: any = {};
      if (newObj?.creative?.variable_values) {
        for (let i = 0; i < newObj.creative.variable_values?.length; i++) {
          const value = JSON.parse(JSON.stringify(newObj.creative.variable_values[i]));

          for (let j = 0; j < value?.variable_values?.length; j++) {
            const variableValuesFirst = JSON.parse(JSON.stringify(value?.variable_values[j]));
            delete value?.is_active;
            delete variableValuesFirst?.is_active;

            if (
              variableValuesFirst?.nc_variable_name &&
              variableValuesFirst?.nc_variable_name !== ''
            ) {
              const hash = sha256.create();
              hash.update(variableValuesFirst?.nc_variable_name);
              hash.hex();
              const stringHash = hash?.toString();

              const { children_values, ...otherValues } = variableValuesFirst;
              variablesTest[stringHash] = {
                ...otherValues,
                nc_variable_type: value?.select_variable_value,
                id: stringHash,
              };

              if (value?.parent_variable_type !== 'default' && value?.parent_variable_type) {
                variablesTest[stringHash].parent_values = {
                  nc_parent_type: value?.parent_variable_type,
                  parent_values:
                    children_values
                      ?.filter((item: any) => item?.is_active)
                      ?.map((item: any) => item?.parentValue) || [],
                };
              }
            }
          }
        }
      }

      if (variablesTest && partnerData?.campaignName) {
        setDoc(doc(campaignFirestore, 'variables', partnerData?.campaignName), variablesTest);
      }

      if (newObj?.creative?.variable_types) {
        for (let i = 0; i < newObj?.creative?.variable_types?.length; i++) {
          const value = JSON.parse(JSON.stringify(newObj.creative.variable_types[i]));
          if (value?.nc_variable_type && value?.is_active) {
            const hash = sha256.create();
            hash.update(value?.nc_variable_type);
            hash.hex();
            const stringHash = hash?.toString();
            delete value?.is_active;

            variablesTypes[stringHash] = {
              id: stringHash,
              ...value,
            };
          }
        }

        if (variablesTypes && partnerData?.campaignName) {
          setDoc(
            doc(campaignFirestore, 'variables_types', partnerData?.campaignName),
            variablesTypes,
          );
        }
      }

      if (newObj?.campaign_objectives) {
        const test_campaign_objectives: any = {};
        for (let i = 0; i < newObj?.campaign_objectives?.length; i++) {
          const copyValue = JSON.parse(JSON.stringify(newObj?.campaign_objectives[i]));

          if (copyValue?.campaign_objective) {
            test_campaign_objectives[copyValue?.campaign_objective] = {};
            const copyConventions = JSON.parse(
              JSON.stringify(conventionsTest?.dv360?.FILE_TYPE_INSERTION_ORDER),
            )?.sort((a: any, b: any) => a.element_order - b.element_order);

            const separator =
              conventionsTest?.dv360 &&
              Object.prototype.hasOwnProperty.call(conventionsTest?.dv360, 'Separator character')
                ? conventionsTest?.dv360['Separator character']
                : '';
            const subSeparator =
              conventionsTest?.dv360 &&
              Object.prototype.hasOwnProperty.call(
                conventionsTest?.dv360,
                'Separator character subtitle',
              )
                ? conventionsTest?.dv360['Separator character subtitle']
                : '';

            if (copyValue?.creative_list) {
              for (let j = 0; j < copyValue?.creative_list?.length; j++) {
                const copyCreativeNames = JSON.parse(JSON.stringify(copyValue?.creative_list[j]));
                if (copyCreativeNames?.creative_name && copyCreativeNames?.is_active) {
                  const findTp = JSON.parse(
                    JSON.stringify(
                      newObj?.touchpoints?.find(
                        (item: any) => item?.name === copyCreativeNames?.creative_name,
                      ) || {},
                    ),
                  );
                  delete findTp?.is_active;
                  const hash = sha256.create();
                  if (findTp?.name) {
                    hash.update(findTp?.name);
                    hash.hex();
                  }
                  const stringHash = hash?.toString();
                  let name = '';
                  for (let k = 0; k < copyConventions?.length; k++) {
                    const variableName = copyConventions[k]?.VariableValue;
                    const currSeparator = copyConventions[k]?.is_merge ? subSeparator : separator;
                    if (findTp && Object.prototype.hasOwnProperty.call(findTp, variableName)) {
                      name += findTp[variableName] + currSeparator;
                    }
                    if (k === copyConventions?.length - 1) {
                      name.slice(0, -1);
                    }
                  }
                  test_campaign_objectives[copyValue?.campaign_objective][
                    copyCreativeNames?.creative_name
                  ] = {
                    ...findTp,
                    nc_touchpoint: stringHash || '',
                    showName: name,
                    creative_list:
                      findTp?.creative_list
                        ?.filter((item: any) => item?.is_active)
                        ?.map((item: any) => {
                          const copyItem = JSON.parse(JSON.stringify(item));
                          delete copyItem?.is_active;
                          return copyItem;
                        }) || [],
                    targeting_compatibility:
                      findTp?.targeting_compatibility
                        ?.filter((item: any) => item?.is_active)
                        ?.map((item: any) => item?.name) || [],
                  };
                }
              }
            }
          }
        }

        Object?.keys(test_campaign_objectives)?.map((key: any) => {
          // setDoc(doc(firestore, 'campaign-objectives', key), test_campaign_objectives[key]);
          setDoc(doc(campaignFirestore, 'campaign-objectives', key), test_campaign_objectives[key]);
        });
      }

      Object?.keys(optimisationSettingsTest)?.map((key: any) => {
        setDoc(doc(campaignFirestore, 'optimisation-settings', key), optimisationSettingsTest[key]);
      });

      // if (clientObj && partnerData?.campaignName && masterFirestore) {
      //   const saveObj = { ...removeEmpty(clientObj) };
      //   const newSaveObj = JSON.parse(JSON.stringify(saveObj));
      //   delete newSaveObj?.divisions;
      //   delete newSaveObj?.brands;
      // setDoc(doc(masterFirestore, 'clients-test', partnerData?.campaignName), newSaveObj);

      // if (saveObj?.brands) {
      //   Object.keys(saveObj?.brands)?.map(async (key: any) => {
      //     const brandsRef = doc(
      //       masterFirestore,
      //       'clients-test',
      //       partnerData?.campaignName,
      //       'brands',
      //       key,
      //     );
      //     setDoc(brandsRef, saveObj?.brands[key]);
      //   });
      // }

      // if (sdf_default_settings) {
      //   Object.keys(sdf_default_settings)?.map(async (key: any) => {
      //     const sdfDefaultSettingsRef = doc(
      //       masterFirestore,
      //       'clients-test',
      //       partnerData?.campaignName,
      //       'sdf_default_settings',
      //       key,
      //     );
      //     setDoc(sdfDefaultSettingsRef, sdf_default_settings[key]);
      //   });
      // }

      // if (saveObj?.categories) {
      //   saveObj?.categories?.map(async (item: any) => {
      //     const brandsRef = doc(
      //       masterFirestore,
      //       'clients-test',
      //       partnerData?.campaignName,
      //       'categories',
      //       item?.nc_category,
      //     );
      //     setDoc(brandsRef, item);
      //   });
      // }

      // if (saveObj?.divisions) {
      //   Object.keys(saveObj?.divisions)?.map(async (key: any) => {
      //     const brandsRef = doc(
      //       masterFirestore,
      //       'clients-test',
      //       partnerData?.campaignName,
      //       'divisions',
      //       key,
      //     );
      //     setDoc(brandsRef, saveObj?.divisions[key]);
      //   });
      // }
      // }

      if (conventionsTest) {
        setDoc(doc(campaignFirestore, 'naming-conventions', 'definitions'), {
          ...removeEmpty(conventionsTest),
        });
        setSaveTabsLoading(true);
      }
    }
    // else {
    //   setModalSubtext(check?.difference || '');
    //   setIsShowModal(true);
    //   setActiveTab('Campaign objectives')
    // }
  };

  return (
    <div>
      <ModalWindow
        isShowModal={isShowModal}
        setIsShow={setIsShowModal}
        description={`Please define these touchpoints on the Campaign Objective tab: \n`}
        subDescription=""
        title={'Select all touchpoints'}
      />
      {loadPage ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <FilterNavigation
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={TABS}
            isShowScrollButtons={true}
          />
          <GetTabs
            watch={watch}
            activeTab={activeTab}
            register={register}
            getValues={getValues}
            control={control}
            setValue={setValue}
            setSaveDisables={setSaveDisables}
          />
          <button
            className={cn('mt-2 ms-auto btn btn-primary d-block py-1 px-4', styles.saveButton)}
            onClick={saveTabs}>
            Save{saveTabsLoading && <CheckIcon />}
          </button>
        </>
      )}
    </div>
  );
};

export default PlatformsBrandsProductsSetup;
