import React, { FC, useEffect } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { Control, useWatch } from 'react-hook-form';
import cn from 'classnames';
import styles from '../PlatformsBrandsProductsSetup.module.scss';
import { FormControl } from 'react-bootstrap';
import CopyIcon from '../../UI/icons/CopyIcon';
interface Props {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  getValues: UseFormGetValues<any>;
  removeItem: (name: string, index: number) => void;
  setSaveDisables: any;
}

const GetCampaignObjectives: FC<Props> = ({
  control,
  setValue,
  getValues,
  register,
  removeItem,
  setSaveDisables,
}): JSX.Element => {
  const campaignObjectives = useWatch({ control, name: `campaign_objectives` });
  useEffect(() => {}, []);

  const getCreativeNames = (mainName: string, name: string, index: number, parentIndex: number) => {
    const prevTpValue = getValues(`${name}.${index}.creative_name_prev`);

    // const filterUnique = () => {
    //   const selectedValues = getValues(`${mainName}.${parentIndex}.creative_list`)
    //     ?.filter((item: any, index2: number) => item?.is_active && index2 !== index)
    //     ?.map((item: any) => item?.creative_name)
    //   const allValues = getValues('touchpoints')?.map((item: any) => item?.name);
    //
    //   const filteredValues = allValues?.filter((item: any) => !selectedValues?.includes(item) && item && item !== '');
    //   return filteredValues;
    // }

    const getValuesByName = (compareName: string): Array<string> => {
      return getValues(name)?.filter((item: any) => item?.creative_name === compareName);
    };

    const checkIsExistCreativeName = (): boolean => {
      const currentName = getValues(`${name}.${index}.creative_name`);
      const searchVal = getValuesByName(currentName);
      if (searchVal?.length > 1) {
        setSaveDisables(true);
      }
      // setSaveDisables
      return searchVal?.length > 1;
    };

    const removeCreativeName = (removeName: string, index: number) => {
      const currentName = getValues(`${name}.${index}.creative_name`);
      const searchVal = getValuesByName(currentName);
      if (searchVal?.length === 1) {
        const copyTps = JSON.parse(JSON.stringify(getValues('touchpoints')));
        const newTps = copyTps?.filter((item: any) => item?.name !== currentName);
        // newTps.unshift(1)
        setValue('touchpoints', newTps);
      }
      setSaveDisables(false);
      // checkIsExistCreativeName()
      removeItem(name, index);
    };

    // const CopyCreativeName = (CopyName: string, index: number) => {
    //   const currentName = getValues(`${name}.${index}.creative_name`);
    //   const searchVal = getValuesByName(currentName);
    //   // console.log('searchVal',searchVal)
    //   if (searchVal?.length === 1) {
    //     const copyTps = JSON.parse(JSON.stringify(getValues('touchpoints')))
    //     const newTps = copyTps?.find((item: any) => item?.name === currentName);
    //     const hash = sha256.create();
    //     hash.update(`${currentName} (new)`);
    //     hash.hex();
    {
      /*    const stringHash = hash?.toString();*/
    }
    {
      /*    const newCopyTPS = {*/
    }
    {
      /*      ...newTps,*/
    }
    {
      /*      name:`${currentName} (new)`,*/
    }
    //       nc_touchpoint:stringHash
    //     }
    //     copyTps.push(newCopyTPS)
    //     // console.log('ппппп',getValues(`${name}`).length)
    //     setValue('touchpoints', copyTps);
    //     setValue(`${name}.${getValues(`${name}`).length-1}`, { creative_name: newCopyTPS.name, is_active: true})
    //     // `campaign_objectives.${index}.creative_list`
    //   }
    //   // copyItem(name, index)
    // }
    const CopyCreativeName = (CopyName: string, index: number) => {
      const currentName = getValues(`${name}.${index}.creative_name`);
      const searchVal = getValuesByName(currentName);
      // console.log('searchVal',searchVal)
      if (searchVal?.length === 1) {
        const copyTps = JSON.parse(JSON.stringify(getValues('touchpoints')));
        const newTps = copyTps?.find((item: any) => item?.name === currentName);
        // const hash = sha256.create();
        // hash.update(`${currentName} (new)`);
        // hash.hex();
        // const stringHash = hash?.toString();
        const allTps = getValues('touchpoints')?.map((item: any) => item?.name);
        const newName = `${currentName} (new)`;
        const newCopyTPS = {
          ...newTps,
          name: newName,
          // nc_touchpoint:stringHash
        };

        if (!allTps?.includes(newName)) {
          copyTps.push(newCopyTPS);
          setValue('touchpoints', copyTps);
        }
        setValue(`${name}.${getValues(`${name}`).length - 1}`, {
          creative_name: newCopyTPS.name,
          creative_name_prev: newName,
          is_active: true,
        });
        // `campaign_objectives.${index}.creative_list`
      }
      // copyItem(name, index)
    };
    return (
      <React.Fragment key={`campaign_objectives_${name}_${index}`}>
        <div className={'row mx-2 mt-4'}>
          <div className={'col-6'}>
            <FormControl
              placeholder="Creative name"
              aria-label="Creative name"
              type={'text'}
              className={checkIsExistCreativeName() ? 'border-danger' : ''}
              {...register(`${name}.${index}.creative_name`)}
              onChange={(e: any) => {
                const value = e?.target?.value;
                setValue(`${name}.${index}.creative_name`, value);
                setSaveDisables(checkIsExistCreativeName());
              }}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                let findTpIndex: any = null;

                getValues(`touchpoints`)?.find((item: any, index: number) => {
                  if (item?.name === prevTpValue) {
                    findTpIndex = index;
                  }
                });

                if (findTpIndex !== null) {
                  setValue(`touchpoints.${findTpIndex}.name`, value);
                } else {
                  const isExist = getValues(`touchpoints`)?.find(
                    (item: any) => item?.name === value,
                  );
                  if (!isExist) {
                    const lgt = getValues(`touchpoints`)?.length;
                    setValue(`touchpoints.${lgt}`, {
                      name: value,
                      is_active: true,
                    });
                  }
                }
                setValue(`${name}.${index}.creative_name`, value);
                setValue(`${name}.${index}.creative_name_prev`, value);
                if (value && value != '') {
                  setValue(`${name}.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />

            {checkIsExistCreativeName() ? (
              <p className={'text-danger'}>Please rename identical touchpoints</p>
            ) : null}
            {/*<select*/}
            {/*  className="form-select col-3 gap-2"*/}
            {/*  aria-label="Creative name"*/}
            {/*  value={getValues(`${name}.${index}.creative_name`)}*/}
            {/*  defaultValue='default'*/}
            {/*  onChange={(e: any) => {*/}
            {/*    setValue(`${name}.${index}.creative_name`, e?.target?.value)*/}
            {/*    setValue(`${name}.${index}.is_active`, true, {shouldValidate: true})*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <option value="default">Creative name</option>*/}
            {/*  {*/}
            {/*    filterUnique()*/}
            {/*      ?.sort()*/}
            {/*      ?.map((rt: string, index2: number) =>*/}
            {/*      <option key={`${name}_${index}_${index2}`} value={rt}>{rt}</option>*/}
            {/*    )*/}
            {/*  }*/}
            {/*</select>*/}
          </div>
          <div className={'col-1 d-flex align-items-center'}>
            {campaignObjectives?.length >= parentIndex &&
            campaignObjectives[parentIndex]?.creative_list?.length >= index &&
            campaignObjectives[parentIndex]?.creative_list[index]?.is_active ? (
              /*this is test filed*/
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() => removeCreativeName(name, index)}
              />
            ) : null}
            {campaignObjectives?.length >= parentIndex &&
            campaignObjectives[parentIndex]?.creative_list?.length >= index &&
            campaignObjectives[parentIndex]?.creative_list[index]?.is_active ? (
              /*this is test filed*/

              <button
                className={cn('d-flex p-0 align-self-center ms-2', styles.copy)}
                disabled={checkIsExistCreativeName()}
                onClick={() => CopyCreativeName(name, index)}>
                <CopyIcon />
              </button>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getFields = (index: number) => {
    const getCampaignObjectives = () => {
      const allValues = getValues('campaign_objectives')?.map(
        (item: any) => item?.campaign_objective,
      );
      const currentValue = getValues(`campaign_objectives.${index}.campaign_objective`);

      const kpis: any = [];
      getValues(`creative`)
        ?.variable_values?.find(
          (item: any) => item?.select_variable_value === 'nc_campaign_objective',
        )
        ?.variable_values?.filter((item: any) => item?.is_active)
        ?.map((item: any) => {
          if (
            item?.nc_variable_name &&
            item?.variable_name &&
            (!allValues?.includes(item?.variable_name) || currentValue === item?.variable_name)
          ) {
            kpis.push({
              id: item?.nc_variable_name,
              name: item?.variable_name,
            });
          }
        });

      return kpis;
    };

    return (
      <React.Fragment key={`campaign_objectives_${index}`}>
        <div className={'row mt-4'}>
          {/*<div className={'col-3'}>*/}
          {/*  <FormControl*/}
          {/*    placeholder="Campaign objective"*/}
          {/*    aria-label="Campaign objective"*/}
          {/*    type={'text'}*/}
          {/*    {...register(`campaign_objectives.${index}.name`)}*/}
          {/*    onBlur={(e: any) => {*/}
          {/*      const value = e?.target?.value;*/}
          {/*      if (value && value != '') {*/}
          {/*        setValue(`campaign_objectives.${index}.is_active`, true, {shouldValidate: true})*/}
          {/*      }*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={'col-3'}>*/}
          {/*  <FormControl*/}
          {/*    placeholder="nc_campaign_objective"*/}
          {/*    aria-label="nc_campaign_objective"*/}
          {/*    type={'text'}*/}
          {/*    {...register(`campaign_objectives.${index}.nc_campaign_objective`)}*/}
          {/*    onBlur={(e: any) => {*/}
          {/*      const value = e?.target?.value;*/}
          {/*      if (value && value != '') {*/}
          {/*        setValue(`campaign_objectives.${index}.is_active`, true, {shouldValidate: true})*/}
          {/*      }*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className={'col-5'}>
            <select
              {...register(`campaign_objectives.${index}.campaign_objective`)}
              className="form-select col-3 gap-2"
              aria-label="Campaign objective"
              defaultValue="default"
              value={getValues(`campaign_objectives.${index}.campaign_objective`) || 'default'}
              onChange={(e: any) => {
                setValue(`campaign_objectives.${index}.campaign_objective`, e?.target?.value);
                setValue(`campaign_objectives.${index}.is_active`, true);
              }}>
              <option value="default">Campaign objective</option>
              {getCampaignObjectives()
                ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
                ?.map((co: any, index2: number) => (
                  <option key={`campaign_objectives_${index}_${index2}`} value={co?.name}>
                    {co?.name}
                  </option>
                ))}
            </select>
          </div>

          <div className={'col-1 d-flex align-items-center'}>
            {campaignObjectives?.length >= index && campaignObjectives[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() => removeItem('campaign_objectives', index)}
              />
            ) : null}
          </div>
        </div>
        {campaignObjectives?.length >= index
          ? campaignObjectives[index]?.creative_list
              ?.filter((itemCreativeName: any) => itemCreativeName?.is_active)
              ?.map((item: any, index2: number) => {
                return getCreativeNames(
                  'campaign_objectives',
                  `campaign_objectives.${index}.creative_list`,
                  index2,
                  index,
                );
              })
          : null}
        {getCreativeNames(
          'campaign_objectives',
          `campaign_objectives.${index}.creative_list`,
          campaignObjectives?.length >= index + 1 && campaignObjectives[index]?.creative_list
            ? campaignObjectives[index]?.creative_list?.filter((itemCreativeName: any) => {
                return (
                  itemCreativeName &&
                  Object.prototype.hasOwnProperty.call(itemCreativeName, 'is_active') &&
                  itemCreativeName.is_active
                );
              })?.length
            : 0,
          index,
        )}
      </React.Fragment>
    );
  };

  return (
    <div className={'row'}>
      <p className="mt-3 col-12">Campaign objectives</p>
      {campaignObjectives?.length
        ? campaignObjectives
            ?.filter((itemCampaignObjectives: any) => itemCampaignObjectives?.is_active)
            ?.map((item: any, index: number) => {
              return getFields(index);
            })
        : null}
      {getFields(
        campaignObjectives?.filter(
          (itemCampaignObjectives: any) =>
            itemCampaignObjectives &&
            Object.prototype.hasOwnProperty.call(itemCampaignObjectives, 'is_active') &&
            itemCampaignObjectives.is_active,
        )?.length || 0,
      )}
    </div>
  );
};

export default GetCampaignObjectives;
