import React, { FC, useMemo } from 'react';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { IAdsSetupForm } from '../../../types/AdsSetupForm';
import { SelectedLineItems } from '../AdsSetup';
import { Control } from 'react-hook-form';
import LineItemLine from '../LineItemLine/LineItemLine';
import TouchpointLine from '../TouchpointLine/TouchpointLine';
interface Props {
  getValues: UseFormGetValues<IAdsSetupForm>;
  control: Control<IAdsSetupForm>;

  selectedLineItems: SelectedLineItems;
  setSelectedLineItems: React.Dispatch<React.SetStateAction<SelectedLineItems>>;
}

const SelectTouchpoint: FC<Props> = ({
  getValues,
  selectedLineItems,
  setSelectedLineItems,
  control,
}) => {
  // static data of touchpoints is used here, it can be read only once
  const touchpoints = useMemo(() => getValues('touchpoints'), [getValues]);

  const isTpSelected = (tIndex: number) => {
    const liCount = selectedLineItems.filter(
      (selected) => selected.touchpointIndex === tIndex,
    ).length;

    return liCount === touchpoints[tIndex].lineItems.length;
  };
  const isLiSelected = (tIndex: number, liIndex: number) =>
    selectedLineItems.some(
      (selected) => selected.lineItemIndex === liIndex && selected.touchpointIndex === tIndex,
    );

  const handleTouchpointClick = (tIndex: number) => {
    const liCount = selectedLineItems.filter(
      (selected) => selected.touchpointIndex === tIndex,
    ).length;

    if (liCount === touchpoints[tIndex].lineItems.length) {
      // unselect all line items of a given touchpoint
      const newState = selectedLineItems.filter((selected) => selected.touchpointIndex !== tIndex);
      setSelectedLineItems(newState);
    } else {
      // select all remaining line items of a given touchpoint
      const toAdd = touchpoints[tIndex].lineItems
        .map((lineItem, lineItemIndex) => {
          const selected = selectedLineItems.some(
            (selectedLi) =>
              selectedLi.touchpointIndex === tIndex && selectedLi.lineItemIndex === lineItemIndex,
          );
          if (selected) {
            return null;
          }

          return {
            touchpointIndex: tIndex,
            lineItemIndex,
          };
        })
        .filter((el): el is SelectedLineItems[number] => el != null);
      setSelectedLineItems((prev) => [...prev, ...toAdd]);
    }
  };

  const handleLineItemClick = (tIndex: number, liIndex: number) => {
    const index = selectedLineItems.findIndex(
      (selected) => selected.lineItemIndex === liIndex && selected.touchpointIndex === tIndex,
    );

    if (index === -1) {
      setSelectedLineItems((prev) => [
        ...prev,
        { touchpointIndex: tIndex, lineItemIndex: liIndex },
      ]);
    } else {
      const newArray = [...selectedLineItems];
      newArray.splice(index, 1);
      setSelectedLineItems(newArray);
    }
  };

  return (
    <div className={'row gy-1'}>
      {touchpoints.map((item, tIndex) => {
        return (
          <React.Fragment key={tIndex}>
            <TouchpointLine
              selected={isTpSelected(tIndex)}
              onClick={() => handleTouchpointClick(tIndex)}
              tIndex={tIndex}
              getValues={getValues}
              touchpoint={item}
            />
            {item.lineItems.map((lineItem, liIndex) => (
              <LineItemLine
                key={liIndex}
                control={control}
                lineItem={lineItem}
                touchpointIndex={tIndex}
                lineItemIndex={liIndex}
                onClick={() => handleLineItemClick(tIndex, liIndex)}
                selected={isLiSelected(tIndex, liIndex)}
              />
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SelectTouchpoint;
