import React, { FC } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { Control, useWatch } from 'react-hook-form';
import styles from '../PlatformsBrandsProductsSetup.module.scss';

interface Props {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  getValues: UseFormGetValues<any>;
  removeItem: (name: string, index: number) => void;
  currentTouchpoint: string | number | null;
  setCurrentTouchpoint: (state: string | number | null) => void;
}

const GetTouchpointCompatibility: FC<Props> = ({
  control,
  setValue,
  getValues,
  register,
  removeItem,
  currentTouchpoint,
  setCurrentTouchpoint,
}): JSX.Element => {
  const touchpoints = useWatch({ control, name: `touchpoints` });

  const getExcludeTouchpoints = (index: number) => {
    const getShowTouchpoints = () => {
      const currentValue = getValues(
        `touchpoints.${currentTouchpoint}.targeting_compatibility.${index}.name`,
      );
      const currentTouchpoints = getValues(
        `touchpoints.${currentTouchpoint}.targeting_compatibility`,
      )
        ?.filter((item: any) => item?.is_active)
        ?.map((item: any) => item?.name);
      const allTouchpoints = touchpoints
        ?.filter((item: any) => item?.name !== '' && item?.name !== 'default')
        ?.map((item: any) => item?.name);

      return allTouchpoints?.filter(
        (item: any) => !currentTouchpoints?.includes(item) || item === currentValue,
      );
    };

    return (
      <React.Fragment key={`exclude_touchpoints_${currentTouchpoint}_${index}`}>
        <div className={'row mt-4'}>
          <div className={'col-5'}>
            <select
              {...register(
                `touchpoints.${currentTouchpoint}.targeting_compatibility.${index}.name`,
              )}
              className="form-select col-3 gap-2"
              aria-label="Touchpoint"
              defaultValue="default"
              value={getValues(
                `touchpoints.${currentTouchpoint}.targeting_compatibility.${index}.name`,
              )}
              onChange={(e: any) => {
                setValue(
                  `touchpoints.${currentTouchpoint}.targeting_compatibility.${index}.name`,
                  e?.target?.value,
                );
                setValue(
                  `touchpoints.${currentTouchpoint}.targeting_compatibility.${index}.is_active`,
                  true,
                );
              }}>
              <option value="default">Touchpoint</option>
              {getShowTouchpoints()
                ?.sort()
                ?.map((item: any, index2: number) =>
                  item !== '' && item !== 'default' ? (
                    <option
                      key={`exclude_touchpoints_kpi_${currentTouchpoint}_${index}_${index2}`}
                      value={item}>
                      {item}
                    </option>
                  ) : null,
                )}
            </select>
          </div>
          <div className={'col-1 d-flex align-items-center'}>
            {currentTouchpoint &&
            touchpoints?.length >= currentTouchpoint &&
            touchpoints[currentTouchpoint]?.targeting_compatibility?.length >= index &&
            touchpoints[currentTouchpoint]?.targeting_compatibility[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() =>
                  removeItem(`touchpoints.${currentTouchpoint}.targeting_compatibility`, index)
                }
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className={'row'}>
        <div className={'col-5'}>
          <select
            className="form-select col-3 gap-2"
            aria-label="KPI - Type"
            defaultValue="default"
            value={currentTouchpoint !== null ? currentTouchpoint : 'default'}
            onChange={(e: any) => {
              setCurrentTouchpoint(e?.target?.value);
            }}>
            <option value="default">Touchpoint</option>
            {touchpoints
              ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
              ?.map((item: any, index2: number) =>
                item?.is_active ? (
                  <option key={`touchpoints_compatibility${index2}`} value={index2}>
                    {item?.name}
                  </option>
                ) : null,
              )}
          </select>
        </div>

        <p className={'fw-bold mx-2'}>Compatible touchpoints</p>
        {currentTouchpoint ? (
          <>
            {currentTouchpoint && touchpoints?.length >= currentTouchpoint
              ? touchpoints[currentTouchpoint]?.targeting_compatibility
                  ?.filter((item: any) => item?.is_active)
                  ?.map((item: any, index: number) => {
                    return getExcludeTouchpoints(index);
                  })
              : null}
            {getExcludeTouchpoints(
              currentTouchpoint && touchpoints?.length >= currentTouchpoint
                ? touchpoints[currentTouchpoint]?.targeting_compatibility?.filter(
                    (item: any) => item?.is_active,
                  )?.length || 0
                : 0,
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default GetTouchpointCompatibility;
