import cn from 'classnames';
import styles from './TargetGroupCheckboxes.module.scss';
import React, { FC, RefObject } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm, IVariants } from '../../../types/CreateCampaignForm';
import { TargetGroupForVariant } from '../../../types/TargetGroup';
import { Coordinates } from '../../../types/Coordinates';
import { VariantCheckbox } from './VariantCheckbox/VariantCheckbox';
import { UpdateCheckboxesFunction } from '../VariantTargetingForm/VariantTargetingForm';

type Props = {
  targetGroup: TargetGroupForVariant;
  register: UseFormRegister<ICreateCampaignForm>;
  selectedSet: number;
  variants: IVariants;
  coordinatesRef: RefObject<Coordinates>;
  wrapperRef: RefObject<HTMLDivElement>;
  clicked: boolean;
  updateCheckboxes: UpdateCheckboxesFunction;
};
export const TargetGroupCheckboxes: FC<Props> = ({
  targetGroup,
  register,
  selectedSet,
  variants,
  coordinatesRef,
  wrapperRef,
  clicked,
  updateCheckboxes,
}) => {
  return (
    <div className={styles.checkboxesContainer}>
      {targetGroup.values.map((targetingName: string, targetingIndex: number) => (
        <div key={targetingName} className={cn('d-flex flex-direction-row')}>
          {variants.map((variant, variantIndex) => {
            return (
              <VariantCheckbox
                key={`${targetGroup.type}-${targetGroup.subType}-${targetingName}_${variantIndex}_${variant.uniqId}`}
                register={register}
                selectedSet={selectedSet}
                targetGroup={targetGroup}
                targetingIndex={targetingIndex}
                variantIndex={variantIndex}
                coordinatesRef={coordinatesRef}
                wrapperRef={wrapperRef}
                clicked={clicked}
                updateCheckboxes={updateCheckboxes}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};
