import { gql } from '../__generated__';
import { ICreateCampaignSetPartial } from '../../types/CreateCampaignForm';
import { CampaignSetFragment } from '../__generated__/graphql';

export const CAMPAIGN_SET_FRAGMENT = gql(`
    fragment CampaignSet on CampaignSet {
        uniqId
        goals
        touchpoints {
            name
        }
        creatives
        name
    }
`);

export const campaignSetsFragmentToSets = (
  campaignSets: CampaignSetFragment[],
): ICreateCampaignSetPartial[] => {
  return (
    campaignSets.map((set) => ({
      uniqId: set.uniqId,
      goal: set.goals.length !== 0 ? set.goals[0] : undefined,
      touchpoints: getTouchpoints(set.touchpoints),
      creatives: set.creatives,
      name: set.name,
    })) || []
  );
};

const getTouchpoints = (touchpoints: CampaignSetFragment['touchpoints']) => {
  return touchpoints
    .filter((el) => el.name != null)
    .map((el) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return { name: el.name!, frequency: undefined };
    });
};
