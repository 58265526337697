import React, { FC } from 'react';
import Sets from '../CampaignGoals/CampaignGoalsSetup/Sets/Sets';
import { useFieldArray, UseFormGetValues } from 'react-hook-form';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../types/CreateCampaignForm';

type Props = {
  control: Control<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  onSetChange: (index: number) => void;
};

export const SelectSet: FC<Props> = ({ control, getValues, setValue, register, onSetChange }) => {
  const {
    fields: sets,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'sets',
  });

  return (
    <Sets
      setSelectedSet={onSetChange}
      register={register}
      getValues={getValues}
      setValue={setValue}
      control={control}
      sets={sets}
      append={append}
      remove={remove}
      withMultiple={false}
      withCopy={false}
    />
  );
};
