import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { CREATE_TOUCHPOINT_NAME_QUERY } from '../../../../../../graphql/queries/CreateTouchpointNameQuery';
import { useGraphQLError } from '../../../../../../hooks/useGraphQLError';
import HorizontalLoader from '../../../../../UI/Loader/HorizontalLoader';

interface Props {
  campaignId: string;
  setId: string;
  touchpointId: string;
  partnerId?: string;
}

const TouchpointNameRow: FC<Props> = ({
  campaignId,
  setId,
  touchpointId,
  partnerId,
}): JSX.Element => {
  const { data, loading, error } = useQuery(CREATE_TOUCHPOINT_NAME_QUERY, {
    variables: {
      campaignId: campaignId,
      setId: setId,
      touchpointId: touchpointId,
      partnerId: partnerId,
    },
  });

  useGraphQLError([error]);

  if (loading) {
    return <HorizontalLoader />;
  }

  return <span className={'col-12 text-break ms-2'}>{data?.touchpointName}</span>;
};

export default React.memo(TouchpointNameRow);
