import React, { FC } from 'react';
import { FormControl } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { Control, Controller, useWatch } from 'react-hook-form';
import cn from 'classnames';
import styles from '../PlatformsBrandsProductsSetup.module.scss';
import UploadBlock from '../../UI/UploadBlock/UploadBlock';

interface Props {
  register: UseFormRegister<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  removeItem: (name: string, index: number) => void;
}

const BUSINESS_MODEL = ['License', 'Revenue share'];
export const PLATFORMS = ['Firebase', 'DoubleClick', 'DV360', 'AdForm'];

const GetClientSetup: FC<Props> = ({
  register,
  control,
  getValues,
  setValue,
  removeItem,
}): JSX.Element => {
  const platforms = useWatch({ control, name: `platforms` });

  const onUploadCredential = async (event: any, name: string) => {
    const file_to_read = event.target.files[0];
    const fileread = new FileReader();
    fileread.onload = (e: any) => {
      const content = e.target.result;
      const values = JSON.parse(content);
      setValue(`${name}.credentials`, values);
    };
    fileread.readAsText(file_to_read);
  };

  const getCurrentPlatforms = (index: number) => {
    return getValues()
      ?.platforms?.map((item: any) => item?.Platform)
      .filter((_: any, index2: any) => index !== index2);
  };

  const getPlatform = (index: number): JSX.Element => {
    //тест
    const getAllPlatforms = () => {
      const newPlatforms = [...PLATFORMS];
      const copyPlatforms = getValues()?.platforms?.map((item: any) => item?.Platform);
      for (let i = 0; i < copyPlatforms?.length; i++) {
        if (!newPlatforms?.includes(copyPlatforms[i])) {
          newPlatforms?.push(copyPlatforms[i]);
        }
      }

      return newPlatforms;
    };

    return (
      <div key={`platform_${index}`} className={'row mt-4'}>
        <div className={'row'}>
          <div className={'col-3'}>
            <select
              {...register(`platforms.${index}.Platform`)}
              className="form-select col-3 gap-2"
              aria-label="Platform"
              defaultValue="default"
              value={getValues(`platforms.${index}.Platform`)}
              onChange={(e: any) => {
                setValue(`platforms.${index}.Platform`, e?.target?.value);
              }}>
              <option value="default">Platform</option>
              {getAllPlatforms()
                ?.sort()
                ?.map((platform: string, index2: number) => {
                  if (!getCurrentPlatforms(index)?.includes(platform)) {
                    return (
                      <option key={`platform_${index2}`} value={platform}>
                        {platform}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
          <div className={'col-3'}>
            <Controller
              control={control}
              name={`platforms.${index}.scope_description`}
              defaultValue=""
              render={({ field: { onChange } }) => (
                <FormControl
                  placeholder="Scope description"
                  aria-label="Scope description"
                  defaultValue={getValues(`platforms.${index}.scope_description`)}
                  type={'text'}
                  onBlur={(e: any) => {
                    const value = e?.target?.value;
                    if (value && value != '') {
                      setValue(`platforms.${index}.is_active`, true, { shouldValidate: true });
                      onChange(e);
                    }
                  }}
                />
              )}
            />
          </div>
          <div className={'col-3'}>
            <Controller
              control={control}
              name={`platforms.${index}.client_platform_id`}
              defaultValue=""
              render={({ field: { onChange } }) => (
                <FormControl
                  placeholder="Client platform Id"
                  aria-label="Client platform Id"
                  type={'text'}
                  defaultValue={getValues(`platforms.${index}.client_platform_id`)}
                  onBlur={(e: any) => {
                    const value = e?.target?.value;
                    if (value && value != '') {
                      setValue(`platforms.${index}.is_active`, true, { shouldValidate: true });
                      onChange(e);
                    }
                  }}
                />
              )}
            />
          </div>
          <div className={'row col-2'}>
            <Controller
              control={control}
              name={`platforms.${index}.service_account`}
              defaultValue=""
              render={({ field: { onChange } }) => (
                <FormControl
                  placeholder="Platform service account"
                  aria-label="Platform service account"
                  type={'text'}
                  defaultValue={getValues(`platforms.${index}.service_account`)}
                  onBlur={(e: any) => {
                    const value = e?.target?.value;
                    if (value && value != '') {
                      setValue(`platforms.${index}.is_active`, true, { shouldValidate: true });
                      onChange(e);
                    }
                  }}
                />
              )}
            />
          </div>

          <div className={'col-1 d-flex align-items-center'}>
            {platforms?.length >= index && platforms[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() => removeItem('platforms', index)}
              />
            ) : null}
          </div>
        </div>

        <div className={'row mt-3'}>
          <div className={'col-3'} />
          <div className={'col-6'}>
            <Controller
              control={control}
              name={`platforms.${index}.Additions`}
              defaultValue=""
              render={({ field: { onChange } }) => (
                <textarea
                  className={cn('w-100 h-100', styles.additions)}
                  placeholder={'Additions'}
                  defaultValue={getValues(`platforms.${index}.Additions`)}
                  onBlur={(e: any) => {
                    const value = e?.target?.value;
                    if (value && value != '') {
                      setValue(`platforms.${index}.is_active`, true, { shouldValidate: true });
                      onChange(e);
                    }
                  }}
                />
              )}
            />
          </div>
          <UploadBlock
            customText={'Upload credential files json'}
            accept={'application/JSON'}
            withDefault={false}
            onUpload={(e: any) => {
              setValue(`platforms.${index}.is_active`, true, { shouldValidate: true });
              onUploadCredential(e, `platforms.${index}`);
            }}
            className={{
              mainContainer: 'max-w-imp _1000 max-h _200',
              targetInfo: styles.targetInfo,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={'row'}>
        <div className={'row'}>
          <div className={'col-3'}>
            <select
              {...register('business_model')}
              className="form-select col-3 gap-2"
              aria-label="Business model"
              defaultValue="default">
              <option value="default">Business model</option>
              {BUSINESS_MODEL?.sort()?.map((model: string, index: number) => {
                return (
                  <option key={`business_model${index}`} value={model}>
                    {model}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={'col-3'}>
            <FormControl
              placeholder="Reference value"
              aria-label="Reference value"
              type={'number'}
              {...register('fixed_revenue_value', { required: true })}
            />
          </div>
          <div className={'row col-4 align-items-center mx-2'}>
            <Form.Check
              className={'col-6'}
              label={'Active'}
              type={'checkbox'}
              // checked={getValues()?.is_active}
              {...register('is_active')}
              aria-label={'Active'}
            />
            <Form.Check
              className={'col-6'}
              label={'Billable'}
              type={'checkbox'}
              // checked={getValues()?.billable}
              {...register('billable')}
              aria-label={'Billable'}
            />
          </div>
        </div>
      </div>

      {platforms?.length
        ? platforms
            ?.filter((itemPlatform: any) => itemPlatform?.is_active)
            ?.map((item: any, index: number) => {
              return getPlatform(index);
            })
        : null}
      {getPlatform(
        platforms?.filter(
          (itemPlatform: any) =>
            itemPlatform &&
            Object.prototype.hasOwnProperty.call(itemPlatform, 'is_active') &&
            itemPlatform.is_active,
        )?.length || 0,
      )}
    </>
  );
};

export default GetClientSetup;
