import React, { FC, useMemo, useState } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { Control } from 'react-hook-form';
import { IAdsSetupForm } from '../../../types/AdsSetupForm';
import AdItem from '../AdItem/AdItem';
import { Ad } from '../../../types/Ad';
import { SelectedLineItems } from '../AdsSetup';
import { UpdateLineItemsSelectedProperty } from '../../../types/UpdateLineItemsSelectedProperty';
import AdsTableHeader from './AdsTableHeader/AdsTableHeader';
import debounce from 'lodash.debounce';
import AdsFilteringInput from './AdsTableHeader/AdsFilteringInput';

interface Props {
  ads: Ad[];
  setValue: UseFormSetValue<IAdsSetupForm>;
  getValues: UseFormGetValues<IAdsSetupForm>;
  register: UseFormRegister<IAdsSetupForm>;
  control: Control<IAdsSetupForm>;
  selectedLineItems: SelectedLineItems;
}

const AdsTable: FC<Props> = ({
  selectedLineItems,
  ads,
  register,
  setValue,
  getValues,
  control,
}): JSX.Element => {
  const [currentAd, setCurrentAd] = useState<string | null>(null);

  const [placementFiltering, setPlacementFiltering] = useState<string[]>([]);

  const updateLineItemsSelectedProperty: UpdateLineItemsSelectedProperty = (
    adId: string,
    value: boolean,
  ) => {
    setValue(`currentAds.${adId}`, {
      options: { selected: value },
      sharedOptions: { selected: true },
    });

    const currentTouchpoints = getValues('touchpoints');

    selectedLineItems.forEach((selected) => {
      currentTouchpoints[selected.touchpointIndex].lineItems[selected.lineItemIndex].ads[
        adId
      ].options.selected = value;
    });

    setValue('touchpoints', currentTouchpoints, { shouldDirty: true });
  };

  const filteredAds: Ad[] = useMemo(() => {
    if (!placementFiltering) {
      return ads;
    }
    return ads.filter((ad) => {
      for (const filter of placementFiltering) {
        if (!ad.placementName.toLowerCase().includes(filter.toLowerCase())) {
          return false;
        }
      }
      return true;
    });
  }, [placementFiltering]);

  const placementChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlacementFiltering(e.target.value.split(/\s+/));
  };

  const searchDebounce = debounce(placementChangeHandler, 250);

  if (!ads || ads.length === 0) {
    return <></>;
  }

  return (
    <div className="mt-4 font-size _14">
      <AdsFilteringInput placementChangeHandler={searchDebounce} />
      <AdsTableHeader
        control={control}
        selectedLineItems={selectedLineItems}
        filteredAds={filteredAds}
        setValue={setValue}
        getValues={getValues}
      />
      {filteredAds.map((ad) => (
        <AdItem
          key={ad.id}
          register={register}
          getValues={getValues}
          control={control}
          setCurrentAd={setCurrentAd}
          currentAd={currentAd}
          updateLineItemsSelectedProperty={updateLineItemsSelectedProperty}
          ad={ad}
        />
      ))}
    </div>
  );
};

export default AdsTable;
