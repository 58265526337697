import { gql } from '../__generated__';
import {
  AssignAdsToLineItemInput,
  AssignLineItemSetInput,
  UpsertAdsSetupMutationVariables,
} from '../__generated__/graphql';
import { IAdsSetupForm } from '../../types/AdsSetupForm';

export const UPSERT_ADS_SETUP_MUTATION = gql(` 
    mutation UpsertAdsSetup($input: AssignAdsToCampaignInput!) {
        assignAdsToCampaign(input: $input) {
            campaign { 
                id 
            }
        }
    }
`);

export const getUpsertAdsSetupMutationVariables = (
  data: IAdsSetupForm,
  id: string,
  updatedAt: string,
): UpsertAdsSetupMutationVariables => {
  return {
    input: {
      campaignId: id,
      sets: getSets(data),
      updatedAt: updatedAt,
    },
  };
};

export const getSets = (data: IAdsSetupForm): AssignLineItemSetInput[] => {
  const result: AssignLineItemSetInput[] = [];

  data.touchpoints.forEach((touchpoint) => {
    touchpoint.lineItems.forEach((lineItem) => {
      const assignedAds = Object.entries(lineItem.ads)
        .filter(([, value]) => value.options.selected === true)
        .map<AssignAdsToLineItemInput>(([adId]) => ({
          campaignAdId: adId,
        }));
      let setIndex = result.findIndex((el) => el.uniqId === touchpoint.setId);
      if (setIndex === -1) {
        setIndex = -1 + result.push({ uniqId: touchpoint.setId, lis: [] });
      }
      result[setIndex].lis?.push({ uniqId: lineItem.uniqId, ads: assignedAds });
    });
  });
  return result;
};
