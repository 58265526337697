import React, { FC } from 'react';
import GenerateSegments from '../GenerateSegments/GenerateSegments';
import GenerateSegmentsList from '../GenerateSegmentsList/GenerateSegmentsList';
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../../../types/CreateCampaignForm';
import { useFieldArray } from 'react-hook-form';

interface Props {
  control: Control<ICreateCampaignForm>;
  selectedTouchpointIndex: number;
  register: UseFormRegister<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
}

const Segments: FC<Props> = ({
  control,
  selectedTouchpointIndex,
  register,
  getValues,
  setValue,
}) => {
  const {
    fields: segments,
    remove,
    replace: replaceSegments,
  } = useFieldArray({
    control,
    name: `budgetSplits.${selectedTouchpointIndex}.segmentsList`,
  });

  return (
    <div className={'row pt-5'}>
      <div className={'col-8 zeroMargin'}>
        <GenerateSegments
          segments={segments}
          removeSegment={remove}
          getValues={getValues}
          setValue={setValue}
          register={register}
          control={control}
          selectedTouchpointIndex={selectedTouchpointIndex}
        />
      </div>
      <div className={'col-4'}>
        <GenerateSegmentsList
          replaceSegments={replaceSegments}
          getValues={getValues}
          setValue={setValue}
          selectedTouchpointIndex={selectedTouchpointIndex}
        />
      </div>
    </div>
  );
};

export default Segments;
