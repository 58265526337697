import React, { FC } from 'react';

const Calendar: FC = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="48.000000pt"
      height="48.000000pt"
      viewBox="0 0 48.000000 48.000000"
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
        fill="#CED4DA"
        stroke="none">
        <path d="M120 440 c0 -13 -7 -20 -18 -20 -38 0 -42 -19 -42 -180 0 -108 4 -160 12 -168 8 -8 46 -12 103 -12 79 0 94 3 110 20 18 20 17 20 -83 20 l-102 0 0 100 0 100 140 0 140 0 0 -62 c0 -60 0 -61 20 -43 17 16 20 31 20 110 0 94 -8 115 -42 115 -11 0 -18 7 -18 20 0 13 -7 20 -20 20 -13 0 -20 -7 -20 -20 0 -18 -7 -20 -80 -20 -73 0 -80 2 -80 20 0 13 -7 20 -20 20 -13 0 -20 -7 -20 -20z m260 -80 c0 -19 -7 -20 -140 -20 -133 0 -140 1 -140 20 0 19 7 20 140 20 133 0 140 -1 140 -20z" />
        <path d="M430 170 c-13 -8 -12 -12 6 -30 17 -18 22 -19 32 -7 16 19 -16 50 -38 37z" />
        <path d="M347 92 c-49 -49 -63 -92 -29 -92 9 1 42 23 72 50 54 48 54 49 36 69 -11 12 -22 21 -25 21 -4 0 -28 -22 -54 -48z" />
      </g>
    </svg>
  );
};

export default Calendar;
