import React, { FC, useEffect, useMemo, useState } from 'react';
import BudgetSplit from '../../components/BudgetSplit/BudgetSplit';
import { useForm } from 'react-hook-form';
import { BudgetSplitSchema, ICreateCampaignForm } from '../../types/CreateCampaignForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from 'react-router-dom';
import { Paths } from '../../constants/paths';
import Loader from '../../components/UI/Loader/Loader';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  UPSERT_BUDGET_SPLIT_QUERY,
  upsertBudgetSplitSetsToForm,
} from '../../graphql/queries/UpsertBudgetSplitQuery';
import { useGraphQLError } from '../../hooks/useGraphQLError';
import { useFragment } from '../../graphql/__generated__';
import {
  CAMPAIGN_SETUP_FRAGMENT,
  campaignSetupFragmentToForm,
} from '../../graphql/fragments/CampaignSetupFragment';
import { VARIABLE_NAME_FRAGMENT } from '../../graphql/fragments/VariableNameFragment';
import { CampaignSetupStatusEnumModel } from '../../graphql/__generated__/graphql';
import {
  getUpsertBudgetSplitMutationVariables,
  UPSERT_BUDGET_SPLIT_MUTATION,
} from '../../graphql/mutations/UpsertBudgetSplitMutation';
import { getPath } from '../../helpers/getSetupStatus';
import { toastMissingUpdateDate } from '../../helpers/toastMissingUpdateDate';

type BudgetSplitParams = {
  id: string;
};

const UpsertBudgetSplit: FC = () => {
  const [initialized, setInitialized] = useState(false);

  const { id } = useParams<BudgetSplitParams>();
  const navigate = useNavigate();

  const [getUpsertBudgetSplit, { error, data }] = useLazyQuery(UPSERT_BUDGET_SPLIT_QUERY);
  const [upsertBudgetSplit, { loading: upsertLoading, data: upsertData, error: upsertError }] =
    useMutation(UPSERT_BUDGET_SPLIT_MUTATION);
  const { setValue, getValues, register, control, reset, handleSubmit, clearErrors } =
    useForm<ICreateCampaignForm>({
      resolver: zodResolver(BudgetSplitSchema),
    });

  useEffect(() => {
    if (!id) {
      return;
    }

    getUpsertBudgetSplit({ variables: { campaignId: id } });
  }, [id]);

  useGraphQLError([error, upsertError]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const campaignSetup = useFragment(CAMPAIGN_SETUP_FRAGMENT, data.campaign);

    const status = campaignSetup?.setupStatus || '';
    if (
      status === CampaignSetupStatusEnumModel.Initialized ||
      status === CampaignSetupStatusEnumModel.WithTargetGroups
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      navigate(getPath(status, id!));
      return;
    }

    reset({
      setup: campaignSetupFragmentToForm(campaignSetup),
      budgetSplits: upsertBudgetSplitSetsToForm(data, campaignSetup),
    });
    setInitialized(true);
  }, [data]);

  useEffect(() => {
    if (!upsertData) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    navigate(Paths.getUpsertLisAdgs(id!));
  }, [upsertData]);

  const handleNextButton = () => {
    if (upsertLoading) {
      return;
    }

    if (!data?.campaign?.updatedAt) {
      toastMissingUpdateDate();
      return;
    }
    const updatedAt = data.campaign.updatedAt;

    handleSubmit((data) => {
      upsertBudgetSplit({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: getUpsertBudgetSplitMutationVariables(data, id!, updatedAt),
      });
    })();
  };

  const handlePreviousButton = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    navigate(Paths.getUpsertVariantTargeting(id!));
  };

  const partners = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.variables?.map((variable) => useFragment(VARIABLE_NAME_FRAGMENT, variable)) ?? [];
  }, [data]);

  if (!initialized) {
    return <Loader />;
  }

  if (!id) {
    return <></>;
  }

  return (
    <BudgetSplit
      clearErrors={clearErrors}
      handleNext={handleNextButton}
      handlePrevious={handlePreviousButton}
      handlerLoading={upsertLoading}
      setValue={setValue}
      getValues={getValues}
      register={register}
      control={control}
      partners={partners}
      campaignId={id}
    />
  );
};

export default UpsertBudgetSplit;
