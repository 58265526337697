import React, { FC, useContext, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore/lite';
import { FirebaseContext } from '../../context/FirebaseContext';
import cn from 'classnames';
import styles from './PlatformsBrandsProductsSetup.module.scss';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { Control, Controller, useWatch } from 'react-hook-form';

interface Props {
  register: UseFormRegister<any>;
  index: number;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  control: Control;
  name: string;
  isGlobal?: boolean;
  mainObj?: any;
}

const ShowTypes: FC<Props> = ({
  register,
  mainObj,
  index,
  setValue,
  getValues,
  control,
  name,
  isGlobal = false,
}): JSX.Element => {
  const [sdfTypes, setSdfTypes] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [selectedAdvertiserItem, setSelectedAdvertiserItem] = useState(null);
  const [selectedSdfTypeItem, setSelectedSdfTypeItem] = useState(null);
  const { campaignFirestore } = useContext(FirebaseContext);
  const watchAdvertiserItem = useWatch({ control, name: `${name}.advertiser` });
  const watchSdfTypeItem = useWatch({ control, name: `${name}.SDF_type` });

  const getTypes = async () => {
    const arr = [
      {
        id: 'default',
        name: 'Sdf type',
      },
    ];
    if (campaignFirestore) {
      const docSnap = await getDoc(doc(campaignFirestore, 'sdfs_defs', watchAdvertiserItem));
      if (docSnap?.exists()) {
        for (const [key] of Object.entries(docSnap.data())) {
          arr.push({
            id: key,
            name: key,
          });
        }
      }

      // const sdfsDefsSnapshot = await getDocs(sdfsDefsCol);
      // sdfsDefsSnapshot?.docs?.map((item: any) => {
      //   console.log(item?.data())
      //   arr.push({
      //     id: item?.id,
      //     name: item?.id,
      //   })
      // })
      return arr;
    } else {
      return arr;
    }
  };

  // brand-safety
  // const OBJ = {
  //   Global: {
  //
  //   },
  //   Advertiser: {
  //     advertiser_id1: {
  //       sdf_type: 'sdf_type',
  //       field_type: 'field_type',
  //       value: 'value',
  //     },
  //     advertiser_id2: {
  //
  //     }
  //   }
  // }

  const getFields = async () => {
    const arr = [
      {
        id: 'default',
        name: 'Field type',
      },
    ];
    if (campaignFirestore) {
      const docSnap = await getDoc(doc(campaignFirestore, 'sdfs_defs', watchAdvertiserItem));
      if (docSnap?.exists()) {
        for (const [key, value] of Object.entries(docSnap.data())) {
          if (key === watchSdfTypeItem) {
            Object.values(value)?.map((item: any) => {
              if (
                item &&
                Object.prototype.hasOwnProperty.call(item, 'Field name') &&
                item['Field name']
              ) {
                arr.push({
                  id: item['Field name'],
                  name: item['Field name'],
                });
              }
            });
          }
        }
      }
      return arr;
    } else {
      return arr;
    }
  };

  useEffect(() => {
    if (selectedAdvertiserItem !== watchAdvertiserItem && watchAdvertiserItem) {
      getTypes().then((res: any) => {
        setSelectedAdvertiserItem(watchAdvertiserItem);
        setSdfTypes(res);
      });
    }
  }, [watchAdvertiserItem]);

  useEffect(() => {
    if (selectedSdfTypeItem !== watchSdfTypeItem && watchSdfTypeItem) {
      getFields().then((res: any) => {
        setSelectedSdfTypeItem(watchSdfTypeItem);
        setFieldTypes(res);
      });
    }
  }, [watchSdfTypeItem]);

  const getSubName = () => {
    const splitName = name?.split('.');
    splitName.pop();
    return splitName.join('.');
  };

  const removeItem = () => {
    const splitName = name?.split('.');
    splitName.pop();
    const subName = getSubName();
    if (subName) {
      const conventionsCopy = JSON.parse(JSON.stringify(getValues(subName)));
      conventionsCopy.splice(index, 1);
      setValue(subName, conventionsCopy);
    }
  };

  return (
    <>
      <div className={'col-4'}>
        <select
          {...register(`${name}.SDF_type`)}
          className={cn(styles.select_40, 'form-select col-3 gap-2')}
          aria-label="SDF_type"
          defaultValue="default"
          value={getValues(`${name}.SDF_type`)}
          onChange={(e: any) => {
            setValue(`${name}.SDF_type`, e?.target?.value);
            setValue(`${name}.is_active`, true, { shouldValidate: true });
            setValue(`${name}.field_name`, 'default', { shouldValidate: true });
            if (isGlobal) {
              const splitName = name?.split('.');
              const currentIndex = splitName[splitName?.length - 1];
              splitName?.pop();
              setValue(
                `${splitName?.join('.')}.${+currentIndex + 1}.advertiser`,
                watchAdvertiserItem,
              );
            }
          }}
          disabled={!watchAdvertiserItem || watchAdvertiserItem === 'default'}>
          {sdfTypes?.length ? (
            sdfTypes
              ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
              ?.map(({ name, id }: any, index2: number) => (
                <option key={`${name}_SDF_type${index}_${index2}`} value={id}>
                  {name}
                </option>
              ))
          ) : (
            <option key={`${name}_SDF_type${index}_-1`} value={'default'}>
              {'SDF type'}
            </option>
          )}
        </select>
      </div>
      <div className={'col-4'}>
        <select
          {...register(`${name}.field_name`)}
          className={cn(styles.select_40, 'form-select col-3 gap-2')}
          aria-label="Field type"
          defaultValue="default"
          value={getValues(`${name}.field_name`)}
          onChange={(e: any) => {
            if (isGlobal) {
              const splitName = name?.split('.');
              const currentIndex = splitName[splitName?.length - 1];
              splitName?.pop();
              setValue(
                `${splitName?.join('.')}.${+currentIndex + 1}.advertiser`,
                watchAdvertiserItem,
              );
            }
            setValue(`${name}.field_name`, e?.target?.value);
            setValue(`${name}.is_active`, true, { shouldValidate: true });
          }}
          disabled={!selectedSdfTypeItem || selectedSdfTypeItem === 'default'}>
          {fieldTypes?.length ? (
            fieldTypes
              ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
              ?.map(({ name, id }: any, index2: number) => (
                <option key={`${name}_Field_type${index}_${index2}`} value={id}>
                  {name}
                </option>
              ))
          ) : (
            <option key={`${name}_Field_type${index}_-1`} value={'default'}>
              {'Field type'}
            </option>
          )}
        </select>
      </div>
      <div className={'col-3'}>
        <Controller
          control={control}
          name={`${name}.values`}
          defaultValue=""
          render={({ field: { onChange } }) => (
            <textarea
              className={cn('max-w _1000 w-100 px-2 pt-2 pb-0', styles.values)}
              placeholder={'Values'}
              defaultValue={getValues(`${name}.values`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  if (isGlobal) {
                    const splitName = name?.split('.');
                    const currentIndex = splitName[splitName?.length - 1];
                    splitName?.pop();
                    setValue(
                      `${splitName?.join('.')}.${+currentIndex + 1}.advertiser`,
                      watchAdvertiserItem,
                    );
                  }
                  setValue(`${name}.is_active`, true, { shouldValidate: true });
                  onChange(e);
                }
              }}
            />
          )}
        />
      </div>

      <div className={'col-1 d-flex align-items-center'}>
        {mainObj?.length >= index && mainObj[index]?.is_active ? (
          <img className={styles.stop} src={'/stop.png'} onClick={removeItem} />
        ) : null}
      </div>
    </>
  );
};

export default ShowTypes;
