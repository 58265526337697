import React, { FC } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { Control, useWatch } from 'react-hook-form';
import styles from '../PlatformsBrandsProductsSetup.module.scss';
import { FormControl } from 'react-bootstrap';
import { IDropdownItem, IOptions } from '../GetTabs';
import Form from 'react-bootstrap/Form';

interface Props {
  register: UseFormRegister<any>;
  options: IOptions;
  setValue: UseFormSetValue<any>;
  control: Control;
  getValues: UseFormGetValues<any>;
  removeItem: (name: string, index: number) => void;
}

const GetNamingConventions: FC<Props> = ({
  control,
  options,
  getValues,
  setValue,
  register,
  removeItem,
}): JSX.Element => {
  const conventions = useWatch({ control, name: `conventions` });

  const getValueReference = (name: string, index: number, showNewVariablesCheckbox = false) => {
    const customName = `${name}.reference.${index}`;
    const getVariables = () => {
      // if (isSelectedFileTypeCampaign) {
      let values: any = [];
      if (getValues()?.creative?.variable_types?.length) {
        values = getValues()
          ?.creative?.variable_types?.filter((item: any) => item?.is_active)
          ?.map((item: any) => ({
            id: item?.nc_variable_type,
            name: item?.variable_type,
          }));
      }

      // Targeting gender"
      // "Targeting age min"
      // "Targeting age max"
      // "Language"
      // Device targeting
      // Partners
      return [
        {
          id: 'default',
          name: 'Variable',
        },
        {
          id: 'targeting_gender',
          name: 'Targeting gender',
        },
        {
          id: 'tactic_details',
          name: 'Tactic details',
        },
        {
          id: 'other',
          name: 'Other',
        },
        {
          id: 'targeting_age_min',
          name: 'Targeting age min',
        },
        {
          id: 'targeting_age_max',
          name: 'Targeting age max',
        },
        {
          id: 'language',
          name: 'Language',
        },
        {
          id: 'device_targeting',
          name: 'Device targeting',
        },
        {
          id: 'partners',
          name: 'Partners',
        },
        {
          id: 'brandName',
          name: 'Brand Name',
        },
        {
          id: 'category',
          name: 'Category',
        },
        {
          id: 'franchiseName',
          name: 'Line/Franchise name',
        },
        {
          id: 'campaignDescription',
          name: 'Campaign description',
        },
        {
          id: 'poNumber',
          name: 'PO-number',
        },
        {
          id: 'nc_kpi_value',
          name: 'KPI Value',
        },
        {
          id: 'creative_list',
          name: 'Length',
        },
        {
          id: 'nc_spot_length',
          name: 'Spot length',
        },
        {
          id: 'nc_asset_description',
          name: 'Asset description',
        },
        {
          id: 'nc_cities_geo_template',
          name: 'Cities',
        },
        {
          id: 'nc_targeting_objective',
          name: 'Targeting objective',
        },
        ...values,
      ];
      // }
      // else {
      //   let values: any = [];
      //   if (getValues()?.creative?.variable_types?.length) {
      //     values = getValues()?.creative?.variable_types
      //       ?.filter((item: any) => item?.is_active)
      //       ?.map((item: any) => ({
      //         id: item?.nc_variable_type,
      //         name: item?.variable_type,
      //       }))
      //   }
      //   return ([
      //     {
      //       id: 'default',
      //       name: 'Variable'
      //     },
      //     ...values
      //   ])
      // }
    };

    return (
      <React.Fragment key={`${name}_${index}`}>
        <div className={'row mx-3 mt-2'}>
          <div className={'col-3'}>
            <select
              {...register(`${customName}.Variable`)}
              className="form-select col-3 gap-2"
              aria-label="Variable"
              defaultValue="default"
              value={
                getValues(`${customName}.Variable`) === ''
                  ? 'default'
                  : getValues(`${customName}.Variable`)
              }
              onChange={(e: any) => {
                setValue(`${customName}.Variable`, e?.target?.value);
                const currentVal = getVariables()?.find(
                  (item: any) => item?.id === e?.target?.value,
                );
                setValue(`${customName}.VariableValue`, currentVal?.name);
                setValue(`${customName}.is_active`, true, { shouldValidate: true });
              }}>
              {getVariables()
                ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
                ?.map((valueReference: IDropdownItem, valueReferenceIndex: number) => (
                  <option key={`${customName}_${valueReferenceIndex}`} value={valueReference?.id}>
                    {valueReference?.name}
                  </option>
                ))}
              {/*{*/}
              {/*  options?.references?.map((valueReference: IDropdownItem, valueReferenceIndex: number) =>*/}
              {/*    <option*/}
              {/*      key={`${customName}_${valueReferenceIndex}`} value={valueReference?.id}>{valueReference?.name}</option>*/}
              {/*  )*/}
              {/*}*/}
            </select>
          </div>

          {/*<div className={'col-5'}>*/}
          {/*  <FormControl*/}
          {/*    placeholder="Allowed values list"*/}
          {/*    aria-label="Allowed values list"*/}
          {/*    type={'text'}*/}
          {/*    {...register(`${customName}.Allowed values list`)}*/}
          {/*    onBlur={(e: any) => {*/}
          {/*      const value = e?.target?.value;*/}
          {/*      if (value && value != '') {*/}
          {/*        setValue(`${customName}.is_active`, true, {shouldValidate: true})*/}
          {/*      }*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className={'col-3'}>
            <FormControl
              placeholder="Default value"
              aria-label="Default value"
              type={'text'}
              {...register(`${customName}.Default value`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`${customName}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>
          {!showNewVariablesCheckbox && (
            <div className={'col-1'}>
              <img
                className={styles.stop}
                onClick={() => removeItem(`${name}.reference`, index)}
                src={'/stop.png'}
              />
            </div>
          )}
          {!showNewVariablesCheckbox && index < getValues(`${name}.reference`).length - 2 && (
            <div style={{ position: 'relative' }} className={'col-3'}>
              <Form.Check
                style={{ position: 'absolute', top: '20px' }}
                className={'col-6'}
                label={'merge'}
                type={'checkbox'}
                // checked={getValues()?.is_active}
                {...register(`${customName}.is_merge`)}
                aria-label={'merge'}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  const getCampaignItemType = (parentIndex: number, index: number) => {
    const subConventionName = `conventions.${parentIndex}.campaignItemTypes`;
    if (getValues(`${subConventionName}.${index}.Campaign Item Type`) === 'FILE_TYPE_CAMPAIGN') {
      // isSelectedFileTypeCampaign = true;
    }
    return (
      <React.Fragment key={`campaignItemType${index}`}>
        <div className={'row mx-3 mt-2'}>
          <div className={'col-3'}>
            <select
              {...register(`${subConventionName}.${index}.Campaign Item Type`)}
              className="form-select col-3 gap-2"
              aria-label="Campaign Item Type"
              defaultValue="default"
              value={
                getValues(`${subConventionName}.${index}.Campaign Item Type`) === ''
                  ? 'default'
                  : getValues(`${subConventionName}.${index}.Campaign Item Type`)
              }
              onChange={(e: any) => {
                setValue(`${subConventionName}.${index}.Campaign Item Type`, e?.target?.value);
                setValue(`${subConventionName}.${index}.is_active`, true, { shouldValidate: true });
              }}>
              {options?.types
                ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
                ?.map((type: IDropdownItem, campaignItemIndex: number) => {
                  return (
                    <option
                      key={`campaignItemType_${parentIndex}_${index}_${campaignItemIndex}`}
                      value={type?.id}>
                      {type?.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className={'row'}>
            {conventions?.length &&
            conventions[parentIndex]?.campaignItemTypes?.length &&
            conventions[parentIndex]?.campaignItemTypes[index]?.reference?.length
              ? conventions[parentIndex]?.campaignItemTypes[index]?.reference
                  ?.filter((itemReference: any) => itemReference?.is_active)
                  ?.map((item: any, itemIndex: number) => {
                    return getValueReference(`${subConventionName}.${index}`, itemIndex);
                  })
              : null}
            {getValueReference(
              `${subConventionName}.${index}`,
              (conventions?.length &&
                conventions[parentIndex]?.campaignItemTypes?.length &&
                conventions[parentIndex]?.campaignItemTypes[index]?.reference?.filter(
                  (itemReference: any) =>
                    itemReference &&
                    Object.prototype.hasOwnProperty.call(itemReference, 'is_active') &&
                    itemReference.is_active,
                )?.length) ||
                0,
              true,
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getConventions = (index: number) => {
    return (
      <React.Fragment key={`conventions${index}`}>
        <div className={'row mx-3 mt-1'}>
          <div className={'col-3'}>
            <select
              {...register(`conventions.${index}.Platform`)}
              className="form-select col-3 gap-2"
              aria-label="Platform"
              defaultValue="default"
              onChange={(e: any) => {
                setValue(`conventions.${index}.Platform`, e?.target?.value);
                setValue(`conventions.${index}.is_active`, true, { shouldValidate: true });
              }}>
              {options?.platforms
                ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
                ?.map((platform: IDropdownItem, index: number) => (
                  <option key={`conventions.platforms_${index}`} value={platform?.id}>
                    {platform?.name}
                  </option>
                ))}
            </select>
          </div>
          <div className={'col-3 d-flex gap-2'}>
            <FormControl
              placeholder="Separator character"
              aria-label="Separator character"
              type={'text'}
              {...register(`conventions.${index}.Separator character`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`conventions.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
            <FormControl
              placeholder="Separator character subtitle"
              aria-label="Separator character subtitle"
              type={'text'}
              {...register(`conventions.${index}.Separator character subtitle`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`conventions.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>
          <div className={'col-3 d-flex align-items-center'}>
            {conventions?.length >= index && conventions[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() => removeItem('conventions', index)}
              />
            ) : null}
          </div>

          <div className={'row'}>
            {conventions?.length && conventions[index]?.campaignItemTypes?.length
              ? conventions[index]?.campaignItemTypes
                  ?.filter((itemCampaignItemType: any) => {
                    return (
                      itemCampaignItemType &&
                      Object.prototype.hasOwnProperty.call(
                        itemCampaignItemType,
                        'Campaign Item Type',
                      ) &&
                      itemCampaignItemType.is_active &&
                      options?.types?.length
                    );
                  })
                  ?.map((item: any, itemIndex: number) => {
                    return getCampaignItemType(index, itemIndex);
                  })
              : null}
            {getCampaignItemType(
              index,
              (conventions?.length &&
                conventions[index]?.campaignItemTypes?.filter(
                  (itemCampaignItemType: any) =>
                    itemCampaignItemType &&
                    Object.prototype.hasOwnProperty.call(itemCampaignItemType, 'is_active') &&
                    itemCampaignItemType.is_active,
                )?.length) ||
                0,
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className={'row'}>
        <p className="mt-3 col-12">Naming conventions</p>
        {options?.platforms?.length ? (
          <>
            {conventions?.length
              ? conventions
                  ?.filter((itemConventions: any) => itemConventions?.is_active)
                  ?.map((item: any, index: number) => {
                    return getConventions(index);
                  })
              : null}
            {getConventions(
              conventions?.length
                ? conventions?.filter(
                    (itemConventions: any) =>
                      itemConventions &&
                      Object.prototype.hasOwnProperty.call(itemConventions, 'is_active') &&
                      itemConventions.is_active,
                  )?.length
                : 0,
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default GetNamingConventions;
