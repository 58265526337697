import React, { FC, ReactElement } from 'react';
import styles from './Layout.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: ReactElement;
}

const Layout: FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.layout}>
      <img className={styles.logo} src={'/Header_Logo.png'} onClick={() => navigate('/')} />
      {children}
    </div>
  );
};

export default Layout;
