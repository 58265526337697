import { User } from 'firebase/auth';
import { IPartner } from '../../types/Partner';

export enum LoginActionEnum {
  SetPartner,
  // MasterUserLogout,
  LoginMasterUser,
  LogoutMasterUser,
  LogoutGuiUser,
  LoginGuiUser,
  InitGuiUserLogin,
  InitMasterUserLogin,
  LogoutPartnerUser,
  LoginPartnerUser,
  InitPartnerDataRead,
  PartnerLogin,
}

export type LoginAction = {
  type: LoginActionEnum;
  user?: User;
  token?: string;
  partner?: IPartner;
};
