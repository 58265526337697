import cn from 'classnames';
import styles from './VariantLabel.module.scss';
import { FormControl } from 'react-bootstrap';
import React from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { FieldErrors } from 'react-hook-form';

interface Props {
  register: UseFormRegister<ICreateCampaignForm>;
  errors: FieldErrors<ICreateCampaignForm>;
  onRemove: () => void;
  selectedSet: number;
  variantIndex: number;
}

export const VariantLabel = ({ register, errors, onRemove, variantIndex, selectedSet }: Props) => {
  return (
    <div className={styles.paddingSides}>
      <img
        alt="Remove variant"
        className={cn('d-block mx-auto mb-1', styles.removeIcon)}
        src={'/stop.png'}
        onClick={onRemove}
      />
      <FormControl
        {...register(`setsWithVariants.${selectedSet}.variants.${variantIndex}.name`)}
        aria-label="Variant name"
        placeholder={'Name'}
        className={styles.w100px}
        isInvalid={
          errors.setsWithVariants?.[selectedSet]?.variants?.[variantIndex]?.name !== undefined ||
          errors.setsWithVariants?.[selectedSet]?.variants?.[variantIndex]?.message !== undefined
        }
      />
    </div>
  );
};
