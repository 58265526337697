import React, { FC, useContext } from 'react';
import styles from './CampaignSetup.module.scss';
import { Button } from 'react-bootstrap';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore/lite';
import { FirebaseContext } from '../../context/FirebaseContext';
import { ICampaign, ICampaigns } from '../../types/Campaign';
import { Paths } from '../../constants/paths';

interface Props {
  campaigns: null | ICampaigns;
  setCampaigns: (state: (prevState: ICampaigns | null) => ICampaign[] | null) => void;
}

const CampaignSetup: FC<Props> = ({ campaigns, setCampaigns }): JSX.Element => {
  const { campaignFirestore } = useContext(FirebaseContext);
  const navigate = useNavigate();

  const clickStatusButton = async (el: ICampaign) => {
    if (el?.data?.status === 'For approval' && campaignFirestore) {
      const docSnap = doc(campaignFirestore, 'campaigns', el?.id);
      await setDoc(docSnap, { status: 'Approved/Edit' }, { merge: true });
      setCampaigns((prevState: ICampaigns | null) =>
        prevState
          ? prevState.filter((item: ICampaign) => {
              if (item?.id !== el?.id) {
                return item;
              } else {
                const currentItem = item;
                currentItem.data.status = 'Approved/Edit';
                return currentItem;
              }
            })
          : null,
      );
    } else {
      navigate(Paths.getUpsertCampaignGoals(el.id));
    }
  };

  return (
    <div
      className={`row px-4 pt-3 pb-4 justify-content-between flex-nowrap ${styles.campaignsWrapper}`}>
      <div className={`col-12 min-w _450`}>
        <div className="row gap-2 min-w _1000">
          <h6 className={'col-2'}>Campaign name</h6>
          <h6 className={'col-2'}>Status</h6>
          <h6 className={'col-2'}>Ads</h6>
          <h6 className={'col-2'}>Media plan</h6>
        </div>
        <div
          className={cn(
            'row flex-column gap-2 mt-2 min-w _1000 overflow-auto',
            styles.campaignsListWrapper,
          )}>
          {campaigns?.map((el: ICampaign, index: number) => {
            return (
              <div
                key={`campaign_${index}`}
                className={'row gap-2 zeroHorizontalPadding zeroMargin'}>
                <input
                  className={cn('col-2 border rounded min-w _200', styles.campaignInput)}
                  disabled={true}
                  type="text"
                  placeholder={el?.data?.setup?.name || 'Campaign name'}
                />
                <div className="col-2 flex-column">
                  <Button
                    key={`campaign-button-first_${index}`}
                    variant={el?.data?.status === 'Approved/Edit' ? 'success' : 'primary'}
                    onClick={() => clickStatusButton(el)}
                    className="w-100">
                    {el?.data?.status}
                  </Button>
                </div>
                <div className="col-2 flex-column">
                  <Button
                    variant={el?.data?.adsStatus === 'Approve/Edit' ? 'success' : 'primary'}
                    key={`campaign-button-second_${index}`}
                    onClick={() => navigate(Paths.getAdsSetupForm(el.id))}
                    className="w-100">
                    {el?.data?.adsStatus || 'Create'}
                  </Button>
                </div>
                <div className="col-2 flex-column">
                  <Button
                    variant={'secondary'}
                    key={`campaign-button-inactive_${index}`}
                    className="w-100">
                    (inactive)
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CampaignSetup;
