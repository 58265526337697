import React, { FC } from 'react';
import Header from '../../components/Header/Header';
import PlatformsBrandsProductsSetupPlatforms from '../../components/PlatformsBrandsProductsSetup/PlatformsBrandsProductsSetupPlatform';

const PlatformsBrandsProductsPlatforms: FC = (): JSX.Element => {
  return (
    <div>
      <Header
        campaignSetupText={'Platforms, Brands, products & naming conventions'}
        withButtons={false}
      />
      <PlatformsBrandsProductsSetupPlatforms />
    </div>
  );
};
export default PlatformsBrandsProductsPlatforms;
