import React, { FC, useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './OptimisationM.module.scss';
import Header from '../../components/Header/Header';
import CreateCampaign from '../../components/CreateCampaign/CreateCampaign';
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from 'react-hook-form';
import { getDate } from '../../helpers/getQueryParams';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore/lite';
// import useSetUser from '../../hooks/useSetUser';
import { FirebaseContext } from '../../context/FirebaseContext';

const OptimisationM: FC = (): JSX.Element => {
  // const {setPartner} = useSetUser();
  const {
    firestoreGui,
    masterFirestore,
    campaignFirestore,
    partnerData: partner,
  } = useContext(FirebaseContext);
  const [showCampaigns, setShowCampaigns] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [changeCampaignData, setChangeCampaignData] = useState<any>({
    campaignId: null,
    iosId: null,
  });
  const { setValue, getValues, watch, control } = useForm();
  const partnerData = partner;

  // useEffect(() => {
  //   if (partnerData && setCampaignFirebase && setCampaignFirestore) {
  //     // @ts-ignore
  //     const config = partnerData?.platforms?.Firebase?.API?.firebaseConfig;
  //     const campaignProject = initializeApp(config, "campaign");
  //     LoginFirebase(campaignProject)
  //     const campaignStorage = getStorage(campaignProject);
  //     const campaignFirestore = getFirestore(campaignProject);
  //     setCampaignFirebase(campaignStorage);
  //     setCampaignFirestore(campaignFirestore);
  //   }
  // }, [partnerData])

  useEffect(() => {
    const getAdvertId = async () => {
      if (campaignFirestore) {
        const date = getDate(new Date(), true);
        const splitDate: any = date?.split('-');
        const newDate = getDate(new Date(splitDate[0], splitDate[1] - 1, splitDate[2] - 1), true);
        let advertisers: any = [];
        const colSnapshot = collection(campaignFirestore, 'dv360-advertisers-dv');
        const stateQueryName = query(
          colSnapshot,
          where('partnerId', '==', partnerData?.partner_id),
        );

        const docs: any = await getDocs(stateQueryName);
        const advertisersId: any = [];
        docs.forEach((doc: any) => {
          advertisersId.push(doc?.id);
        });

        const colSnap = collection(
          campaignFirestore,
          'active-campaigns',
          newDate,
          'dv360-campaigns',
        );
        await Promise.all(
          advertisersId.map(async (item: any) => {
            const stateQueryAdvertisers = query(colSnap, where('advertiserId', '==', item));

            const docsAdvertisers: any = await getDocs(stateQueryAdvertisers);
            docsAdvertisers.forEach((doc: any) => {
              advertisers.push(doc?.data());
            });
          }),
        );

        const exclusionsArr: any = [];

        if (partnerData) {
          const exclusionsSnap = await getDoc(
            doc(campaignFirestore, 'exclusions', date, 'partners', partnerData?.partner_id),
          );
          const exclusions: any = exclusionsSnap.data();

          for (let i = 0; i < advertisers?.length; i++) {
            const advertise: any = {
              id: advertisers[i]?.campaignId,
              ios: [],
            };

            let iosArr: any = [];
            const exclId = advertisers[i]?.campaignId;
            if (exclusions && Object.prototype.hasOwnProperty.call(exclusions, exclId)) {
              advertisers[i].optimized = exclusions[exclId]?.excluded || false;
              iosArr = exclusions[exclId]?.excluded_ios || null;
            } else {
              advertisers[i].optimized = false;
            }

            const docSnap = doc(campaignFirestore, 'sdfs', advertisers[i]?.campaignId);
            const children_ids: any = (await getDoc(docSnap)).data()?.children_ids;
            advertisers[i].ios = [];

            if (children_ids) {
              const iosWaitArr = await Promise.all(
                children_ids?.map(async (item: any) => {
                  const ios = await getDoc(
                    doc(campaignFirestore, 'sdfs', item, 'daily_sdfs', newDate),
                  );
                  return {
                    id: item,
                    ios,
                  };
                }),
              );

              iosWaitArr?.map((item: any) => {
                const iosData = item?.ios?.data();
                const ios = {
                  ...iosData,
                  id: item?.id,
                  optimized:
                    iosArr && Object.prototype.hasOwnProperty.call(iosArr, item?.id)
                      ? iosArr[item?.id]
                      : false,
                };
                advertise?.ios.push(ios);
                advertisers[i].ios.push(ios);
              });
            }
            exclusionsArr.push(advertise);
          }

          Object.keys(exclusions)?.map((item: any) => {
            const excl = {
              id: item,
              ...exclusions[item],
            };

            let isExist = false;
            for (let j = 0; j < exclusionsArr?.length; j++) {
              if (excl?.id === exclusionsArr[j]?.id) {
                isExist = true;
                exclusionsArr[j].optimized = excl?.excluded || exclusionsArr[j]?.optimized || false;
                // eslint-disable-next-line no-unsafe-optional-chaining
                const originalIosArr = [...exclusionsArr[j]?.ios?.map((item: any) => item?.id)];
                const newIosArr = excl?.excluded_ios;
                if (newIosArr && !newIosArr?.length && Object.keys(newIosArr)?.length) {
                  let keys: any = [];
                  keys = Object.keys(newIosArr);
                  for (let k = 0; k < keys?.length; k++) {
                    // console.log(originalIosArr, keys[k])
                    if (!originalIosArr?.includes(keys[k])) {
                      exclusionsArr[j]?.ios.push({
                        id: keys[k],
                        optimized: newIosArr[keys[k]],
                      });
                    } else {
                      exclusionsArr[j].ios = exclusionsArr[j]?.ios.map((io: any) =>
                        io?.id === keys[k]
                          ? {
                              ...io,
                              id: keys[k],
                              optimized: newIosArr[keys[k]],
                            }
                          : io,
                      );
                    }
                  }
                }
                // if (!isExist) {
                //   const obj: any = {
                //     campaignId: excl?.id,
                //   }
                //   obj.optimized = excl?.excluded || excl?.exclude_cmpaign || false
                //   if (excl?.hasOwnProperty('excluded_ios')) {
                //     if (!excl?.excluded_ios?.length && Object.keys(excl?.excluded_ios)?.length) {
                //       obj.ios = Object.keys(excl?.excluded_ios)?.map((key: any) => ({id: key, optimized: excl?.excluded_ios[key]?.excluded}))
                //     } else {
                //       if (excl?.excluded_ios?.length) {
                //         const iosItems = excl?.excluded_ios;
                //         obj.ios = iosItems?.map((item: any) => (
                //           {id: item, optimized: true}
                //         ))
                //       }
                //     }
                //   }
                //   exclusionsArr.push(obj)
                // }
              }
            }
            if (!isExist) {
              console.log(excl);
              exclusionsArr.push(excl);
            }
          });

          for (let i = 0; i < exclusionsArr?.length; i++) {
            let check = false;
            advertisers = advertisers?.map((item: any) => {
              if (item?.campaignId === exclusionsArr[i]?.id) {
                check = true;
                return {
                  ...item,
                  ios: exclusionsArr[i]?.ios,
                  optimized:
                    exclusionsArr[i]?.optimized != null
                      ? exclusionsArr[i]?.optimized
                      : item?.optimized || false,
                };
              } else {
                return item;
              }
            });
            if (!check) {
              const arr: any = [];
              if (exclusionsArr[i]?.excluded_ios) {
                Object?.keys(exclusionsArr[i]?.excluded_ios)?.map((key: any) => {
                  arr.push({
                    id: key,
                    optimized: exclusionsArr[i]?.excluded_ios[key],
                  });
                });
              }
              advertisers?.push({
                campaignId: exclusionsArr[i]?.id,
                optimized: exclusionsArr[i]?.excluded || false,
                ios: arr || [],
              });
            }
          }

          console.log(advertisers);

          setValue(
            `campaigns`,
            advertisers?.sort((a: any, b: any) => (a.id > b.id ? 1 : -1)),
          );
        }
      }
    };
    if (firestoreGui && campaignFirestore && partnerData && !isLoad) {
      setIsLoad(true);
      getAdvertId().then(() => {});
    }

    // if (!partnerData && masterFirestore) {
    //   setPartner();
    // }
  }, [campaignFirestore, partnerData, masterFirestore]);

  const getIosIndex = (name: any) => {
    // ts-ignore
    const ios = watch(name)?.ios;
    if (ios?.length) {
      const lastCampaign = ios[ios?.length - 1];
      if (lastCampaign?.id && lastCampaign?.id !== '') {
        return ios?.length;
      } else {
        return ios?.length - 1;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const { campaignId, iosId } = changeCampaignData;
    if (campaignId || iosId) {
      const campaigns: any = [];
      // let allIos: any = [];
      getValues()?.campaigns?.map((campaign: any) => {
        if (campaign?.campaignId) {
          const ios: any = [];
          // allIos = [campaign];
          const activeIos: any = {};
          campaign?.ios?.map((item: any) => {
            if (item?.id) {
              ios.push(item?.id);
              activeIos[item?.id] = item?.optimized;
              // const iosItem = {...item};
              // delete iosItem?.optimized;
              // delete iosItem?.id;
              // if (item?.id && (index2 === iosId)) {
              //   allIos.push(iosItem);
              // }
            }
          });
          const exclusions: any = {};
          // if (campaign?.optimized) {
          //   exclusions.excluded = true;
          //   exclusions.excluded_ios = activeIos;
          // } else {
          exclusions.excluded = campaign?.optimized;
          exclusions.excluded_ios = activeIos;
          // }

          const copyCampaign = { ...campaign };
          delete copyCampaign?.ios;
          campaigns.push({
            campaignId: campaign?.campaignId,
            dv360Campaigns: {
              ...copyCampaign,
              campaignId: campaign?.campaignId,
              displayName: campaign?.displayName || '',
            },
            exclusions: exclusions,
            sdfs: { children_ids: ios },
          });
        }
      });

      const date = getDate(new Date(), true);
      // const splitDate: any = date?.split('-');
      // const newDate = getDate(new Date(splitDate[0], splitDate[1] - 1, splitDate[2] - 1), true);

      // @ts-ignore
      if (campaigns?.length && campaignFirestore && partnerData) {
        // campaigns?.map(async (item: any, index: any) => {
        //   console.log(date, item?.campaignId, item?.exclusions)
        // await setDoc(doc(firestore, "active-campaigns", newDate, "dv360-campaigns", item?.campaignId), item?.dv360Campaigns);
        // firestore.collection('exclusions', '2022-04-09', 'partners', partnerRef?.partner_id)
        //   .doc(item?.campaignId).set(item?.exclusions)
        // await setDoc(doc(firestore, 'exclusions', '2022-04-09', 'partners', partnerRef?.partner_id), {[item?.campaignId]: item?.exclusions});
        // await setDoc(doc(firestore, "sdfs", item?.campaignId), item?.sdfs);
        // })
        const parseExclusions: any = {};
        campaigns?.map((item: any) => {
          parseExclusions[item?.campaignId] = item?.exclusions;
        });
        setDoc(
          doc(campaignFirestore, 'exclusions', date, 'partners', partnerData?.partner_id),
          parseExclusions,
        );
      }

      // if (allIos?.length) {
      //   allIos?.map(async (item: any, index: any) => {
      //     await setDoc(doc(firestore, "sdfs", item['Io Id'], "daily_sdfs", date), item);
      //   })
      // }
      setChangeCampaignData({
        campaignId: null,
        iosId: null,
      });
    }
  }, [changeCampaignData]);

  const getIos = (
    ios: any,
    item: any,
    index: any,
    name: any,
    isMap: any = false,
    campaignIndex: any,
  ) => {
    const iosName = `${name}.ios.${index}`;
    return index + 1 >= ios?.length || (item?.id && item?.id !== '') || (index === 0 && !isMap) ? (
      <div key={`${iosName}.id`} className={styles.campaignsWrapperSecondaryInfo}>
        <div
          className={cn(
            'd-flex align-items-center position-relative mb-5',
            styles.campaignsWrapperTextInputs,
          )}>
          <Controller
            control={control}
            name={`${iosName}.optimized`}
            defaultValue={true}
            render={({ field: { onChange } }) => (
              <Form.Check
                type={'checkbox'}
                disabled={!getValues(iosName)?.id}
                // defaultChecked={true}
                checked={getValues(iosName)?.optimized}
                onChange={(e: any) => {
                  setChangeCampaignData({
                    campaignId: campaignIndex,
                    iosId: index,
                  });
                  onChange(e);
                  let check = true;
                  for (let i = 0; i < ios?.length; i++) {
                    if (ios[i]?.optimized !== undefined && !ios[i]?.optimized) {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      check = false;
                    }
                  }
                  // setValue(`${name}.optimized`, check, {shouldValidate: true})
                }}
                className={styles.campaignsWrapperCheckBox}
              />
            )}
          />
          <Controller
            control={control}
            name={`${iosName}.id`}
            defaultValue=""
            render={({ field: { onChange } }) => (
              <Form.Control
                onBlur={(e: any) => {
                  setChangeCampaignData({
                    campaignId: campaignIndex,
                    iosId: index,
                  });
                  onChange(e);
                }}
                className={cn('ln-1 py-0 px-2 max-w _250 me-3', styles.campaignsWrapperInfoInput)}
                size="sm"
                type="text"
                defaultValue={item?.id}
                placeholder={'IO id'}
              />
            )}
          />
          <Controller
            control={control}
            name={`${iosName}.Name`}
            defaultValue=""
            render={({ field: { onChange } }) => (
              <textarea
                className={cn(
                  'max-w _1000 w-100 font-size _14 ln-1 py-0 px-2',
                  styles.campaignsWrapperInfoInput,
                )}
                onBlur={(e: any) => {
                  setChangeCampaignData({
                    campaignId: campaignIndex,
                    iosId: index,
                  });
                  onChange(e);
                }}
                defaultValue={item?.Name}
                placeholder={'IO name'}
              />
            )}
          />
        </div>
      </div>
    ) : null;
  };

  const getCampaign = (campaigns: any, item: any, index: any, isMap: any = false) => {
    const name = `campaigns.${index}`;
    return index + 1 >= campaigns?.length ||
      (item?.campaignId && item?.campaignId !== '') ||
      (index === 0 && !isMap) ? (
      <div key={`${name}.campaignId`} className="position-relative mb-5">
        <div
          className={cn(
            'd-flex align-items-center position-relative',
            styles.campaignsWrapperTextInputs,
          )}>
          <span className={cn('fw-bold position-absolute', styles.excludedLabel)}>Excluded:</span>
          <Controller
            control={control}
            name={`${name}.optimized`}
            defaultValue={true}
            render={({ field: { onChange } }) => (
              <Form.Check
                type={'checkbox'}
                defaultChecked={true}
                checked={item?.optimized}
                onChange={(e: any) => {
                  onChange(e);
                  setChangeCampaignData({
                    campaignId: item?.campaignId,
                    iosId: 'all',
                  });
                }}
                disabled={!item?.campaignId}
                className={styles.campaignsWrapperCheckBox}
              />
            )}
          />
          <Controller
            control={control}
            name={`${name}.campaignId`}
            defaultValue=""
            render={({ field: { onChange } }) => (
              <Form.Control
                onBlur={(e: any) => {
                  const checkIds = getValues()?.campaigns?.map((item: any) => item?.campaignId);
                  if (!checkIds?.includes(e?.target?.value)) {
                    onChange(e);
                    setChangeCampaignData({
                      campaignId: item?.campaignId,
                      iosId: null,
                    });
                  }
                }}
                className={cn('ln-1 py-0 px-2 max-w _250 me-3', styles.campaignsWrapperInfoInput)}
                size="sm"
                type="text"
                placeholder={'Campaign ID'}
                defaultValue={item?.campaignId}
              />
            )}
          />

          <Controller
            control={control}
            name={`${name}.displayName`}
            render={({ field: { onChange } }) => (
              <Form.Control
                className={cn('max-w _1000 w-100', styles.campaignsWrapperInfoInput)}
                size="sm"
                type="text"
                onBlur={(e) => {
                  onChange(e);
                  setChangeCampaignData({
                    campaignId: item?.campaignId,
                    iosId: null,
                  });
                }}
                placeholder={'Campaign name'}
                defaultValue={item.displayName}
              />
            )}
          />
        </div>
        {item?.ios &&
          item?.ios?.map((iosItem: any, index: any) => {
            return getIos(item?.ios, iosItem, index, name, true, item?.campaignId);
          })}
        {getIosIndex(name) !== item?.ios?.length - 1
          ? getIos(item?.ios, {}, item?.ios?.length || 0, name, false, item?.campaignId)
          : null}
      </div>
    ) : null;
  };

  const getCampaigns = () => {
    const campaigns = searchValue ? showCampaigns : getValues()?.campaigns;
    return (
      campaigns &&
      campaigns?.map((item: any, index: any) => {
        let findIndex = index;

        if (searchValue) {
          for (let i = 0; i < getValues()?.campaigns?.length; i++) {
            if (getValues()?.campaigns[i]?.campaignId === item?.campaignId) {
              findIndex = i;
              break;
            }
          }
        }

        return getCampaign(campaigns, item, findIndex, true);
      })
    );
  };

  const getIndex = () => {
    const campaigns = watch()?.campaigns;
    if (campaigns?.length) {
      const lastCampaign = campaigns[campaigns?.length - 1];
      if (lastCampaign?.campaignId && lastCampaign?.campaignId !== '') {
        return campaigns?.length;
      } else {
        return campaigns?.length - 1;
      }
    } else {
      return 0;
    }
  };

  const searchOptimisation = (search: any) => {
    const campaigns = getValues()?.campaigns;
    if (search && search !== '') {
      const selectCampaigns: any = [];
      for (let i = 0; i < campaigns?.length; i++) {
        if (
          campaigns[i]?.displayName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          campaigns[i]?.campaignId?.toLowerCase()?.includes(search?.toLowerCase())
        ) {
          selectCampaigns.push(campaigns[i]);
        } else {
          const ios = campaigns[i]?.ios;
          let addCompany: any = {};
          const newIos: any = [];
          for (let j = 0; j < ios?.length; j++) {
            if (
              ios[j]?.Name?.toLowerCase()?.includes(search?.toLowerCase()) ||
              ios[j]?.id?.toLowerCase()?.includes(search?.toLowerCase())
            ) {
              newIos.push(ios[j]);
            }
          }

          if (newIos?.length) {
            addCompany = { ...campaigns[i] };
            addCompany.ios = newIos;
            selectCampaigns.push(addCompany);
          }
        }
      }

      setSearchValue(search);
      setShowCampaigns(selectCampaigns);
    } else {
      setSearchValue(null);
      setShowCampaigns(campaigns);
    }
  };

  return (
    <div>
      <Header campaignSetupText={'optimisation management (on/off)'} withButtons={false} />
      <CreateCampaign
        setValue={setValue}
        getValues={getValues}
        customSearch={searchOptimisation}
        onlySearch={true}
        control={control}
        isShowCreate={false}
        isShowStatus={false}
      />
      <div className={styles.campaignsWrapper}>
        <div
          className={cn(
            'd-flex align-items-center justify-content-between fw-bold font-size _18 max-w _400',
            styles.campaignsWrapperTitle,
          )}>
          <p>Campaign ID/IO ID</p>
          <p>Campaign/IO Name</p>
        </div>

        <div className={styles.campaignsWrapperInfoContainer}>
          {getCampaigns()}
          {getIndex() !==
          (searchValue ? showCampaigns?.length - 1 : getValues()?.campaigns?.length - 1)
            ? getCampaign(searchValue ? showCampaigns : getValues()?.campaigns, {}, getIndex())
            : null}
        </div>
      </div>
    </div>
  );
};

export default OptimisationM;
