import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { Form } from 'react-bootstrap';
import { SelectOption } from '../../../types/SelectOption';

interface Props {
  placeholder: string;
  name: `categoryData.${keyof ICreateCampaignForm['categoryData']}` | 'setup.scenarioName';
  options?: Array<SelectOption>;
  register: UseFormRegister<ICreateCampaignForm>;
  disabled?: boolean;
  isInvalid?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLOptionElement>) => void;
}

const CreateCampaignDropdown: FC<Props> = ({
  placeholder,
  name,
  options,
  disabled = false,
  isInvalid = false,
  register,
  value,
  onChange,
}): JSX.Element => {
  return (
    <Form.Select
      {...register(name, {
        onChange,
      })}
      aria-label={placeholder}
      className={'w-auto'}
      disabled={disabled}
      isInvalid={isInvalid}
      value={value}>
      <option value={''}>{placeholder}</option>
      {options?.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </Form.Select>
  );
};

export default CreateCampaignDropdown;
