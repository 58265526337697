import React, { FC } from 'react';
import styles from './CopyRemoveIcons.module.scss';
import cn from 'classnames';
import FileIcon from '../UI/icons/FileIcon';
import StopIcon from '../UI/icons/StopIcon';

interface Props {
  copy?: () => void;
  remove?: () => void;
  copyVisible?: boolean;
  removeVisible?: boolean;
}

const CopyRemoveIcons: FC<Props> = ({ copy, remove, copyVisible = true, removeVisible = true }) => {
  return (
    <div className={cn('d-flex align-items-center ms-4', styles.icons)}>
      {copyVisible && (
        <span onClick={copy}>
          <FileIcon />
        </span>
      )}
      {removeVisible && (
        <span onClick={remove}>
          <StopIcon className={styles.stop} />
        </span>
      )}
    </div>
  );
};

export default CopyRemoveIcons;
