import { useCallback, useEffect, useMemo, useState } from 'react';
import { NamingDetailsFragment } from '../../../graphql/__generated__/graphql';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { getNcTargetingObjectiveName } from '../../../helpers/getNcTargetingObjectiveName';
import { useLazyQuery } from '@apollo/client';
import { LANGUAGE_CODE_QUERY } from '../../../graphql/queries/LanguageCodeQuery';
import { useGraphQLError } from '../../../hooks/useGraphQLError';

const supportedNamingConvetion = [
  'brandName',
  'category',
  'franchiseName',
  'poNumber',
  'nc_division',
  'campaignDescription',
  'nc_li_type',
  'nc_li_subtype',
  'nc_environment',
  'nc_kpi_io_goal',
  'nc_kpi_nc_io_goal',
  'nc_io_format_naming_other',
  'nc_kpi_reporting_goal',
  'nc_cities_geo_template',
  'nc_targeting_objective',
  'creative_list',
  'nc_sustainability',
  'nc_ad_environment',
  'tactic_details',
  'nc_main_tactics',
  'nc_publisher',
  'partners',
  'targeting_age_min',
  'targeting_gender',
  'targeting_age_max',
  'language',
  'device_targeting',
] as const;

const targetings = [
  'affinity_include',
  'in-market_include',
  'first_party_include',
  'third_party_include',
  'custom_audience_list',
  'category_targeting_include',
] as const;

type TargetingType = [string, string[] | undefined];
type FilteredTargetingType = [(typeof targetings)[number], string[] | undefined];

const getTabs = (
  getValues: UseFormGetValues<ICreateCampaignForm>,
  lisAdgsIndex: number,
  lineItemIndex: number,
) => {
  return getValues(`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.variants`);
};

const filterActiveTargetings = (liTargetings: TargetingType[]): FilteredTargetingType[] => {
  const filteredTargetings = liTargetings.filter((item: TargetingType) =>
    targetings.includes(item[0] as (typeof targetings)[number]),
  );
  return filteredTargetings as FilteredTargetingType[];
};

const getEntriesFromTargetCategory = (
  supportedTargetCategories: string[],
  tabs: {
    [x: string]: {
      [x: string]: string[] | undefined;
    };
  },
): FilteredTargetingType[] => {
  let entries: FilteredTargetingType[] = [];
  supportedTargetCategories.forEach((categoryName) => {
    const targetCategoryDetails = tabs[categoryName];
    if (targetCategoryDetails != null) {
      const customEntries: TargetingType[] = Object.entries(targetCategoryDetails);
      const filteredCustomEntries = filterActiveTargetings(customEntries);
      entries = entries.concat(filteredCustomEntries);
    }
  });

  return entries;
};

const getTacticCategory = (
  getValues: UseFormGetValues<ICreateCampaignForm>,
  lisAdgsIndex: number,
  lineItemIndex: number,
  subseparator: string,
) => {
  const tabs = getTabs(getValues, lisAdgsIndex, lineItemIndex);
  let tacticCategory = '';
  let categoryDetails = '';
  if (tabs == null) {
    return [tacticCategory, categoryDetails];
  }

  const categoriesWithImpactOnName = [
    'nc_first_third_party_custom_lists',
    'nc_behavioral',
    'nc_categories',
  ];
  const entries = getEntriesFromTargetCategory(categoriesWithImpactOnName, tabs);

  if (entries.length > 1) {
    tacticCategory = 'group';
  } else if (entries.length === 1) {
    const selectorName = entries[0][0];
    const selectorValues = entries[0][1] || [];

    if (selectorValues.length > 1) {
      categoryDetails = 'na';
    }

    switch (selectorName) {
      case 'affinity_include': {
        tacticCategory = '2p-affinity';
        if (selectorValues.length === 1) {
          categoryDetails = selectorValues[0].replaceAll('&', '').replace(/\s+/g, ' ');
        }
        break;
      }

      case 'in-market_include': {
        tacticCategory = '2p-in-market';
        if (selectorValues.length === 1) {
          categoryDetails = selectorValues[0].replaceAll('&', '').replace(/\s+/g, ' ');
        }
        break;
      }

      case 'first_party_include': {
        // Naming should detect if selected value is a list or not.
        // If it is then it 'na' should be used even with one selected value
        // Currently GUI does not know if selected value is a list or not,
        // therefore 'na' is always used for first_party_include and custom_audience_list
        categoryDetails = 'na';
        tacticCategory = '1p-combined-1p';
        break;
      }

      case 'third_party_include': {
        tacticCategory = '3p-audiences';
        break;
      }

      case 'custom_audience_list': {
        // Naming should detect if selected value is a list or not.
        // If it is then it 'na' should be used even with one selected value
        // Currently GUI does not know if selected value is a list or not,
        // therefore 'na' is always used for first_party_include and custom_audience_list
        categoryDetails = 'na';
        tacticCategory = '2p-custom-lists';
        break;
      }

      case 'category_targeting_include': {
        tacticCategory = '2p-category';
        break;
      }

      default: {
        break;
      }
    }
  }
  categoryDetails = categoryDetails.replaceAll(' ', subseparator);
  return [tacticCategory, categoryDetails];
};

const handleTargetings = (
  variable: (typeof supportedNamingConvetion)[number],
  getValues: UseFormGetValues<ICreateCampaignForm>,
  lisAdgsIndex: number,
  lineItemIndex: number,
  languageCode: string | null,
  separator: string,
  subseparator: string,
): string | null => {
  const tabs = getTabs(getValues, lisAdgsIndex, lineItemIndex);

  switch (variable) {
    case 'nc_publisher': {
      const publisher = getValues(`lisAdgs.${lisAdgsIndex}.publisher`);
      if (publisher) {
        return publisher;
      }
      break;
    }

    case 'targeting_age_max': {
      const demographic = tabs['nc_demographic'];
      if (demographic && demographic['age']?.length) {
        if (demographic['age'].includes('65+')) {
          return '99';
        }
        const ageArr = demographic['age']
          .join('-')
          .replace(/\+/g, '')
          .split('-')
          .sort((a: string, b: string) => +a - +b);
        return ageArr[ageArr?.length - 1];
      } else {
        return '99';
      }
    }
    case 'targeting_age_min': {
      const demographic = tabs['nc_demographic'];
      if (demographic && demographic['age']?.length) {
        const ageArr = demographic['age']
          .join('-')
          .replace(/\+/g, '')
          .split('-')
          .sort((a: string, b: string) => +a - +b);
        return ageArr[0];
      } else {
        return '18';
      }
    }
    case 'partners': {
      const partnerId = getValues(`lisAdgs.${lisAdgsIndex}.partnerId`);
      if (partnerId) {
        return partnerId;
      }
      break;
    }
    case 'device_targeting': {
      const devicesTab = tabs['nc_devices_dv360'];
      let deviceName = 'all';

      if (devicesTab) {
        const devices = devicesTab['devices'];

        if (!devices || devices.length === 0) {
          return 'all';
        }

        const devicesNamings: string[] = [];

        devices.forEach((device) => {
          switch (device) {
            case 'Connected TV':
              devicesNamings.push('ctv');
              break;
            case 'Desktop':
              devicesNamings.push('desk');
              break;
            case 'Mobile':
              devicesNamings.push('mob');
              break;
            case 'Tablet':
              devicesNamings.push('tab');
              break;
          }
        });

        if (devicesNamings.length !== 0) {
          deviceName = devicesNamings.join('-');
        }
      }
      return deviceName;
    }
    case 'targeting_gender': {
      const demographic = tabs['nc_demographic'];
      if (demographic && demographic['gender']?.length) {
        const gender = demographic['gender'];
        if (gender.includes('Male')) {
          if (gender.includes('Female')) {
            return 'm-w';
          } else {
            return 'm';
          }
        } else if (gender.includes('Female')) {
          return 'w';
        } else {
          return 'm-w';
        }
      } else {
        return 'm-w';
      }
    }
    case 'language': {
      const geoLanguage = tabs['nc_geo_language'];
      if (geoLanguage && geoLanguage['languages']?.length) {
        const languagesArr = geoLanguage['languages'];
        if (languagesArr.length > 1) {
          return 'all';
        } else if (languagesArr.length === 1) {
          return languageCode;
        }
      }
      break;
    }
    case 'tactic_details': {
      const [tacticCategory, categoryDetails] = getTacticCategory(
        getValues,
        lisAdgsIndex,
        lineItemIndex,
        subseparator,
      );

      const tacticDetails = getValues(
        `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.tacticDetails`,
      );

      if (tacticDetails) {
        if (tacticCategory) {
          return tacticCategory + separator + tacticDetails;
        }
        return '';
      } else {
        if (tacticCategory == '') {
          break;
        } else if (categoryDetails) {
          return tacticCategory + separator + categoryDetails;
        } else {
          return tacticCategory;
        }
      }
    }
    case 'nc_main_tactics': {
      const mainTactics = getValues(
        `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.mainTactics`,
      );
      if (mainTactics) {
        return mainTactics.split(' ').join('-');
      }
      break;
    }
    case 'nc_cities_geo_template': {
      // const tabs = getValues(`lisAdgs.${lisAdgsIndex}.targetGroups`);
      // const goe = tabs['nc_geo_language']['nc_cities_geo_template']['templateName'];
      // TODO: it is a template used in the nc_geo tab
      //  when template was set then to nc_geo_language the key 'nc_cities_geo_template' was added with value:
      //  {
      //     "microtemplateId": "default",
      //     "templateName": "cites300k"
      //  }
      //   currently we do not support templates
      break;
    }
    case 'creative_list': {
      const creativeLength = getValues(`lisAdgs.${lisAdgsIndex}.creativeLength`);
      if (creativeLength) {
        return creativeLength;
      }
      break;
    }
    case 'brandName': {
      const brand = getValues('categoryData.brandName');
      if (brand) {
        return brand;
      }
      break;
    }
    case 'franchiseName': {
      const name = getValues('setup.franchiseName');
      if (name) {
        return name;
      }
      break;
    }
    case 'poNumber': {
      const po = getValues('setup.po');
      if (po) {
        return po;
      }
      break;
    }
    case 'campaignDescription': {
      const description = getValues('setup.franchiseDescription');
      if (description) {
        return description;
      }
      break;
    }
    case 'nc_li_type': {
      const liType = getValues(`lisAdgs.${lisAdgsIndex}.liType`);
      if (liType) {
        return liType;
      }
      break;
    }
    case 'nc_environment': {
      const env = getValues(`lisAdgs.${lisAdgsIndex}.environment`);
      if (env) {
        return env;
      }
      break;
    }
    case 'nc_sustainability': {
      // it should have a constant value
      return 'tbd';
    }
    case 'nc_ad_environment': {
      // it should have a constant value
      return 'all';
    }
    case 'nc_kpi_io_goal': {
      const ioGoal = getValues(`lisAdgs.${lisAdgsIndex}.kpiIoGoal`);
      if (ioGoal) {
        return ioGoal;
      }
      break;
    }
    case 'nc_kpi_nc_io_goal': {
      const ncIoGoal = getValues(`lisAdgs.${lisAdgsIndex}.kpiNamingIoGoal`);
      if (ncIoGoal) {
        return ncIoGoal;
      }
      break;
    }
    case 'nc_io_format_naming_other': {
      const ioFormatNamingOther = getValues(`lisAdgs.${lisAdgsIndex}.ioFormatOther`);
      if (ioFormatNamingOther) {
        return ioFormatNamingOther;
      }
      break;
    }
    case 'nc_kpi_reporting_goal': {
      const reportingGoal = getValues(`lisAdgs.${lisAdgsIndex}.kpiReportingGoal`);
      if (reportingGoal) {
        return reportingGoal;
      }
      break;
    }
    case 'nc_targeting_objective': {
      const tabs = getTabs(getValues, lisAdgsIndex, lineItemIndex);
      return getNcTargetingObjectiveName(tabs);
    }
    // Line Item and AdGroup name is dependent on only first adgs liSubtype.
    // It might change in future, but for now only first AdGroup is used in DV360.
    case 'nc_li_subtype': {
      const liSubtype = getValues(
        `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs.0.liSubtype`,
      );

      if (liSubtype) {
        return liSubtype;
      }
      break;
    }
    case 'category': {
      const product = getValues(`categoryData.product`);
      if (product) {
        return product;
      }
      break;
    }
    case 'nc_division': {
      const division = getValues(`categoryData.divisionFull`);
      if (division) {
        return division;
      }
      break;
    }
  }

  return null;
};

interface Props {
  initialName?: string;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  lisAdgsIndex: number;
  lineItemIndex: number;
  separator: string;
  subSeparator: string;
  namingConvention: NamingDetailsFragment[];
}

const useLiName = ({
  initialName,
  separator,
  subSeparator,
  namingConvention,
  getValues,
  lisAdgsIndex,
  lineItemIndex,
}: Props): [{ name: string }, () => void] => {
  const [name, setName] = useState<{ name: string }>({ name: initialName ?? '' });
  const [queryLanguageCode, { loading: codeLoading, error: codeError, data: codeData }] =
    useLazyQuery(LANGUAGE_CODE_QUERY);

  useGraphQLError([codeError]);

  const convention = useMemo(() => {
    return namingConvention.filter(
      (naming): naming is { variable: string; elementOrder: number; isMerge: boolean } =>
        naming.variable != null && naming.elementOrder != null && naming.isMerge != null,
    );
  }, [namingConvention]);

  const languageToQuery: string | null = useMemo(() => {
    const hasLanguage = convention.some((item) => item.variable === 'language');
    if (!hasLanguage) {
      return null;
    }

    const tabs = getTabs(getValues, lisAdgsIndex, lineItemIndex);

    const geoLanguage = tabs['nc_geo_language'];
    if (geoLanguage && geoLanguage['languages']?.length) {
      if (geoLanguage['languages'].length === 1) {
        return geoLanguage['languages'][0];
      }
    }

    return null;
  }, [convention]);

  const updateName = useCallback(
    (language: string | null) => {
      let newName = '';

      convention.forEach((item, index) => {
        if (
          supportedNamingConvetion.includes(
            item.variable as (typeof supportedNamingConvetion)[number],
          )
        ) {
          const toAdd = handleTargetings(
            item.variable as (typeof supportedNamingConvetion)[number],
            getValues,
            lisAdgsIndex,
            lineItemIndex,
            language,
            separator,
            subSeparator,
          );

          if (toAdd) {
            const isLast = index === convention.length - 1;
            const currSeparator = isLast ? '' : item.isMerge ? subSeparator : separator;
            newName += toAdd + currSeparator;
          }
        }
      });

      newName = newName.toLocaleLowerCase().replace(' ', subSeparator);
      setName({ name: `${newName}` });
    },
    [
      subSeparator,
      separator,
      lineItemIndex,
      lisAdgsIndex,
      getValues,
      convention,
      supportedNamingConvetion,
      handleTargetings,
      setName,
    ],
  );

  useEffect(() => {
    if (!codeData) {
      return;
    }

    // update name, when language code arrives
    updateName(codeData.languageCode?.code ?? null);
  }, [codeData, updateName]);

  const fetchNewName = useCallback(() => {
    if (languageToQuery && !codeData) {
      // query language code and update name inside useEffect
      if (!codeLoading) {
        queryLanguageCode({ variables: { languageName: languageToQuery } });
      }
      return;
    }

    updateName(codeData?.languageCode?.code ?? null);
  }, [languageToQuery, codeData, codeLoading, updateName]);

  return [name, fetchNewName];
};

export default useLiName;
