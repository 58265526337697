import { gql } from '../__generated__';

export const CAMPAIGN_FREQUENCY_FRAGMENT = gql(`
    fragment CampaignFrequency on FrequencyTemplate {
        scenarioName
        frequency {
          limit
          per
          period
        }
    }
`);
