import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import styles from './SelectBlock.module.scss';
import cn from 'classnames';
import { Controller, useWatch } from 'react-hook-form';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { collection, getDocs, query, where, limit } from 'firebase/firestore/lite';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getDate } from '../../helpers/getQueryParams';

interface Props {
  content: Array<string>;
  title: string;
  subName?: string;
  name: string;
  setValue?: any;
  getValues?: any;
  control?: any;
  watch?: any;
  onChange?: any;
  collectionSource?: string;
  platform?: string;
  isSearchable?: boolean;
  removeLastWord?: boolean;
  withCheck?: boolean;
  withIndex?: boolean;
  withMultiple?: boolean;
  showValue?: any;
  checkMethod?: any;
  isShowChecked?: boolean;
  isRequired?: boolean;
  checkedOptions?: string[];
  changeSelectedOptions?: any;
  disabledOptions?: any;
  searchFields?: string[];
  nc_selector_name?: string;
  VariantInputs?: any;
  withoutMt?: any;
  withoutGap?: any;
  testPoint?: any;
  adgs?: any;
}

const SelectBlock: FC<Props> = ({
  testPoint,
  content,
  withoutMt,
  withoutGap,
  checkMethod,
  showValue,
  isRequired = false,
  changeSelectedOptions,
  nc_selector_name,
  checkedOptions,
  onChange,
  platform = 'dv360',
  collectionSource,
  isSearchable,
  removeLastWord = false,
  withIndex = false,
  withCheck = false,
  withMultiple = true,
  isShowChecked = false,
  title,
  subName,
  watch,
  name,
  setValue,
  getValues,
  disabledOptions,
  control,
  searchFields,
  VariantInputs,
  adgs,
}): JSX.Element => {
  const [shiftStart, setShiftStart] = useState<null | number>(null);
  const [optionContent, setOptionContent] = useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<string>('');
  const { campaignFirestore, masterFirestore } = useContext(FirebaseContext);
  const searchInputRef = useRef(null);
  const previousSearchValue = useRef<string | null>(null);
  const watchName = useWatch({ control, name: name });
  // console.log('testPoint',testPoint.sort((a:any, b:any) => a.name > b.name ? 1 : -1))
  // console.log('sortTest',testPoint.map((item: any, index: number) => item.someProp=`${item?.name} ${index}`)
  //   ?.sort((first:any,second:any) => first?.someProp?.localeCompare(second.someProp)))

  // const sortTestPoint = testPoint.sort((a:any, b:any) => a.name > b.name ? 1 : -1)
  const getSubName = (): string => (subName ? `.${subName}` : '');
  const getIndex = (index: number): string => (withIndex ? `.${index}` : '');

  useEffect(() => {
    if (withCheck && watchName == null) {
      setSelectValue('');
    }
  }, [withCheck, watchName]);

  const getName = (index: number): string => {
    let selectName = name;
    if (getIndex(index) !== '') {
      selectName += getIndex(index);
    }
    if (getSubName() !== '') {
      selectName += getSubName();
    }

    return selectName;
  };

  const handleChange = (value: number): void => {
    if (onChange) {
      onChange(value);
    }
  };

  const setCheckedValue = (e: any, value: string, index: number): void => {
    const isShift = e?.shiftKey;
    if (isShift) {
      if (shiftStart === null) {
        setShiftStart(index);
        setValue(getName(index), withMultiple ? [...getValues(name), value] : value, true);
        console.log('value', value);
        setSelectValue(value);
        handleChange(index);
      } else {
        const arr = [];
        const values = getValues(name);
        const startIndex = index < shiftStart ? index : shiftStart;
        const endIndex = index > shiftStart ? index : shiftStart;
        if (shiftStart !== null) {
          for (let i = startIndex; i <= endIndex; i++) {
            if (!values?.includes(optionContent[i])) {
              arr.push(optionContent[i]);
            }
          }
        }
        setValue(getName(index), withMultiple ? [...getValues(name), ...arr] : value, true);
        setSelectValue(value);
        handleChange(index);
      }
    } else {
      console.log('> selecting touchpoint', 137, value, optionContent);
      setShiftStart(null);
      if (Array.isArray(getValues(name)) && getValues(name)?.includes(value)) {
        setValue(
          getName(index),
          getValues(name)?.filter((item: string) => item !== value),
          true,
        );
        setSelectValue(value);
        handleChange(index);
      } else {
        setValue(
          getName(index),
          withMultiple ? (getValues(name) ? [...getValues(name), value] : [value]) : value,
          true,
        );
        setSelectValue(value);
        handleChange(index);
      }
    }
  };

  useEffect(() => {
    setOptionContent(content);
  }, [content]);

  const findByName = (
    collection: any,
    searchField: string | undefined,
    value: string,
    documentName?: string,
  ) => {
    console.log('Search field', searchField, documentName);
    if (searchField) {
      // console.log(collection, documentName === 'third-party-list' ? 'displayName' : searchField,
      //   '>',
      //   searchField === 'tdb' ? value : `${value.charAt(0).toUpperCase() + value.slice(1)}`);
      return query(
        collection,
        where(
          documentName === 'third-party-list' ? 'displayName' : searchField,
          '>=',
          documentName === 'third-party-list' ? value : `${value}`,
        ),
        where(
          documentName === 'third-party-list' ? 'displayName' : searchField,
          '<=',
          documentName === 'third-party-list' ? value : `${value}\uf8ff`,
        ),
        limit(20),
      );
      //return query(collection(firestore, 'targeting-lists', 'dv360', 'category-list'), where('Category', '>', '/Sports/Team Sports'))
    }
    return null;
  };

  const handleSearch = async (value: string, documentName: string) => {
    if (value === previousSearchValue.current || !campaignFirestore) {
      return;
    }
    const [currentFirestore, targetingList] =
      documentName === 'third-party-list' ||
      documentName === 'first-party-list' ||
      nc_selector_name === 'Third party include' ||
      nc_selector_name === 'Third party exclude' ||
      nc_selector_name === 'First party include' ||
      nc_selector_name === 'First party exclude' ||
      nc_selector_name === 'Custom auidence list'
        ? [campaignFirestore, 'targeting-lists']
        : [masterFirestore, 'global-targeting-lists'];

    const searchField = searchFields?.filter(
      (field: string) => !field.toLowerCase().includes('code'),
    )[0];
    setButtonDisabled(true);
    let currDocument = documentName;
    let colSnapshot = null;
    if (currentFirestore) {
      if (documentName === '{the-date}') {
        const date = getDate(new Date(), true);
        const splitDate: any = date?.split('-');
        currDocument = getDate(new Date(splitDate[0], splitDate[1] - 1, splitDate[2] - 1), true);
        colSnapshot = collection(
          currentFirestore,
          targetingList,
          platform?.toLowerCase(),
          'custom-list',
          getValues()?.categoryData?.brandId?.toString(),
          currDocument,
        );
      } else {
        console.log(documentName, nc_selector_name);
        if (documentName === '{advertiser_id}') {
          const searchDocument =
            nc_selector_name === 'Third party exclude' || nc_selector_name === 'Third party include'
              ? 'third-party-list'
              : nc_selector_name === 'Custom auidence list'
              ? 'custom-list'
              : 'first-party-list';
          if (searchDocument === 'custom-list') {
            // console.log('targetingList',targetingList)
            const date = getDate(new Date(), true);
            const splitDate: any = date?.split('-');
            currDocument = getDate(
              new Date(splitDate[0], splitDate[1] - 1, splitDate[2] - 1),
              true,
            );
            colSnapshot = collection(
              currentFirestore,
              targetingList,
              platform?.toLowerCase(),
              'custom-list',
              getValues()?.categoryData?.brandId?.toString(),
              currDocument,
            );
          } else {
            console.log(
              targetingList,
              platform?.toLowerCase(),
              searchDocument,
              getValues()?.categoryData?.brandId?.toString(),
              currDocument,
            );
            colSnapshot = collection(
              currentFirestore,
              targetingList,
              platform?.toLowerCase(),
              searchDocument,
              'current',
              getValues()?.categoryData?.brandId?.toString(),
            );
          }
        } else {
          colSnapshot = collection(
            currentFirestore,
            targetingList,
            platform?.toLowerCase(),
            documentName,
          );
        }
      }
    }
    let q;
    if (value.includes(' ')) {
      const searchByCodeField = searchFields?.find((field: string) =>
        field.toLowerCase().includes('code'),
      );
      if (searchByCodeField != null && colSnapshot) {
        q = query(
          colSnapshot,
          where(`${searchByCodeField}`, '==', [
            value.trim().toLowerCase(),
            value.trim().toUpperCase(),
          ]),
        );
      } else {
        console.log(200);
        q = findByName(colSnapshot, searchField, value, documentName);
      }
    } else {
      console.log(205);
      q = findByName(colSnapshot, searchField, value, documentName);
    }
    const querySnapshot = q ? await getDocs(q) : null;
    const rawContentData: string[] = [];
    console.log(querySnapshot);
    if (querySnapshot != null) {
      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        // @ts-ignore
        doc.data()[`${searchField === 'tdb' ? 'displayName' : searchField}`] &&
          // @ts-ignore
          rawContentData.push(doc.data()[`${searchField === 'tdb' ? 'displayName' : searchField}`]);
      });
    }
    console.log(rawContentData);
    previousSearchValue.current = value;
    setButtonDisabled(false);
    setOptionContent(rawContentData);
  };

  const handleChangeSearch = (e: any): void => {
    const value = e?.target.value;
    if (value.length >= 3 && !buttonDisabled) {
      handleSearch(value || '', collectionSource || '');
    }
  };

  const handlePressSearch = (e: any): void => {
    const value = e?.target?.value;
    if (e?.keyCode === 13 && value.length >= 3 && !buttonDisabled) {
      handleSearch(value || '', collectionSource || '');
    }
  };

  useEffect(() => {
    if (searchInputRef.current) {
      // @ts-ignore
      searchInputRef.current.value = '';
    }
  }, [title]);

  const removeLast = (str: string) => {
    const lastIndexOfSpace = str.lastIndexOf(' ');

    if (lastIndexOfSpace === -1) {
      return str;
    }

    return str.substring(0, lastIndexOfSpace);
  };

  return (
    <div
      className={cn(
        'd-grid col-lg-3 col-md-4 col-sm-6 flex-column max-w-imp _300',
        styles.selectWrap,
        {
          ['mt-3']: !withoutMt,
          ['gap-2']: !withoutGap,
        },
      )}>
      <p>{title}</p>
      {isSearchable && (
        <InputGroup className={`mb-3 col-2  ${styles.activeTabSearchInput}`}>
          <FormControl
            placeholder="Search"
            aria-label="Search"
            ref={searchInputRef}
            onChange={handleChangeSearch}
            onKeyUp={handlePressSearch}
          />
          <Button
            disabled={buttonDisabled}
            onClick={() => {
              // @ts-ignore
              handleSearch(searchInputRef.current.value || '', collectionSource || '');
            }}
            variant="outline-secondary">
            Search
          </Button>
        </InputGroup>
      )}
      {isShowChecked &&
        checkedOptions?.map((option: string, index: number) => {
          return (
            <span
              key={`checked-${title}-${index}`}
              className={cn(styles.optionStyle, { [styles.active]: true })}
              onClick={(e) => {
                setCheckedValue(e, option, index);
                changeSelectedOptions(title, option);
              }}
              onKeyUp={() => setShiftStart(null)}>
              {option}
            </span>
          );
        })}

      <Controller
        control={control}
        name={name}
        defaultValue={[]}
        rules={{
          required: isRequired,
        }}
        render={() => {
          return (
            <div
              className={cn(
                'border rounded d-flex flex-column h-100 overflow-auto max-h _200 position-relative',
                styles.customFocus,
              )}
              id={'optionsWrapper'}>
              {optionContent?.map((item: any, index: number) => {
                // console.log('optionCONTENT',item)
                const value = typeof item === 'string' ? item : item[showValue];
                let check = false;
                if (checkMethod) {
                  check = checkMethod(value, index);
                }
                if (disabledOptions?.includes(value)) {
                  check = true;
                }
                return (
                  <React.Fragment key={`${title}_${value}_${index}`}>
                    {' '}
                    <span
                      className={cn(styles.optionStyle, {
                        [styles.active]: !withMultiple
                          ? value === selectValue
                          : checkedOptions?.includes(value) ||
                            watch(getName(index))?.includes(value),
                        [styles.disabled]: check,
                      })}
                      onClick={(e: any) => {
                        if (!check) {
                          setCheckedValue(e, value, index);
                          changeSelectedOptions && changeSelectedOptions(title, value);
                        }
                      }}
                      onKeyUp={() => setShiftStart(null)}
                      key={`${title}_${value}_${index}`}>
                      {removeLastWord ? removeLast(value) : value}
                      {adgs && adgs[index].map((ad: any) => <div key={ad.name}>{ad.name}</div>)}
                    </span>
                    {VariantInputs && testPoint ? VariantInputs(index, testPoint[index]?.id) : null}
                  </React.Fragment>
                );
              })}
            </div>
          );
        }}
      />

      {/*<div className={cn('d-flex rounded flex-column gap-1 border border-grey', styles.checkboxWrap)}>*/}
      {/*  {*/}
      {/*    content.map((item: string, index: number) =>*/}
      {/*      <label*/}
      {/*        key={`${title}_${item}_${index}`}*/}
      {/*        className={cn(styles.label, {[styles.active]: selectValues?.includes(item)})}*/}
      {/*        htmlFor={`${title}_${item}_${index}`}*/}
      {/*      >*/}
      {/*        {item}*/}
      {/*        <input*/}
      {/*          type={'checkbox'}*/}
      {/*          onChange={(e: any) => {setValue(e)}}*/}
      {/*          name={item}*/}
      {/*          id={`${title}_${item}_${index}`}*/}
      {/*          value={item}*/}
      {/*        />*/}
      {/*      </label>*/}
      {/*    )*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
  );
};

export default SelectBlock;
