import React, { FC } from 'react';
import cn from 'classnames';
import { Button } from 'react-bootstrap';
import Loader360 from '../UI/Loader/Loader360';
import styles from './FormNavigationButtons.module.scss';

interface Props {
  handleNext?: () => void;
  handlePrevious?: () => void;
  className?: { next?: string };
  isLoading?: boolean;
  isOrange?: boolean;
}

export const FormNavigationButtons: FC<Props> = ({
  handleNext,
  handlePrevious,
  className,
  isLoading,
  isOrange,
}) => {
  return (
    <div className="row mt-3 justify-content-end gap-4">
      {handlePrevious && (
        <Button
          className={cn('col-1 min-w-imp _80', styles.navigationButton)}
          onClick={handlePrevious}>
          Previous
        </Button>
      )}
      {handleNext && (
        <Button
          disabled={isLoading}
          onClick={handleNext}
          className={cn(
            'col-1 min-w-imp _80 d-flex justify-content-center align-items-center',
            className?.next,
            styles.navigationButton,
          )}>
          {!isLoading ? 'Next' : <Loader360 isOrange={isOrange} />}
        </Button>
      )}
    </div>
  );
};
