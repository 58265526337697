import { User } from 'firebase/auth';
import { IPartner } from '../../types/Partner';

export enum LoginStateEnum {
  Init,
  GuiUserLoginInit,
  GuiUserLoggedIn,
  MasterUserLoggedIn,
  PartnerDataReadInit,
  PreLoggedIn, //logged into gui and master projects
  PartnerSet, //partner successfully read from the master project
  PartnerNotFound, //the partner has not been found
  PartnerLoginInit, //the partner login has been initialised
  PartnerLoggedIn, //the partner login has succeeded
  PartnerLoginFailed, //the partner login has failed
  Relogin,
  LoggedOut, //logged out - isn't this the same as "Init"?
}

export interface State {
  state: LoginStateEnum;
  masterUser?: User;
  guiUser?: User;
  campaignUser?: User;
  partner?: IPartner;
}
