import { gql } from '../__generated__';
import { ICreateCampaignBudgetSplit } from '../../types/CreateCampaignForm';
import { BudgetSplitDetailsFragment, PartnerListTypeEnumModel } from '../__generated__/graphql';
import { getFromUTC } from '../../helpers/handleUTCTime';

export const BUDGET_SPLIT_DETAILS_FRAGMENT = gql(`
    fragment BudgetSplitDetails on BudgetSplit {
        partnerId
        budget
        kpiType
        kpiValue
        startDate
        endDate
        isDisabled
        isPartner
        partners
        partnerListType
        segmentsType
        segments {
            budget
            startDate
            endDate
        }
    }
`);

export const budgetSplitFragmentToBudgetSplit = (
  budgetSplit: BudgetSplitDetailsFragment,
  setUniqId: string,
  touchpointUniqId: string,
  setName: string,
  touchpointName: string,
): ICreateCampaignBudgetSplit => {
  return {
    isPartner: budgetSplit.isPartner ?? false,
    partnersType: budgetSplit.partnerListType ?? PartnerListTypeEnumModel.Cascade,
    segmentsType: budgetSplit.segmentsType,
    isDisabled: budgetSplit.isDisabled ?? false,
    partners: budgetSplit.partners?.filter((partner): partner is string => partner != null),
    partner: budgetSplit.partnerId ?? undefined,

    setUniqId: setUniqId,
    touchpointUniqId: touchpointUniqId,
    setName: setName,
    name: touchpointName,
    budget: budgetSplit.budget,
    startDate: getFromUTC(budgetSplit.startDate),
    endDate: getFromUTC(budgetSplit.endDate),

    KPI: budgetSplit.kpiType,
    kpiValue: budgetSplit.kpiValue,
    segmentsList:
      budgetSplit.segments
        ?.filter(
          (
            segment,
          ): segment is NonNullable<NonNullable<BudgetSplitDetailsFragment['segments']>[number]> =>
            segment != null,
        )
        .map((segment) => ({
          startDate: getFromUTC(segment.startDate),
          endDate: getFromUTC(segment.endDate),
          budget: segment.budget,
        })) ?? [],
  };
};
