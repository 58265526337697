import React, { FC } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { Control, useWatch } from 'react-hook-form';
import cn from 'classnames';
import styles from '../PlatformsBrandsProductsSetup.module.scss';
import { FormControl } from 'react-bootstrap';
import { PLATFORMS } from '../GetClientSetup/GetClientSetup';

import { Controller } from 'react-hook-form';

interface Props {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  errors?: any;
  getValues: UseFormGetValues<any>;
  removeItem: (name: string, index: number) => void;
  currentTouchpoint: string | number | null;
  setCurrentTouchpoint: (state: string | number | null) => void;
}

const GetTouchpoints: FC<Props> = ({
  control,
  setValue,
  getValues,
  register,
  removeItem,
  currentTouchpoint,
  setCurrentTouchpoint,
}): JSX.Element => {
  const touchpoints = useWatch({ control, name: `touchpoints` });
  const getSelectVariables = (name: string) => {
    const values: any = [];

    getValues(`creative`)
      ?.variable_values?.find((item: any) => item?.select_variable_value === name)
      ?.variable_values?.filter((item: any) => item?.is_active)
      ?.map((item: any) => {
        if (item?.nc_variable_name && item?.variable_name) {
          values.push({
            id: item?.nc_variable_name,
            name: item?.variable_name,
          });
        }
      });

    return values?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1));
  };

  const checkIsExist = (val: any, name: any) => {
    const vals = getSelectVariables(name);
    const ids = vals?.map((item: any) => item?.id);
    return ids?.includes(val) ? val : 'default';
  };

  const getCreativeNames = (name: string, index: number, parentIndex: number) => {
    return (
      <React.Fragment key={`touchpoint_campaign_objectives_${name}_${index}`}>
        <div className={'row mx-2 mt-4 gap-2'}>
          <div className={'col-4 col-xl-3 col-lg-2'}>
            <FormControl
              placeholder="Creative name"
              aria-label="Creative name"
              type={'text'}
              {...register(`${name}.creative_list.${index}.creative_name`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`${name}.creative_list.${index}.is_active`, true, {
                    shouldValidate: true,
                  });
                }
              }}
            />
          </div>

          <div className={cn('has-validation', styles.fitContent, styles.number)}>
            <Controller
              control={control}
              name={`${name}.creative_list.${index}.Creative length`}
              rules={{
                required: false,
                pattern: {
                  value: /^[A-Za-z0-9+-]+$/g,
                  message: 'Please input correct-value (0-60 or 60+)',
                },
                validate: (value: any) => {
                  if (!value || value === '') {
                    console.log(76);
                    return true;
                  }

                  const splitByPlus = value?.split('+');
                  const splitByMinus = value?.split('-');

                  if (value === '60+') {
                    return true;
                  }

                  if (splitByPlus?.length === 2) {
                    return false;
                  }

                  if (splitByMinus?.length === 2 && splitByMinus[1] === '') {
                    return false;
                  }

                  const val = parseInt(value);
                  if (splitByMinus?.length === 1 && val >= 0 && val <= 60) {
                    return true;
                  }

                  const splitVal = value?.split('-')?.map((item: any) => parseInt(item));
                  if (
                    splitVal?.length === 2 &&
                    splitVal[0] >= 0 &&
                    splitVal[1] <= 60 &&
                    splitVal[1] > splitVal[0]
                  ) {
                    return true;
                  }

                  return false;
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }: any) => (
                <FormControl
                  placeholder="Length"
                  aria-label="Length"
                  type={'text'}
                  onChange={(e: any) => {
                    onChange(e?.target?.value);
                  }}
                  value={getValues(`${name}.creative_list.${index}.Creative length`)}
                  isInvalid={!!error}
                  onBlur={(e: any) => {
                    const value = e?.target?.value;
                    if (value && value != '') {
                      setValue(`${name}.creative_list.${index}.is_active`, true, {
                        shouldValidate: true,
                      });
                    }
                  }}
                />
              )}
            />
            <div className="invalid-feedback">Please input correct-value (0-60 or 60+)</div>
          </div>

          {/*<div className={cn('max-w _200', styles.fitContent)}>*/}
          {/*  <select*/}
          {/*    {...register(`${name}.creative_list.${index}.Video Ad Format`)}*/}
          {/*    className="form-select col-3 gap-2"*/}
          {/*    aria-label="Video Ad Format"*/}
          {/*    defaultValue='default'*/}
          {/*    value={getValues(`${name}.creative_list.${index}.Video Ad Format`)}*/}
          {/*    onChange={(e: any) => {*/}
          {/*      setValue(`${name}.creative_list.${index}.Video Ad Format`, e?.target?.value)*/}
          {/*      setValue(`${name}.creative_list.${index}.is_active`, e?.target?.value)*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <option value="default">Video Ad Format</option>*/}
          {/*    {*/}
          {/*      getSelectVariables('video_ad_format')*/}
          {/*        ?.map((kpi: any, index2: number) =>*/}
          {/*          <option key={`touchpoints_kpi_li_type_${name}_${index}_${index2}`} value={kpi?.id}>{kpi?.name}</option>*/}
          {/*        )*/}
          {/*    }*/}
          {/*  </select>*/}
          {/*</div>*/}

          {/*<div className={cn(styles.fitContent)}>
            <select
              className="form-select col-3 gap-2"
              aria-label="For Action"
              defaultValue='default'
              value={checkIsExist(getValues(`${name}.creative_list.${index}.Publisher`), 'nc_publisher') || 'default'}
              onChange={(e: any) => {
                setValue(`${name}.creative_list.${index}.Publisher`, e?.target?.value)
                setValue(`${name}.creative_list.${index}.is_active`, e?.target?.value)
              }}
            >
              <option value="default">Publisher LI naming</option>
              {
                getSelectVariables('nc_publisher')?.map((kpi: any, index2: number) =>
                  <option key={`touchpoints_nc_publishers_${name}_${index}_${index2}`} value={kpi?.id}>{kpi?.name}</option>
                )
              }
            </select>
          </div>*/}

          <div className={'col-1 d-flex align-items-center'}>
            {touchpoints?.length >= parentIndex &&
            touchpoints[parentIndex]?.creative_list?.length >= index &&
            touchpoints[parentIndex]?.creative_list[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() => removeItem(`${name}.creative_list`, index)}
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const removeTouchpoint = (name: string, index: number) => {
    const currentValue = JSON.parse(JSON.stringify(getValues(`${name}.${index}`)));
    const copyObject = JSON.parse(JSON.stringify(getValues('touchpoints')));

    for (let i = 0; i < copyObject?.length; i++) {
      if (copyObject[i]?.targeting_compatibility?.length) {
        const newCompatibility: any = [];
        for (let j = 0; j < copyObject[i]?.targeting_compatibility?.length; j++) {
          const currentCompatibility = copyObject[i]?.targeting_compatibility[j];
          if (currentCompatibility?.name !== currentValue?.name) {
            newCompatibility?.push(currentCompatibility);
          }
        }
        copyObject[i].targeting_compatibility = newCompatibility;
      }
    }

    if (currentTouchpoint && currentTouchpoint !== 'default') {
      if (currentTouchpoint === index) {
        setCurrentTouchpoint(null);
      } else if (currentTouchpoint && +currentTouchpoint > index) {
        setCurrentTouchpoint(+currentTouchpoint - 1);
      }
    }

    setValue('touchpoints', copyObject);

    const copyCO = JSON.parse(JSON.stringify(getValues('campaign_objectives')));
    const newCO = copyCO?.map((item: any) => ({
      ...item,
      creative_list: item?.creative_list?.filter(
        (item: any) => item?.creative_name !== currentValue?.name,
      ),
    }));

    console.log(newCO);

    setValue('campaign_objectives', newCO);

    removeItem(name, index);
  };

  const getTouchpoints = (index: number) => {
    // const getFormats = () => {
    //   const kpis: any = [];
    //   getValues(`creative`)
    //     ?.variable_values?.find((item: any) => item?.select_variable_value === 'item_format')
    //     ?.variable_values?.filter((item: any) => item?.is_active)
    //     ?.map((item: any) => {
    //       if (item?.nc_variable_name && item?.variable_name) {
    //         kpis.push({
    //           id: item?.nc_variable_name,
    //           name: item?.variable_name,
    //         });
    //       }
    //     });
    //   // getValues(`touchpoint_formats`)
    //   //   ?.filter((item: any) => item?.is_active)
    //   //   ?.map((item: any) => {
    //   //     if (item?.nc_touchpoint_format && item?.touchpoint_format) {
    //   //       kpis.push({
    //   //         id: item?.nc_touchpoint_format,
    //   //         name: item?.touchpoint_format,
    //   //       })
    //   //     }
    //   //   })

    //   return kpis;
    // };

    // const getTypes = () => {
    //   const kpis: any = [];

    //   getValues(`creative`)
    //     ?.variable_values?.find((item: any) => item?.select_variable_value === 'io_type')
    //     ?.variable_values?.filter((item: any) => item?.is_active)
    //     ?.map((item: any) => {
    //       if (item?.nc_variable_name && item?.variable_name) {
    //         kpis.push({
    //           id: item?.nc_variable_name,
    //           name: item?.variable_name,
    //         });
    //       }
    //     });

    //   // getValues(`touchpoint_types`)
    //   //   ?.filter((item: any) => item?.is_active)
    //   //   ?.map((item: any) => {
    //   //     if (item?.nc_touchpoint_type_name && item?.touchpoint_type_name) {
    //   //       kpis.push({
    //   //         id: item?.nc_touchpoint_type_name,
    //   //         name: item?.touchpoint_type_name,
    //   //       })
    //   //     }
    //   //   })

    //   return kpis;
    // };

    return (
      <React.Fragment key={`touchpoints_${index}`}>
        <div className={cn(styles.verticalGap, styles.tpWrap, 'row mt-2 w-100')}>
          <div className={'col-4'}>
            <FormControl
              placeholder="Touchpoint name"
              aria-label="Touchpoint name"
              type={'text'}
              disabled={true}
              {...register(`touchpoints.${index}.name`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  // setValue(`touchpoints.${index}.index`, index, {shouldValidate: true})
                  setValue(`touchpoints.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>

          {/*<div className={cn(styles.fitContent)}>*/}
          {/*  <FormControl*/}
          {/*    placeholder="nc_touchpoint"*/}
          {/*    aria-label="nc_touchpoint"*/}
          {/*    type={'text'}*/}
          {/*    {...register(`touchpoints.${index}.nc_touchpoint`)}*/}
          {/*    onBlur={(e: any) => {*/}
          {/*      const value = e?.target?.value;*/}
          {/*      if (value && value != '') {*/}
          {/*        setValue(`touchpoints.${index}.is_active`, true, {shouldValidate: true})*/}
          {/*      }*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className={cn(styles.fitContent)}>
            <select
              className="form-select col-3 gap-2"
              aria-label="Platform"
              defaultValue="default"
              value={getValues(`touchpoints.${index}.Platform`) || 'default'}
              onChange={(e: any) => {
                setValue(`touchpoints.${index}.Platform`, e?.target?.value);
                setValue(`touchpoints.${index}.is_active`, e?.target?.value);
              }}>
              <option value="default">Platform</option>
              {PLATFORMS?.sort()?.map((platform: string, index2: number) => (
                <option key={`touchpoints_platform_${index}_${index2}`} value={platform}>
                  {platform}
                </option>
              ))}
            </select>
          </div>

          <div className={cn(styles.fitContent)}>
            <select
              className="form-select col-3 gap-2"
              aria-label="KPI - Type"
              defaultValue="default"
              value={
                checkIsExist(
                  getValues(`touchpoints.${index}.KPI Naming IO goal`),
                  'nc_kpi_nc_io_goal',
                ) || 'default'
              }
              onChange={(e: any) => {
                setValue(`touchpoints.${index}.KPI Naming IO goal`, e?.target?.value);
                setValue(`touchpoints.${index}.is_active`, e?.target?.value);
              }}>
              <option value="default">KPI Naming</option>
              {getSelectVariables('nc_kpi_nc_io_goal')?.map((kpi: any, index2: number) => (
                <option key={`touchpoints_kpi_${index}_${index2}`} value={kpi?.id}>
                  {kpi?.name}
                </option>
              ))}
            </select>
          </div>

          <div className={cn(styles.fitContent)}>
            <select
              className="form-select col-3 gap-2"
              aria-label="KPI IO goal"
              defaultValue="default"
              value={
                checkIsExist(getValues(`touchpoints.${index}.KPI IO goal`), 'nc_kpi_io_goal') ||
                'default'
              }
              onChange={(e: any) => {
                setValue(`touchpoints.${index}.KPI IO goal`, e?.target?.value);
                setValue(`touchpoints.${index}.is_active`, e?.target?.value);
              }}>
              <option value="default">KPI perf. goal</option>
              {getSelectVariables('nc_kpi_io_goal')?.map((kpi: any, index2: number) => (
                <option key={`touchpoints_kpi_pref_${index}_${index2}`} value={kpi?.id}>
                  {kpi?.name}
                </option>
              ))}
            </select>
          </div>

          <div className={cn(styles.fitContent)}>
            <select
              className="form-select col-3 gap-2"
              aria-label="KPI rep. goal"
              defaultValue="default"
              value={
                checkIsExist(
                  getValues(`touchpoints.${index}.KPI Reporting goal`),
                  'nc_kpi_reporting_goal',
                ) || 'default'
              }
              onChange={(e: any) => {
                setValue(`touchpoints.${index}.KPI Reporting goal`, e?.target?.value);
                setValue(`touchpoints.${index}.is_active`, e?.target?.value);
              }}>
              <option value="default">KPI rep. goal</option>
              {getSelectVariables('nc_kpi_reporting_goal')?.map((kpi: any, index2: number) => (
                <option key={`touchpoints_kpi_reporting_${index}_${index2}`} value={kpi?.id}>
                  {kpi?.name}
                </option>
              ))}
            </select>
          </div>

          <div className={cn(styles.fitContent)}>
            <select
              className="form-select col-3 gap-2"
              aria-label="LI Type"
              defaultValue="default"
              value={
                checkIsExist(getValues(`touchpoints.${index}.LI Type`), 'nc_li_type') || 'default'
              }
              onChange={(e: any) => {
                setValue(`touchpoints.${index}.LI Type`, e?.target?.value);
                setValue(`touchpoints.${index}.is_active`, e?.target?.value);
              }}>
              <option value="default">LI Type</option>
              {getSelectVariables('nc_li_type')?.map((kpi: any, index2: number) => (
                <option key={`touchpoints_kpi_li_type_${index}_${index2}`} value={kpi?.id}>
                  {kpi?.name}
                </option>
              ))}
            </select>
          </div>

          <div className={cn(styles.fitContent)}>
            <select
              className="form-select col-3 gap-2"
              aria-label="LI Subtype"
              defaultValue="default"
              value={
                checkIsExist(getValues(`touchpoints.${index}.LI Subtype`), 'nc_li_subtype') ||
                'default'
              }
              onChange={(e: any) => {
                setValue(`touchpoints.${index}.LI Subtype`, e?.target?.value);
                setValue(`touchpoints.${index}.is_active`, e?.target?.value);
              }}>
              <option value="default">LI Subtype</option>
              {getSelectVariables('nc_li_subtype')?.map((kpi: any, index2: number) => (
                <option key={`touchpoints_kpi_li_subtype_${index}_${index2}`} value={kpi?.id}>
                  {kpi?.name}
                </option>
              ))}
            </select>
          </div>

          <div className={cn(styles.fitContent)}>
            <select
              className="form-select col-3 gap-2"
              aria-label="IO format other"
              defaultValue="default"
              value={
                checkIsExist(
                  getValues(`touchpoints.${index}.IO format other`),
                  'nc_io_format_naming_other',
                ) || 'default'
              }
              onChange={(e: any) => {
                setValue(`touchpoints.${index}.IO format other`, e?.target?.value);
                setValue(`touchpoints.${index}.is_active`, e?.target?.value);
              }}>
              <option value="default">IO format other</option>
              {getSelectVariables('nc_io_format_naming_other')?.map((kpi: any, index2: number) => (
                <option key={`touchpoints_kpi_io_format_other_${index}_${index2}`} value={kpi?.id}>
                  {kpi?.name}
                </option>
              ))}
            </select>
          </div>

          <div className={cn(styles.fitContent)}>
            <select
              className="form-select col-3 gap-2"
              aria-label="For Action"
              defaultValue="default"
              value={
                checkIsExist(getValues(`touchpoints.${index}.Publisher`), 'nc_publisher') ||
                'default'
              }
              onChange={(e: any) => {
                setValue(`touchpoints.${index}.Publisher`, e?.target?.value);
                setValue(`touchpoints.${index}.is_active`, e?.target?.value);
              }}>
              <option value="default">Publisher LI naming</option>
              {getSelectVariables('nc_publisher')?.map((kpi: any, index2: number) => (
                <option key={`touchpoints_nc_publishers_${index}_${index2}`} value={kpi?.id}>
                  {kpi?.name}
                </option>
              ))}
            </select>
          </div>

          {/*<div className={cn(styles.fitContent)}>*/}
          {/*  <select*/}
          {/*    {...register(`touchpoints.${index}.Environment`)}*/}
          {/*    className="form-select col-3 gap-2"*/}
          {/*    aria-label="Environment"*/}
          {/*    defaultValue='default'*/}
          {/*    value={getValues(`touchpoints.${index}.Environment`)}*/}
          {/*    onChange={(e: any) => {*/}
          {/*      setValue(`touchpoints.${index}.Environment`, e?.target?.value)*/}
          {/*      setValue(`touchpoints.${index}.is_active`, e?.target?.value)*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <option value="default">Environment</option>*/}
          {/*    {*/}
          {/*      getSelectVariables('nc_environment')?.map((kpi: any, index2: number) =>*/}
          {/*        <option key={`touchpoints_kpi_environment_${index}_${index2}`} value={kpi?.id}>{kpi?.name}</option>*/}
          {/*      )*/}
          {/*    }*/}
          {/*  </select>*/}
          {/*</div>*/}

          {/*<div className={cn(styles.fitContent)}>*/}
          {/*  <FormControl*/}
          {/*    placeholder="Default KPI val."*/}
          {/*    aria-label="Default KPI val."*/}
          {/*    type={'text'}*/}
          {/*    {...register(`touchpoints.${index}.Default KPI val`)}*/}
          {/*    onBlur={(e: any) => {*/}
          {/*      const value = e?.target?.value;*/}
          {/*      if (value && value != '') {*/}
          {/*        setValue(`touchpoints.${index}.is_active`, true, {shouldValidate: true})*/}
          {/*      }*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}

          {/*<div className={'col-2'}>*/}
          {/*  <select*/}
          {/*    {...register(`touchpoints.${index}.item_format`)}*/}
          {/*    className="form-select col-3 gap-2"*/}
          {/*    aria-label="item_format"*/}
          {/*    defaultValue='default'*/}
          {/*    value={getValues(`touchpoints.${index}.item_format`)}*/}
          {/*    onChange={(e: any) => {*/}
          {/*      setValue(`touchpoints.${index}.item_format`, e?.target?.value)*/}
          {/*      setValue(`touchpoints.${index}.is_active`, e?.target?.value)*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <option value="default">item_format</option>*/}
          {/*    {*/}
          {/*      getFormats()?.map((format: any, index2: number) =>*/}
          {/*        <option key={`touchpoints_format_${index}_${index2}`} value={format?.id}>{format?.name}</option>*/}
          {/*      )*/}
          {/*    }*/}
          {/*  </select>*/}
          {/*</div>*/}

          {/*<div className={'col-2'}>*/}
          {/*  <select*/}
          {/*    {...register(`touchpoints.${index}.IO_Type`)}*/}
          {/*    className="form-select col-3 gap-2"*/}
          {/*    aria-label="IO_Type"*/}
          {/*    defaultValue='default'*/}
          {/*    value={getValues(`touchpoints.${index}.IO_Type`)}*/}
          {/*    onChange={(e: any) => {*/}
          {/*      setValue(`touchpoints.${index}.IO_Type`, e?.target?.value)*/}
          {/*      setValue(`touchpoints.${index}.is_active`, e?.target?.value)*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <option value="default">IO_Type</option>*/}
          {/*    {*/}
          {/*      getTypes()?.map((item: any, index2: number) =>*/}
          {/*        <option key={`touchpoints_IO_Type_${index}_${index2}`} value={item?.id}>{item?.name}</option>*/}
          {/*      )*/}
          {/*    }*/}
          {/*  </select>*/}
          {/*</div>*/}

          <div className={cn(styles.deleteContent, 'col-1 d-flex align-items-center')}>
            {touchpoints?.length >= index && touchpoints[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() => removeTouchpoint('touchpoints', index)}
              />
            ) : null}
          </div>
        </div>

        {touchpoints?.length >= index && touchpoints[index]?.creative_list
          ? touchpoints[index]?.creative_list
              ?.filter((itemCreativeName: any) => itemCreativeName?.is_active)
              ?.map((item: any, index2: number) => {
                return getCreativeNames(`touchpoints.${index}`, index2, index);
              })
          : null}
        {getCreativeNames(
          `touchpoints.${index}`,
          touchpoints?.length >= index + 1 && touchpoints[index]?.creative_list
            ? touchpoints[index]?.creative_list?.filter(
                (itemTouchpoint: any) =>
                  Object.prototype.hasOwnProperty.call(itemTouchpoint, 'isActive') &&
                  itemTouchpoint?.is_active,
              )?.length
            : 0,
          index,
        )}
      </React.Fragment>
    );
  };

  // const getTps = () => {
  //   return getValues()?.campaign_objectives
  //     ?.map((item: any) => item?.creative_list?.map((item2: any) => item2?.creative_name))
  //     ?.flat(Infinity)
  //     ?.filter((item: string) => item && item !== '')
  //     ?.sort()
  // }

  return (
    <>
      <div className={'row'}>
        <p className="mt-3 col-12">Touchpoints</p>
        {touchpoints?.length
          ? touchpoints
              ?.filter((itemTouchpoint: any) => itemTouchpoint?.is_active)
              ?.map((item: any, index: number) => {
                return getTouchpoints(index);
              })
          : null}
        {/*{getTouchpoints(touchpoints?.filter((itemTouchpoint: any) => itemTouchpoint?.hasOwnProperty('is_active') && itemTouchpoint?.is_active)?.length || 0)}*/}
      </div>
    </>
  );
};

export default GetTouchpoints;
