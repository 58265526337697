import React from 'react';
import useWatchAndTrigger from '../hooks/useWatchAndTrigger';
import { Control, FieldPath, FieldValues, UseFormTrigger } from 'react-hook-form';

interface Props<T extends FieldValues> {
  control: Control<T>;
  trigger: UseFormTrigger<T>;
  name: FieldPath<T>;
}

export const WatchAndTrigger = <T extends FieldValues>({ control, trigger, name }: Props<T>) => {
  useWatchAndTrigger<T>(control, name, trigger);

  return <></>;
};
