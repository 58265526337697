import React, { FC } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { ICreateCampaignForm } from '../../../../types/CreateCampaignForm';
import FrequencyInput from '../../../FrequencyInput/FrequencyInput';

interface Props {
  name:
    | 'setup.frequency'
    | `sets.${number}.touchpoints.${number}.frequency`
    | `lisAdgs.${number}.lineItems.${number}.frequency`;
  isDisabled?: boolean;
  register: UseFormRegister<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  isInvalid?: {
    limit?: boolean;
    per?: boolean;
    period?: boolean;
  };
}

const LimitFrequency: FC<Props> = ({
  register,
  name,
  setValue,
  isDisabled = false,
  isInvalid,
}): JSX.Element => {
  return (
    <FrequencyInput
      limitLabel={'Limit frequency to'}
      periodDefault={'frequency period'}
      name={name}
      setValue={setValue}
      register={register}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isViewFrequency={false}
    />
  );
};

export default LimitFrequency;
