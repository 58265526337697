import axios from 'axios';
import { FirebaseApp } from 'firebase/app';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import UploadBlock from '../UI/UploadBlock/UploadBlock';
import { Control, useFormState } from 'react-hook-form';
import { IAdsSetupForm } from '../../types/AdsSetupForm';
import { getHeaders } from '../../helpers/getHeaders';

interface Props {
  campaignId: string;
  firebaseGui: FirebaseApp;
  refreshData: () => void;
  control: Control<IAdsSetupForm>;
}

const UploadAdsBlock: FC<Props> = ({
  control,
  campaignId,
  firebaseGui,
  refreshData,
}): JSX.Element => {
  const [uploading, setUploading] = useState(false);

  const { dirtyFields } = useFormState({ control });

  const isBlocked = dirtyFields.touchpoints ? true : false;

  const handleExcel = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (uploading || isBlocked) {
      return;
    }

    if (e.target.files === null || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files.item(0);
    if (file === null) {
      return;
    }
    const headers = await getHeaders(firebaseGui);

    const url = process.env?.REACT_APP_BACKEND_URL;
    const fileData = new FormData();
    fileData.append('upload', file);
    setUploading(true);
    await axios
      .post(`${url}/campaigns/${campaignId}/upload`, fileData, {
        headers: {
          ...headers,
        },
      })
      .then(() => {
        setUploading(false);
        refreshData();
      })
      .catch(() => {
        toast.error('Something went wrong. Please try again later.');
        setUploading(false);
      });
  };

  return (
    <div className="col-3">
      <UploadBlock onUpload={handleExcel} isLoading={uploading} disabled={isBlocked} />
      {isBlocked && (
        <span className="text-danger">If you want to upload file, save your data first!</span>
      )}
    </div>
  );
};
export default UploadAdsBlock;
