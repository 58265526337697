import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

const generalError = 'An unexpected error occurred';

export const useGraphQLError = (
  deps: (ApolloError | undefined)[],
  handlers?: [{ code: string; handler: (errMessage: string) => void }],
) => {
  useEffect(() => {
    deps.forEach((el) => {
      if (el !== undefined) {
        el.graphQLErrors.map((error) => {
          if (!error?.extensions?.code) {
            if (error?.message) {
              toast.error(error.message);
              return;
            }
            toast.error(generalError);
            return;
          }
          if (error.extensions.code == 'INVALID OBJECT MODIFICATION') {
            toast.error(
              'The changes cannot be saved. The campaign has been changed in another session.',
            );
            return;
          }

          if (handlers) {
            for (const handlerElem of handlers) {
              if (handlerElem.code == error.extensions.code) {
                handlerElem.handler(error.message ?? generalError);
                return;
              }
            }
          }

          if (error?.message) {
            toast.error(error.message);
          } else {
            toast.error('Internal server error. Please contact the stystem maintainer.');
          }
        });
      }
    });
  }, deps);
};
