import { gql, useFragment } from '../__generated__';
import { GetUpsertListAdgsQuery } from '../__generated__/graphql';
import {
  ICreateCampaignLisAdgs,
  ICreateCampaignLisAdgsPartial,
  ICreateCampaignLisAdgsTargetGroups,
} from '../../types/CreateCampaignForm';
import { LINE_ITEM_DETAILS_FRAGMENT } from '../fragments/LineItemDetailsFragment';
import { CAMPAIGN_SET_TABS_FOR_VARIANTS_FRAGMENT } from '../fragments/CampaignSetTabsForVariantsFragment';
import { LINE_ITEM_FREQUENCY_FRAGMENT } from '../fragments/LineItemFrequencyFragment';
import { CAMPAIGN_SETUP_FRAGMENT } from '../fragments/CampaignSetupFragment';

export const UPSERT_LIS_ADGS_QUERY = gql(`
    query GetUpsertListAdgs($campaignId: String!) {
        campaign(campaignId: $campaignId) {
            ...CampaignSetup
            ...CampaignCategory
            sets {
                uniqId
                name
                ...CampaignSetTabsForVariants
                touchpoints {
                    id
                    name
                    liType
                    liSubtype
                    environment
                    kpiIoGoal
                    ioFormatOther
                    creatives{
                      id
                      publisher
                    }
                    kpiReportingGoal
                    kpiNamingIoGoal
                    creativeLength
                    ncTouchpointName
                    budgetSplits {
                        partnerId
                        ncTouchpointName
                        isDisabled
                    }
                    frequency {
                        limit
                        per
                        period
                    }
                }
                variants {
                    name
                    targetGroups {
                        type
                        subType
                        values
                    }
                }
            }
            lineItemsGroups {
                setId
                name
                ncTouchpointName
                lineItems {
                    ...LineItemDetails
                }
            }
            updatedAt
        }
       mainTactics: variables(type: "nc_main_tactics") {
            ...VariableName
        }
        liSubtypes: variables(type: "nc_li_subtype") {
            ...VariableName
        }
        namingLineItem: namingConventions(type: FILE_TYPE_LINE_ITEM) {
            separatorCharacter
            separatorCharacterSubtitle
            conventions {
                ...NamingDetails
            }
        }
        namingAdGroup: namingConventions(type: FILE_TYPE_AD_GROUP) {
            conventions {
                ...NamingDetails
            }
        }
        frequencyTemplates (type: LINE_ITEM_LEVEL) {
          ...LineItemFrequency
        }
    }
`);

export const upsertLisAdgsToLisAdgs = (
  data: GetUpsertListAdgsQuery,
): ICreateCampaignLisAdgsPartial[] => {
  const rawLineItems = getRawLineItems(data);

  // merge previously saved line items with raw line items
  data.campaign?.lineItemsGroups?.forEach((lineItemGroup) => {
    lineItemGroup?.lineItems?.forEach((lineItem) => {
      const lineItemFragment = useFragment(LINE_ITEM_DETAILS_FRAGMENT, lineItem);
      if (!lineItemFragment) {
        return;
      }

      const lisAdgsIndex = rawLineItems.findIndex(
        (lisAdg) =>
          lisAdg.setId === lineItemGroup?.setId &&
          lisAdg.partnerId === (lineItemFragment.partnerId ?? undefined) &&
          lisAdg.touchpointId === lineItemFragment.touchpointId,
      );

      if (lisAdgsIndex === -1) {
        return;
      }

      const lineItemIndex = rawLineItems[lisAdgsIndex].lineItems?.findIndex(
        (resultLineItem) => resultLineItem.variantName === lineItemFragment.variantName,
      );
      if (lineItemIndex === undefined || lineItemIndex === -1) {
        return;
      }

      rawLineItems[lisAdgsIndex].lineItems[lineItemIndex] = {
        ...rawLineItems[lisAdgsIndex].lineItems[lineItemIndex],
        uniqId: lineItemFragment.uniqId,
        variantName: lineItemFragment.variantName,
        name: lineItemFragment.name,
        frequency: {
          per: lineItemFragment.frequency?.per?.toString(),
          limit: lineItemFragment.frequency?.limit?.toString(),
          period: lineItemFragment.frequency?.period ?? undefined,
        },
        view: {
          limit: lineItemFragment.view?.limit?.toString(),
          period: lineItemFragment.view?.period ?? undefined,
        },
        mainTactics: lineItemFragment.mainTactics ?? '',
        tacticDetails: lineItemFragment.tacticDetails ?? '',
        adgs:
          lineItemFragment.adgs
            ?.filter((adg): adg is { name: string; liSubtype: string } => adg != null)
            ?.map((adg) => ({
              name: adg.name,
              liSubtype: adg.liSubtype,
            })) ?? [],
      };
    });
  });
  return rawLineItems;
};

const getRawLineItems = (data: GetUpsertListAdgsQuery): ICreateCampaignLisAdgs[] => {
  const scenarioName = useFragment(CAMPAIGN_SETUP_FRAGMENT, data.campaign)?.usersNamingSection;
  const frequencyTemplates = data.frequencyTemplates?.map((template) => {
    const castedTemplate = useFragment(LINE_ITEM_FREQUENCY_FRAGMENT, template);
    if (castedTemplate.scenarioName !== scenarioName) {
      return;
    }
    return castedTemplate;
  });
  return (
    data.campaign?.sets
      .map((set) => {
        const variants = set.variants ?? [];
        const targetGroups = getTargetGroups(
          useFragment(CAMPAIGN_SET_TABS_FOR_VARIANTS_FRAGMENT, set).targetGroups ?? [],
        );

        return set.touchpoints
          .filter((touchpoint) => touchpoint.id != null && touchpoint.name != null)
          .map((touchpoint) => {
            return (
              touchpoint.budgetSplits
                ?.filter((split) => split != null && split.isDisabled !== true)
                .map((split) => {
                  const tcFrequencyTemplate = frequencyTemplates?.find(
                    (template) => template?.touchpointName === touchpoint.name,
                  );
                  return {
                    setId: set.uniqId,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    touchpointId: touchpoint.id!,
                    name: `${set.name} ${touchpoint.name} ${split?.partnerId ?? ''}`,
                    // used during line items/adgs names creation
                    liType: touchpoint.liType ?? '',
                    liSubtype: touchpoint.liSubtype ?? '',
                    environment: touchpoint.environment ?? '',
                    ioFormatOther: touchpoint.ioFormatOther ?? '',
                    kpiIoGoal: touchpoint.kpiIoGoal ?? '',
                    kpiReportingGoal: touchpoint.kpiReportingGoal ?? '',
                    kpiNamingIoGoal: touchpoint.kpiNamingIoGoal ?? '',
                    creativeLength: touchpoint.creativeLength ?? '',
                    targetGroups: targetGroups,
                    partnerId: split?.partnerId ?? undefined,
                    ncTouchpointName: split?.ncTouchpointName ?? touchpoint.ncTouchpointName ?? '',
                    publisher: touchpoint?.creatives?.[0]?.publisher ?? '',
                    lineItems: variants.map((variant) => ({
                      uniqId: undefined,
                      variantName: variant.name,
                      variants: getVariants(variant.targetGroups),
                      name: '',

                      // line items default frequency is taken from their touchpoint
                      frequency: {
                        per: tcFrequencyTemplate?.frequency.per?.toString(),
                        limit: tcFrequencyTemplate?.frequency?.limit?.toString(),
                        period: tcFrequencyTemplate?.frequency?.period ?? undefined,
                      },
                      view: {
                        limit: tcFrequencyTemplate?.trueView?.limit?.toString(),
                        period: tcFrequencyTemplate?.trueView?.period ?? undefined,
                      },
                      mainTactics: '',
                      tacticDetails: '',
                      adgs: [],
                    })),
                  };
                })
                .flat() ?? []
            );
          })
          .flat();
      })
      .flat() ?? []
  );
};

const getTargetGroups = (
  tabs: { type: string; subType: string; values: string[] }[],
): ICreateCampaignLisAdgsTargetGroups => {
  const result: ICreateCampaignLisAdgsTargetGroups = {};
  tabs.forEach((tab) => {
    if (result[tab.type] === undefined) {
      result[tab.type] = {};
    }
    result[tab.type][tab.subType] = tab.values;
  });

  return result;
};

const getVariants = (
  tabs: { type: string; subType: string; values: string[] }[],
): ICreateCampaignLisAdgsTargetGroups => {
  return getTargetGroups(tabs);
};
