import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import styles from './VariantTargetingForm.module.scss';
import { useFormState, useWatch } from 'react-hook-form';
import {
  ICreateCampaignForm,
  ICreateCampaignVariantTargetGroup,
} from '../../../types/CreateCampaignForm';
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form/dist/types/form';
import { TargetGroupName } from '../TargetGroupName/TargetGroupName';
import { TargetGroupCheckboxes } from '../TargetGroupCheckboxes/TargetGroupCheckboxes';
import { VariantLabel } from '../VariantLabel/VariantLabel';
import { TargetGroupForVariant } from '../../../types/TargetGroup';
import useMultiCheckboxing from '../../../hooks/useMultiCheckboxing';
import { v4 as uuidv4 } from 'uuid';
import { WatchAndTrigger } from '../../../helpers/WatchAndTrigger';

type Props = {
  selectedSet: number;
  register: UseFormRegister<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  trigger: UseFormTrigger<ICreateCampaignForm>;
};

export type UpdateCheckboxesFunction = (
  variantIndex: number,
  type: string,
  subType: string,
  targetingIndex: number,
) => void;

export const VariantTargetingForm: FC<Props> = ({
  selectedSet,
  control,
  setValue,
  register,
  trigger,
}) => {
  const { errors } = useFormState({
    control,
    name: `setsWithVariants.${selectedSet}.variants`,
  });

  const variants = useWatch({
    control,
    name: `setsWithVariants.${selectedSet}.variants`,
  });
  const targetGroups = useWatch({ control, name: `setsWithVariants.${selectedSet}.tabs` });

  const formattedTargetGroups: TargetGroupForVariant[] = useMemo(() => {
    const isValidSubType = (subType: any) => {
      return !(subType === undefined || subType.length === 0);
    };

    const convertToSubTypeArray = (subType: string | string[]): string[] => {
      return typeof subType === 'string' ? [subType] : subType;
    };

    return (
      Object.keys(targetGroups)
        .map((key) => {
          const subTypes = targetGroups[key];
          return Object.keys(subTypes)
            .filter((subTypeKey) => isValidSubType(subTypes[subTypeKey]))
            .map((subTypeKey) => {
              const subType = convertToSubTypeArray(subTypes[subTypeKey] || []);
              return {
                type: key.toString(),
                subType: subTypeKey.toString(),
                values: subType,
              };
            });
        })
        .flat()
        // To avoid displaying target groups without checkbox, we need to filter targetGroups without values
        .filter((targetGroup) => targetGroup.values.length != 0)
    );
  }, [targetGroups]);

  const emptyTabs = useMemo(() => {
    const result: ICreateCampaignVariantTargetGroup = {};
    formattedTargetGroups.map((targetGroup) => {
      if (result[targetGroup.type] === undefined) {
        result[targetGroup.type] = {};
      }

      result[targetGroup.type][targetGroup.subType] = targetGroup.values.map((value) => ({
        name: value,
        value: true,
      }));
    });
    return result;
  }, [formattedTargetGroups]);

  const removeVariant = (uniqId: string) => {
    const newValue = variants.filter((item) => item.uniqId !== uniqId);
    setValue(`setsWithVariants.${selectedSet}.variants`, newValue);
  };

  const addVariant = () => {
    const newVariants = [
      ...variants,
      {
        uniqId: uuidv4(),
        name: '',
        tabs: emptyTabs,
      },
    ];
    setValue(`setsWithVariants.${selectedSet}.variants`, newVariants);
  };

  const {
    parentStyle,
    rectangleStyle,
    dragRectangle,
    dragWrapper,
    clickState: clicked,
    coordinatesRef,
  } = useMultiCheckboxing();

  const updateCheckboxes = useMemo(() => {
    let timeout: NodeJS.Timeout | null = null;
    const queue: { variantIndex: number; type: string; subType: string; targetingIndex: number }[] =
      [];

    return (variantIndex: number, type: string, subType: string, targetingIndex: number) => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }

      queue.push({ variantIndex, type, subType, targetingIndex });

      timeout = setTimeout(() => {
        for (const item of queue) {
          const currValue =
            variants[item.variantIndex].tabs[item.type][item.subType][item.targetingIndex].value;
          variants[item.variantIndex].tabs[item.type][item.subType][item.targetingIndex].value =
            !currValue;
        }

        setValue(`setsWithVariants.${selectedSet}.variants`, variants);

        queue.splice(0, queue.length);
        timeout = null;
      }, 10);
    };
  }, [setValue, selectedSet, variants]);

  return (
    <div className={'container-fluid'}>
      <WatchAndTrigger<ICreateCampaignForm>
        control={control}
        trigger={trigger}
        name={`setsWithVariants.${selectedSet}.variants`}
      />
      <div className={'row pt-2'}>
        <div className={cn('col-4', styles.noSelection)}>
          <div className={cn('row', styles.h70px)}>
            <h6 className={'mt-auto mb-3 text-end'}>Line items:</h6>
          </div>
          <div className={cn('row', styles.bgGray)}>
            {formattedTargetGroups.map((targetGroup, index) => (
              <TargetGroupName key={index} targetGroup={targetGroup} />
            ))}
          </div>
        </div>
        <div className={cn('d-flex justify-content-between col-8 ps-0')}>
          <div className={styles.overflowXScroll}>
            <div className={cn('d-flex flex-direction-row', styles.h70px, styles.noSelection)}>
              {variants?.map((item, index) => (
                <VariantLabel
                  key={item.uniqId}
                  register={register}
                  errors={errors}
                  onRemove={() => removeVariant(item.uniqId)}
                  selectedSet={selectedSet}
                  variantIndex={index}
                />
              ))}
            </div>
            <div style={parentStyle} ref={dragWrapper}>
              <div style={rectangleStyle} ref={dragRectangle}></div>
              {formattedTargetGroups.map((targetGroup, index) => (
                <TargetGroupCheckboxes
                  key={index}
                  register={register}
                  coordinatesRef={coordinatesRef}
                  wrapperRef={dragWrapper}
                  clicked={clicked}
                  targetGroup={targetGroup}
                  selectedSet={selectedSet}
                  variants={variants}
                  updateCheckboxes={updateCheckboxes}
                />
              ))}
            </div>
          </div>
          <div className={cn('d-flex float-end py-2 mt-3 mx-2 min-w _100')}>
            <button className={'btn btn-outline-primary'} onClick={addVariant}>
              New variant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
