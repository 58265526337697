import React from 'react';
import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form';

export function handleFloatChange<T extends FieldValues>(
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<T>,
  name: Path<T>,
) {
  const value = event.target.value;
  const regex = /^(\d*\.?\d*)/g;
  const filteredValue = value.match(regex)?.toString() || '';
  setValue(name, filteredValue as PathValue<T, Path<T>>);
}

export function handleIntChange<T extends FieldValues>(
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<T>,
  name: Path<T>,
) {
  const value = event.target.value;
  const regex = /\d*/g;
  const filteredValue = value.match(regex)?.join('') || '';
  setValue(name, filteredValue as PathValue<T, Path<T>>);
}
