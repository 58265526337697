import { gql } from '../__generated__';
import { GetAdsSetupFormQuery } from '../__generated__/graphql';
import { IAdsSetupForm } from '../../types/AdsSetupForm';

export const ADS_SETUP_FORM_QUERY = gql(`
    query GetAdsSetupForm($campaignId: String!) {
        campaign(campaignId: $campaignId) {
            ...CampaignSetup
            brand {
                id
            },
            category,
            franchiseName
            franchiseDescription
            poNumber
            sets {
                uniqId
                name
                goals
                touchpoints {
                    id
                    name
                    budgetSplits {
                        partnerId
                    }
                }
                variants {
                    name
                    targetGroups {
                        type
                        subType
                        values
                    }
                }
            }
            lineItemsGroups {
                setId
                lineItems {
                    uniqId
                    touchpointId
                    variantName
                    name
                    partnerId
                    adgs {
                        name
                    }
                    ads {
                        campaignAdId
                    }
                }
            }
            updatedAt
        }
        ads(campaignId: $campaignId) {
            id
            site
            placementName
            clickTag
        }
    }
`);

export const adsSetupToForm = (data: GetAdsSetupFormQuery): IAdsSetupForm => {
  const campaignDetails = {
    brandName: data.campaign?.brand.id ?? '',
    category: data.campaign?.category ?? '',
    franchiseName: data.campaign?.franchiseName ?? '',
    campaignDescription: data.campaign?.franchiseDescription ?? '',
    poNumber: data.campaign?.poNumber ?? '',
  };
  const currentAds = getCurrentAds(data);
  const sets = getSets(data);
  const touchpoints = getTouchpoints(data);

  return {
    campaignDetails,
    sets,
    currentAds,
    touchpoints,
  };
};

const getCurrentAds = (data: GetAdsSetupFormQuery): IAdsSetupForm['currentAds'] => {
  const currentAds: IAdsSetupForm['currentAds'] = {};
  data.ads.forEach((ad) => {
    currentAds[ad.id] = {
      options: { selected: false },
      sharedOptions: {
        selected: true,
      },
    };
  });

  return currentAds;
};

const getSets = (data: GetAdsSetupFormQuery): IAdsSetupForm['sets'] => {
  const sets: IAdsSetupForm['sets'] = {};

  data.campaign?.sets?.forEach((set) => {
    const touchpoints: IAdsSetupForm['sets'][string]['touchpoints'] = {};

    set.touchpoints?.forEach((touchpoint) => {
      if (touchpoint.id == null || touchpoint.name == null) {
        return;
      }
      touchpoints[touchpoint.id] = {
        name: touchpoint.name,
      };
    });

    const variants: IAdsSetupForm['sets'][string]['variants'] = {};

    set.variants?.forEach((variant) => {
      if (variants[variant.name] === undefined) {
        variants[variant.name] = {};
      }

      variant.targetGroups.forEach((targetGroup) => {
        if (variants[variant.name][targetGroup.type] === undefined) {
          variants[variant.name][targetGroup.type] = {};
        }
        variants[variant.name][targetGroup.type][targetGroup.subType] = targetGroup.values;
      });
    });

    sets[set.uniqId] = {
      name: set.name,
      touchpoints: touchpoints,
      variants: variants,
      goals: set.goals,
    };
  });

  return sets;
};
const getTouchpoints = (data: GetAdsSetupFormQuery): IAdsSetupForm['touchpoints'] => {
  const touchpoints: IAdsSetupForm['touchpoints'] = [];

  data.campaign?.lineItemsGroups?.forEach((lineGroup) => {
    if (!lineGroup) {
      return;
    }
    lineGroup.lineItems?.forEach((lineItem) => {
      if (lineItem == null) {
        return;
      }

      // check if its corresponding set, touchpoint and budget split exists
      const hasBudgetSplit = data.campaign?.sets.some(
        (set) =>
          set.uniqId === lineGroup?.setId &&
          set.touchpoints.some(
            (touchpoint) =>
              touchpoint.id === lineItem.touchpointId &&
              touchpoint.budgetSplits?.some((split) => split?.partnerId === lineItem.partnerId),
          ),
      );

      if (!hasBudgetSplit) {
        return;
      }

      let index = touchpoints.findIndex(
        (el) =>
          el.touchpointId === lineItem.touchpointId &&
          el.partnerId === (lineItem.partnerId ?? undefined),
      );

      if (index === -1) {
        index =
          -1 +
          touchpoints.push({
            setId: lineGroup.setId,
            touchpointId: lineItem.touchpointId,
            partnerId: lineItem.partnerId ?? undefined,
            lineItems: [],
          });
      }

      const result: IAdsSetupForm['touchpoints'][number]['lineItems'][number]['ads'] = {};

      data.ads.forEach((ad) => {
        const found = lineItem.ads?.find((liAd) => liAd?.campaignAdId === ad.id);
        if (found) {
          result[ad.id] = {
            options: {
              selected: true,
            },
          };
        } else {
          const defaultOptions = { selected: false };

          result[ad.id] = {
            options: { ...defaultOptions },
          };
        }
      });

      touchpoints[index].lineItems.push({
        uniqId: lineItem.uniqId,
        name: lineItem.name,
        variantName: lineItem.variantName,
        adgs: lineItem.adgs.map((el) => el?.name).filter((el): el is string => el != null),
        ads: result,
      });
    });
  });

  return touchpoints;
};
