import { gql } from '../__generated__';
import {
  ICreateCampaignForm,
  ICreateCampaignSetWithVariants,
} from '../../types/CreateCampaignForm';
import { UpsertVariantTargetingMutationVariables, VariantInput } from '../__generated__/graphql';

export const UPSERT_VARIANT_TARGETING_MUTATION = gql(`
    mutation UpsertVariantTargeting($input: UpsertCampaignVariantsInput!) {
        upsertCampaignVariants(input: $input) {
            campaign { 
                id 
            }
        }
    }
`);

export const getUpsertVariantTargetingMutationVariables = (
  data: ICreateCampaignForm,
  id: string,
  updatedAt: string,
): UpsertVariantTargetingMutationVariables => {
  return {
    input: {
      campaignId: id,
      sets:
        data.setsWithVariants?.map((set) => ({
          uniqId: set.setUniqId,
          variants: getVariants(set),
        })) ?? [],
      updatedAt: updatedAt,
    },
  };
};

const getVariants = (setWithVariants: ICreateCampaignSetWithVariants): VariantInput[] => {
  return setWithVariants.variants?.map((variant) => {
    const targetGroups = Object.keys(variant.tabs)
      .map((type) => {
        const subTypes = variant.tabs[type];

        return Object.keys(subTypes).map((subType) => {
          const rawValues = variant.tabs[type][subType];
          const values = rawValues
            .filter((value) => value.value === true)
            .map((value) => value.name);

          return {
            type: type.toString(),
            subType: subType.toString(),
            values,
          };
        });
      })
      .flat();

    const groupsWithValues = targetGroups.filter((group) => group.values.length > 0);
    return (
      {
        name: variant.name,
        targetGroups: groupsWithValues,
      } ?? []
    );
  });
};
