import React, { FC } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { Control, useWatch } from 'react-hook-form';
import styles from '../PlatformsBrandsProductsSetup.module.scss';
import { FormControl } from 'react-bootstrap';

interface Props {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  getValues: UseFormGetValues<any>;
  removeItem: (name: string, index: number) => void;
}

const VARIABLE_TYPE = ['float', 'str', 'int'];

const GetOptimisationSettings: FC<Props> = ({
  control,
  setValue,
  getValues,
  register,
  removeItem,
}): JSX.Element => {
  const optimisationSettings = useWatch({ control, name: `optimisation_settings` });

  const getOptimisationSettings = (index: number) => {
    return (
      <React.Fragment key={`optimisation_settings${index}`}>
        <div className={'row mt-4'}>
          <div className={'col-3'}>
            <select
              {...register(`optimisation_settings.${index}.variable_type`)}
              className="form-select col-3 gap-2"
              aria-label="Variable"
              defaultValue="default"
              value={getValues(`optimisation_settings.${index}.variable_type`)}
              onChange={(e: any) => {
                setValue(`optimisation_settings.${index}.variable_type`, e?.target?.value);
                setValue(`optimisation_settings.${index}.is_active`, true, {
                  shouldValidate: true,
                });
              }}>
              <option value="default">Variable</option>
              {VARIABLE_TYPE?.sort()?.map((variable: string, index: number) => (
                <option key={`variable_type${index}`} value={variable}>
                  {variable}
                </option>
              ))}
            </select>
          </div>
          <div className={'col-3'}>
            <FormControl
              placeholder="Value"
              aria-label="Value"
              type={'text'}
              {...register(`optimisation_settings.${index}.default_variable_values`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`optimisation_settings.${index}.is_active`, true, {
                    shouldValidate: true,
                  });
                }
              }}
            />
          </div>
          <div className={'col-5'}>
            <FormControl
              placeholder="Variable description"
              aria-label="Variable description"
              type={'text'}
              {...register(`optimisation_settings.${index}.variable_description`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`optimisation_settings.${index}.is_active`, true, {
                    shouldValidate: true,
                  });
                }
              }}
            />
          </div>

          <div className={'col-1 d-flex align-items-start'}>
            {optimisationSettings?.length > index && optimisationSettings[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() => removeItem(`optimisation_settings`, index)}
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className={'row'}>
        <p className="mt-3 col-12">Optimisation settings</p>
        {optimisationSettings?.length
          ? optimisationSettings
              ?.filter((itemOptimisationSettings: any) => itemOptimisationSettings?.is_active)
              ?.map((item: any, index: number) => {
                return getOptimisationSettings(index);
              })
          : null}
        {getOptimisationSettings(
          optimisationSettings?.filter(
            (itemOptimisationSettings: any) =>
              itemOptimisationSettings &&
              Object.prototype.hasOwnProperty.call(itemOptimisationSettings, 'is_active') &&
              itemOptimisationSettings.is_active,
          )?.length || 0,
        )}
      </div>
    </>
  );
};

export default GetOptimisationSettings;
