import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import sharedStyles from '../shared.module.scss';
import styles from './AdItem.module.scss';
import { FormCheck } from 'react-bootstrap';
import { UseFormGetValues, UseFormRegister } from 'react-hook-form/dist/types/form';
import { IAdsSetupForm } from '../../../types/AdsSetupForm';
import { Control, useWatch } from 'react-hook-form';
import { Ad } from '../../../types/Ad';
import { UpdateLineItemsSelectedProperty } from '../../../types/UpdateLineItemsSelectedProperty';

interface Props {
  register: UseFormRegister<IAdsSetupForm>;
  getValues: UseFormGetValues<IAdsSetupForm>;
  control: Control<IAdsSetupForm>;
  setCurrentAd: (id: string) => void;
  currentAd: string | null;
  updateLineItemsSelectedProperty: UpdateLineItemsSelectedProperty;
  ad: Ad;
}

const AdItem: FC<Props> = ({
  register,
  getValues,
  setCurrentAd,
  currentAd,
  updateLineItemsSelectedProperty,
  ad,
  control,
}) => {
  const isSharedSelected = useWatch({
    control,
    name: `currentAds.${ad.id}.sharedOptions.selected`,
  });

  const touchpoints = useWatch({ control, name: 'touchpoints' });

  const assignCount = useMemo(() => {
    return touchpoints.reduce((prev, tp) => {
      return (
        prev +
        tp.lineItems.reduce((prev, item) => {
          return item.ads[ad.id]?.options.selected === true ? prev + 1 : prev;
        }, 0)
      );
    }, 0);
  }, [touchpoints]);

  return (
    <div
      className={cn('row text-break border rounded my-2', styles.adRow, {
        [sharedStyles.active]: currentAd === ad.id,
      })}
      onClick={() => setCurrentAd(ad.id)}>
      <div className={'col-3 d-flex align-items-center border-end'}>
        <FormCheck
          className={cn({ [styles.notSharedValueCheckbox]: !isSharedSelected })}
          type={'checkbox'}
          inline={true}
          {...register(`currentAds.${ad.id}.options.selected`, {
            onChange: () =>
              updateLineItemsSelectedProperty(
                ad.id,
                getValues(`currentAds.${ad.id}.options.selected`),
              ),
          })}
        />
        <p>{ad.placementName}</p>
      </div>
      <div className={'col-3 d-flex align-items-center border-end'}>
        <p>{ad.clickTag}</p>
      </div>
      <div className={'col-3 d-flex align-items-center border-end'}>{ad.site}</div>
      <div className={'col-3 d-flex align-items-center'}>
        {assignCount > 0 ? `Assigned ${assignCount} times` : 'Not assigned'}
      </div>
    </div>
  );
};

export default AdItem;
