import React, { FC } from 'react';
import { Button, FormCheck, FormControl } from 'react-bootstrap';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useForm, UseFormGetValues, useWatch } from 'react-hook-form';
import {
  ICreateCampaignForm,
  ICreateCampaignBudgetSplit,
} from '../../../../types/CreateCampaignForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { diffDays, diffMonths } from '../../../../helpers/helpers';
import {
  GenerateSegmentsForm,
  GenerateSegmentsFormSchema,
} from '../../../../types/GenerateSegmentsForm';
import { BudgetSplitSegmentsTypeEnumModel } from '../../../../graphql/__generated__/graphql';
import { getBudgetSplitSegmentsTypeEnumAsString } from '../../../../helpers/getBudgetSplitSegmentsTypeEnumAsString';
import { UseFieldArrayReplace } from 'react-hook-form/dist/types/fieldArray';
import { handleIntChange } from '../../../../helpers/handleInput';

interface Props {
  getValues: UseFormGetValues<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  selectedTouchpointIndex: number | null;
  replaceSegments: UseFieldArrayReplace<ICreateCampaignForm, `budgetSplits.${number}.segmentsList`>;
}

const GenerateSegmentsList: FC<Props> = ({
  setValue,
  selectedTouchpointIndex,
  getValues,
  replaceSegments,
}): JSX.Element => {
  const {
    register: segmentsRegister,
    control: segmentsControl,
    handleSubmit,
    setValue: set,
    formState: { errors },
  } = useForm<GenerateSegmentsForm>({
    values: { segmentsType: BudgetSplitSegmentsTypeEnumModel.ByMonths, segmentsNumber: 1 },
    resolver: zodResolver(GenerateSegmentsFormSchema),
  });

  const segmentsType = useWatch({ control: segmentsControl, name: 'segmentsType' });

  const createSegmentsByMonths = (touchpoint: ICreateCampaignBudgetSplit) => {
    if (selectedTouchpointIndex === null) {
      return;
    }

    const lengthInDays = diffDays(touchpoint.startDate, touchpoint.endDate) + 1;
    const oneDayCost = touchpoint.budget / lengthInDays;
    const campaignLengthInMonths = diffMonths(touchpoint.startDate, touchpoint.endDate) + 1;

    const segments: ICreateCampaignBudgetSplit['segmentsList'][number][] = new Array(
      campaignLengthInMonths,
    );

    const year = touchpoint.startDate.getFullYear();
    const month = touchpoint.startDate.getMonth();
    const day = touchpoint.startDate.getDate();

    for (let x = 0; x < campaignLengthInMonths; ++x) {
      const startDate: Date = new Date(year, month + x, 1);
      const endDate: Date = new Date(year, month + x + 1, 0);

      if (x === 0) {
        startDate.setDate(day);
      }

      if (x === campaignLengthInMonths - 1) {
        endDate.setDate(touchpoint.endDate.getDate());
      }

      const segmentDays = diffDays(startDate, endDate) + 1;
      const budget = Number((oneDayCost * segmentDays).toFixed(2));

      segments[x] = {
        startDate: startDate,
        endDate: endDate,
        budget: budget,
      };
    }

    replaceSegments(segments);
  };

  const createEqualSegments = (touchpoint: ICreateCampaignBudgetSplit, segmentsNumber: number) => {
    if (selectedTouchpointIndex === null) {
      return;
    }

    const lengthInDays = diffDays(touchpoint.startDate, touchpoint.endDate) + 1;
    const oneDayCost = touchpoint.budget / lengthInDays;
    const daysPerSegment = lengthInDays / segmentsNumber;

    const year = touchpoint.startDate.getFullYear();
    const month = touchpoint.startDate.getMonth();
    const day = touchpoint.startDate.getDate();

    const segments: ICreateCampaignBudgetSplit['segmentsList'][number][] = new Array(
      segmentsNumber,
    );

    for (let x = 0; x < segmentsNumber; ++x) {
      const daysToAdd = daysPerSegment * x;
      const startDate = new Date(year, month, Math.round(day + daysToAdd));
      const endDate = new Date(year, month, Math.round(day + daysToAdd + daysPerSegment - 1));

      const segmentDays = diffDays(startDate, endDate) + 1;
      const budget = Number((oneDayCost * segmentDays).toFixed(2));

      segments[x] = {
        startDate: startDate,
        endDate: endDate,
        budget: budget,
      };
    }

    replaceSegments(segments);
  };

  const createSegments = (data: GenerateSegmentsForm) => {
    if (selectedTouchpointIndex === null) {
      return;
    }

    setValue(`budgetSplits.${selectedTouchpointIndex}.segmentsType`, segmentsType);

    const touchpoint = getValues(`budgetSplits.${selectedTouchpointIndex}`);

    if (data.segmentsType === BudgetSplitSegmentsTypeEnumModel.ByMonths) {
      createSegmentsByMonths(touchpoint);
      return;
    }

    const lengthInDays = diffDays(touchpoint.startDate, touchpoint.endDate) + 1;

    const segmentsNumber = data.segmentsNumber ?? 1;

    createEqualSegments(touchpoint, segmentsNumber > lengthInDays ? lengthInDays : segmentsNumber);
  };
  return (
    <div className={'row'}>
      <div className={'col-6'}>
        <p className={'mb-2'}>Number of segments</p>
        <FormControl
          {...segmentsRegister('segmentsNumber', {
            valueAsNumber: true,
            onChange: (e) => {
              handleIntChange(e, set, 'segmentsNumber');
            },
          })}
          type={'text'}
          inputMode={'numeric'}
          disabled={segmentsType !== BudgetSplitSegmentsTypeEnumModel.Equal}
          isInvalid={
            segmentsType === BudgetSplitSegmentsTypeEnumModel.Equal &&
            errors.segmentsNumber?.message !== undefined
          }
        />
        <Button
          className="mt-2 w-100"
          onClick={handleSubmit(createSegments)}
          disabled={selectedTouchpointIndex === null}>
          Create segments
        </Button>
      </div>
      <div className={'col-6 mt-4 flex-column'}>
        {Object.values(BudgetSplitSegmentsTypeEnumModel).map((segmentType) => (
          <FormCheck
            {...segmentsRegister('segmentsType')}
            key={segmentType}
            id={segmentType}
            type={'radio'}
            label={getBudgetSplitSegmentsTypeEnumAsString(segmentType)}
            value={segmentType}
            isInvalid={errors.segmentsType?.message !== undefined}
          />
        ))}
      </div>
    </div>
  );
};

export default GenerateSegmentsList;
