import React, { FC } from 'react';
import { Control, UseFormRegister, UseFormTrigger } from 'react-hook-form/dist/types/form';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import CampaignSetupHeader from '../CampaignSetupHeader/CampaignSetupHeader';
import {
  GoalDetailsFragment,
  InsertionFrequencyFragment,
  NamingDetailsFragment,
} from '../../../graphql/__generated__/graphql';
import { CreateSets } from './CreateSets/CreateSets';
import { NamingConventionsSeparators } from '../../../types/NamingConventions';

interface Props {
  namingConventions: NamingDetailsFragment[];
  namingConventionsSeparators: NamingConventionsSeparators;
  goals: GoalDetailsFragment[];
  matchedInsertionFreqTemplates: InsertionFrequencyFragment[];
  control: Control<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  trigger: UseFormTrigger<ICreateCampaignForm>;
}

const CampaignGoalsSetup: FC<Props> = ({
  namingConventions,
  namingConventionsSeparators,
  goals,
  matchedInsertionFreqTemplates,
  setValue,
  getValues,
  register,
  control,
  trigger,
}) => {
  return (
    <div className="mt-3">
      {/*TODO: templates support will be added later*/}
      <div className={'mt-2'}>
        <CampaignSetupHeader
          namingConventions={namingConventions}
          namingConventionsSeparators={namingConventionsSeparators}
          setValue={setValue}
          getValues={getValues}
          register={register}
          control={control}
          isShowNamingSection={true}
        />
      </div>

      <CreateSets
        control={control}
        getValues={getValues}
        setValue={setValue}
        matchedInsertionFreqTemplates={matchedInsertionFreqTemplates}
        register={register}
        trigger={trigger}
        goals={goals}
      />
    </div>
  );
};

export default CampaignGoalsSetup;
