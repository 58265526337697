import React, { FC } from 'react';
import Header from '../../components/Header/Header';
import PlatformsBrandsProductsSetup from '../../components/PlatformsBrandsProductsSetup/PlatformsBrandsProductsSetup';

const PlatformsBrandsProducts: FC = (): JSX.Element => {
  return (
    <div>
      <Header
        campaignSetupText={'Platforms, Brands, products & naming conventions'}
        withButtons={false}
      />
      <PlatformsBrandsProductsSetup />
    </div>
  );
};

export default PlatformsBrandsProducts;
