import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import styles from '../shared.module.scss';
import { IAdsSetupForm } from '../../../types/AdsSetupForm';
import { Control, useFormState, useWatch } from 'react-hook-form';

interface Props {
  control: Control<IAdsSetupForm>;
  lineItem: IAdsSetupForm['touchpoints'][number]['lineItems'][number];
  touchpointIndex: number;
  lineItemIndex: number;
  onClick: () => void;
  selected: boolean;
}

const LineItemLine: FC<Props> = ({
  lineItem,
  touchpointIndex,
  lineItemIndex,
  onClick,
  selected,
  control,
}) => {
  const { errors } = useFormState({
    control,
    name: `touchpoints.${touchpointIndex}.lineItems.${lineItemIndex}.ads`,
  });

  const ads = useWatch({
    control,
    name: `touchpoints.${touchpointIndex}.lineItems.${lineItemIndex}.ads`,
  });

  const adsNumber = useMemo(() => {
    return Object.values(ads).reduce((prev, ad) => (ad.options.selected ? prev + 1 : prev), 0);
  }, [ads]);

  return (
    <div className={'col-12'}>
      <div className={'row'}>
        <div className={'col-6 p-0 ps-4 d-flex flex-direction-row'}>
          <h6 className={'my-auto me-1'}>LI:</h6>
          <div
            className={cn(styles.option, 'w-100 p-1 ps-2 border rounded', {
              [styles.active]: selected,
              'border-danger':
                errors?.touchpoints?.[touchpointIndex]?.lineItems?.[lineItemIndex]?.ads !==
                undefined,
            })}
            onClick={onClick}>
            {lineItem.name}
            {lineItem.adgs.map((adg, index) => (
              <div key={index}>{adg}</div>
            ))}
          </div>
        </div>

        <p className={'col-6 my-auto'}>Assigned {adsNumber} ads</p>
      </div>
    </div>
  );
};

export default LineItemLine;
