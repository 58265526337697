import { Control, UseFormGetValues, UseFormSetValue, useWatch } from 'react-hook-form';
import CustomDatePicker from '../../../CampaignGoals/CustomDatePicker/CustomDatePicker';
import { ICreateCampaignForm } from '../../../../types/CreateCampaignForm';
import React, { FC, useEffect, useMemo } from 'react';

interface Props {
  control: Control<ICreateCampaignForm>;
  selectedTouchpointIndex: number;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  segmentId: number;
}

const SegmentDataPickers: FC<Props> = ({
  control,
  selectedTouchpointIndex,
  getValues,
  setValue,
  segmentId,
}): JSX.Element => {
  const watchedStartData = useWatch({
    control,
    name: `budgetSplits.${selectedTouchpointIndex}.segmentsList.${segmentId}.startDate`,
  });

  const getMinEndDate = useMemo((): Date => {
    if (watchedStartData === undefined) {
      return new Date();
    }
    const startDateTime = new Date(watchedStartData).getTime();
    const currentDateTime = new Date().getTime();
    return currentDateTime > startDateTime ? new Date() : new Date(watchedStartData);
  }, [watchedStartData.getTime()]);

  useEffect(() => {
    if (watchedStartData === undefined) {
      return;
    }

    const endDate = getValues(
      `budgetSplits.${selectedTouchpointIndex}.segmentsList.${segmentId}.endDate`,
    );
    if (endDate === undefined) {
      return;
    }

    if (watchedStartData.getTime() > endDate.getTime()) {
      setValue(
        `budgetSplits.${selectedTouchpointIndex}.segmentsList.${segmentId}.endDate`,
        watchedStartData,
      );
    }
  }, [watchedStartData.getTime()]);

  return (
    <>
      <CustomDatePicker
        dateFormat={'dd-MM-yyyy'}
        className={'col-4'}
        control={control}
        withIcon={true}
        name={`budgetSplits.${selectedTouchpointIndex}.segmentsList.${segmentId}.startDate`}
      />
      <CustomDatePicker
        dateFormat={'dd-MM-yyyy'}
        className={'col-4'}
        control={control}
        withIcon={true}
        minDate={getMinEndDate}
        name={`budgetSplits.${selectedTouchpointIndex}.segmentsList.${segmentId}.endDate`}
      />
    </>
  );
};

export default SegmentDataPickers;
