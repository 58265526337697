import React, { FC, useContext } from 'react';
import styles from '../../../containers/TemplatesM/TemplatesM.module.scss';
import Table from 'react-bootstrap/Table';
import cn from 'classnames';
import FileIcon from '../../UI/icons/FileIcon';
import { useNavigate } from 'react-router-dom';
import { PartnerContext } from '../../../context/PartnerContext';
import { deleteDoc, doc } from 'firebase/firestore/lite';
import { ITemplate } from '../../../types/Template';
import { IExclusionTemplate } from '../../../types/ExclusionTemplate';
import { FirebaseContext } from '../../../context/FirebaseContext';

interface Props {
  getAllTemplates: () => Promise<(ITemplate | IExclusionTemplate)[]>;
  templates: Array<ITemplate | IExclusionTemplate>;
  setTemplates: (state: Array<ITemplate | IExclusionTemplate>) => void;
}

const TABLE_HEAD: Array<string> = ['', 'Template name', 'Template type', 'Description', ''];

const TemplatesTable: FC<Props> = ({ getAllTemplates, templates, setTemplates }): JSX.Element => {
  const navigate = useNavigate();
  const { campaignFirestore } = useContext(FirebaseContext);
  const { partnerData } = useContext(PartnerContext);

  const removeEl = async (type: string, id: string): Promise<void> => {
    if (campaignFirestore) {
      const collectionName = type === 'exclusion_template' ? 'exclusions_templates' : 'templates';
      await deleteDoc(doc(campaignFirestore, collectionName, id)).then(() =>
        getAllTemplates().then((res: Array<ITemplate | IExclusionTemplate>) => {
          setTemplates(res);
        }),
      );
    }
  };

  return (
    <div className={styles.TemplatesMTable}>
      <Table borderless hover size="lg">
        <thead>
          <tr>
            {TABLE_HEAD &&
              TABLE_HEAD.map((item: string, index: number) => {
                return <td key={`table_head_${index}`}>{item}</td>;
              })}
          </tr>
        </thead>
        <tbody>
          {templates &&
            templates.map((item: any) => {
              return (
                <tr key={item.id}>
                  <td
                    className={cn('col-1', styles.doc)}
                    onClick={() => {
                      if (item.data.type === 'exclusion_template') {
                        navigate(`/exclusions/${partnerData?.partner_id}`, {
                          state: { templateId: item?.id },
                        });
                      } else {
                        navigate(`/edit/campaign-goals/${item?.id}`, {
                          state: { editTemplate: true },
                        });
                      }
                    }}>
                    <FileIcon />
                  </td>
                  <td className="col-3">{item.data.templateName}</td>
                  <td className="col-2">
                    {item.data.type === 'exclusion_template'
                      ? 'Exclusion template'
                      : 'Campaign template'}
                  </td>
                  <td className="col-2">{item.data.description}</td>
                  <td
                    className={cn('d-inline-flex', styles.iconCol)}
                    onClick={() => removeEl(item.data.type, item.id)}>
                    <div className={styles.circleRed} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default TemplatesTable;
