import React, { FC, useContext, useEffect, useState } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form/dist/types/form';
import { Control } from 'react-hook-form';
import { PartnerContext } from '../../context/PartnerContext';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore/lite';
import { DocumentSnapshot } from '@firebase/firestore/lite';
import { DocumentData } from 'firebase/firestore/lite';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getDate } from '../../helpers/getQueryParams';
import GetCreatives from './GetCreatives/GetCreatives';
import GetClientSetup from './GetClientSetup/GetClientSetup';
import GetReports from './GetReports/GetReports';
import GetNamingConventions from './GetNamingConventions/GetNamingConventions';
import GetOptimisationSettings from './GetOptimisationSettings/GetOptimisationSettings';
import GetBrandSafety from './GetBrandSafety/GetBrandSafety';
import GetCampaignObjectives from './GetCampaignObjectives/GetCampaignObjectives';
import GetTouchpoints from './GetTouchpoints/GetTouchpoints';
import GetTouchpointCompatibility from './GetTouchpointCompatibility/GetTouchpointCompatibility';
import GetTouchpointTypesAndFormats from './GetTouchpointTypesAndFormats/GetTouchpointTypesAndFormats';

interface GetTabsProps {
  activeTab: string;
  register: UseFormRegister<any>;
  errors?: any;
  getValues: UseFormGetValues<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  setSaveDisables: any;
}

export interface IDropdownItem {
  name: string;
  id: string;
  nc_category?: string;
  nc_parent?: string;
  version?: string;
}

export interface IOptions {
  division?: Array<IDropdownItem>;
  category?: Array<IDropdownItem>;
  brand?: Array<IDropdownItem>;
  product?: Array<IDropdownItem>;
  platforms?: Array<IDropdownItem>;
  types?: Array<IDropdownItem>;
  references?: Array<IDropdownItem>;
  versions?: Array<IDropdownItem>;
  advertisers?: Array<IDropdownItem>;
}

const GetTabs: FC<GetTabsProps> = ({
  activeTab,
  errors,
  register,
  getValues,
  setValue,
  control,
  watch,
  setSaveDisables,
}): JSX.Element => {
  const [options, setOptions] = useState<IOptions>({});
  const [currentTouchpoint, setCurrentTouchpoint] = useState<string | number | null>(null);
  const { partnerData } = useContext(PartnerContext);
  const { firestoreGui, masterFirestore, campaignFirestore } = useContext(FirebaseContext);

  // const onUpload = (e: any, brand: string, sdfType: string, sdfVersion: string): void => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const form: any = new FormData();
  //     form.append('file_path', file);
  //     form.append('campaign_name', `${brand}_${sdfType}_${sdfVersion}`);
  //     form.append('output_collection_name', 'sdfs_gui');
  //     form.append('client_name', partnerData?.campaignName);
  //     form.append('advertiser_id', partnerData?.partner_id);

  //     setLoader(true);
  //     // axios.post('https://europe-central2-optimaster-global.cloudfunctions.net/dv360-get-sdfs_from_disk', form)
  //     //   .finally(() => {
  //     //     setLoader(false)
  //     //   })
  //   }
  // };

  // const getBrandsAndProducts = async (): Promise<{
  //   brand: Array<IDropdownItem>;
  //   brandId: Array<IDropdownItem>;
  //   product: Array<IDropdownItem>;
  // }> => {
  //   if (partnerData && masterFirestore) {
  //     const brandsCol = collection(
  //       masterFirestore,
  //       'clients-test',
  //       partnerData?.campaignName,
  //       'brands',
  //     );
  //     const brandsSnapshot = await getDocs(brandsCol);
  //     return {
  //       brand: brandsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
  //         id: doc.id,
  //         name: doc.data()?.Brand,
  //       })),
  //       brandId: brandsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
  //         id: doc.id,
  //         name: doc.data()?.dv360_id,
  //       })),
  //       product: brandsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
  //         id: doc.id,
  //         name: doc.data()?.product_categories_refs,
  //       })),
  //     };
  //   }
  //   return {
  //     brand: [],
  //     brandId: [],
  //     product: [],
  //   };
  // };

  // const getCategories = async (): Promise<{ category: Array<IDropdownItem> }> => {
  //   // const categoriesCol = collection(firestore, 'categories');
  //   if (partnerData && masterFirestore) {
  //     const categoriesCol = collection(masterFirestore, 'clients-test', partnerData?.campaignName, 'categories');
  //     const categoriesSnapshot = await getDocs(categoriesCol);
  //     return {
  //       category: categoriesSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({id: doc.id, name: doc.data()?.category}))
  //     }
  //   }
  //   return {
  //     category: []
  //   }
  // }

  // const getCategories = async (): Promise<{ category: Array<IDropdownItem> }> => {
  //   if (partnerData && masterFirestore) {
  //     const categoriesCol = collection(
  //       masterFirestore,
  //       'clients-test',
  //       partnerData?.campaignName,
  //       'categories',
  //     );
  //     const categoriesSnapshot = await getDocs(categoriesCol);

  //     return {
  //       category: categoriesSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => {
  //         const data = doc.data();
  //         return {
  //           id: doc.id,
  //           name: data?.category,
  //           nc_category: data?.nc_category,
  //           nc_parent: data?.nc_parent || 'default',
  //         };
  //       }),
  //     };
  //   }
  //   return {
  //     category: [],
  //   };
  // };

  const getAdvertisersAndTypes = async (): Promise<{ advertisers: Array<IDropdownItem> }> => {
    if (campaignFirestore) {
      const date = getDate(new Date(), true);
      const splitDate: any = date?.split('-');
      const prevDate = getDate(new Date(splitDate[0], splitDate[1] - 1, splitDate[2] - 1), true);

      const advertisersCol = collection(
        campaignFirestore,
        'active-advertisers',
        prevDate,
        'dv360-advertisers',
      );
      const advertisersSnapshot = await getDocs(advertisersCol);
      return {
        advertisers: advertisersSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => {
          const data = doc.data();
          const version = data?.dataAccessConfig?.sdfConfig?.sdfConfig?.version;
          return {
            id: doc.id,
            name: data?.displayName,
            version:
              version?.split('SDF_VERSION_')?.length > 1 ? version?.split('SDF_VERSION_')[1] : '',
          };
        }),
      };
    } else {
      return {
        advertisers: [],
      };
    }
  };

  // const getDivisionsCollection = async (): Promise<{ division: Array<IDropdownItem> }> => {
  //   if (partnerData && masterFirestore) {
  //     const divisionsCol = collection(
  //       masterFirestore,
  //       'clients-test',
  //       partnerData?.campaignName,
  //       'divisions',
  //     );
  //     const divisionsSnapshot = await getDocs(divisionsCol);
  //     return {
  //       division: divisionsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
  //         id: doc.id,
  //         name: doc.data()?.division,
  //       })),
  //     };
  //   }
  //   return {
  //     division: [],
  //   };
  // };

  // const getVersionsCollection = async (): Promise<{ versions: Array<IDropdownItem> }> => {
  //   if (campaignFirestore) {
  //     const versionsCol = collection(campaignFirestore, 'sdfs_defs');
  //     const versionsSnapshot = await getDocs(versionsCol);
  //     return {
  //       versions: versionsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
  //         id: doc.id,
  //         name: doc.id,
  //       })),
  //     };
  //   }
  //   return {
  //     versions: [],
  //   };
  // };

  const getPlatforms = async (): Promise<{ platforms: Array<IDropdownItem> }> => {
    if (masterFirestore) {
      const docSnap = await getDoc(doc(masterFirestore, 'targeting', 'nc_platforms'));
      const data: Array<IDropdownItem> = [];
      if (docSnap?.exists()) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [key, value] of Object.entries(docSnap.data())) {
          if (value && Object.prototype.hasOwnProperty.call(value, 'Platform name')) {
            data.push({
              id: value?.nc_platform_name,
              name: value['Platform name'],
            });
          }
        }
      }

      return {
        platforms: data,
      };
    }

    return { platforms: [] };
  };

  const getTypes = async (): Promise<{ types: Array<IDropdownItem> }> => {
    if (masterFirestore) {
      const docSnap = await getDoc(doc(masterFirestore, 'naming-conventions', 'items-types'));
      const data: Array<IDropdownItem> = [];
      if (docSnap?.exists()) {
        for (const [key, value] of Object.entries(docSnap.data())) {
          if (value && Object.prototype.hasOwnProperty.call(value, 'Campaign item type')) {
            data.push({
              id: key,
              name: value['Campaign item type'],
            });
          }
        }
      }

      return {
        types: data,
      };
    }

    return { types: [] };
  };

  // const getReferences = async (): Promise<{ references: Array<IDropdownItem> }> => {
  //   if (campaignFirestore) {
  //     const docSnap = await getDoc(doc(campaignFirestore, 'naming-conventions', 'references'));
  //     const data: Array<IDropdownItem> = [];
  //     if (docSnap?.exists()) {
  //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //       for (const [key, value] of Object.entries(docSnap.data())) {
  //         if (value && Object.prototype.hasOwnProperty.call(value, 'Value reference name')) {
  //           data.push({
  //             id: value?.value_reference_property_name,
  //             name: value['Value reference name'],
  //           });
  //         }
  //       }
  //     }

  //     return {
  //       references: data,
  //     };
  //   }

  //   return { references: [] };
  // };

  useEffect(() => {
    const prefillData = async () => {
      const data: IOptions = {};
      // await getBrandsAndProducts().then(
      //   (res: { brand: Array<IDropdownItem>; product: Array<IDropdownItem> }) => {
      //     data.brand = [{ name: 'Brand', id: 'default' }, ...res.brand];
      //     data.product = [{ name: 'Product', id: 'default' }, ...res.product];
      //   },
      // );
      // await getDivisionsCollection().then((res: { division: Array<IDropdownItem> }) => {
      //   data.division = [...res.division];
      // });
      // await getCategories().then((res: { category: Array<IDropdownItem> }) => {
      //   data.category = [
      //     { name: 'Category', id: 'default', nc_category: 'default', nc_parent: 'default' },
      //     ...res.category,
      //   ];
      // });
      await getPlatforms().then((res: { platforms: Array<IDropdownItem> }) => {
        data.platforms = [{ name: 'Platform', id: 'default' }, ...res.platforms];
      });
      await getTypes().then((res: { types: Array<IDropdownItem> }) => {
        data.types = [{ name: 'Campaign item type', id: 'default' }, ...res.types];
      });
      // await getReferences().then((res: { references: Array<IDropdownItem> }) => {
      //   data.references = [{ name: 'Value reference', id: 'default' }, ...res.references];
      // });
      // await getVersionsCollection().then((res: { versions: Array<IDropdownItem> }) => {
      //   data.versions = [{ name: 'SDF version', id: 'default' }, ...res.versions];
      // });
      await getAdvertisersAndTypes().then((res: { advertisers: Array<IDropdownItem> }) => {
        data.advertisers = [{ name: 'Advertiser', id: 'default' }, ...res.advertisers];
      });

      return data;
    };

    if (firestoreGui && campaignFirestore) {
      prefillData().then((res: IOptions) => {
        setOptions(res);
      });
    }
  }, [firestoreGui, campaignFirestore]);

  const removeItem = (name: string, index: number) => {
    const itemCopy = JSON.parse(JSON.stringify(getValues(name)));
    itemCopy.splice(index, 1);
    setValue(name, itemCopy);
  };
  // const copyItem = (name: string, index: number) => {
  //   const itemCopy = JSON.parse(JSON.stringify(getValues(name)));
  //   // itemCopy.splice(index, 1);
  //   console.log('itemCopy',itemCopy)
  //   // console.log('itemCopy',itemCopy)
  //   // setValue(name, itemCopy);
  // }

  const getTab = (): JSX.Element => {
    switch (activeTab) {
      case 'Client setup':
        return (
          <GetClientSetup
            register={register}
            setValue={setValue}
            getValues={getValues}
            control={control}
            removeItem={removeItem}
          />
        );
      case 'Reporting':
        return (
          <GetReports
            register={register}
            setValue={setValue}
            getValues={getValues}
            control={control}
            removeItem={removeItem}
          />
        );
      // case 'Categories, brands, product':
      //   return (
      //     <GetCategoriesBrandsProduct
      //       register={register}
      //       getValues={getValues}
      //       setValue={setValue}
      //       control={control}
      //       removeItem={removeItem}
      //     />
      //   );
      case 'Naming conventions':
        return (
          <GetNamingConventions
            register={register}
            getValues={getValues}
            options={options}
            setValue={setValue}
            control={control}
            removeItem={removeItem}
          />
        );
      case 'Optimisation settings':
        return (
          <GetOptimisationSettings
            register={register}
            setValue={setValue}
            control={control}
            getValues={getValues}
            removeItem={removeItem}
          />
        );
      case 'Brand safety':
        return (
          <GetBrandSafety
            register={register}
            options={options}
            setValue={setValue}
            control={control}
            getValues={getValues}
            removeItem={removeItem}
          />
        );
      case 'Variables':
        return (
          <GetCreatives
            getValues={getValues}
            control={control}
            setValue={setValue}
            register={register}
            watch={watch}
            removeItem={removeItem}
          />
        );
      case 'Campaign objectives':
        return (
          <GetCampaignObjectives
            getValues={getValues}
            control={control}
            setValue={setValue}
            register={register}
            removeItem={removeItem}
            setSaveDisables={setSaveDisables}
          />
        );
      case 'Touchpoints':
        return (
          <GetTouchpoints
            currentTouchpoint={currentTouchpoint}
            setCurrentTouchpoint={setCurrentTouchpoint}
            getValues={getValues}
            errors={errors}
            control={control}
            setValue={setValue}
            register={register}
            removeItem={removeItem}
          />
        );
      case 'Touchpoint compatibility':
        return (
          <GetTouchpointCompatibility
            getValues={getValues}
            control={control}
            setValue={setValue}
            register={register}
            removeItem={removeItem}
            currentTouchpoint={currentTouchpoint}
            setCurrentTouchpoint={setCurrentTouchpoint}
          />
        );
      case 'Touchpoint types and formats':
        return (
          <GetTouchpointTypesAndFormats
            getValues={getValues}
            control={control}
            setValue={setValue}
            register={register}
            removeItem={removeItem}
          />
        );
      case 'Main tactics':
      default:
        return <></>;
    }
  };

  return (
    <div className={'row border rounded mt-3 pb-3 mx-1'}>
      <p className="mt-3 col-12">{partnerData?.campaignName}</p>
      <div className={'row px-4 mt-3'}>{getTab()}</div>
    </div>
  );
};

export default GetTabs;

// campaign item type - хардкод
//   'FILE_TYPE_AD',
//   'FILE_TYPE_AD_GROUP',
//   'FILE_TYPE_LINE_ITEM',
//   'FILE_TYPE_INSERTION_ORDER',
//   'FILE_TYPE_CAMPAIGN'

// value reference - хардкод
// [Brand name] - source for name creation "nc_brand", displayed field "Brand"
//   [Product category] - source for name creation "nc_category", displayed field "Category"
//   [Campaign name] - user input
//   [Month of start] - extracted from GUI data.
//   [PO Number] - extracted from GUI
