import { gql } from '../__generated__';
import { ICreateCampaignCategoryPartial } from '../../types/CreateCampaignForm';
import { CampaignCategoryFragment } from '../__generated__/graphql';

export const CAMPAIGN_CATEGORY_FRAGMENT = gql(`
    fragment CampaignCategory on Campaign {
        brand {
            id
            ncBrand
        }
        category
        division
        product
        divisionFull
    }
`);

export const campaignCategoryFragmentToForm = (
  categoryData: CampaignCategoryFragment | undefined | null,
): ICreateCampaignCategoryPartial => {
  return {
    brand: categoryData?.brand.id,
    category: categoryData?.category,
    division: categoryData?.division,
    product: categoryData?.product ?? undefined,
    divisionFull: categoryData?.divisionFull ?? undefined,
    brandName: categoryData?.brand.ncBrand,
  };
};
