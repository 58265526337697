import { gql } from '../__generated__';

export const LINE_ITEM_DETAILS_FRAGMENT = gql(`
    fragment LineItemDetails on LineItem {
        uniqId
        touchpointId
        variantName
        name
        tacticDetails
        mainTactics
        partnerId
        adgs {
            name
            liSubtype
        }
        frequency {
            per
            period
            limit
        }
        view {
            period
            limit
        }
    }
`);
