import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import CampaignGoals from '../../components/CampaignGoals/CampaignGoals';
import {
  CampaignGoalsSchema,
  ICreateCampaignForm,
  ICreateCampaignFormPartial,
} from '../../types/CreateCampaignForm';
import { useMutation, useQuery } from '@apollo/client';
import {
  getUpsertCampaignMutationVariables,
  UPSERT_CAMPAIGN_MUTATION,
} from '../../graphql/mutations/UpsertCampaignMutation';
import { useGraphQLError } from '../../hooks/useGraphQLError';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../constants/paths';
import Loader from '../../components/UI/Loader/Loader';
import { namingConventionsVariables } from '../../constants/namingConventionsVariables';
import { CREATE_CAMPAIGN_GOALS_QUERY } from '../../graphql/queries/CreateCampaignGoalsQuery';
import { useFragment } from '../../graphql/__generated__';
import { NAMING_DETAILS_FRAGMENT } from '../../graphql/fragments/NamingDetailsFragment';
import { BRAND_DETAILS_FRAGMENT } from '../../graphql/fragments/BrandDetailsFragment';
import { GOAL_DETAILS_FRAGMENT } from '../../graphql/fragments/GoalDetailsFragment';
import { zodResolver } from '@hookform/resolvers/zod';
import { NamingDetailsFragment } from '../../graphql/__generated__/graphql';
import { CAMPAIGN_FREQUENCY_FRAGMENT } from '../../graphql/fragments/CampaignFrequencyFragment';
import { INSERTION_FREQUENCY_FRAGMENT } from '../../graphql/fragments/InsertionFrequencyFragment';

const CreateCampaignGoals: FC = () => {
  const [initialized, setInitialized] = useState(false);

  const [
    upsertCampaign,
    { loading: upsertCampaignLoading, error: upsertCampaignError, data: upsertCampaignData },
  ] = useMutation(UPSERT_CAMPAIGN_MUTATION);

  const { data: initialData, error: initialDataError } = useQuery(CREATE_CAMPAIGN_GOALS_QUERY);

  const navigate = useNavigate();

  const { setValue, getValues, register, control, reset, handleSubmit, trigger } =
    useForm<ICreateCampaignForm>({
      resolver: zodResolver(CampaignGoalsSchema),
    });

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const defaultValue: ICreateCampaignFormPartial = {};
    defaultValue.categoryData = {};
    defaultValue.setup = {};

    // namingConventions contains default values of some fields
    const namingConventions =
      initialData.namingConventions?.conventions
        ?.map((el) => useFragment(NAMING_DETAILS_FRAGMENT, el))
        ?.filter(
          (el): el is { variable: string; defaultValue: string } =>
            el?.variable != null && el?.defaultValue != null,
        ) || [];

    for (const item of namingConventions) {
      switch (item.variable) {
        case namingConventionsVariables.brandId.name:
          defaultValue.categoryData.brand = item.defaultValue;
          break;
        case namingConventionsVariables.categoryId.name:
          defaultValue.categoryData.category = item.defaultValue;
          break;
        case namingConventionsVariables.franchiseName.name:
          defaultValue.setup.franchiseName = item.defaultValue;
          break;
        case namingConventionsVariables.franchiseDescription.name:
          defaultValue.setup.franchiseDescription = item.defaultValue;
          break;
        case namingConventionsVariables.poNumber.name:
          defaultValue.setup.po = item.defaultValue;
          break;
      }
    }

    reset(defaultValue);
    setInitialized(true);
  }, [initialData]);

  useEffect(() => {
    if (!upsertCampaignData) {
      return;
    }

    if (upsertCampaignData.upsertCampaign?.campaign?.id) {
      navigate(Paths.getUpsertTargetGroup(upsertCampaignData.upsertCampaign.campaign.id));
    }
  }, [upsertCampaignData]);

  useGraphQLError([upsertCampaignError, initialDataError]);

  const handleNextButton = () => {
    if (upsertCampaignLoading) {
      return;
    }

    handleSubmit((data) =>
      upsertCampaign({
        variables: getUpsertCampaignMutationVariables(data),
      }),
    )();
  };

  const brands = useMemo(() => {
    return initialData?.brands?.map((el) => useFragment(BRAND_DETAILS_FRAGMENT, el)) ?? [];
  }, [initialData]);

  const goals = useMemo(() => {
    return initialData?.goals?.map((el) => useFragment(GOAL_DETAILS_FRAGMENT, el)) ?? [];
  }, [initialData]);

  const campaignFrequencyTemplates =
    initialData?.campaignFrequencyTemplates?.map((el) =>
      useFragment(CAMPAIGN_FREQUENCY_FRAGMENT, el),
    ) ?? [];

  const insertionFrequencyTemplates =
    initialData?.insertionFrequencyTemplates?.map((el) =>
      useFragment(INSERTION_FREQUENCY_FRAGMENT, el),
    ) ?? [];

  const namingConventions = useMemo(() => {
    return (
      initialData?.namingConventions?.conventions
        ?.map((el) => useFragment(NAMING_DETAILS_FRAGMENT, el))
        .filter((el): el is NamingDetailsFragment => el != null) ?? []
    );
  }, [initialData]);

  const namingSeparators = useMemo(() => {
    return {
      separator: initialData?.namingConventions?.separatorCharacter ?? '',
      subSeparator: initialData?.namingConventions?.separatorCharacterSubtitle ?? '',
    };
  }, [initialData]);

  if (!initialized) {
    return <Loader />;
  }

  return (
    <CampaignGoals
      goals={goals}
      brands={brands}
      namingConventions={namingConventions}
      campaignFrequencyTemplates={campaignFrequencyTemplates}
      insertionFrequencyTemplates={insertionFrequencyTemplates}
      namingConventionsSeparators={namingSeparators}
      setValue={setValue}
      getValues={getValues}
      register={register}
      control={control}
      handleNext={handleNextButton}
      handlerLoading={upsertCampaignLoading}
      trigger={trigger}
    />
  );
};

export default CreateCampaignGoals;
