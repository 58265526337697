import {
  BudgetSplitSegmentsTypeEnumModel,
  FrequencyPeriodEnumModel,
  ViewFrequencyPeriodEnumModel,
  PartnerListTypeEnumModel,
} from '../graphql/__generated__/graphql';
import * as z from 'zod';

const CategoryDataSchema = z.object({
  brand: z.string().min(1),
  brandName: z.string().optional(),
  category: z.string().min(1),
  division: z.string().min(1),
  product: z.string().min(1),
  divisionFull: z.string().optional(),
});
const CategoryDataSchemaPartial = CategoryDataSchema.deepPartial();

const FrequencySchema = z.object({
  limit: z
    .string()
    .optional()
    .transform((limit) => (limit === '' ? undefined : limit)),
  per: z
    .string()
    .optional()
    .transform((per) => (per === '' ? undefined : per)),
  period: z
    .nativeEnum(FrequencyPeriodEnumModel)
    .or(z.enum(['']))
    .optional()
    .transform((period) => (period === '' ? undefined : period)),
});

const ViewFrequencySchema = z.object({
  limit: z
    .string()
    .optional()
    .transform((limit) => (limit === '' ? undefined : limit)),
  period: z
    .nativeEnum(ViewFrequencyPeriodEnumModel)
    .or(z.enum(['']))
    .optional()
    .transform((period) => (period === '' ? undefined : period)),
});

const CampaignSetupSchema = z.object({
  franchiseDescription: z.string().min(1),
  franchiseName: z.string().min(1),
  po: z.string().min(1),
  name: z.string().min(1),
  budget: z.number().min(0),
  endDate: z.date(),
  startDate: z.date(),
  scenarioName: z.string().min(1),
  frequency: FrequencySchema.optional(),
});
const CampaignSetupSchemaPartial = CampaignSetupSchema.deepPartial();
// TODO: refined breaks deepPartial
// .refine((setup) => setup.endDate.getTime() >= setup.startDate.getTime(), {
//     message: 'End date should be greater than the start date.',
//     path: ['endDate'],
// });

const CampaignTargetGroupSchema = z
  .object({})
  .catchall(z.object({}).catchall(z.string().or(z.array(z.string())).optional()));

const CampaignSetWithTabsSchema = z.object({
  setUniqId: z.string(),
  tabs: CampaignTargetGroupSchema,
});

function validateSetWithTabs(schema: typeof CampaignSetWithTabsSchema, message: string) {
  return schema.refine(
    (set) => {
      const tabs = Object.values(set.tabs);
      const options = tabs.map((tab) => Object.values(tab)).flat();
      const containTabs = options.some((option) => option !== undefined && option.length !== 0);
      return containTabs;
    },
    { message: message },
  );
}

function validateFrequency(schema: typeof FrequencySchema, message: string) {
  return schema.refine(
    (frequency) => {
      const { limit, per, period } = frequency;
      const allUndefined = limit === undefined && per === undefined && period === undefined;
      const allDefined = limit !== undefined && per !== undefined && period !== undefined;

      return allUndefined || allDefined;
    },
    { message: message },
  );
}

function validateViewFrequency(schema: typeof ViewFrequencySchema, message: string) {
  return schema.refine(
    (frequency) => {
      const { limit, period } = frequency;
      const allUndefined = limit === undefined && period === undefined;
      const allDefined = limit !== undefined && period !== undefined;

      return allUndefined || allDefined;
    },
    { message: message },
  );
}

const CampaignVariantsTargetGroupSchema = z
  .object({})
  .catchall(
    z.object({}).catchall(z.array(z.object({ name: z.string().min(1), value: z.boolean() }))),
  );

const CampaignVariantSchema = z.object({
  uniqId: z.string(),
  name: z.string().min(1),
  tabs: CampaignVariantsTargetGroupSchema,
});

const CampaignSetWithVariantsSchema = CampaignSetWithTabsSchema.extend({
  variants: z
    .array(validateSetWithVariants(CampaignVariantSchema, 'Select any targeting for this variant.'))
    .min(1, { message: 'You need to define at least one variant.' }),
});
function validateSetWithVariants(schema: typeof CampaignVariantSchema, message: string) {
  return schema.refine(
    (variant) => {
      const tabs = Object.values(variant.tabs);
      const options = tabs.map((tab) => Object.values(tab)).flat();
      return options.some((option) => option.some((el) => el.value === true));
    },
    { message: message },
  );
}

const CampaignSetTouchpointSchema = z.object({
  name: z.string().min(1),
  frequency: FrequencySchema.optional(),
});

const CampaignSetSchema = z.object({
  uniqId: z.string().min(1).optional(),
  goal: z.string().min(1),
  touchpoints: z.array(CampaignSetTouchpointSchema).min(1),
  creatives: z.array(z.string().min(1)).min(1),
  name: z.string().min(1),
});
const CampaignSetSchemaPartial = CampaignSetSchema.deepPartial();

const CampaignBudgetSplitSchema = z.object({
  setUniqId: z.string(),
  touchpointUniqId: z.string(),
  setName: z.string(),
  name: z.string(),

  startDate: z.date(),
  endDate: z.date(),

  isDisabled: z.boolean(),
  isPartner: z.boolean().optional(),
  partners: z.array(z.string()).optional(),
  partner: z.string().optional(),
  partnersType: z.nativeEnum(PartnerListTypeEnumModel).optional(),

  KPI: z.string(), //KPI IO goal
  kpiValue: z
    .number()
    .min(0)
    .or(z.nan())
    .optional()
    .transform((limit) => (Number.isNaN(limit) ? undefined : limit)),

  budget: z.number(),
  segmentsType: z.nativeEnum(BudgetSplitSegmentsTypeEnumModel),
  segmentsList: z
    .array(
      z.object({
        startDate: z.date(),
        endDate: z.date(),
        budget: z
          .number()
          .or(z.nan())
          .optional()
          .transform((limit) => (Number.isNaN(limit) ? undefined : limit)),
      }),
    )
    .min(1),
});

function validateBudgetSplit(schema: typeof CampaignBudgetSplitSchema) {
  return schema
    .refine(
      (budgetSplit) => {
        if (budgetSplit.isDisabled) {
          return true;
        }

        if (budgetSplit.kpiValue === undefined || budgetSplit.kpiValue === 0) {
          return false;
        }

        return true;
      },
      { message: 'KPI value is required.', path: ['kpiValue'] },
    )
    .refine(
      (budgetSplit) => {
        if (budgetSplit.isDisabled) {
          return true;
        }

        if (budgetSplit.segmentsList.some((segment) => segment.budget === undefined)) {
          return false;
        }

        return true;
      },
      (budgetSplit) => {
        const index = budgetSplit.segmentsList.findIndex((segment) => segment.budget === undefined);
        return {
          message: 'Budget is required',
          path: ['segmentsList', index, 'budget'],
        };
      },
    );
}

const CampaignBudgetSplitSchemaPartial = CampaignBudgetSplitSchema.deepPartial();

const adgItemSchema = z.object({
  name: z.string().min(1),
  liSubtype: z.string().optional(),
});

const validateAdgItems = (adgs: z.infer<typeof adgItemSchema>[], ctx: z.RefinementCtx) => {
  const allNames = adgs.map((adg) => adg.name);
  adgs.forEach((adg, adgIndex) => {
    const isDuplicate = allNames.some((name, index) => name === adg.name && index != adgIndex);
    if (isDuplicate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: [adgIndex, 'name'],
        message: `No duplicates allowed.`,
      });
    }
  });
};

const CampaignLisAdgsTargetGroupsSchema = z
  .object({})
  .catchall(z.object({}).catchall(z.array(z.string()).optional()));

const CampaignLisAdgsSchema = z.object({
  setId: z.string(),
  touchpointId: z.string(),
  name: z.string(),
  liType: z.string(),
  liSubtype: z.string(),
  environment: z.string(),
  ioFormatOther: z.string(),
  kpiIoGoal: z.string(),
  kpiReportingGoal: z.string(),
  kpiNamingIoGoal: z.string(),
  creativeLength: z.string(),
  targetGroups: CampaignLisAdgsTargetGroupsSchema,
  partnerId: z.string().optional(),
  ncTouchpointName: z.string(),
  publisher: z.string(),
  lineItems: z
    .array(
      z.object({
        uniqId: z.string().optional(),
        variantName: z.string(),
        variants: CampaignLisAdgsTargetGroupsSchema,
        name: z.string().min(1),
        frequency: validateFrequency(
          FrequencySchema,
          'You need to fill all frequency inputs or leave them clear.',
        ).optional(),
        view: validateViewFrequency(
          ViewFrequencySchema,
          'You need to fill all view inputs or leave them clear.',
        ).optional(),
        mainTactics: z.string().optional(),
        tacticDetails: z.string().optional(),
        adgs: z.array(adgItemSchema).min(1).superRefine(validateAdgItems),
      }),
    )
    .min(1),
});

const CampaignLisAdgsSchemaPartial = CampaignLisAdgsSchema.deepPartial();

const CreateCampaignSchema = z.object({
  setup: CampaignSetupSchema,
  categoryData: CategoryDataSchema,
  sets: z.array(CampaignSetSchema).min(1, { message: 'You need to define at least one set.' }),
  setsWithTabs: z
    .array(validateSetWithTabs(CampaignSetWithTabsSchema, 'Select any targeting for this set.'))
    .min(1)
    .optional(),
  setsWithVariants: z.array(CampaignSetWithVariantsSchema).min(1).optional(),
  budgetSplits: z.array(validateBudgetSplit(CampaignBudgetSplitSchema)).optional(),
  lisAdgs: z.array(CampaignLisAdgsSchema).optional(),
});

const CreateCampaignSchemaPartial = CreateCampaignSchema.deepPartial();

export const CampaignGoalsSchema = CreateCampaignSchema.pick({
  setup: true,
  categoryData: true,
  sets: true,
});
export const TargetGroupSchema = CreateCampaignSchema.required().pick({ setsWithTabs: true });
export const VariantTargetingSchema = CreateCampaignSchema.required().pick({
  setsWithVariants: true,
});

export const BudgetSplitSchema = CreateCampaignSchema.required().pick({
  budgetSplits: true,
});

export const LisAdgsSchema = CreateCampaignSchema.required().pick({
  lisAdgs: true,
});

export type IFrequency = z.infer<typeof FrequencySchema>;
export type IViewFrequency = z.infer<typeof ViewFrequencySchema>;
export type ICreateCampaignSetupPartial = z.infer<typeof CampaignSetupSchemaPartial>;
export type ICreateCampaignCategoryPartial = z.infer<typeof CategoryDataSchemaPartial>;
export type ICreateCampaignTargetGroup = z.infer<typeof CampaignTargetGroupSchema>;
export type ICreateCampaignSetWithTabs = z.infer<typeof CampaignSetWithTabsSchema>;
export type ICreateCampaignSetWithVariants = z.infer<typeof CampaignSetWithVariantsSchema>;
export type ICreateCampaignSet = z.infer<typeof CampaignSetSchema>;
export type ICreateCampaignBudgetSplit = z.infer<typeof CampaignBudgetSplitSchema>;
export type ICreateCampaignBudgetSplitPartial = z.infer<typeof CampaignBudgetSplitSchemaPartial>;
export type ICreateCampaignLisAdgs = z.infer<typeof CampaignLisAdgsSchema>;
export type ICreateCampaignLisAdgsPartial = z.infer<typeof CampaignLisAdgsSchemaPartial>;
export type ICreateCampaignLisAdgsTargetGroups = z.infer<typeof CampaignLisAdgsTargetGroupsSchema>;
export type ICreateCampaignSetPartial = z.infer<typeof CampaignSetSchemaPartial>;
export type ICreateCampaignForm = z.infer<typeof CreateCampaignSchema>;
export type ICreateCampaignFormPartial = z.infer<typeof CreateCampaignSchemaPartial>;
export type ICreateCampaignVariantTargetGroup = z.infer<typeof CampaignVariantsTargetGroupSchema>;
export type IVariants = z.infer<typeof CampaignSetWithVariantsSchema>['variants'];
