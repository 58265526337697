import React, { FC, useState } from 'react';
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form/dist/types/form';
import { UseFormGetValues, useFormState } from 'react-hook-form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { VariantTargetingForm } from '../VariantTargetingForm/VariantTargetingForm';
import { SelectSet } from '../../SelectSet/SelectSet';
import { Error } from '../../Error/Error';

export interface Props {
  getValues: UseFormGetValues<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  trigger: UseFormTrigger<ICreateCampaignForm>;
}

const VariantTargetingInfo: FC<Props> = ({
  getValues,
  setValue,
  register,
  control,
  trigger,
}): JSX.Element => {
  const [selectedSet, setSelectedSet] = useState<number | null>(null);

  const { errors } = useFormState({
    control,
    name: `setsWithVariants`,
  });

  return (
    <div>
      <SelectSet
        control={control}
        getValues={getValues}
        register={register}
        setValue={setValue}
        onSetChange={setSelectedSet}
      />
      {selectedSet !== null && (
        <VariantTargetingForm
          key={selectedSet}
          selectedSet={selectedSet}
          setValue={setValue}
          register={register}
          control={control}
          trigger={trigger}
        />
      )}
      {Object.entries(errors).length !== 0 && (
        <Error>Every variant should have filled name and some targeting selected</Error>
      )}
    </div>
  );
};

export default VariantTargetingInfo;
