import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Coordinates } from '../types/Coordinates';

const useMultiCheckboxing = () => {
  const dragRectangle = useRef<HTMLDivElement>(null);
  const dragWrapper = useRef<HTMLDivElement>(null);
  const coordinatesRef = useRef<Coordinates>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  // Default styles
  const rectangleStyle: CSSProperties = {
    position: 'absolute',
    background: 'grey',
    pointerEvents: 'none',
    opacity: '0',
    left: 0,
    top: 0,
  };
  const parentStyle: CSSProperties = {
    position: 'relative',
    width: 'fit-content',
  };

  const [clickState, setClickState] = useState<boolean>(false);
  useEffect(() => {
    let clicked = false;
    const start = {
      x: 0,
      y: 0,
    };
    const dragContainer = dragRectangle.current;
    const wrapperContainer = dragWrapper.current;

    const handleMouseDown = (event: MouseEvent) => {
      if (!dragContainer || !wrapperContainer) {
        return;
      }

      const wrapperRectangle = wrapperContainer.getBoundingClientRect();

      start.x = event.clientX - wrapperRectangle.left;
      start.y = event.clientY - wrapperRectangle.top;

      // clicked start outside an intended div
      if (
        start.x < 0 ||
        start.x > wrapperRectangle.width ||
        start.y < 0 ||
        start.y > wrapperRectangle.height
      ) {
        return;
      }
      clicked = true;
      setClickState(true);

      dragContainer.style.left = `${start.x}px`;
      dragContainer.style.top = `${start.y}px`;
      dragContainer.style.opacity = '0.5';

      coordinatesRef.current = { left: start.x, top: start.y, width: 0, height: 0 };
    };

    const handleMouseUp = () => {
      clicked = false;
      setClickState(false);

      if (!dragContainer) {
        return;
      }

      dragContainer.style.opacity = '0';
      dragContainer.style.width = `0px`;
      dragContainer.style.height = `0px`;

      coordinatesRef.current = { left: 0, top: 0, width: 0, height: 0 };
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!clicked || !dragContainer || !wrapperContainer) {
        return;
      }

      const wrapperRectangle = wrapperContainer.getBoundingClientRect();

      const newCoordinates: Partial<Coordinates> = {};

      const currX = event.clientX - wrapperRectangle.left;
      const currY = event.clientY - wrapperRectangle.top;

      if (currX > start.x) {
        // Right move
        if (currX > wrapperRectangle.width) {
          //Outside
          dragRectangle.current.style.width = `${wrapperRectangle.width - start.x}px`;
          newCoordinates.width = wrapperRectangle.width - start.x;
        } else {
          //Inside
          dragRectangle.current.style.width = `${currX - start.x}px`;
          newCoordinates.width = currX - start.x;
        }
        dragRectangle.current.style.left = `${start.x}px`;
        newCoordinates.left = start.x;
      } else {
        // Left move
        if (currX < 0) {
          dragRectangle.current.style.width = `${start.x}px`;
          dragRectangle.current.style.left = `${0}px`;
          newCoordinates.width = start.x;
          newCoordinates.left = 0;
        } else {
          dragRectangle.current.style.width = `${start.x - currX}px`;
          dragRectangle.current.style.left = `${currX}px`;
          newCoordinates.width = start.x - currX;
          newCoordinates.left = currX;
        }
      }

      if (currY > start.y) {
        // Down move
        if (currY > wrapperRectangle.height) {
          dragRectangle.current.style.height = `${wrapperRectangle.height - start.y}px`;
          newCoordinates.height = wrapperRectangle.height;
        } else {
          dragRectangle.current.style.height = `${currY - start.y}px`;
          newCoordinates.height = currY - start.y;
        }
        dragRectangle.current.style.top = `${start.y}px`;
        newCoordinates.top = start.y;
      } else {
        // Up move
        if (currY < 0) {
          dragRectangle.current.style.top = `${0}px`;
          dragRectangle.current.style.height = `${start.y}px`;
          newCoordinates.top = 0;
          newCoordinates.height = start.y;
        } else {
          dragRectangle.current.style.top = `${currY}px`;
          dragRectangle.current.style.height = `${start.y - currY}px`;
          newCoordinates.top = currY;
          newCoordinates.height = start.y - currY;
        }
      }
      coordinatesRef.current = { ...coordinatesRef.current, ...newCoordinates };
    };

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return { parentStyle, rectangleStyle, dragRectangle, dragWrapper, clickState, coordinatesRef };
};

export default useMultiCheckboxing;
