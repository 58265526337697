import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import styles from '../shared.module.scss';
import { UseFormGetValues } from 'react-hook-form';
import { IAdsSetupForm } from '../../../types/AdsSetupForm';

interface Props {
  selected: boolean;
  onClick: () => void;
  tIndex: number;
  getValues: UseFormGetValues<IAdsSetupForm>;
  touchpoint: IAdsSetupForm['touchpoints'][number];
}

const TouchpointLine: FC<Props> = ({ selected, onClick, tIndex, getValues, touchpoint }) => {
  const name = useMemo(() => {
    const setName = getValues(`sets.${touchpoint.setId}.name`);
    const touchpointName = getValues(
      `sets.${touchpoint.setId}.touchpoints.${touchpoint.touchpointId}.name`,
    );

    return `${setName} ${touchpointName} ${touchpoint?.partnerId ?? ''}`;
  }, [getValues, tIndex, touchpoint]);

  return (
    <div className={'col-6 pe-0 d-flex flex-direction-row'}>
      <h6 className={'my-auto me-1'}>IO:</h6>
      <div
        className={cn(styles.option, 'w-100 p-1 ps-2 border rounded', {
          [styles.active]: selected,
        })}
        onClick={onClick}>
        {name}
      </div>
    </div>
  );
};

export default TouchpointLine;
