import React, { FC } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Control, FieldArrayWithId, UseFieldArrayRemove, useFormState } from 'react-hook-form';
import { ICreateCampaignForm } from '../../../../types/CreateCampaignForm';
import TouchpointTableItem from './TouchpointTableItem/TouchpointTableItem';
import { PartnerListTypeEnumModel } from '../../../../graphql/__generated__/graphql';

interface Props {
  register: UseFormRegister<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  selectedTouchpointIndex: null | number;
  setSelectedTouchpointIndex: (index: number | null) => void;
  campaignId: string;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  touchpoints: FieldArrayWithId<ICreateCampaignForm, `budgetSplits`>[];
  remove: UseFieldArrayRemove;
}

const LeftTouchpointsTable: FC<Props> = ({
  control,
  register,
  selectedTouchpointIndex,
  setSelectedTouchpointIndex,
  setValue,
  campaignId,
  touchpoints,
  remove,
}): JSX.Element => {
  const { errors } = useFormState({ control, name: 'budgetSplits' });

  const removeTouchpointHandler = (touchpointIndex: number) => {
    if (!touchpoints[touchpointIndex].isPartner) {
      return;
    }

    const setId = touchpoints[touchpointIndex].setUniqId;
    const partnerName = touchpoints[touchpointIndex].partner;

    if (partnerName === undefined) {
      return;
    }

    //Updating a parent set
    const parentSetIndex = touchpoints.findIndex((tc) => !tc.isPartner && tc.setUniqId === setId);
    if (parentSetIndex == -1) {
      return;
    }

    const parentSet = touchpoints[parentSetIndex];
    const newPartnerList = parentSet.partners?.filter((item) => item != partnerName);

    setValue(
      `budgetSplits.${parentSetIndex}.isDisabled`,
      newPartnerList ? newPartnerList.length !== 0 : false,
    );
    setValue(`budgetSplits.${parentSetIndex}.partners`, newPartnerList);
    setValue(
      `budgetSplits.${parentSetIndex}.partnersType`,
      newPartnerList?.length === 0 ? PartnerListTypeEnumModel.Cascade : parentSet.partnersType,
    );

    if (selectedTouchpointIndex != null && !touchpoints[selectedTouchpointIndex + 1]) {
      setSelectedTouchpointIndex(null);
    }

    remove(touchpointIndex);
  };

  return (
    <div className={'row col-9 align-content-start'}>
      <p className={'col-6'}>Insertion orders</p>
      <p className={'col-2'}>KPI type</p>
      <p className={'col-2'}>KPI value</p>
      <div className={'row'}>
        <div className={'col-12 p-0 m-0 my-2 border'}>
          {touchpoints?.map((touchpoint, touchpointIndex) => (
            <TouchpointTableItem
              key={touchpoint.id}
              register={register}
              errors={errors}
              setValue={setValue}
              selectedTouchpointIndex={selectedTouchpointIndex}
              setSelectedTouchpointIndex={setSelectedTouchpointIndex}
              touchpoint={touchpoint}
              touchpointIndex={touchpointIndex}
              campaignId={campaignId}
              removeTouchpointHandler={removeTouchpointHandler}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default LeftTouchpointsTable;
