import { gql } from '../__generated__';

export const UPSERT_VARIANT_TARGETING_QUERY = gql(`
    query GetUpsertVariantTargeting($campaignId: String!) {
        campaign(campaignId: $campaignId) {
            ...CampaignSetup
            sets {
              ...CampaignSet
              ...CampaignSetVariants
            }
            updatedAt
        }
    }
`);
