import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { ICreateCampaignForm, VariantTargetingSchema } from '../../types/CreateCampaignForm';
import { Paths } from '../../constants/paths';
import { useGraphQLError } from '../../hooks/useGraphQLError';
import Loader from '../../components/UI/Loader/Loader';
import { UPSERT_VARIANT_TARGETING_QUERY } from '../../graphql/queries/UpsertVariantTargetingQuery';
import {
  getUpsertVariantTargetingMutationVariables,
  UPSERT_VARIANT_TARGETING_MUTATION,
} from '../../graphql/mutations/UpsertVariantTargetingMutation';
import VariantTargeting from '../../components/VariantTergeting/VariantTargeting';
import { useFragment } from '../../graphql/__generated__';
import {
  CAMPAIGN_SETUP_FRAGMENT,
  campaignSetupFragmentToForm,
} from '../../graphql/fragments/CampaignSetupFragment';
import {
  CAMPAIGN_SET_FRAGMENT,
  campaignSetsFragmentToSets,
} from '../../graphql/fragments/CampaignSetFragment';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CAMPAIGN_SET_VARIANTS_FRAGMENT,
  campaignSetVariantsFragmentToSetWithVariants,
} from '../../graphql/fragments/CampaignSetVariantsFragment';
import { getPath } from '../../helpers/getSetupStatus';
import { CampaignSetupStatusEnumModel } from '../../graphql/__generated__/graphql';
import { toastMissingUpdateDate } from '../../helpers/toastMissingUpdateDate';

type VariantTargetingParams = {
  id: string;
};

export const UpsertVariantTargeting: FC = () => {
  const [initialized, setInitialized] = useState(false);
  const { id } = useParams<VariantTargetingParams>();
  const navigate = useNavigate();

  const [getUpsertVariantTargeting, { error, data }] = useLazyQuery(UPSERT_VARIANT_TARGETING_QUERY);

  const [upsertVariantTargeting, { error: upsertError, loading: upsertLoading, data: upsertData }] =
    useMutation(UPSERT_VARIANT_TARGETING_MUTATION);

  const { setValue, getValues, register, control, reset, handleSubmit, trigger } =
    useForm<ICreateCampaignForm>({
      resolver: zodResolver(VariantTargetingSchema),
    });

  useEffect(() => {
    if (!id) {
      return;
    }

    getUpsertVariantTargeting({ variables: { campaignId: id } });
  }, [id]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const campaignSetup = useFragment(CAMPAIGN_SETUP_FRAGMENT, data.campaign);
    const campaignSets =
      data.campaign?.sets.map((set) => useFragment(CAMPAIGN_SET_FRAGMENT, set)) ?? [];
    const campaignSetTabs =
      data.campaign?.sets.map((set) => useFragment(CAMPAIGN_SET_VARIANTS_FRAGMENT, set)) ?? [];

    const status = campaignSetup?.setupStatus || '';
    if (status === CampaignSetupStatusEnumModel.Initialized) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      navigate(getPath(status, id!));
      return;
    }
    reset({
      setup: campaignSetupFragmentToForm(campaignSetup),
      sets: campaignSetsFragmentToSets(campaignSets),
      setsWithVariants: campaignSetVariantsFragmentToSetWithVariants(campaignSetTabs),
    });
    setInitialized(true);
  }, [data]);

  useEffect(() => {
    if (!upsertData) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    navigate(Paths.getUpsertBudgetSplit(id!));
  }, [upsertData]);

  useGraphQLError([error, upsertError]);

  const handleNextButton = () => {
    if (upsertLoading) {
      return;
    }
    if (!data?.campaign?.updatedAt) {
      toastMissingUpdateDate();
      return;
    }
    const updatedAt = data.campaign.updatedAt;
    handleSubmit((data) => {
      upsertVariantTargeting({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: getUpsertVariantTargetingMutationVariables(data, id!, updatedAt),
      });
    })();
  };

  const handlePreviousButton = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    navigate(Paths.getUpsertTargetGroup(id!));
  };

  if (!initialized) {
    return <Loader />;
  }

  return (
    <VariantTargeting
      setValue={setValue}
      getValues={getValues}
      register={register}
      control={control}
      trigger={trigger}
      handleNext={handleNextButton}
      handlePrevious={handlePreviousButton}
      handlerLoading={upsertLoading}
    />
  );
};
