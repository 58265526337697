import { gql } from '../__generated__';
import { ICreateCampaignForm, ICreateCampaignSetWithTabs } from '../../types/CreateCampaignForm';
import { TargetGroupInput, UpsertTargetGroupMutationVariables } from '../__generated__/graphql';

export const UPSERT_TARGET_GROUP_MUTATION = gql(`
    mutation UpsertTargetGroup($input: UpsertCampaignTargetGroupsInput!) {
        upsertCampaignTargetGroups(input: $input) {
            campaign { 
                id 
            }
        }
    }
`);

export const getUpsertTargetGroupMutationVariables = (
  data: ICreateCampaignForm,
  id: string,
  updatedAt: string,
): UpsertTargetGroupMutationVariables => {
  return {
    input: {
      campaignId: id,
      sets:
        data.setsWithTabs?.map((set) => ({
          uniqId: set.setUniqId,
          targetGroups: getTargetGroups(set),
        })) ?? [],
      updatedAt: updatedAt,
    },
  };
};

const getTargetGroups = (set: ICreateCampaignSetWithTabs): TargetGroupInput[] => {
  return Object.keys(set.tabs)
    .map((type) => {
      const subTypes = set.tabs[type];
      return Object.keys(subTypes)
        .filter((subType) => {
          const value = subTypes[subType];
          return !(value === undefined || value.length === 0);
        })
        .map((subType) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          let value = subTypes[subType]!;
          if (typeof value === 'string') {
            value = [value];
          }

          return {
            type: type.toString(),
            subType: subType.toString(),
            values: value,
          };
        });
    })
    .flat();
};
