import { FirebaseApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { firebaseContext } from '../context/FirebaseContext';

export const getHeaders = async (firebase: FirebaseApp) => {
  const user = getAuth(firebase).currentUser;
  const token = await user?.getIdToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    'Project-Name': `${firebaseContext.partnerData?.campaignName}`,
  };
  return headers;
};
