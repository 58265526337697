import { IFrequency, IViewFrequency } from '../../types/CreateCampaignForm';
import { FrequencyInput, ViewFrequencyInput } from '../__generated__/graphql';

export const getFrequencyInput = (input: IFrequency | undefined): FrequencyInput | null => {
  if (!input) {
    return {};
  }

  const result: FrequencyInput = {};

  if (input.limit !== undefined && !isNaN(parseInt(input.limit))) {
    result.limit = parseInt(input.limit);
  }

  if (input.per !== undefined && !isNaN(parseInt(input.per))) {
    result.per = parseInt(input.per);
  }

  if (input.period !== undefined) {
    result.period = input.period;
  }
  return result;
};

export const getViewFrequencyInput = (
  input: IViewFrequency | undefined,
): ViewFrequencyInput | null => {
  if (!input) {
    return {};
  }

  const result: ViewFrequencyInput = {};

  if (input.limit !== undefined && !isNaN(parseInt(input.limit))) {
    result.limit = parseInt(input.limit);
  }

  if (input.period !== undefined) {
    result.period = input.period;
  }
  return result;
};
