import { gql } from '../__generated__';

export const INSERTION_FREQUENCY_FRAGMENT = gql(`
    fragment InsertionFrequency on FrequencyTemplate {
        scenarioName
        touchpointName
        frequency {
            limit
            per
            period
        }
    }
`);
