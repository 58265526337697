import * as z from 'zod';
import { BudgetSplitSegmentsTypeEnumModel } from '../graphql/__generated__/graphql';

export const GenerateSegmentsFormSchema = z
  .object({
    segmentsType: z.nativeEnum(BudgetSplitSegmentsTypeEnumModel),
    segmentsNumber: z
      .number()
      .int()
      .or(z.nan())
      .optional()
      .transform((number) => (Number.isNaN(number) ? undefined : number)),
  })
  .refine(
    (form) => {
      if (
        form.segmentsType === BudgetSplitSegmentsTypeEnumModel.Equal &&
        (form.segmentsNumber === undefined || form.segmentsNumber < 1)
      ) {
        return false;
      }

      return true;
    },
    {
      message: 'Number of segments is required for the equal option.',
      path: ['segmentsNumber'],
    },
  );

export type GenerateSegmentsForm = z.infer<typeof GenerateSegmentsFormSchema>;
