import React, { FC } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import FrequencyInput from '../../FrequencyInput/FrequencyInput';

interface Props {
  name: `lisAdgs.${number}.lineItems.${number}.view`;
  register: UseFormRegister<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  isDisabled?: boolean;
  isInvalid?: {
    limit?: boolean;
    per?: boolean;
    period?: boolean;
  };
}

const LimitView: FC<Props> = ({
  register,
  setValue,
  name,
  isDisabled = false,
  isInvalid,
}): JSX.Element => {
  return (
    <FrequencyInput
      limitLabel={'Limit views to'}
      periodDefault={'view period'}
      name={name}
      register={register}
      setValue={setValue}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      isViewFrequency={true}
    />
  );
};

export default LimitView;
