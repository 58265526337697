import { FirebaseApp, initializeApp } from 'firebase/app';
import { createContext } from 'react';
import { Firestore } from '@firebase/firestore/lite';
import { getFirestore } from 'firebase/firestore/lite';
import { IPartner } from '../types/Partner';
import { Unsubscribe, User } from 'firebase/auth';

//gui
const firebaseGuiConfig = JSON.parse(process.env?.REACT_APP_FIREBASE_CONFIG_DEFAULT || '{}');
//master
const firebaseUserConfig = JSON.parse(process.env?.REACT_APP_FIREBASE_USER_CONFIG || '{}');
//

const appGui = initializeApp(firebaseGuiConfig, 'gui');
const appMaster = initializeApp(firebaseUserConfig, 'master');
export interface IFirebaseContext {
  firebaseGui: FirebaseApp;
  firestoreGui: Firestore;
  email?: string;
  password?: string;
  token?: string;
  partnerData?: IPartner;
  masterFirestore: Firestore;
  setFirebase: (app: FirebaseApp) => void;
  masterFirebase: FirebaseApp; // (app: FirebaseApp) => void;
  setDb: (db: Firestore) => void;
  campaignFirebase?: FirebaseApp;
  campaignFirestore?: Firestore;
  guiUser?: User;
  masterUser?: User;
  partnerUser?: User;
  setCampaignFirebase?: (app?: any) => void;
  setCampaignFirestore?: (db: Firestore) => void;
  guiAuthUnsubscribe?: Unsubscribe;
  masterAuthUnsubscribe?: Unsubscribe;
  partnerAuthUnsubscribe?: Unsubscribe;
}

export interface IFirebaseApps {
  firebase: FirebaseApp;
  masterFirebase: FirebaseApp;
  // campaignFirebase: FirebaseApp;
}

export const firebaseContext: IFirebaseContext = {
  firebaseGui: appGui,
  firestoreGui: getFirestore(appGui),
  masterFirebase: appMaster,
  masterFirestore: getFirestore(appMaster),
  setFirebase: () => {},
  setDb: () => {},
};

export const FirebaseContext = createContext<IFirebaseContext>(firebaseContext);
