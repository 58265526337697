export const Paths = {
  home: '/',
  createCampaignGoals: '/create-campaign-goals',
  upsertCampaignGoals: '/create-campaign-goals/:id',
  getUpsertCampaignGoals: (id: string) => `/create-campaign-goals/${id}`,
  upsertTargetGroup: '/create-target-group/:id',
  getUpsertTargetGroup: (id: string) => `/create-target-group/${id}`,
  upsertVariantTargeting: '/create-variant-targeting/:id',
  getUpsertVariantTargeting: (id: string) => `/create-variant-targeting/${id}`,
  upsertBudgetSplit: '/create-budget-split/:id',
  getUpsertBudgetSplit: (id: string) => `/create-budget-split/${id}`,
  upsertLisAdgs: '/create-lis-adgs/:id',
  getUpsertLisAdgs: (id: string) => `/create-lis-adgs/${id}`,
  adsSetupForm: '/create-ads-setup/:id',
  getAdsSetupForm: (id: string) => `/create-ads-setup/${id}`,
};
