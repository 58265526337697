import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './MultipleGoalsSelect.module.scss';
import { Control, UseFormGetValues, UseFormSetValue, useWatch } from 'react-hook-form';
import { ISetForm } from '../../../types/SetForm';
import SelectBlock from '../SelectBlock/SelectBlock';
import { GoalDetailsFragment } from '../../../graphql/__generated__/graphql';

interface Props {
  setValue: UseFormSetValue<ISetForm>;
  getValues: UseFormGetValues<ISetForm>;
  control: Control<ISetForm>;
  goals: GoalDetailsFragment[];
}

interface IOptions {
  goals: string[];
  touchpoints: string[];
  creatives: string[];
}

const MultipleGoalsSelect: FC<Props> = ({
  goals,
  setValue,
  getValues,
  control,
}: Props): JSX.Element => {
  const goal = useWatch({ control, name: 'goal' });
  const touchpoints = useWatch({ control, name: 'touchpoints' });
  const creatives = useWatch({ control, name: 'creatives' });

  const [options, setOptions] = useState<IOptions>({ goals: [], touchpoints: [], creatives: [] });

  const allTouchpoints = useMemo(() => {
    return (
      goals
        .map((goal) => goal.touchpoints || [])
        .flat()
        .filter((touchpoint) => touchpoint?.id != null)
        .map((touchpoint) => ({
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          id: touchpoint!.id!,
          targetingCompatibility: touchpoint!.targetingCompatibility || [],
          /* eslint-enable @typescript-eslint/no-non-null-assertion */
        })) || []
    );
  }, [goals]);

  const disabledTouchpoints: string[] = useMemo(() => {
    const selectedTouchpoints = allTouchpoints.filter((touchpoint) =>
      touchpoints.includes(touchpoint.id),
    );
    if (selectedTouchpoints.length === 0) {
      return [];
    }

    return options.touchpoints.filter((touchpoint) =>
      selectedTouchpoints.some((sTouch) => {
        const sameName = sTouch.id === touchpoint;
        if (sameName) {
          return false;
        }
        const compatible = sTouch.targetingCompatibility.includes(touchpoint);
        return !compatible;
      }),
    );
  }, [JSON.stringify(touchpoints)]);

  useEffect(() => {
    setOptions({
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
      goals: goals.filter((goal) => goal?.id != null).map((goal) => goal?.id!) || [],
      touchpoints: [],
      creatives: [],
    });
  }, []);

  useEffect(() => {
    const foundDataGoal = goals.find((dataGoal) => dataGoal?.id === goal);
    const touchpointOptions =
      foundDataGoal?.touchpoints
        ?.filter((touchpoint) => touchpoint?.id != null)
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
        .map((touchpoint) => touchpoint?.id!) || [];

    //update selected touchpoints
    const selectedTouchpoints = touchpoints.filter((touchpoint) =>
      touchpointOptions.includes(touchpoint),
    );
    setValue('touchpoints', selectedTouchpoints);
    setOptions((prev) => ({ ...prev, touchpoints: touchpointOptions.sort() }));
  }, [goal]);

  useEffect(() => {
    const creativeOptions = touchpoints
      .map((touchpoint) => {
        return (
          goals
            .map((goal) => {
              const dataTouchpoint = goal?.touchpoints?.find(
                (dataTouchpoint) => dataTouchpoint?.id === touchpoint,
              );

              return (
                dataTouchpoint?.creatives
                  ?.filter((creative) => creative?.creativeName != null)
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
                  .map((creative) => creative?.creativeName!) || []
              );
            })
            .flat() || []
        );
      })
      .flat();

    //update selected creatives
    const selectedCreatives = creatives.filter((creative) => creativeOptions.includes(creative));
    setValue('creatives', selectedCreatives);

    setOptions((prev) => ({ ...prev, creatives: creativeOptions.sort() }));
  }, [JSON.stringify(touchpoints), goals]);

  return (
    <div className="row zeroMargin zeroLeftPadding marginCenter d-flex mw-100">
      <SelectBlock
        control={control}
        getValues={getValues}
        setValue={setValue}
        isRequired={true}
        withMultiple={false}
        name={'goal'}
        content={options.goals}
        title={'Goal/Objectives'}
        className={{
          option: styles.option,
          selectWrap: styles.selectWrap,
        }}
      />
      {goal !== '' && (
        <SelectBlock
          control={control}
          getValues={getValues}
          setValue={setValue}
          isRequired={true}
          name={'touchpoints'}
          disabledOptions={disabledTouchpoints}
          content={options.touchpoints}
          title={'Touchpoints'}
          className={{
            option: styles.option,
            selectWrap: styles.selectWrap,
          }}
        />
      )}
      {touchpoints.length > 0 && (
        <SelectBlock
          control={control}
          getValues={getValues}
          setValue={setValue}
          isRequired={true}
          name={'creatives'}
          content={options.creatives}
          title={'Creatives'}
          className={{
            option: styles.option,
            selectWrap: styles.selectWrap,
          }}
        />
      )}
    </div>
  );
};
export default MultipleGoalsSelect;
