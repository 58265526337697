import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { Paths } from '../../constants/paths';
import { ICreateCampaignForm, TargetGroupSchema } from '../../types/CreateCampaignForm';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPSERT_TARGET_GROUP_QUERY } from '../../graphql/queries/UpsertTargetGroupQuery';
import {
  getUpsertTargetGroupMutationVariables,
  UPSERT_TARGET_GROUP_MUTATION,
} from '../../graphql/mutations/UpsertTargetGroupMutation';
import { useGraphQLError } from '../../hooks/useGraphQLError';
import Loader from '../../components/UI/Loader/Loader';
import { useFragment } from '../../graphql/__generated__';
import {
  CAMPAIGN_SETUP_FRAGMENT,
  campaignSetupFragmentToForm,
} from '../../graphql/fragments/CampaignSetupFragment';
import {
  CAMPAIGN_SET_FRAGMENT,
  campaignSetsFragmentToSets,
} from '../../graphql/fragments/CampaignSetFragment';
import {
  campaignSetsTabsFragmentToSetWithTabs,
  CAMPAIGN_SET_TABS_FRAGMENT,
} from '../../graphql/fragments/CampaignSetTabsFragment';
import { zodResolver } from '@hookform/resolvers/zod';
import TargetGroup from '../../components/TargetGroup/TargetGroup';
import { toastMissingUpdateDate } from '../../helpers/toastMissingUpdateDate';

type TargetGroupParams = {
  id: string;
};

const UpsertTargetGroup: FC = () => {
  const [initialized, setInitialized] = useState(false);
  const { id } = useParams<TargetGroupParams>();
  const navigate = useNavigate();

  const [getUpsertTargetGroup, { error, data }] = useLazyQuery(UPSERT_TARGET_GROUP_QUERY);
  const [upsertTargetGroup, { error: upsertError, loading: upsertLoading, data: upsertData }] =
    useMutation(UPSERT_TARGET_GROUP_MUTATION);

  const { setValue, getValues, register, control, reset, handleSubmit, trigger } =
    useForm<ICreateCampaignForm>({
      resolver: zodResolver(TargetGroupSchema),
    });

  useEffect(() => {
    if (!id) {
      return;
    }

    getUpsertTargetGroup({ variables: { campaignId: id } });
  }, [id]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const campaignSetup = useFragment(CAMPAIGN_SETUP_FRAGMENT, data.campaign);

    const campaignSets =
      data.campaign?.sets.map((set) => useFragment(CAMPAIGN_SET_FRAGMENT, set)) ?? [];
    const campaignSetTabs =
      data.campaign?.sets.map((set) => useFragment(CAMPAIGN_SET_TABS_FRAGMENT, set)) ?? [];

    reset({
      setup: campaignSetupFragmentToForm(campaignSetup),
      sets: campaignSetsFragmentToSets(campaignSets),
      setsWithTabs: campaignSetsTabsFragmentToSetWithTabs(campaignSetTabs),
    });
    setInitialized(true);
  }, [data]);

  useEffect(() => {
    if (!upsertData) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    navigate(Paths.getUpsertVariantTargeting(id!));
  }, [upsertData]);

  useGraphQLError([error, upsertError]);

  const handleNextButton = () => {
    if (upsertLoading) {
      return;
    }

    if (!data?.campaign?.updatedAt) {
      toastMissingUpdateDate();
      return;
    }

    const updatedAt = data.campaign.updatedAt;

    handleSubmit((data) => {
      upsertTargetGroup({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: getUpsertTargetGroupMutationVariables(data, id!, updatedAt),
      });
    })();
  };

  const handlePreviousButton = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    navigate(Paths.getUpsertCampaignGoals(id!));
  };

  if (!initialized) {
    return <Loader />;
  }

  if (!id) {
    return <></>;
  }

  return (
    <TargetGroup
      campaignId={id}
      setValue={setValue}
      getValues={getValues}
      register={register}
      trigger={trigger}
      control={control}
      handleNext={handleNextButton}
      handlerLoading={upsertLoading}
      handlePrevious={handlePreviousButton}
    />
  );
};

export default UpsertTargetGroup;
