import styles from './Loader.module.scss';
import React from 'react';
import cn from 'classnames';

const HorizontalLoader = () => (
  <div
    className={cn(
      'd-flex flex-row justify-content-start align-items-center',
      styles.horizontalLoader,
    )}>
    <div />
    <div />
    <div />
  </div>
);

export default HorizontalLoader;
