import cn from 'classnames';
import styles from './TargetGroupName.module.scss';
import React, { FC } from 'react';
import { TargetGroupForVariant } from '../../../types/TargetGroup';

type Props = {
  targetGroup: TargetGroupForVariant;
};
export const TargetGroupName: FC<Props> = ({ targetGroup }) => {
  return (
    <div className={cn('col-12', styles.targetGroupName)}>
      <div className={'row py-1'}>
        <div className={cn(styles.targeting, styles.targetingCategory, 'col-6 my-auto')}>
          <p>{targetGroup.subType.split('_')?.join(' ')}</p>
          <div>{targetGroup.subType.split('_')?.join(' ')}</div>
        </div>
        <div className={cn('col-6')}>
          {targetGroup.values.map((checkboxItem: string) => (
            <div key={checkboxItem} className={styles.targeting}>
              <p>{checkboxItem}</p>
              <div>{checkboxItem}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
