import { gql } from '../__generated__';
import {
  ICreateCampaignSetWithTabs,
  ICreateCampaignTargetGroup,
} from '../../types/CreateCampaignForm';
import { CampaignSetTabsFragment } from '../__generated__/graphql';

export const CAMPAIGN_SET_TABS_FRAGMENT = gql(`
    fragment CampaignSetTabs on CampaignSet {
        uniqId
        targetGroups {
            type
            subType
            selectedValues
        }
    }
`);

export const campaignSetsTabsFragmentToSetWithTabs = (
  campaignSets: readonly CampaignSetTabsFragment[],
): ICreateCampaignSetWithTabs[] => {
  return (
    campaignSets.map((set) => {
      return campaignSetTabsFragmentToSetWithTabs(set);
    }) ?? []
  );
};

export const campaignSetTabsFragmentToSetWithTabs = (
  campaignSet: CampaignSetTabsFragment,
): ICreateCampaignSetWithTabs => {
  const tabsResult: ICreateCampaignTargetGroup = {};
  campaignSet.targetGroups?.forEach((tab) => {
    if (tabsResult[tab.type] === undefined) {
      tabsResult[tab.type] = {};
    }

    tabsResult[tab.type][tab.subType] = tab.selectedValues;
  });

  return {
    setUniqId: campaignSet.uniqId,
    tabs: tabsResult,
  };
};
