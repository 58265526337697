import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './ExclusionsSetup.module.scss';
import FilterNavigation from '../FilterNavigation/FilterNavigation';
import SelectBlock from '../SelectBlock/SelectBlock';
import { UseFormGetValues, useForm } from 'react-hook-form';
import { addDoc, collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore/lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ContainerProps, SelectorProps, TabProps } from '../../types/Tabs';
import { Button } from 'react-bootstrap';
import { flatten } from '../../helpers/flatten';
// import useSetUser from '../../hooks/useSetUser';
import { FirebaseContext } from '../../context/FirebaseContext';
import { PartnerContext } from '../../context/PartnerContext';
import { IExclusionTemplate } from '../../types/ExclusionTemplate';
import { getDate } from '../../helpers/getQueryParams';

// const GENDER = ['Male', 'Female', 'Unknown'];
// const AGE = ['16-24', '25-34', '35-44', '45-54', '55-65', '65+'];

const getSelectors = async (
  container: ContainerProps,
  firestore: any,
  platform: string,
  getValues: UseFormGetValues<any>,
) => {
  return await Promise.all(
    container?.selectors?.map(async (selector: SelectorProps) => {
      if (
        (!selector.isSearchable || selector?.keystrokeCount === 0) &&
        selector?.collectionSource !== 'category-list'
      ) {
        let docs;
        if (selector?.collectionSource === '{the-date}') {
          // const date = getDate(new Date(), true);
          const date = new Date();
          date.setDate(date.getDate() - 1);
          const dateBefore = getDate(date, true);
          const splitDate: any = dateBefore?.split('-');
          const currDocument = getDate(
            new Date(splitDate[0], splitDate[1] - 1, splitDate[2]),
            true,
          );
          docs = await getDocs(
            collection(
              firestore,
              'targeting-lists',
              platform === 'adform' ? 'AdForm' : platform?.toLowerCase(),
              'custom-list',
              getValues()?.categoryData?.brandId?.toString(),
              currDocument,
            ),
          );
        } else {
          docs = await getDocs(
            collection(
              firestore,
              'global-targeting-lists',
              platform === 'adform' ? 'AdForm' : platform?.toLowerCase(),
              `${selector?.collectionSource}`,
            ),
          );
        }

        return {
          name: selector.name,
          collectionSource: selector?.collectionSource,
          isSearchable: selector?.isSearchable,
          selectorType: selector?.selectorType,
          keystrokeCount: selector?.keystrokeCount,
          // options: [],
          options: docs.docs.map((doc: any) => {
            const data = doc?.data();
            return selector?.collectionSource === '{the-date}'
              ? data?.displayName
              : data && Object.prototype.hasOwnProperty.call(data, 'Language name')
              ? data['Language name']
              : data?.displayName || data?.Name || doc?.id;
          }),
          searchFields: selector?.searchFields,
        };
      }

      return {
        name: selector?.name,
        collectionSource: selector?.collectionSource,
        isSearchable: true,
        selectorType: selector?.selectorType,
        keystrokeCount: selector?.keystrokeCount,
        options: [],
        searchFields: selector?.searchFields,
      };
    }),
  );
};

const getActiveTabData = async (
  tab: TabProps | undefined,
  firestore: any,
  getValues: UseFormGetValues<any>,
  platform = 'dv360',
) => {
  if (tab) {
    return await Promise.all(
      tab.containers.map(async (container: ContainerProps) => {
        const selectors = await getSelectors(container, firestore, platform, getValues);
        return {
          saveTabName: tab?.saveTabName,
          name: container.name,
          custom_lists: container.custom_lists === 'True',
          containerOrder: container.containerOrder,
          selectors: selectors,
        };
      }),
    );
  }
  return [];
};

const getTabData = async (firestore: any) => {
  const objectivesSnapshot = await getDocs(collection(firestore, 'targeting'));
  let platform = '';
  const data = objectivesSnapshot.docs.map((doc: any) => {
    const docData = doc.data();
    const colOptionsArr: ContainerProps[] = [];
    if (docData?.containers) {
      for (const key of Object.keys(docData.containers)) {
        const container = docData.containers[`${key?.toString()}`];
        const selectorsRawArr: SelectorProps[] = [];
        for (const key2 of Object.keys(container?.selectors)) {
          const selector = container?.selectors[`${key2?.toString()}`];
          const {
            selector_name,
            selector_order,
            collection_source,
            searchable,
            selector_type,
            keystroke_count,
            display_fields_name,
          } = selector;
          const splitSource = collection_source?.split('/');
          selectorsRawArr.push({
            name: selector_name,
            order: selector_order,
            options: [],
            collectionSource: splitSource ? splitSource[splitSource?.length - 1] : null,
            isSearchable: searchable,
            selectorType: selector_type,
            keystrokeCount: keystroke_count,
            searchFields: display_fields_name,
          });
        }

        platform = docData?.platform;
        colOptionsArr.push({
          custom_lists: container?.custom_lists,
          name: container.container_name,
          containerOrder: container.container_order,
          selectors: selectorsRawArr.sort(
            (a: SelectorProps, b: SelectorProps) => a?.order - b?.order,
          ),
        });
      }
    } else {
      console.log('docData ====== ', docData);
    }
    return {
      platform: platform,
      saveTabName: docData['nc_tab_name'],
      tabName: docData['tab name'],
      containers: colOptionsArr.sort(
        (a: ContainerProps, b: ContainerProps) => a?.containerOrder - b?.containerOrder,
      ),
      tabOrder: docData?.tab_order,
    };
  });

  ///Tabs sorting (do not disturb)
  data.sort((a: TabProps, b: TabProps) => a?.tabOrder - b?.tabOrder);
  const newData: any = [];
  for (let i = 0; i < data?.length; i++) {
    // const prevData = newData[newData?.length - 1];
    // if (prevData?.tabOrder === data[i]?.tabOrder) {
    //   prevData.containers = prevData?.containers?.concat(data[i]?.containers).sort((a: ContainerProps, b: ContainerProps) => a?.containerOrder - b?.containerOrder);
    //   const containers: any = data[i]?.containers;
    //   for (let j = 0; j < containers?.length; j++) {
    //     if (containers[j]?.containerOrder === 1) {
    //       prevData.tabName = data[i]?.tabName
    //     }
    //   }
    //  } else {
    newData.push(data[i]);
    // }
  }
  console.log('tabData: ', newData);
  return newData;
};

const ExclusionsSetup = () => {
  const { campaignFirestore, masterFirestore } = useContext(FirebaseContext);
  const { id } = useParams();
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const { partnerData } = useContext(PartnerContext);
  const { setValue, getValues, control, watch, register, reset } = useForm();
  const [activeTab, setActiveTab] = useState('Age/Gender');
  const [tabs, setTabs] = useState<TabProps[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columns, setColumns] = useState<any>({});
  const [isError, setIsError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTouchpoint, setCurrentTouchpoint] = useState('');
  const [tabContainers, setTabContainers] = useState<ContainerProps[]>([]);
  const [brands, setBrands] = useState([]);
  const [templates, setTemplates] = useState([]);

  const getBrands = async () => {
    if (masterFirestore && partnerData) {
      const brandsCol = collection(
        masterFirestore,
        'clients-test',
        partnerData?.campaignName,
        'brands',
      );
      const brandsSnapshot = await getDocs(brandsCol);
      return brandsSnapshot.docs.map((doc: any) => ({ id: doc.id, name: doc.data() }));
    }

    return [];
  };

  const getExclusionsTemplates = async () => {
    if (campaignFirestore) {
      const colSnapshot = collection(campaignFirestore, 'exclusions_templates');
      const stateQueryName = query(colSnapshot, where('partner_id', '==', id));
      const docs: any = await getDocs(stateQueryName);
      const mp: any = [];
      docs.forEach((doc: any) => {
        mp.push({
          id: doc?.id,
          data: doc?.data(),
        });
      });

      return mp;
    }

    return [];
  };

  useEffect(() => {
    if (partnerData && campaignFirestore) {
      getBrands().then((res: any) => {
        setBrands(res);
      });
      getExclusionsTemplates().then((res: any) => {
        setTemplates(res);
      });
      getTabData(campaignFirestore).then((res: TabProps[]) => {
        setTabs(res);
      });
    }
  }, [partnerData, campaignFirestore]);

  useEffect(() => {
    if (state?.templateId && templates) {
      setValue('template', state?.templateId);
    }
  }, [state, templates]);

  useEffect(() => {
    const tab = tabs.find((el: TabProps) => el.tabName === activeTab);

    // @ts-ignore
    getActiveTabData(tab, campaignFirestore, getValues).then((res: ContainerProps[]) => {
      setTabContainers(res);
    });
  }, [activeTab]);

  useEffect(() => {
    const findTemplate: any = templates?.find(
      (template: any) => template?.id === watch('template'),
    );
    const flatTemplate = flatten(findTemplate?.data);
    delete flatTemplate?.template;

    if (Object.keys(flatTemplate)?.length) {
      Object.keys(flatTemplate).map((key: any) => {
        setValue(key, flatTemplate[key]);
      });
    } else {
      reset();
      setValue('templateName', '');
      setValue('description', '');
    }
  }, [watch('template')]);

  const saveTemplate = async () => {
    if (!getValues()?.brand) {
      setIsError(true);
    } else {
      if (campaignFirestore) {
        setIsError(false);
        let docSnapCampaign: any = null;
        if (getValues()?.template) {
          docSnapCampaign = doc(campaignFirestore, 'exclusions_templates', getValues()?.template);
        }
        if (state?.templateId) {
          const docSnapCampaign = doc(campaignFirestore, 'exclusions_templates', state?.templateId);
          await setDoc(
            docSnapCampaign,
            { ...getValues(), partner_id: id, type: 'exclusion_template' },
            { merge: true },
          );
        } else if (docSnapCampaign?.id) {
          try {
            const newObj = {
              ...getValues(),
              partner_id: id,
              type: 'exclusion_template',
            };
            await setDoc(
              docSnapCampaign,
              { ...getValues(), partner_id: id, type: 'exclusion_template' },
              { merge: true },
            );
            setTemplates((prevState: any) =>
              prevState?.map((item: any) =>
                item?.id === docSnapCampaign?.id ? { id: docSnapCampaign?.id, data: newObj } : item,
              ),
            );
          } catch (err: any) {
            console.log(err);
          }
        } else {
          const template = await addDoc(collection(campaignFirestore, 'exclusions_templates'), {
            ...getValues(),
            partner_id: id,
            type: 'exclusion_template',
          });
          const dataObj: any = {
            data: { ...getValues(), partner_id: id, type: 'exclusion_template' },
            id: template?.id,
          };
          // @ts-ignore
          setTemplates((prevState: any) => [...prevState, dataObj]);
          setValue('template', template?.id);
        }
        return true;
      }
    }
  };

  const getTabs = () => {
    const filteredTabs: any = [];

    for (let i = 0; i < tabs?.length; i++) {
      if (!filteredTabs?.includes(tabs[i]?.tabName)) {
        filteredTabs.push(tabs[i]?.tabName);
      }
    }
    return filteredTabs || [];
  };

  const changeSelectedOptions = (columnName: string, value: string) => {
    setColumns((prevState: any) => {
      const newState = { ...prevState };
      newState[`${columnName}`] = newState[`${columnName}`]?.includes(value)
        ? newState[`${columnName}`].filter((el: any) => {
            return el !== value;
          })
        : newState[`${columnName}`]
        ? [...newState[`${columnName}`], value]
        : [value];
      return newState;
    });
  };

  const assignBrand = async () => {
    if (watch('templateName')) {
      saveTemplate().then((res) => {
        if (res) {
          navigate(`/${partnerData?.gui_token}`);
        }
      });
    }
  };

  const checkTemplateName = (value: string) => {
    const names = templates?.map((item: IExclusionTemplate) =>
      item?.data?.templateName?.toLowerCase(),
    );
    return value?.trim() !== '' && names?.includes(value?.toLowerCase()?.trim());
  };

  return (
    <div className={'row gap-2'}>
      <div className={'row'}>
        <div className="zeroMargin zeroLeftPadding col-xl-3 col-md-3 col-12">
          <select
            className="form-select"
            aria-label="Brands list"
            onChange={(e) => {
              setValue('brand', e?.target?.value);
            }}>
            <option selected={!getValues()?.brand}>Brands list</option>
            {brands?.map((brand: any, index: number) => (
              <option
                key={`brand_${index}`}
                value={brand?.name?.Brand}
                selected={getValues()?.brand === brand?.name?.Brand}>
                {brand?.name?.Brand}
              </option>
            ))}
          </select>
        </div>
        <div className={cn('zeroLeftPadding col-xl-5 col-md-6 col-sm-5')}>
          <input
            {...register('templateName', {
              required: true,
              validate: (value: any) => checkTemplateName(value),
            })}
            className={`col-12 zeroMargin border rounded campaignInput`}
            placeholder={'Template name'}
          />
        </div>
      </div>
      <div className={cn('row', styles.exclusionsFiltered)}>
        <div className="zeroMargin zeroLeftPadding col-xl-3 col-md-3 col-sm-12">
          <select
            disabled={state?.templateId}
            className="form-select"
            aria-label="Brands safety list"
            onChange={(e) => {
              setValue('template', e?.target?.value);
            }}>
            <option selected>Brands safety list</option>
            {templates?.map((item: any, index: number) => (
              <option
                key={`template_${index}`}
                value={item?.id}
                selected={item?.id === getValues()?.template}>
                {item?.data?.templateName}
              </option>
            ))}
          </select>
        </div>
        <div className={cn('zeroLeftPadding col-xl-5 col-md-6 col-sm-5')}>
          <input
            {...register('description')}
            className={`col-12 zeroMargin border rounded campaignInput`}
            placeholder={'Brand safety description'}
          />
        </div>
        <div
          className={cn(
            'zeroMargin col-xl-2 col-md-3 col-sm-5 form-check justify-content-center d-flex align-items-center gap-3',
            styles.productFiltered,
          )}>
          <input className={cn('form-check-input mt-0')} type="checkbox" id="productFiltered" />
          <label className="form-check-label" htmlFor="productFiltered">
            Product filtered
          </label>
        </div>
        <button
          className={cn('zeroMargin btn btn-primary col-xl-2 col-lg-3 col-sm-5 col-12')}
          onClick={saveTemplate}>
          Save/Update template
        </button>
      </div>

      {watch('templateName') && getTabs()?.length > 0 ? (
        <FilterNavigation activeTab={activeTab} setActiveTab={setActiveTab} tabs={getTabs()} />
      ) : null}

      {watch('templateName') && tabContainers?.length > 0 ? (
        <div className={'row border rounded mt-3 pb-3 mx-1'}>
          {activeTab &&
            tabContainers.map((container: ContainerProps, index: number) => {
              return (
                <React.Fragment key={`currentTemplate.${currentTouchpoint}.${index}`}>
                  {container.selectors.map((selector: any, index2: number) => {
                    if (activeTab !== 'Keywords' && activeTab !== 'Apps & URLs') {
                      return (
                        <SelectBlock
                          key={`selector-${container.name}-${index2}-${currentTouchpoint}-${index}`}
                          watch={watch}
                          name={`tabs.${container?.saveTabName?.toLowerCase()}.${selector.name
                            .toLowerCase()
                            ?.split(' ')
                            ?.join('_')}.${selector?.collectionSource?.toLowerCase()}`}
                          control={control}
                          getValues={getValues}
                          setValue={setValue}
                          changeSelectedOptions={changeSelectedOptions}
                          collectionSource={selector.collectionSource}
                          isSearchable={selector.isSearchable}
                          searchFields={selector?.searchFields}
                          title={selector.name}
                          isShowChecked={true}
                          checkedOptions={getValues(
                            `tabs.${container?.saveTabName?.toLowerCase()}.${selector.name
                              .toLowerCase()
                              ?.split(' ')
                              ?.join('_')}.${selector?.collectionSource?.toLowerCase()}`,
                          )}
                          content={selector?.options || []}
                        />
                      );
                    } else {
                      return (
                        <React.Fragment key={`keywords_${selector}`}>
                          <div className={cn('col-4 mb-3', styles.textareaWrap)}>
                            <p className={'mb-0'}>{selector.name}</p>
                            <textarea
                              value={getValues(
                                `tabs.${container?.saveTabName?.toLowerCase()}.${selector.name
                                  .toLowerCase()
                                  ?.split(' ')
                                  ?.join('_')}.${selector?.collectionSource?.toLowerCase()}`,
                              )}
                              onChange={(e) => {
                                setValue(
                                  `tabs.${container?.saveTabName?.toLowerCase()}.${selector.name
                                    .toLowerCase()
                                    ?.split(' ')
                                    ?.join('_')}.${selector?.collectionSource?.toLowerCase()}`,
                                  [e?.target?.value],
                                  { shouldValidate: true },
                                );
                              }}
                              placeholder={''}
                            />
                          </div>
                        </React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
        </div>
      ) : null}
      {isError ? <p className={'text-danger fw-bold'}>Please fill in all fields</p> : null}
      <Button
        className={cn('btn warning text-white max-w-imp _275 col-12 px-2 mt-5', styles.assign)}
        onClick={assignBrand}>
        Assign brand safety list to the brand
      </Button>
    </div>
  );
};

export default ExclusionsSetup;
