import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { Navbar } from 'react-bootstrap';
import styles from './CreateCampaign.module.scss';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore/lite';
import useEffectAfterMount from '../../hooks/useEffectAfterMount';
import { HeaderContext } from '../../context/HeaderContext';
import { FirebaseContext } from '../../context/FirebaseContext';
import Search from '../UI/Search/Search';
import { DocumentSnapshot } from '@firebase/firestore/lite';
import { DocumentData } from 'firebase/firestore/lite';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { PartnerContext } from '../../context/PartnerContext';
import { Paths } from '../../constants/paths';

interface IDropdown {
  name: 'division' | 'category' | 'iab' | 'brand' | 'product' | 'status';
  placeholder: string;
}

interface Props {
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  onSubmit?: () => void;
  customSearch?: (search: string) => void;
  control: any;
  isShowCreate?: boolean;
  isShowStatus?: boolean;
  isShowSearch?: boolean;
  isShowIAB?: boolean;
  isShowProduct?: boolean;
  isPaddingLeft?: boolean;
  onlySearch?: boolean;
  setMethod?: any;
}

interface IDropdownItem {
  name: string;
  id: string;
}

interface IOptions {
  division?: Array<IDropdownItem>;
  category?: Array<IDropdownItem>;
  iab?: Array<IDropdownItem>;
  brand?: Array<IDropdownItem>;
  brandId?: Array<IDropdownItem>;
  product?: Array<IDropdownItem>;
  status?: Array<IDropdownItem>;
}

const STATUS_ARR: Array<IDropdownItem> = [
  {
    name: 'Status',
    id: 'default',
  },
  {
    name: 'In progress',
    id: 'in_progress',
  },
  {
    name: 'For approval',
    id: 'for_approval',
  },
  {
    name: 'Approved/Edit',
    id: 'approved_edit',
  },
];

const DROPDOWNS: Array<IDropdown> = [
  {
    name: 'division',
    placeholder: 'Division',
  },
  {
    name: 'brand',
    placeholder: 'Brand',
  },
  {
    name: 'category',
    placeholder: 'Category',
  },
  // {
  //     name: 'iab',
  //     placeholder: 'IAB',
  // },
  // {
  //   name: 'product',
  //   placeholder: 'Product',
  // },
  {
    name: 'status',
    placeholder: 'Status',
  },
];

const CreateCampaign: FC<Props> = ({
  setValue,
  getValues,
  onSubmit,
  customSearch,
  control,
  setMethod,
  isShowCreate = true,
  isShowStatus = true,
  isShowSearch = true,
  isShowIAB = true,
  isShowProduct = true,
  isPaddingLeft = false,
  onlySearch = false,
}) => {
  const navigate = useNavigate();
  const { partnerData } = useContext(PartnerContext);
  // const {setPartner} = useSetUser();
  const { pathname } = useLocation();
  const { token } = useParams();
  const { masterFirestore } = useContext(FirebaseContext);
  const { headerObjectValue, setHeaderObjectValue } = useContext(HeaderContext);
  const [options, setOptions] = useState<IOptions>({});
  const [brand, setBrand] = useState<null | undefined | string>(
    headerObjectValue?.categoryData?.brand,
  );
  const [product, setProduct] = useState<null | string>(null);
  const [category, setCategory] = useState<null | string>(null);

  // useEffect(() => {
  //     if (!partnerData) {
  //         setPartner();
  //     }
  // }, [partnerData])

  const splitPathname = useMemo(() => {
    return pathname?.split('/');
  }, []);

  const isHome = useMemo(() => {
    return (
      (splitPathname?.length === 2 && token && splitPathname[0] === '') ||
      (splitPathname?.length === 2 && splitPathname[1] === '')
    );
  }, []);

  const checkSelectBrand = useMemo(() => {
    const path = splitPathname[1] === 'edit' ? splitPathname[2] : splitPathname[1];
    return path === 'templates-management' || path === 'campaign-goals';
  }, []);

  const checkDisabled = (name?: string): boolean => {
    const isTM = splitPathname[1] === 'templates-management';
    const isCG =
      splitPathname[1] === 'edit'
        ? splitPathname[2] === 'campaign-goals'
        : splitPathname[1] === 'campaign-goals';
    const checkName = name ? name === 'division' || name === 'iab' : true;
    const checkDisabledProductAndCategory = name
      ? (name === 'product' || name === 'category') &&
        (!headerObjectValue?.categoryData?.brand ||
          headerObjectValue?.categoryData?.brand === 'Brand')
      : true;
    return isHome || isTM || (isCG && !checkName && !checkDisabledProductAndCategory);
  };

  const getDivisions = async (): Promise<{ division: Array<IDropdownItem> }> => {
    if (partnerData && masterFirestore) {
      const divisionsCol = collection(
        masterFirestore,
        'clients-test',
        partnerData?.campaignName,
        'divisions',
      );
      // const divisionsCol = collection(firestore, 'divisions');
      const divisionsSnapshot = await getDocs(divisionsCol);
      return {
        division: divisionsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
          id: doc.id,
          name: doc.data()?.division,
        })),
      };
    }
    return {
      division: [],
    };
  };
  const getCategories = async (): Promise<{ category: Array<IDropdownItem> }> => {
    // const categoriesCol = collection(firestore, 'categories');
    if (partnerData && masterFirestore) {
      const categoriesCol = collection(
        masterFirestore,
        'clients-test',
        partnerData?.campaignName,
        'categories',
      );
      const categoriesSnapshot = await getDocs(categoriesCol);
      return {
        category: categoriesSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
          id: doc.id,
          name: doc.data()?.category,
        })),
      };
    }
    return {
      category: [],
    };
  };
  const getIab = async (): Promise<{ iab: Array<IDropdownItem> }> => {
    if (masterFirestore) {
      const iabCol = collection(masterFirestore, 'iab');
      const iabSnapshot = await getDocs(iabCol);
      return {
        iab: iabSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
          id: doc.id,
          name: doc.data()?.name,
        })),
      };
    } else {
      return {
        iab: [{ id: 'default', name: 'default' }],
      };
    }
  };
  const getBrandsAndProducts = async (): Promise<{
    brand: Array<IDropdownItem>;
    brandId: Array<IDropdownItem>;
    product: Array<IDropdownItem>;
  }> => {
    if (partnerData && masterFirestore) {
      const brandsCol = collection(
        masterFirestore,
        'clients-test',
        partnerData?.campaignName,
        'brands',
      );
      const brandsSnapshot = await getDocs(brandsCol);
      return {
        brand: brandsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
          id: doc.id,
          name: doc.data()?.Brand,
        })),
        brandId: brandsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
          id: doc.id,
          name: doc.data()?.dv360_id,
        })),
        product: brandsSnapshot.docs.map((doc: DocumentSnapshot<DocumentData>) => ({
          id: doc.id,
          name: doc.data()?.product_categories_refs,
        })),
      };
    }
    return {
      brand: [],
      brandId: [],
      product: [],
    };
  };

  useEffect(() => {
    const prefillBrand = async () => {
      const data: IOptions = {};
      await getBrandsAndProducts().then(
        (res: { brand: Array<IDropdownItem>; product: Array<IDropdownItem> }) => {
          data.brand = [{ name: 'Brand', id: 'default' }, ...res.brand];
          data.product = [{ name: 'Product', id: 'default' }, ...res.product];
        },
      );

      return data;
    };

    if (checkSelectBrand) {
      prefillBrand().then((res: IOptions) => {
        setOptions(res);
      });
    }
  }, [checkSelectBrand]);

  useEffect(() => {
    const prefillData = async () => {
      const data: IOptions = {
        status: STATUS_ARR,
      };
      await getDivisions().then((res: { division: Array<IDropdownItem> }) => {
        data.division = [{ name: 'Division', id: 'default' }, ...res.division];
      });
      await getCategories().then((res: { category: Array<IDropdownItem> }) => {
        data.category = [{ name: 'Category', id: 'default' }, ...res.category];
      });
      await getIab().then((res: { iab: Array<IDropdownItem> }) => {
        data.iab = [{ name: 'IAB category', id: 'default' }, ...res.iab];
      });
      await getBrandsAndProducts().then(
        (res: { brand: Array<IDropdownItem>; product: Array<IDropdownItem> }) => {
          data.brand = [{ name: 'Brand', id: 'default' }, ...res.brand];
          data.product = [{ name: 'Product', id: 'default' }, ...res.product];
        },
      );
      return data;
    };

    if (
      (isHome || (splitPathname?.length > 1 && splitPathname[1] === 'templates-management')) &&
      partnerData &&
      masterFirestore
    ) {
      prefillData().then((res: IOptions) => {
        setOptions(res);
      });
    }
  }, [partnerData, masterFirestore]);

  useEffectAfterMount(() => {
    const getCampaignData = async () => {
      if (brand && partnerData && masterFirestore) {
        const docSnapShot = await getDoc(
          doc(masterFirestore, 'clients-test', partnerData?.campaignName, 'brands', brand),
        );
        // const docSnapShot = await getDoc(doc(firestore, 'brands', brand));
        if (docSnapShot?.exists()) {
          const divisionSnapShot = await getDoc(
            doc(
              masterFirestore,
              'clients-test',
              partnerData?.campaignName,
              'divisions',
              docSnapShot.data()?.division,
            ),
          );
          // const divisionSnapShot = await getDoc(doc(firestore, 'divisions', docSnapShot.data()?.division));
          setValue('categoryData.division', divisionSnapShot.data()?.division, {
            shouldValidate: true,
          });
          const brandId = docSnapShot.data().dv360_id;
          const product = docSnapShot.data().product_categories_refs.map((item: string) => ({
            name: item,
            id: item?.toLowerCase(),
          }));
          const querySnapshot = await Promise.all(
            product.map(
              async (item: IDropdownItem) =>
                await getDoc(
                  doc(
                    masterFirestore,
                    'clients-test',
                    partnerData?.campaignName,
                    'categories',
                    item?.name,
                  ),
                ),
            ),
          );

          const category = querySnapshot?.map((item: DocumentSnapshot<DocumentData>) => ({
            name: item?.data()?.category,
            id: item?.data()?.nc_category,
          }));

          return {
            product,
            category,
            brandId,
            division: divisionSnapShot.data()?.division,
          };
        }
        return null;
      }
      return null;
    };

    if (
      !isHome &&
      pathname !== '/' &&
      splitPathname?.length > 1 &&
      splitPathname[1] !== 'templates-management' &&
      masterFirestore
    ) {
      if (headerObjectValue?.categoryData?.brand !== 'Brand' && brand) {
        getCampaignData().then(
          (
            res: null | {
              category: Array<{ name: string; id: string }>;
              product: Array<{ name: string; id: string }>;
              brandId: string;
              division: string;
            },
          ) => {
            if (res) {
              console.log('res: ', res);
              setHeaderObjectValue({
                ...headerObjectValue,
                categoryData: {
                  ...headerObjectValue?.categoryData,
                  nc_brand: brand,
                  division: res?.division,
                  brandId: res?.brandId,
                },
              });
              setOptions((prevState: IOptions) => ({
                ...prevState,
                category: res?.category,
                product: res?.product,
              }));
              setValue('categoryData.nc_brand', brand, { shouldValidate: true });
              setValue('categoryData.brandId', res?.brandId, { shouldValidate: true });
            }
          },
        );
      } else {
        setHeaderObjectValue({
          categoryData: {
            ...headerObjectValue?.categoryData,
            division: '',
            product: '',
            iab: '',
          },
        });
        DROPDOWNS?.map((item: IDropdown) => {
          setValue(`categoryData.${item?.name}`, '');
        });
      }
    }
  }, [headerObjectValue?.categoryData?.brand, masterFirestore]);

  useEffect(() => {
    if (setHeaderObjectValue && brand) {
      const product: IDropdownItem = options?.product?.find(
        (item: IDropdownItem) => item?.name === headerObjectValue?.categoryData?.category,
      ) || {
        name: 'Product',
        id: 'default',
      };
      setHeaderObjectValue({
        ...headerObjectValue,
        categoryData: { ...headerObjectValue?.categoryData, product: product?.name },
      });
    }
  }, [headerObjectValue?.categoryData?.category]);

  useEffect(() => {
    const getCategories = async (): Promise<IDropdownItem | null> => {
      if (
        headerObjectValue?.categoryData?.product != null &&
        product &&
        partnerData &&
        masterFirestore
      ) {
        const docSnap = await getDoc(
          doc(masterFirestore, 'clients-test', partnerData?.campaignName, 'categories', product),
        );
        if (docSnap?.exists()) {
          return {
            name: docSnap.data()?.category,
            id: docSnap.id,
          };
        }
      }
      return null;
    };

    if (pathname !== '/' && headerObjectValue?.categoryData?.product && masterFirestore) {
      getCategories().then((res: IDropdownItem | null) => {
        if (res) {
          if (setValue) {
            setValue('categoryData.category', res?.name, { shouldValidate: true });
          }
          setHeaderObjectValue({
            ...headerObjectValue,
            categoryData: { ...headerObjectValue?.categoryData, category: res?.name },
          });
        }
      });
    }
  }, [product]);

  useEffect(() => {
    const currProduct = options?.product?.find((item: any) => item?.id === category)?.name;
    if (currProduct) {
      setHeaderObjectValue({
        ...headerObjectValue,
        categoryData: { ...headerObjectValue?.categoryData, product: currProduct },
      });
      console.log(options?.product);
      setValue('categoryData.product', currProduct, { shouldValidate: true });
    }
  }, [category]);
  console.log(options);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addCompany = () => {
    if (onSubmit) {
      onSubmit();
      navigate('/campaign-goals');
    }
  };

  const getCreateCampaignButton = (): JSX.Element | null => {
    if (isShowCreate) {
      return (
        <button
          className={cn('btn btn-primary', styles.createButton)}
          onClick={() => navigate(Paths.createCampaignGoals)}>
          Create campaign
        </button>
      );
    } else {
      return null;
    }
  };

  const getSelectIdMethod = (name: string): ((val: string | null) => void) | null => {
    switch (name) {
      case 'brand':
        return setBrand;
      case 'product':
        return setProduct;
      case 'category':
        return setCategory;
      default:
        return null;
    }
  };

  const getDropdowns = (): JSX.Element => {
    return (
      <>
        {DROPDOWNS?.map((item: IDropdown, index: number) =>
          (item?.name === 'status' && !isShowStatus) ||
          (item?.name === 'iab' && !isShowIAB) ||
          (item?.name === 'product' && !isShowProduct) ? null : (
            <CustomDropdown
              key={`dropdown_${index}`}
              name={`categoryData.${item?.name}`}
              placeholder={item?.placeholder}
              options={options[item?.name]}
              control={control}
              setSelectId={getSelectIdMethod(item?.name)}
              getValues={getValues}
              disabled={!checkDisabled(item?.name)}
              isRequired={item?.name !== 'iab'}
            />
          ),
        )}
      </>
    );
  };

  console.log(headerObjectValue);
  return (
    <Navbar
      bg="light"
      className={cn('py-2 px-3 flex-wrap gap-3', {
        [styles.paddingLeft]: isPaddingLeft,
      })}>
      {!onlySearch ? (
        <>
          {getCreateCampaignButton()}
          {getDropdowns()}
        </>
      ) : null}
      {isShowSearch && <Search customSearch={customSearch} setMethod={setMethod} />}
    </Navbar>
  );
};

export default CreateCampaign;
