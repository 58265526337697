import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import styles from './CustomDatePicker.module.scss';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import Calendar from '../../UI/icons/Calendar';

interface Props {
  control: Control<ICreateCampaignForm>;
  name:
    | 'setup.startDate'
    | 'setup.endDate'
    | `budgetSplits.${number}.segmentsList.${number}.endDate`
    | `budgetSplits.${number}.segmentsList.${number}.startDate`;
  placeholder?: string;
  className?: string;
  isDisabled?: boolean;
  withIcon?: boolean;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  isInvalid?: boolean;
}

const CustomDatePicker: FC<Props> = ({
  control,
  name,
  placeholder,
  minDate,
  maxDate,
  className,
  dateFormat,
  isDisabled = false,
  withIcon = false,
  isInvalid = false,
}): JSX.Element => {
  return (
    <div
      className={cn(
        'position-relative',
        styles.datePickerWrapper,
        { [styles.datePickerWrapperError]: isInvalid },
        className,
      )}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur } }) => (
          <DatePicker
            disabled={isDisabled}
            selected={value}
            minDate={minDate}
            maxDate={maxDate}
            onBlur={onBlur}
            onChange={onChange}
            dateFormat={dateFormat || 'MMMM d, yyyy'}
            className={cn({ ['ps-5 w-100']: withIcon })}
            autoComplete={'off'}
          />
        )}
      />
      <p className={cn('ps-2 font-size _14 position-absolute', styles.calendarTitle)}>
        {placeholder}
      </p>
      {withIcon ? (
        <div
          className={cn(
            'position-absolute h-100 top-0 d-flex align-items-center ps-1',
            styles.calendar,
          )}>
          <Calendar />
        </div>
      ) : null}
    </div>
  );
};

export default CustomDatePicker;
