import { gql } from '../__generated__';
import { ICreateCampaignSetupPartial } from '../../types/CreateCampaignForm';
import { CampaignSetupFragment } from '../__generated__/graphql';
import { getFromUTC } from '../../helpers/handleUTCTime';

export const CAMPAIGN_SETUP_FRAGMENT = gql(`
    fragment CampaignSetup on Campaign {
        franchiseDescription
        franchiseName
        poNumber
        name
        budget
        endDate
        startDate
        usersNamingSection
        frequency {
            limit
            per
            period
        }
        setupStatus
    }
`);

export const campaignSetupFragmentToForm = (
  campaignSetup: CampaignSetupFragment | undefined | null,
): ICreateCampaignSetupPartial => {
  return {
    franchiseName: campaignSetup?.franchiseName ?? undefined,
    franchiseDescription: campaignSetup?.franchiseDescription ?? undefined,
    po: campaignSetup?.poNumber ?? undefined,
    name: campaignSetup?.name ?? undefined,
    budget: campaignSetup?.budget ?? undefined,
    endDate: campaignSetup?.endDate ? getFromUTC(campaignSetup?.endDate) : undefined,
    startDate: campaignSetup?.startDate ? getFromUTC(campaignSetup?.startDate) : undefined,
    scenarioName: campaignSetup?.usersNamingSection ?? undefined,
    frequency: campaignSetup?.frequency
      ? {
          limit: campaignSetup.frequency.limit?.toString(),
          per: campaignSetup.frequency.per?.toString(),
          period: campaignSetup.frequency.period ?? undefined,
        }
      : undefined,
  };
};
