import { gql } from '../__generated__';

export const LINE_ITEM_FREQUENCY_FRAGMENT = gql(`
    fragment LineItemFrequency on FrequencyTemplate {
        frequency {
          limit
          per
          period
        }
        scenarioName
        touchpointName
        trueView {
          limit
          period
        }
    }
`);
