import { useContext } from 'react';
import { getFirestore } from 'firebase/firestore/lite';
import { IPartnerContext, PartnerContext } from '../context/PartnerContext';
import { FirebaseContext } from '../context/FirebaseContext';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
const adminMail = process.env?.REACT_APP_FIREBASE_ADMIN_MAIL || '';
const secretKey = process.env?.REACT_APP_FIREBASE_ADMIN_KEY || '';

const campaignLoginFirebase = (app: any) => {
  const auth = getAuth(app);
  signInWithEmailAndPassword(auth, adminMail, secretKey)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log('User Logged In  : ', user.email);

      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('ERROR : ', errorCode, errorMessage);
    });
};

const useSetCampaign = () => {
  const { partnerData } = useContext<IPartnerContext>(PartnerContext);
  const { setCampaignFirestore, setCampaignFirebase } = useContext(FirebaseContext);
  const setCampaign = async () => {
    if (partnerData) {
      // @ts-ignore
      const config = partnerData?.platforms?.Firebase?.API?.firebaseConfig;
      const campaignProject = initializeApp(config, 'campaign');
      campaignLoginFirebase(campaignProject);
      const campaignStorage = getStorage(campaignProject);
      const campaignFirestore = getFirestore(campaignProject);
      if (setCampaignFirebase && setCampaignFirestore) {
        setCampaignFirebase(campaignStorage);
        setCampaignFirestore(campaignFirestore);
      }
    }
  };

  return { setCampaign };
};

export default useSetCampaign;
