import { gql } from '../__generated__';
import { ICreateCampaignSetPartial } from '../../types/CreateCampaignForm';
import { CampaignSetWithFrequencyFragment } from '../__generated__/graphql';

export const CAMPAIGN_SET_WITH_FREQUENCY_FRAGMENT = gql(`
    fragment CampaignSetWithFrequency on CampaignSet {
        uniqId
        goals
        touchpoints {
            name
            frequency {
                per
                limit
                period
            }
        }
        creatives
        name
    }
`);

export const campaignSetWithFrequencyFragmentToSets = (
  campaignSets: CampaignSetWithFrequencyFragment[],
): ICreateCampaignSetPartial[] => {
  return (
    campaignSets.map((set) => ({
      uniqId: set.uniqId,
      goal: set.goals.length !== 0 ? set.goals[0] : undefined,
      touchpoints: getTouchpoints(set.touchpoints),
      creatives: set.creatives,
      name: set.name,
    })) || []
  );
};

const getTouchpoints = (touchpoints: CampaignSetWithFrequencyFragment['touchpoints']) => {
  return touchpoints
    .filter((el) => el.name != null)
    .map((el) => {
      const frequency = el.frequency
        ? {
            limit: el.frequency.limit?.toString(),
            per: el.frequency.per?.toString(),
            period: el.frequency.period ?? undefined,
          }
        : undefined;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return { name: el.name!, frequency };
    });
};
