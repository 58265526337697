import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormControl, FormSelect } from 'react-bootstrap';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { Control, UseFormGetValues, useFormState, useWatch } from 'react-hook-form';
import { VariableNameFragment } from '../../../graphql/__generated__/graphql';
import CopyRemoveIcons from '../../CopyRemoveIcons/CopyRemoveIcons';
import { LisAdgsNaming } from '../../../containers/UpsertLisAdgs/UpsertLisAdgs';
import useLiName from '../hooks/useLiName';
import useEffectAfterMount from '../../../hooks/useEffectAfterMount';
import debounce from 'lodash.debounce';

interface Props {
  register: UseFormRegister<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  indexAdg: number;
  lisAdgsIndex: number;
  lineItemIndex: number;
  removeAdgEnabled: boolean;
  copyAdg: (index: number) => void;
  removeAdg: (index: number) => void;
  lisAdgsNaming: LisAdgsNaming;
  liSubtypes: VariableNameFragment[];
}

const AdgItem: FC<Props> = ({
  lisAdgsNaming,
  removeAdgEnabled,
  copyAdg,
  removeAdg,
  indexAdg,
  liSubtypes,
  lisAdgsIndex,
  lineItemIndex,
  register,
  setValue,
  control,
  getValues,
}) => {
  const watchName = useWatch({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs.${indexAdg}.name`,
  });
  const mainTactic = useWatch({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.mainTactics`,
  });
  const tacticDetail = useWatch({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.tacticDetails`,
  });

  const liSubtype = useWatch({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs.${indexAdg}.liSubtype`,
  });

  const [name, fetchNewName] = useLiName({
    initialName: getValues(
      `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs.${indexAdg}.name`,
    ),
    separator: lisAdgsNaming.separator,
    subSeparator: lisAdgsNaming.subSeparator,
    namingConvention: lisAdgsNaming.adGroupNaming,
    getValues,
    lisAdgsIndex,
    lineItemIndex,
  });

  const fetchNewNameDebounce = useCallback(
    debounce(() => fetchNewName(), 250),
    [fetchNewName],
  );

  useEffect(() => {
    setValue(`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs.${indexAdg}.name`, name.name);
  }, [name]);

  useEffect(() => {
    if (!watchName) {
      fetchNewName();
    }
  }, [watchName, fetchNewName]);

  // update name when tactic changes
  useEffectAfterMount(() => {
    fetchNewName();
  }, [mainTactic, liSubtype, fetchNewName]);

  useEffectAfterMount(() => {
    fetchNewNameDebounce();
  }, [tacticDetail, fetchNewNameDebounce]);

  const { errors } = useFormState({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs.${indexAdg}`,
  });

  const isLiSubtype = useMemo(() => {
    return lisAdgsNaming.adGroupNaming.some((naming) => naming.variable === 'nc_li_subtype');
  }, [lisAdgsNaming]);

  const liSubtypesOptions = useMemo(() => {
    return liSubtypes
      .map((subtype) => subtype.variableName)
      .filter((name): name is string => name != null);
  }, [liSubtypes]);

  return (
    <div className={'row align-items-center mb-2'}>
      <div className={'col-6'}>
        <FormControl
          {...register(`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs.${indexAdg}.name`)}
          isInvalid={
            errors.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.adgs?.[indexAdg]?.name
              ?.message !== undefined
          }
        />
      </div>

      {isLiSubtype && (
        <div className={'col-2'}>
          <FormSelect
            {...register(
              `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs.${indexAdg}.liSubtype`,
            )}
            isInvalid={
              errors.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.adgs?.[indexAdg]
                ?.liSubtype?.message !== undefined
            }>
            <option value="">LI Subtype</option>
            {liSubtypesOptions.map((subtype, index) => (
              <option key={index} value={subtype}>
                {subtype}
              </option>
            ))}
          </FormSelect>
        </div>
      )}
      <CopyRemoveIcons
        copy={() => copyAdg(indexAdg)}
        remove={() => removeAdg(indexAdg)}
        removeVisible={removeAdgEnabled}
      />
    </div>
  );
};

export default AdgItem;
