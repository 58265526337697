import { createContext } from 'react';

export interface IHeaderCampaignData {
  brand?: string;
  brandId?: string;
  nc_brand?: string;
  product?: string;
  division?: string;
  category?: string;
  iab?: string;
  status?: string;
}

export interface IHeaderValue {
  categoryData: IHeaderCampaignData;
}

export interface IHeaderContext {
  headerObjectValue: IHeaderValue;
  setHeaderObjectValue: (value: IHeaderValue) => void;
}

const initialState: IHeaderContext = {
  headerObjectValue: {
    categoryData: {},
  },
  setHeaderObjectValue: () => {},
};

export const HeaderContext = createContext<IHeaderContext>(initialState);
