import React, { useEffect, useReducer, useRef, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { LoginFirebase } from '../../helpers/LoginFirebase';
import { FirebaseContext, IFirebaseContext } from '../../context/FirebaseContext';
import Login from '../Login/Login';
import { LoginStateEnum } from './LoginState';
import { LoginActionEnum } from './LoginAction';
import { initializeApp, deleteApp } from 'firebase/app';
import { DocumentReference } from 'firebase/firestore/lite';
import { readPartnerData } from './readPartner';
import { IPartner } from '../../types/Partner';
import { notifyFailed } from '../../helpers/helpers';
import reduceLogin from './reduceLogin';
import { getApolloClient } from '../../graphql/getApolloClient';
import { ApolloProvider } from '@apollo/client';
import { HeaderContext, IHeaderValue } from '../../context/HeaderContext';
import { CampaignContext } from '../../context/CampaignContext';
import { PartnerContext } from '../../context/PartnerContext';
import { ICampaignData } from '../../types/Campaign';
import { ITemplateData } from '../../types/Template';
import Loader from '../UI/Loader/Loader';
import { getFirestore } from 'firebase/firestore/lite';
import { toast } from 'react-toastify';

type Props = {
  firebaseContext: IFirebaseContext;
  children: JSX.Element[] | JSX.Element | string;
};

export const Authentication = ({ firebaseContext, children }: Props): JSX.Element => {
  function logOut() {
    const authGui = getAuth(firebaseContext.firebaseGui);
    const authMaster = getAuth(firebaseContext.masterFirebase);
    signOut(authMaster);
    signOut(authGui);
  }

  const apolloClient = useRef(getApolloClient(firebaseContext.firebaseGui));

  const [loginState, reduce] = useReducer(reduceLogin, {
    state: LoginStateEnum.Init,
  });
  const [headerObjectValue, setHeaderObjectValue] = useState<IHeaderValue>({ categoryData: {} });
  const [campaignRef, setCampaignRef] = useState<null | DocumentReference<ICampaignData>>(null);
  const [templateRef, setTemplateRef] = useState<null | DocumentReference<ITemplateData>>(null);
  const [partnerData, setPartnerData] = useState<null | IPartner>(null);

  useEffect(() => {
    const guiAuth = getAuth(firebaseContext.firebaseGui);
    const masterAuth = getAuth(firebaseContext.masterFirebase);
    const guiUnsubscribe = guiAuth.onAuthStateChanged((user) => {
      if (user) {
        firebaseContext.guiUser = user;
        reduce({ type: LoginActionEnum.LoginGuiUser, user: user });
      } else {
        delete firebaseContext.guiUser;
        reduce({ type: LoginActionEnum.LogoutGuiUser });
      }
    });
    const masterUnsubscribe = masterAuth.onAuthStateChanged((user) => {
      if (user) {
        firebaseContext.masterUser = user;
        reduce({ type: LoginActionEnum.LoginMasterUser, user: user });
      } else {
        delete firebaseContext.masterUser;
        reduce({ type: LoginActionEnum.LogoutMasterUser });
      }
    });
    return () => {
      guiUnsubscribe();
      masterUnsubscribe();
      // TODO: will the initial value be captured by the closure?
      if (firebaseContext.partnerAuthUnsubscribe) {
        firebaseContext.partnerAuthUnsubscribe?.();
      }
    };
  }, []);

  useEffect(() => {
    switch (loginState.state) {
      case LoginStateEnum.Relogin: {
        logOut();
        break;
      }
      case LoginStateEnum.GuiUserLoginInit: {
        LoginFirebase(firebaseContext);
        break;
      }
      case LoginStateEnum.GuiUserLoggedIn: {
        // sign in to the master firebase with the same credentials
        // TODO: remove this after switching completely to the API
        if (firebaseContext.masterUser) {
          //the user is already logged in
          reduce({ type: LoginActionEnum.LoginMasterUser, user: firebaseContext.masterUser });
        } else if (firebaseContext.email && firebaseContext.password) {
          signInWithEmailAndPassword(
            getAuth(firebaseContext.masterFirebase),
            firebaseContext.email,
            firebaseContext.password,
          )
            .then(() => {
              // Signed in
              console.log('User Logged In');
              // notifySuccess();
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log('ERROR : ', errorCode, errorMessage);
              notifyFailed();
              //window.location.replace('/');
            });
        } else {
          //whole login process should be reinitiated
          const authGui = getAuth(firebaseContext.firebaseGui);
          const authMaster = getAuth(firebaseContext.masterFirebase);
          signOut(authMaster);
          signOut(authGui);
        }
        break;
      }
      case LoginStateEnum.PartnerLoginInit: {
        if (firebaseContext.partnerData) {
          firebaseContext.campaignFirebase = initializeApp(
            firebaseContext.partnerData.firebaseConfig,
            'partner',
          );
          const partnerAuth = getAuth(firebaseContext.campaignFirebase);
          firebaseContext.partnerAuthUnsubscribe = partnerAuth.onAuthStateChanged((partnerUser) => {
            if (partnerUser) {
              firebaseContext.partnerUser = partnerUser;
              firebaseContext.campaignFirestore = getFirestore(firebaseContext.campaignFirebase);
              reduce({ type: LoginActionEnum.LoginPartnerUser, user: partnerUser });
            } else {
              reduce({ type: LoginActionEnum.LoginGuiUser });
              // toast.error('Failed to log into the partner database.');
            }
          });
          if (firebaseContext.email && firebaseContext.password) {
            signInWithEmailAndPassword(
              getAuth(firebaseContext.campaignFirebase),
              firebaseContext.email,
              firebaseContext.password,
            )
              .then(() => {
                // Signed in
                console.log('User Logged In');
                toast.success('Login to the partner database successful !');
              })
              .catch((error) => {
                console.log('ERROR : ', error.code, error.message);
                notifyFailed();
              });
          }
        }
        break;
      }
      case LoginStateEnum.PartnerDataReadInit: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        readPartnerData(firebaseContext.masterFirestore, firebaseContext.token!)
          .then((partner) => {
            if (partner) {
              setPartnerData(partner);
              firebaseContext.partnerData = partner;
              if (firebaseContext.campaignFirebase) {
                deleteApp(firebaseContext.campaignFirebase);
              }
              reduce({ type: LoginActionEnum.SetPartner, partner: partner });
            } else {
              toast.error('Failed to log read the partner data. Please provide the correct key.');
              reduce({ type: LoginActionEnum.LoginMasterUser });
            }
          })
          .catch((reason) => {
            console.log('Error: Problem reading partner data:', reason);
            logOut();
          });
        break;
      }
      case LoginStateEnum.PartnerLoggedIn: {
        break;
      }
      default: {
        break;
      }
    }
  }, [loginState.state]);

  return (
    <div>
      {loginState.state == LoginStateEnum.Init ? (
        <Loader />
      ) : loginState.state == LoginStateEnum.PartnerLoggedIn ? (
        <ApolloProvider client={apolloClient.current}>
          <FirebaseContext.Provider value={firebaseContext}>
            <HeaderContext.Provider
              value={{
                headerObjectValue,
                setHeaderObjectValue,
              }}>
              <CampaignContext.Provider
                value={{
                  campaignRef,
                  setCampaignRef,
                  setTemplateRef,
                  templateRef,
                }}>
                <PartnerContext.Provider
                  value={{
                    partnerData,
                    setPartnerData,
                  }}>
                  {children}
                </PartnerContext.Provider>
              </CampaignContext.Provider>
            </HeaderContext.Provider>
          </FirebaseContext.Provider>
        </ApolloProvider>
      ) : loginState.state == LoginStateEnum.PreLoggedIn ? (
        <Login
          setToken={(token: string) => {
            firebaseContext.token = token;
            reduce({ type: LoginActionEnum.InitPartnerDataRead, token: token });
          }}
        />
      ) : (
        <div id="firebaseui-auth-container"></div>
      )}
    </div>
  );
};
