import { gql } from '../__generated__';

export const UPSERT_CAMPAIGN_GOALS_QUERY = gql(`
    query GetUpsertCampaignGoals($campaignId: String!) {
        campaign(campaignId: $campaignId) {
            ...CampaignSetup
            ...CampaignCategory
            sets {
              ...CampaignSetWithFrequency
            }
            updatedAt
        }
        goals {
            ...GoalDetails
        }
        namingConventions(type: FILE_TYPE_CAMPAIGN) {
            conventions {
                ...NamingDetails
            }
            separatorCharacter
            separatorCharacterSubtitle
        }
        brands {
            ...BrandDetails
        }
        campaignFrequencyTemplates: frequencyTemplates (type: CAMPAIGN_LEVEL) {
            ...CampaignFrequency
        }
        insertionFrequencyTemplates: frequencyTemplates (type: INSERTION_ORDER_LEVEL) {
            ...InsertionFrequency
        }
    }
`);
