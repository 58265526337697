import React, { FC, useCallback, useEffect, useState } from 'react';
import { ContainerType, SelectorType } from '../GetTabsContainer/GetTabsContainer';
import SelectBlock from '../../CampaignGoals/SelectBlock/SelectBlock';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { useLazyQuery } from '@apollo/client';
import { TARGETING_SELECTOR_OPTIONS_QUERY } from '../../../graphql/queries/TargetingSelectorOptionsQuery';
import { useGraphQLError } from '../../../hooks/useGraphQLError';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { TargetingSelectorOptionsQueryVariables } from '../../../graphql/__generated__/graphql';
import _debounce from 'lodash.debounce';

type Props = {
  selector: SelectorType;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  name: string;
  activeTab: string;
  container: ContainerType;
  campaignId: string;
};
export const Selector: FC<Props> = ({
  selector,
  setValue,
  getValues,
  control,
  name,
  activeTab,
  container,
  campaignId,
}) => {
  const [getOptions, { data, loading, error }] = useLazyQuery(TARGETING_SELECTOR_OPTIONS_QUERY);
  useGraphQLError([error]);

  const getVariables = (search: string | null = null): TargetingSelectorOptionsQueryVariables => {
    return {
      categoryId: activeTab,
      containerId: container.id,
      selectorId: selector.id,
      campaignId: campaignId,
      searchPhrase: search,
    };
  };

  useEffect(() => {
    if (!selector.isSearchable) {
      getOptions({ variables: getVariables() });
    }
  }, [selector.isSearchable]);

  const [searchValue, setSearchValue] = useState<string>('');

  const [availableOptions, setAvailableOptions] = useState<string[]>([]);

  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (!data || data.targetingSelectorOptions == null) {
      return;
    }
    setAvailableOptions(isEmpty ? [] : data.targetingSelectorOptions);
  }, [data, isEmpty]);

  const handleSearch = (searchVal: string) => {
    setIsEmpty(false);
    if (loading) {
      return;
    }

    getOptions({ variables: getVariables(searchVal) });
  };

  function handleInput(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(event.target.value);
    if (event.target.value.length > 2) {
      debouncedHandleInput(event.target.value);
    } else {
      setIsEmpty(true);
    }
  }

  const debouncedHandleInput = useCallback(_debounce(handleSearch, 500), []);

  const searchPart = (
    <InputGroup className={`mb-3 col-2`}>
      <FormControl
        placeholder="Search"
        aria-label="Search"
        onChange={handleInput}
        value={searchValue}
      />
      <Button disabled={loading} onClick={() => debouncedHandleInput(searchValue)}>
        Search
      </Button>
    </InputGroup>
  );

  return (
    <SelectBlock
      content={availableOptions}
      title={selector.name}
      name={name}
      setValue={setValue}
      getValues={getValues}
      isShowChecked={true}
      control={control}>
      {selector.isSearchable && searchPart}
    </SelectBlock>
  );
};
