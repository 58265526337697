import { gql } from '../__generated__';

export const CREATE_CAMPAIGN_GOALS_QUERY = gql(`
    query GetCreateCampaignGoals {
        goals {
            ...GoalDetails
        }
        namingConventions(type: FILE_TYPE_CAMPAIGN) {
            conventions {
                ...NamingDetails
            }
            separatorCharacter
            separatorCharacterSubtitle
        }
        brands {
            ...BrandDetails
        }
        campaignFrequencyTemplates: frequencyTemplates (type: CAMPAIGN_LEVEL) {
            ...CampaignFrequency
        }
        insertionFrequencyTemplates: frequencyTemplates (type: INSERTION_ORDER_LEVEL) {
            ...InsertionFrequency
        }
    }
`);
