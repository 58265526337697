import React, { FC } from 'react';
import styles from './UploadBlock.module.scss';
import UploadIcon from '../icons/UploadIcon';
import cn from 'classnames';
import Loader from '../Loader/Loader';

interface Props {
  onUpload?: (e: any) => void;
  accept?: string;
  disabled?: boolean;
  withDefault?: boolean;
  className?: any;
  isLoading?: boolean;
  customText?: any;
}

const UploadBlock: FC<Props> = ({
  onUpload,
  accept,
  withDefault = true,
  disabled,
  isLoading = false,
  customText,
  className,
}): JSX.Element => {
  return (
    <div
      className={cn(
        'd-flex justify-content-start align-items-stretch',
        styles.TargetInfoMainContainer,
        className?.mainContainer,
      )}>
      <div
        className={cn(
          'w-100 position-relative d-flex flex-column justify-content-center align-items-center bg-dark-gray',
          styles.UploadImagesContainer,
          className?.targetInfo,
        )}>
        <input
          disabled={disabled}
          className={cn('w-100 h-100 position-absolute opacity-0', styles.UploadImagesInputFile)}
          onChange={onUpload}
          type={'file'}
          accept={(withDefault ? '.xlsx, .xls, .csv ' : '') + (accept ? accept : '')}
          multiple={true}
        />
        {!isLoading && (
          <>
            <div className={styles.UploadImagesImage}>
              <UploadIcon />
            </div>
            <p
              className={cn(
                'd-flex justify-content-center text-center font-size _12 text-white mt-4',
                styles.UploadImagesText,
              )}>
              {customText || 'Drag or click to upload Excel file'}
            </p>
          </>
        )}
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default UploadBlock;
