import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Control, UseFormGetValues, useFormState, useWatch } from 'react-hook-form';
import { UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form';
import LimitFrequency from '../../CampaignGoals/CampaignGoalsSetup/LimitFrequency/LimitFrequency';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import LimitView from '../LimitView/LimitView';
import { FormControl, FormSelect } from 'react-bootstrap';
import { VariableNameFragment } from '../../../graphql/__generated__/graphql';
import AdgList from '../AdgList/AdgList';
import { LisAdgsNaming } from '../../../containers/UpsertLisAdgs/UpsertLisAdgs';
import useLiName from '../hooks/useLiName';
import useEffectAfterMount from '../../../hooks/useEffectAfterMount';
import debounce from 'lodash.debounce';
import { Error } from '../../Error/Error';
import useWatchAndTrigger from '../../../hooks/useWatchAndTrigger';

interface Props {
  getValues: UseFormGetValues<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  lisAdgsIndex: number;
  lineItemIndex: number;
  mainTactics: VariableNameFragment[];
  liSubtypes: VariableNameFragment[];
  lisAdgsNaming: LisAdgsNaming;
  trigger: UseFormTrigger<ICreateCampaignForm>;
}

const LineItem: FC<Props> = ({
  lisAdgsNaming,
  liSubtypes,
  mainTactics,
  lisAdgsIndex,
  lineItemIndex,
  register,
  getValues,
  control,
  trigger,
  setValue,
}): JSX.Element => {
  const { errors } = useFormState({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}`,
  });

  useWatchAndTrigger<ICreateCampaignForm>(
    control,
    `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.frequency`,
    trigger,
  );

  useWatchAndTrigger<ICreateCampaignForm>(
    control,
    `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.view`,
    trigger,
  );

  const nameWatch = useWatch({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.name`,
  });

  const tacticDetailsWatch = useWatch({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.tacticDetails`,
  });

  // Line item name is dependent on the liSubtype of the first adgs only.
  // It looks like other adgs are not used at all in the DV360.
  const liSubtype = useWatch({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs.0.liSubtype`,
  });

  const mainTacticsWatch = useWatch({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.mainTactics`,
  });

  const [name, fetchNewName] = useLiName({
    initialName: getValues(`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.name`),
    separator: lisAdgsNaming.separator,
    subSeparator: lisAdgsNaming.subSeparator,
    namingConvention: lisAdgsNaming.lineItemNaming,
    getValues,
    lisAdgsIndex,
    lineItemIndex,
  });

  const fetchNewNameDebounce = useCallback(
    debounce(() => fetchNewName(), 250),
    [fetchNewName],
  );

  useEffect(() => {
    setValue(`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.name`, name.name);
  }, [name]);

  useEffect(() => {
    if (!nameWatch) {
      fetchNewName();
    }
  }, [nameWatch, fetchNewName]);

  // every time mainTactics changes the new name is generated
  useEffectAfterMount(() => {
    fetchNewName();
  }, [mainTacticsWatch, liSubtype, fetchNewName]);

  // every time tacticDetails changes the new name is generated
  useEffectAfterMount(() => {
    fetchNewNameDebounce();
  }, [tacticDetailsWatch, fetchNewNameDebounce]);

  const mainTacticsOptions = useMemo(() => {
    return mainTactics
      .map((tactic) => tactic.variableName)
      .filter((name): name is string => name != null)
      .sort();
  }, [mainTactics]);

  const hasFrequencyError: string | undefined =
    errors?.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.frequency?.message;

  const hasViewsError: string | undefined =
    errors?.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.view?.message;

  return (
    <div className="row align-items-center mt-2">
      <div className={'row align-items-center'}>
        <span className={'col-1'}>LI:</span>
        <div className={'col-5'}>
          <FormControl
            {...register(`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.name`)}
            isInvalid={
              errors.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.name?.message !==
              undefined
            }
          />
        </div>
        <div className={'col-2'}>
          <FormSelect
            {...register(`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.mainTactics`)}
            isInvalid={
              errors.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.mainTactics?.message !==
              undefined
            }>
            <option value="">Main tactics</option>
            {mainTacticsOptions.map((tactic, index) => (
              <option key={index} value={tactic}>
                {tactic}
              </option>
            ))}
          </FormSelect>
        </div>
        <div className={'col-2'}>
          <FormControl
            {...register(`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.tacticDetails`)}
            placeholder={'Tactic details'}
            isInvalid={
              errors.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.tacticDetails?.message !==
              undefined
            }
          />
        </div>
      </div>
      <div className="d-flex gap-5 mt-3">
        <div className="d-flex flex-column">
          <LimitFrequency
            name={`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.frequency`}
            register={register}
            setValue={setValue}
            isInvalid={{
              per:
                errors.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.frequency?.per
                  ?.message !== undefined || hasFrequencyError !== undefined,
              period:
                errors.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.frequency?.period
                  ?.message !== undefined || hasFrequencyError !== undefined,
              limit:
                errors.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.frequency?.limit
                  ?.message !== undefined || hasFrequencyError !== undefined,
            }}
          />
          {hasFrequencyError != undefined && <Error>{hasFrequencyError}</Error>}
        </div>
        <div className="d-flex flex-column">
          <LimitView
            name={`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.view`}
            register={register}
            setValue={setValue}
            isInvalid={{
              period:
                errors.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.view?.period
                  ?.message !== undefined || hasViewsError !== undefined,
              limit:
                errors.lisAdgs?.[lisAdgsIndex]?.lineItems?.[lineItemIndex]?.view?.limit?.message !==
                  undefined || hasViewsError !== undefined,
            }}
          />
          {hasViewsError != undefined && <Error>{hasViewsError}</Error>}
        </div>
      </div>
      <div className={'row mt-3'}>
        <span className={'col-1 ms-2'}>ADGs:</span>
        <div className={'col-10'}>
          <AdgList
            lisAdgsNaming={lisAdgsNaming}
            liSubtypes={liSubtypes}
            lisAdgsIndex={lisAdgsIndex}
            lineItemIndex={lineItemIndex}
            getValues={getValues}
            setValue={setValue}
            register={register}
            control={control}
          />
        </div>
      </div>
    </div>
  );
};

export default LineItem;
