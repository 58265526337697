import { gql } from '../__generated__';

export const NAMING_DETAILS_FRAGMENT = gql(`
    fragment NamingDetails on Convention {
        allowedValuesList
        campaignItemType
        defaultValue
        elementOrder
        isMerge
        variable
        variableValue
    }
`);
