import React, { FC } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import Header from '../Header/Header';
import { Control, UseFormTrigger } from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../types/CreateCampaignForm';
import CampaignGoalsSetup from './CampaignGoalsSetup/CampaignGoalsSetup';
import CreateCampaignNavbar from './CreateCampaignNavbar/CreateCampaignNavbar';
import { Error } from '../Error/Error';
import {
  BrandDetailsFragment,
  CampaignFrequencyFragment,
  GoalDetailsFragment,
  InsertionFrequencyFragment,
  NamingDetailsFragment,
} from '../../graphql/__generated__/graphql';
import { FormNavigationButtons } from '../FormNavigationButtons/FormNavigationButtons';
import { NamingConventionsSeparators } from '../../types/NamingConventions';
import useWatchAndTrigger from '../../hooks/useWatchAndTrigger';
import useEffectAfterMount from '../../hooks/useEffectAfterMount';

interface Props {
  brands: BrandDetailsFragment[];
  goals: GoalDetailsFragment[];
  campaignFrequencyTemplates: CampaignFrequencyFragment[];
  insertionFrequencyTemplates: InsertionFrequencyFragment[];
  namingConventions: NamingDetailsFragment[];
  namingConventionsSeparators: NamingConventionsSeparators;
  handleNext: () => void;
  handlerLoading: boolean;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  trigger: UseFormTrigger<ICreateCampaignForm>;
}

const CampaignGoals: FC<Props> = ({
  brands,
  goals,
  campaignFrequencyTemplates,
  insertionFrequencyTemplates,
  namingConventions,
  namingConventionsSeparators,
  handleNext,
  handlerLoading,
  setValue,
  getValues,
  register,
  control,
  trigger,
}: Props) => {
  const { errors } = useFormState({ control });

  useWatchAndTrigger<ICreateCampaignForm>(control, 'categoryData', trigger);
  const scenarioName = useWatch({ control, name: 'setup.scenarioName' });

  useEffectAfterMount(() => {
    if (scenarioName === '') {
      return;
    }

    const campaignFrequency = campaignFrequencyTemplates.find(
      (template) => template.scenarioName === scenarioName,
    )?.frequency;

    if (campaignFrequency !== undefined) {
      setValue('setup.frequency', {
        limit: campaignFrequency.limit?.toString(),
        per: campaignFrequency.per?.toString(),
        period: campaignFrequency.period ?? undefined,
      });
    }

    const sets = getValues('sets');

    sets.forEach((set, setIndex) => {
      set.touchpoints.forEach((touchpoint, touchpointIndex) => {
        const touchpointFrequency = matchedInsertionFreqTemplates.find(
          (template) => template.touchpointName === touchpoint.name,
        )?.frequency;
        if (touchpointFrequency !== undefined) {
          setValue(`sets.${setIndex}.touchpoints.${touchpointIndex}.frequency`, {
            limit: touchpointFrequency.limit?.toString(),
            per: touchpointFrequency.per?.toString(),
            period: touchpointFrequency.period ?? undefined,
          });
        }
      });
    });
  }, [scenarioName]);

  const matchedInsertionFreqTemplates = insertionFrequencyTemplates.filter(
    (template) => template.scenarioName === scenarioName,
  );

  const getError = (): JSX.Element | null => {
    if (errors.sets?.message !== undefined) {
      return <Error>{errors.sets?.message}</Error>;
    }

    if (Object.keys(errors).length !== 0) {
      return <Error>Please fill in all of the required fields</Error>;
    }
    return null;
  };

  return (
    <div>
      <Header campaignSetupText={'define campaign goals'} />
      <h5>Campaign level</h5>
      <CreateCampaignNavbar
        brands={brands}
        setValue={setValue}
        register={register}
        control={control}
      />
      <CampaignGoalsSetup
        goals={goals}
        matchedInsertionFreqTemplates={matchedInsertionFreqTemplates}
        namingConventions={namingConventions}
        namingConventionsSeparators={namingConventionsSeparators}
        register={register}
        getValues={getValues}
        setValue={setValue}
        control={control}
        trigger={trigger}
      />
      {getError()}
      <FormNavigationButtons handleNext={handleNext} isLoading={handlerLoading} />
    </div>
  );
};

export default CampaignGoals;
