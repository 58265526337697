import React, { FC } from 'react';
import styles from '../PlatformsBrandsProductsSetup.module.scss';
import { FormControl } from 'react-bootstrap';
import { Control, useWatch } from 'react-hook-form';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';

interface Props {
  register: UseFormRegister<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  removeItem: (name: string, index: number) => void;
}

const FREQUENCY = ['Daily', 'Weekly', 'Monthly'];
const REPORT_TYPE = ['Performance report', 'Technical report'];

const GetReports: FC<Props> = ({ control, register, setValue, removeItem }): JSX.Element => {
  const reports = useWatch({ control, name: `reports` });

  const getReport = (index: number) => {
    return (
      <React.Fragment key={`report_${index}`}>
        <div className={'row mt-4'}>
          <div className={'col-3'}>
            <FormControl
              placeholder="Email"
              aria-label="Email"
              type={'text'}
              {...register(`reports.${index}.Email`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`reports.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>
          <div className={'col-3'}>
            <FormControl
              placeholder="Name & Surname"
              aria-label="Name & Surname"
              type={'text'}
              {...register(`reports.${index}.Name_Surname`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`reports.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>
          <div className={'col-3'}>
            <select
              {...register(`reports.${index}.Frequency`)}
              className="form-select col-3 gap-2"
              aria-label="Frequency"
              defaultValue="default"
              onChange={(e: any) => {
                setValue(`reports.${index}.Frequency`, e?.target?.value);
                setValue(`reports.${index}.is_active`, true, { shouldValidate: true });
              }}>
              <option value="default">Frequency</option>
              {FREQUENCY?.sort()?.map((frq: string, index: number) => (
                <option key={`frequency${index}`} value={frq?.toLocaleLowerCase()}>
                  {frq}
                </option>
              ))}
            </select>
          </div>
          <div className={'col-2'}>
            <select
              {...register(`reports.${index}.Report type`)}
              className="form-select col-3 gap-2"
              aria-label="Report type"
              defaultValue="default"
              onChange={(e: any) => {
                setValue(`reports.${index}.Report type`, e?.target?.value);
                setValue(`reports.${index}.is_active`, true, { shouldValidate: true });
              }}>
              <option value="default">Report type</option>
              {REPORT_TYPE?.sort()?.map((rt: string, index: number) => (
                <option key={`report_type${index}`} value={rt}>
                  {rt}
                </option>
              ))}
            </select>
          </div>

          <div className={'col-1 d-flex align-items-center'}>
            {reports?.length >= index && reports[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() => removeItem('reports', index)}
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className={'row'}>
        <p className="mt-3 col-12">Reporting mails</p>
        {reports?.length
          ? reports
              ?.filter((itemReport: any) => itemReport?.is_active)
              ?.map((item: any, index: number) => {
                return getReport(index);
              })
          : null}
        {getReport(
          reports?.filter(
            (itemReport: any) =>
              itemReport &&
              Object.prototype.hasOwnProperty.call(itemReport, 'is_active') &&
              itemReport.is_active,
          )?.length || 0,
        )}
      </div>
    </>
  );
};

export default GetReports;
