import React, { FC, useMemo, useState } from 'react';
import { Control, UseFormRegister } from 'react-hook-form/dist/types/form';
import { UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import GetTabs from '../GetTabs/GetTabs';
import GetTabsContainer from '../GetTabsContainer/GetTabsContainer';
import { SelectSet } from '../../SelectSet/SelectSet';
import useWatchAndTrigger from '../../../hooks/useWatchAndTrigger';

interface Props {
  campaignId: string;
  control: Control<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  trigger: UseFormTrigger<ICreateCampaignForm>;
}

const TargetGroupSetup: FC<Props> = ({
  control,
  campaignId,
  setValue,
  getValues,
  register,
  trigger,
}): JSX.Element => {
  const [activeTabId, setActiveTabId] = useState<string | null>(null);
  const [selectedSetIndex, setSelectedSetIndex] = useState<number | null>(null);

  useWatchAndTrigger<ICreateCampaignForm>(control, 'setsWithTabs', trigger);

  const selectedSetUniqId = useMemo(() => {
    if (selectedSetIndex === null) {
      return null;
    }

    return getValues(`sets.${selectedSetIndex}.uniqId`) ?? null;
  }, [selectedSetIndex]);

  const updateSelectedSet = (index: number) => {
    setActiveTabId(null);
    setSelectedSetIndex(index);
  };

  return (
    <div>
      <SelectSet
        control={control}
        getValues={getValues}
        setValue={setValue}
        register={register}
        onSetChange={updateSelectedSet}
      />
      <GetTabs
        campaignId={campaignId}
        selectedSetUniqId={selectedSetUniqId}
        activeTabId={activeTabId}
        setActiveTabId={setActiveTabId}
      />
      {activeTabId !== null && selectedSetIndex !== null && (
        <GetTabsContainer
          campaignId={campaignId}
          register={register}
          activeTabId={activeTabId}
          selectedSetIndex={selectedSetIndex}
          setValue={setValue}
          getValues={getValues}
          control={control}
        />
      )}
    </div>
  );
};

export default TargetGroupSetup;
