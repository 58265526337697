export const getQueryParams = (queryString: string): any => {
  if (queryString != null) {
    const queryArray = queryString?.replace('?', '')?.split('&');
    const queryObj: any = {};
    queryArray?.forEach((item: any) => {
      const itemArray = item.split('=');
      if (queryObj && Object.prototype.hasOwnProperty.call(queryObj, itemArray[0])) {
        queryObj[itemArray[0]] = [queryObj[itemArray[0]], itemArray[1]];
      } else {
        queryObj[itemArray[0]] = itemArray[1];
      }
    });
    return queryObj;
  }
};

export const getDate = (date: any, isReverse = false) => {
  const dt = new Date(date);
  if (isReverse) {
    return (
      dt.getFullYear() +
      '-' +
      ('0' + (dt.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + dt.getDate()).slice(-2)
    );
  }
  return (
    ('0' + dt.getDate()).slice(-2) +
    '-' +
    ('0' + (dt.getMonth() + 1)).slice(-2) +
    '-' +
    dt.getFullYear()
  );
};
