import { gql } from '../__generated__';
import { ICreateCampaignBudgetSplit, ICreateCampaignForm } from '../../types/CreateCampaignForm';
import {
  BudgetSplitInput,
  BudgetSplitSetInput,
  UpsertBudgetSplitMutationVariables,
} from '../__generated__/graphql';
import { convertToUTC } from '../../helpers/handleUTCTime';

export const UPSERT_BUDGET_SPLIT_MUTATION = gql(` 
    mutation UpsertBudgetSplit($input: UpsertCampaignBudgetSplitInput!) {
        upsertCampaignBudgetSplit(input: $input) {
            campaign { 
                id 
            }
        }
    }
`);

export const getUpsertBudgetSplitMutationVariables = (
  data: ICreateCampaignForm,
  id: string,
  updatedAt: string,
): UpsertBudgetSplitMutationVariables => {
  return {
    input: {
      campaignId: id,
      sets: getSets(data.budgetSplits),
      updatedAt: updatedAt,
    },
  };
};

export const getSets = (
  budgetSplits: ICreateCampaignBudgetSplit[] | undefined,
): BudgetSplitSetInput[] => {
  const tempResult: { [key: string]: { [key: string]: BudgetSplitInput[] } } = {};
  budgetSplits?.forEach((budgetSplit) => {
    if (tempResult[budgetSplit.setUniqId] === undefined) {
      tempResult[budgetSplit.setUniqId] = {};
    }

    if (tempResult[budgetSplit.setUniqId][budgetSplit.touchpointUniqId] === undefined) {
      tempResult[budgetSplit.setUniqId][budgetSplit.touchpointUniqId] = [];
    }

    const result: BudgetSplitInput = {
      budget: budgetSplit.budget,
      endDate: convertToUTC(budgetSplit.endDate).toISOString(),
      isDisabled: budgetSplit.isDisabled,
      isPartner: budgetSplit.isPartner,
      kpiType: budgetSplit.KPI,
      kpiValue: budgetSplit.kpiValue ?? 0,
      partnerId: budgetSplit.partner,
      partnerListType: budgetSplit.partnersType,
      partners: budgetSplit.partners,
      segmentsType: budgetSplit.segmentsType,
      startDate: convertToUTC(budgetSplit.startDate).toISOString(),
      segments: budgetSplit.segmentsList.map((segment) => ({
        budget: segment.budget ?? 0,
        startDate: convertToUTC(segment.startDate).toISOString(),
        endDate: convertToUTC(segment.endDate).toISOString(),
      })),
    };

    tempResult[budgetSplit.setUniqId][budgetSplit.touchpointUniqId].push(result);
  });

  return Object.keys(tempResult).map((setId) => {
    const touchpoints = Object.keys(tempResult[setId]).map((touchpointId) => {
      return {
        budgetSplits: tempResult[setId][touchpointId],
        touchpointId: touchpointId.toString(),
      };
    });

    return {
      uniqId: setId.toString(),
      touchpoints: touchpoints,
    };
  });
};
