import React, { FC } from 'react';
import styles from './FrequencyInput.module.scss';
import cn from 'classnames';
import { FormControl, FormSelect } from 'react-bootstrap';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { ICreateCampaignForm } from '../../types/CreateCampaignForm';
import {
  FrequencyPeriodEnumModel,
  ViewFrequencyPeriodEnumModel,
} from '../../graphql/__generated__/graphql';
import { handleIntChange } from '../../helpers/handleInput';

type Name =
  | {
      name: `lisAdgs.${number}.lineItems.${number}.view`;
      isViewFrequency: true;
    }
  | {
      name:
        | 'setup.frequency'
        | `sets.${number}.touchpoints.${number}.frequency`
        | `lisAdgs.${number}.lineItems.${number}.frequency`;
      isViewFrequency: false;
    };

type Props = {
  limitLabel: string;
  periodDefault: string;
  register: UseFormRegister<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  isDisabled?: boolean;
  isInvalid?: {
    limit?: boolean;
    per?: boolean;
    period?: boolean;
  };
} & Name;

const FrequencyInput: FC<Props> = ({
  register,
  isInvalid,
  name,
  setValue,
  isDisabled = false,
  limitLabel,
  periodDefault,
  isViewFrequency,
}) => {
  const periodEnum = isViewFrequency ? ViewFrequencyPeriodEnumModel : FrequencyPeriodEnumModel;

  return (
    <div className={cn('d-flex align-items-center', styles.frequencyWrap)}>
      <span>{limitLabel}</span>
      <FormControl
        {...register(`${name}.limit`, {
          onChange: (e) => {
            handleIntChange(e, setValue, `${name}.limit`);
          },
        })}
        type="text"
        inputMode={'numeric'}
        disabled={isDisabled}
        isInvalid={isInvalid?.limit}
      />
      <span>exposures per </span>
      {!isViewFrequency && (
        <FormControl
          {...register(`${name}.per`, {
            onChange: (e) => {
              handleIntChange(e, setValue, `${name}.per`);
            },
          })}
          type={'text'}
          inputMode={'numeric'}
          disabled={isDisabled}
          isInvalid={isInvalid?.per}
        />
      )}
      <FormSelect
        {...register(`${name}.period`)}
        disabled={isDisabled}
        className="ms-3 max-w _200"
        isInvalid={isInvalid?.period}>
        <option value="">{periodDefault}</option>
        {Object.values(periodEnum).map((period, index) => (
          <option key={`period_${index}`} value={period}>
            {period.charAt(0).toUpperCase() + period.slice(1).toLowerCase()} {/* DAYS -> Days*/}
          </option>
        ))}
      </FormSelect>
    </div>
  );
};

export default FrequencyInput;
