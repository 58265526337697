import React, { FC, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import LeftTouchpointsTable from './LeftTouchpointsTable/LeftTouchpointsTable';
import GeneratePartnerList from './GeneratePartnerList/GeneratePartnerList';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { VariableNameFragment } from '../../../graphql/__generated__/graphql';
import Segments from './Segments/Segments';

interface Props {
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  partners: VariableNameFragment[];
  clearErrors: UseFormClearErrors<ICreateCampaignForm>;
  campaignId: string;
}

const BudgetSplitInfo: FC<Props> = ({
  setValue,
  getValues,
  register,
  control,
  partners,
  clearErrors,
  campaignId,
}): JSX.Element => {
  const [selectedTouchpointIndex, setSelectedTouchpointIndex] = useState<number | null>(null);

  const {
    fields: touchpoints,
    insert,
    remove,
  } = useFieldArray({
    control,
    name: `budgetSplits`,
  });

  return (
    <>
      {/*TODO: support for templates will be added later*/}
      <div className={'row'}>
        <div className={'col-8'}>
          <LeftTouchpointsTable
            control={control}
            register={register}
            selectedTouchpointIndex={selectedTouchpointIndex}
            setValue={setValue}
            setSelectedTouchpointIndex={setSelectedTouchpointIndex}
            campaignId={campaignId}
            touchpoints={touchpoints}
            remove={remove}
          />
        </div>
        <div className={'col-4'}>
          <GeneratePartnerList
            clearErrors={clearErrors}
            partnerOptions={partners}
            setValue={setValue}
            getValues={getValues}
            selectedTouchpointIndex={selectedTouchpointIndex}
            setSelectedTouchpointIndex={setSelectedTouchpointIndex}
            touchpoints={touchpoints}
            insert={insert}
          />
        </div>
      </div>

      {selectedTouchpointIndex !== null && !touchpoints[selectedTouchpointIndex]?.isDisabled && (
        <Segments
          key={selectedTouchpointIndex}
          control={control}
          selectedTouchpointIndex={selectedTouchpointIndex}
          register={register}
          getValues={getValues}
          setValue={setValue}
        />
      )}
    </>
  );
};

export default BudgetSplitInfo;
