import { gql } from '../__generated__';

export const TABS_QUERY = gql(`
    query Tabs($campaignId: String!, $uniqId: String!) {
        tabs(campaignId: $campaignId, uniqId: $uniqId) {
            id
            ncTabName
            platform
            tabName
            tabOrder
        }
    }
`);
