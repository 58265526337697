import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const useEffectAfterMount = (callback: EffectCallback, dependencies: DependencyList) => {
  const componentJustMounted = useRef<boolean>(true);
  useEffect(() => {
    if (!componentJustMounted.current) {
      return callback();
    }
    componentJustMounted.current = false;
  }, dependencies);
};

export default useEffectAfterMount;
