import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Paths } from '../../constants/paths';
import { useForm } from 'react-hook-form';
import { ICreateCampaignForm, LisAdgsSchema } from '../../types/CreateCampaignForm';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  UPSERT_LIS_ADGS_QUERY,
  upsertLisAdgsToLisAdgs,
} from '../../graphql/queries/UpsertLisAdgsQuery';
import { useGraphQLError } from '../../hooks/useGraphQLError';
import Loader from '../../components/UI/Loader/Loader';
import { useFragment } from '../../graphql/__generated__';
import {
  CAMPAIGN_SETUP_FRAGMENT,
  campaignSetupFragmentToForm,
} from '../../graphql/fragments/CampaignSetupFragment';
import { zodResolver } from '@hookform/resolvers/zod';
import { VARIABLE_NAME_FRAGMENT } from '../../graphql/fragments/VariableNameFragment';
import {
  CampaignSetupStatusEnumModel,
  NamingDetailsFragment,
} from '../../graphql/__generated__/graphql';
import { NAMING_DETAILS_FRAGMENT } from '../../graphql/fragments/NamingDetailsFragment';
import {
  CAMPAIGN_CATEGORY_FRAGMENT,
  campaignCategoryFragmentToForm,
} from '../../graphql/fragments/CampaignCategoryFragment';
import {
  getUpsertLisAdgsMutationVariables,
  UPSERT_LIS_ADGS_MUTATION,
} from '../../graphql/mutations/UpsertLisAdgsMutation';
import LisAdgs from '../../components/LisAdgs/LisAdgs';
import { getPath } from '../../helpers/getSetupStatus';
import { toastMissingUpdateDate } from '../../helpers/toastMissingUpdateDate';

type UpsertLisAdgs = {
  id: string;
};

export interface LisAdgsNaming {
  separator: string;
  subSeparator: string;
  lineItemNaming: NamingDetailsFragment[];
  adGroupNaming: NamingDetailsFragment[];
}

const UpsertLisAdgs: FC = () => {
  const [initialized, setInitialized] = useState(false);
  const { id } = useParams<UpsertLisAdgs>();
  const navigate = useNavigate();

  const [getUpsertLisAdgs, { error, data }] = useLazyQuery(UPSERT_LIS_ADGS_QUERY);
  const [upsertLisAdgs, { error: upsertError, loading: upsertLoading, data: upsertData }] =
    useMutation(UPSERT_LIS_ADGS_MUTATION);
  const { setValue, register, getValues, control, reset, handleSubmit, trigger } =
    useForm<ICreateCampaignForm>({
      resolver: zodResolver(LisAdgsSchema),
    });

  useEffect(() => {
    if (!id) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getUpsertLisAdgs({ variables: { campaignId: id! } });
  }, [id]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const campaignSetup = useFragment(CAMPAIGN_SETUP_FRAGMENT, data.campaign);
    const categoryData = useFragment(CAMPAIGN_CATEGORY_FRAGMENT, data.campaign);

    const status = campaignSetup?.setupStatus || '';
    if (
      status === CampaignSetupStatusEnumModel.Initialized ||
      status === CampaignSetupStatusEnumModel.WithTargetGroups ||
      status === CampaignSetupStatusEnumModel.WithVariants
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      navigate(getPath(status, id!));
      return;
    }
    reset({
      setup: campaignSetupFragmentToForm(campaignSetup),
      categoryData: campaignCategoryFragmentToForm(categoryData),
      lisAdgs: upsertLisAdgsToLisAdgs(data),
    });
    setInitialized(true);
  }, [data]);

  useGraphQLError([error, upsertError]);

  useEffect(() => {
    if (!upsertData) {
      return;
    }

    navigate(Paths.home);
  }, [upsertData]);

  const handleNext = () => {
    if (upsertLoading) {
      return;
    }
    if (!data?.campaign?.updatedAt) {
      toastMissingUpdateDate();
      return;
    }
    const updatedAt = data.campaign.updatedAt;
    handleSubmit((data) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      upsertLisAdgs({ variables: getUpsertLisAdgsMutationVariables(data, id!, updatedAt) });
    })();
  };

  const handlePrevious = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    navigate(Paths.getUpsertBudgetSplit(id!));
  };

  const mainTactics = useMemo(() => {
    return data?.mainTactics?.map((details) => useFragment(VARIABLE_NAME_FRAGMENT, details)) ?? [];
  }, [data]);

  const liSubtypes = useMemo(() => {
    return data?.liSubtypes?.map((details) => useFragment(VARIABLE_NAME_FRAGMENT, details)) ?? [];
  }, [data]);

  const lisAdgsNaming: LisAdgsNaming = useMemo(
    () => ({
      separator: data?.namingLineItem?.separatorCharacter ?? '_',
      subSeparator: data?.namingLineItem?.separatorCharacterSubtitle ?? '-',
      lineItemNaming:
        data?.namingLineItem?.conventions
          ?.map((naming) => useFragment(NAMING_DETAILS_FRAGMENT, naming))
          .filter((naming): naming is NamingDetailsFragment => naming != null) ?? [],
      adGroupNaming:
        data?.namingAdGroup?.conventions
          ?.map((naming) => useFragment(NAMING_DETAILS_FRAGMENT, naming))
          .filter((naming): naming is NamingDetailsFragment => naming != null) ?? [],
    }),
    [data],
  );

  if (!initialized) {
    return <Loader />;
  }

  return (
    <LisAdgs
      lisAdgsNaming={lisAdgsNaming}
      liSubtypes={liSubtypes}
      mainTactics={mainTactics}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handlerLoading={upsertLoading}
      setValue={setValue}
      getValues={getValues}
      control={control}
      trigger={trigger}
      register={register}
    />
  );
};

export default UpsertLisAdgs;
