import { useEffect } from 'react';
import {
  Control,
  useWatch,
  FieldPath,
  UseFormTrigger,
  useFormState,
  FieldValues,
} from 'react-hook-form';

function useWatchAndTrigger<T extends FieldValues>(
  control: Control<T>,
  name: FieldPath<T>,
  trigger: UseFormTrigger<T>,
) {
  const { isSubmitted } = useFormState({ control, name });
  const watcher = useWatch({ control, name });

  useEffect(() => {
    isSubmitted && trigger(name);
  }, [watcher]);
}

export default useWatchAndTrigger;
