import { BudgetSplitSegmentsTypeEnumModel } from '../graphql/__generated__/graphql';

export const getBudgetSplitSegmentsTypeEnumAsString = (
  segmentsType: BudgetSplitSegmentsTypeEnumModel,
) => {
  switch (segmentsType) {
    case BudgetSplitSegmentsTypeEnumModel.Equal:
      return 'Equal';
    case BudgetSplitSegmentsTypeEnumModel.ByMonths:
      return 'By months';
    default:
      return 'unknown';
  }
};
