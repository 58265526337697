import { createContext } from 'react';
import { ICampaignData } from '../types/Campaign';
import { DocumentReference } from '@firebase/firestore/lite';
import { ITemplateData } from '../types/Template';

export interface ICampaignContext {
  campaignRef: DocumentReference<ICampaignData> | null;
  setCampaignRef: (state: DocumentReference<ICampaignData>) => void;
  templateRef: DocumentReference<ITemplateData> | null;
  setTemplateRef: (state: DocumentReference<ITemplateData>) => void;
}

const initialState: ICampaignContext = {
  campaignRef: null,
  templateRef: null,
  setCampaignRef: () => {},
  setTemplateRef: () => {},
};

export const CampaignContext = createContext<ICampaignContext>(initialState);
