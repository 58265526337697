import React, { FC, useMemo, useEffect } from 'react';
import { Button, FormCheck } from 'react-bootstrap';
import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import SelectBlock from '../../../CampaignGoals/SelectBlock/SelectBlock';
import { FieldArrayWithId, UseFieldArrayInsert, useForm, useWatch } from 'react-hook-form';
import {
  ICreateCampaignBudgetSplit,
  ICreateCampaignForm,
} from '../../../../types/CreateCampaignForm';
import {
  SplitByPartnersForm,
  SplitByPartnersFormSchema,
} from '../../../../types/SplitByPartnersForm';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  BudgetSplitSegmentsTypeEnumModel,
  PartnerListTypeEnumModel,
  VariableNameFragment,
} from '../../../../graphql/__generated__/graphql';
import { getPartnerListTypeEnumAsString } from '../../../../helpers/getPartnerListTypeEnumAsString';
import { diffDays } from '../../../../helpers/helpers';

interface Props {
  getValues: UseFormGetValues<ICreateCampaignForm>;
  clearErrors: UseFormClearErrors<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  setSelectedTouchpointIndex: (index: number | null) => void;
  selectedTouchpointIndex: number | null;
  partnerOptions: VariableNameFragment[];
  touchpoints: FieldArrayWithId<ICreateCampaignForm, `budgetSplits`>[];
  insert: UseFieldArrayInsert<ICreateCampaignForm, 'budgetSplits'>;
}

const GeneratePartnerList: FC<Props> = ({
  setValue,
  getValues,
  selectedTouchpointIndex,
  setSelectedTouchpointIndex,
  partnerOptions,
  clearErrors,
  touchpoints,
  insert,
}): JSX.Element => {
  const {
    handleSubmit,
    register: partnersRegister,
    control: partnersControl,
    setValue: partnersSetValue,
    getValues: partnersGetValues,
    reset: partnersReset,
  } = useForm<SplitByPartnersForm>({
    values: { partners: [], splitType: PartnerListTypeEnumModel.Parallel },
    resolver: zodResolver(SplitByPartnersFormSchema),
  });

  const selectedSetChange: number | null = useMemo(() => {
    if (selectedTouchpointIndex === null || touchpoints[selectedTouchpointIndex].isPartner) {
      return null;
    }

    return selectedTouchpointIndex;
  }, [selectedTouchpointIndex]);

  const options: string[] = useMemo(() => {
    let partners: string[] = [];
    if (selectedSetChange !== null) {
      partners = getValues(`budgetSplits.${selectedSetChange}.partners`) ?? [];
    }

    return partnerOptions
      .map((partner) => partner.ncVariableName)
      .filter(
        (partnerName): partnerName is string =>
          partnerName != null && !partners.includes(partnerName),
      );
  }, [partnerOptions, selectedSetChange, getValues]);

  useEffect(() => {
    partnersReset({ partners: [], splitType: PartnerListTypeEnumModel.Parallel });
  }, [options]);

  const selectedPartners = useWatch({ control: partnersControl, name: 'partners' });

  const isDisabledSplit = selectedSetChange === null || selectedPartners.length === 0;

  const splitByPartners = (data: SplitByPartnersForm): void => {
    if (selectedTouchpointIndex === null) {
      return;
    }

    // Need to use getValues instead of touchpoints array due to possible change of the budget - useFieldArray does not track nested objects
    const touchpoint: ICreateCampaignBudgetSplit = getValues(
      `budgetSplits.${selectedTouchpointIndex}`,
    );

    if (touchpoint.partners === undefined) {
      return;
    }

    const partners: string[] = touchpoint.partners;

    setValue(`budgetSplits.${selectedTouchpointIndex}.isDisabled`, true);
    setValue(`budgetSplits.${selectedTouchpointIndex}.partners`, [...partners, ...data.partners]);
    setValue(`budgetSplits.${selectedTouchpointIndex}.partnersType`, data.splitType);
    // refine inside BudgetSplitSchema won't run, so e.g. budget errors will be still visible(they shouldn't be)
    // that's why they should be cleared
    clearErrors(`budgetSplits.${selectedTouchpointIndex}`);
    setSelectedTouchpointIndex(null);

    const budgetPerPartner = Number((touchpoint.budget / data.partners.length).toFixed(2));

    const lengthInDays = diffDays(touchpoint.startDate, touchpoint.endDate) + 1;
    let daysPerPartner = lengthInDays / data.partners.length;

    // When number of partners is higher than duration of campaign in days
    // variable daysPerPartner may be lower than 1
    // every partner should have at least one day of campaign
    if (data.partners.length > lengthInDays) {
      daysPerPartner = 1;
    }

    const isCascade = data.splitType === PartnerListTypeEnumModel.Cascade;

    const year = touchpoint.startDate.getFullYear();
    const month = touchpoint.startDate.getMonth();
    const day = touchpoint.startDate.getDate();

    const values: ICreateCampaignBudgetSplit[] = data.partners.map((partner, partnerIndex) => {
      let startDate: Date;
      let endDate: Date;

      if (isCascade) {
        // (partnerIndex % lengthInDays) is used for a situation when number of partners
        // is higher than campaign duration in days, if that happens, we assign 1 day
        // for every partner with overlap
        const daysToAdd = daysPerPartner * (partnerIndex % lengthInDays);
        startDate = new Date(year, month, Math.round(day + daysToAdd));
        endDate = new Date(year, month, Math.round(day + daysToAdd + daysPerPartner - 1));
      } else {
        // PartnerListTypeEnumModel.Parallel
        startDate = new Date(touchpoint.startDate);
        endDate = new Date(touchpoint.endDate);
      }

      const result: ICreateCampaignBudgetSplit = {
        ...touchpoint,
        partners: undefined,
        isDisabled: false,
        isPartner: true,
        budget: budgetPerPartner,
        partner,
        startDate,
        endDate,
        segmentsType: BudgetSplitSegmentsTypeEnumModel.Equal,
        segmentsList: [
          {
            budget: budgetPerPartner,
            startDate,
            endDate,
          },
        ],
      };

      return result;
    });

    insert(selectedTouchpointIndex + 1, values);
  };

  return (
    <div className={'row'}>
      <div className={'col-6'}>
        <SelectBlock
          title={'Partners list'}
          name={`partners`}
          control={partnersControl}
          setValue={partnersSetValue}
          getValues={partnersGetValues}
          isShowChecked={true}
          className={{ selectWrap: 'w-100' }}
          content={selectedTouchpointIndex !== null ? options : []}
        />
        <Button
          className="mt-2 w-100 ps-0 pe-0"
          disabled={isDisabledSplit}
          onClick={handleSubmit(splitByPartners)}>
          Split selected IOs for partners
        </Button>
      </div>
      <div className={'col-6 mt-4 flex-column'}>
        {Object.values(PartnerListTypeEnumModel)
          .reverse()
          .map((splitType) => (
            <div key={splitType} className={'col-6'}>
              <FormCheck
                {...partnersRegister('splitType')}
                id={splitType}
                type={'radio'}
                label={getPartnerListTypeEnumAsString(splitType)}
                value={splitType}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default GeneratePartnerList;
