import { gql, useFragment } from '../__generated__';
import {
  BudgetSplitDetailsFragment,
  BudgetSplitSegmentsTypeEnumModel,
  CampaignSetupFragment,
  GetUpsertBudgetSplitQuery,
  PartnerListTypeEnumModel,
} from '../__generated__/graphql';
import { ICreateCampaignBudgetSplitPartial } from '../../types/CreateCampaignForm';
import {
  BUDGET_SPLIT_DETAILS_FRAGMENT,
  budgetSplitFragmentToBudgetSplit,
} from '../fragments/BudgetSplitDetailsFragment';
import { getFromUTC } from '../../helpers/handleUTCTime';

export const UPSERT_BUDGET_SPLIT_QUERY = gql(`
    query GetUpsertBudgetSplit($campaignId: String!) {
        campaign(campaignId: $campaignId) {
            ...CampaignSetup
            sets {
                uniqId
                name
                touchpoints {
                    id
                    name
                    kpiIoGoal
                    budgetSplits {
                        ...BudgetSplitDetails
                    }
                }
            }
            updatedAt
        }
        variables(type: "nc_partners") {
            ...VariableName
        }
    }
`);

export const upsertBudgetSplitSetsToForm = (
  data: GetUpsertBudgetSplitQuery,
  campaignSetup: CampaignSetupFragment | null | undefined,
): ICreateCampaignBudgetSplitPartial[] => {
  const touchpointsNumber =
    data.campaign?.sets.reduce((acc: number, curr) => {
      return acc + curr.touchpoints.length;
    }, 0) ?? 0;

  return (
    data.campaign?.sets
      .map((set) => {
        const touchpointBudget = Number(
          ((campaignSetup?.budget ?? 0) / touchpointsNumber).toFixed(2),
        );

        return set.touchpoints
          .filter(
            (touchpoint) =>
              touchpoint.name != null && touchpoint.kpiIoGoal != null && touchpoint.id != null,
          )
          .map((touchpoint) => {
            const budgetSplits =
              touchpoint.budgetSplits
                ?.map((split) => useFragment(BUDGET_SPLIT_DETAILS_FRAGMENT, split))
                .filter((split): split is BudgetSplitDetailsFragment => split != null) ?? [];

            // Sorting a parent touchpoint to be the first in order
            budgetSplits.sort((a, b) => {
              if (a.isPartner === false && b.isPartner === true) {
                return -1;
              }
              if (a.isPartner === true && b.isPartner === false) {
                return 1;
              }
              return 0;
            });

            // create default segment if it doesn't exist
            if (budgetSplits.length === 0) {
              const startDate = campaignSetup?.startDate
                ? getFromUTC(campaignSetup.startDate)
                : new Date();
              const endDate = campaignSetup?.endDate
                ? getFromUTC(campaignSetup.endDate)
                : new Date();

              return {
                isPartner: false,
                partnersType: PartnerListTypeEnumModel.Cascade,
                segmentsType: BudgetSplitSegmentsTypeEnumModel.ByMonths,
                isDisabled: false,
                partners: [],
                /* eslint-disable @typescript-eslint/no-non-null-assertion */
                setUniqId: set.uniqId,
                touchpointUniqId: touchpoint.id!,
                setName: set.name,
                name: touchpoint.name!,
                budget: touchpointBudget,
                startDate,
                endDate,

                KPI: touchpoint.kpiIoGoal!,
                /* eslint-enable @typescript-eslint/no-non-null-assertion */
                segmentsList: [
                  {
                    startDate,
                    endDate,
                    budget: touchpointBudget,
                  },
                ],
              };
            }

            return budgetSplits.map((split) =>
              budgetSplitFragmentToBudgetSplit(
                /* eslint-disable @typescript-eslint/no-non-null-assertion */
                split,
                set.uniqId,
                touchpoint.id!,
                set.name,
                touchpoint.name!,
                /* eslint-enable @typescript-eslint/no-non-null-assertion */
              ),
            );
          })
          .flat();
      })
      .flat() ?? []
  );
};
