import React, { FC } from 'react';
import Header from '../../components/Header/Header';
import ExclusionsSetup from '../../components/ExclusionsSetup/ExclusionsSetup';

const Exclusions: FC = (): JSX.Element => {
  return (
    <div>
      <Header campaignSetupText={'exclusions, brand safety, etc'} withButtons={false} />
      <ExclusionsSetup />
    </div>
  );
};

export default Exclusions;
