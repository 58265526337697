import { LoginAction, LoginActionEnum } from './LoginAction';
import { LoginStateEnum, State } from './LoginState';

export default function reduceLogin(state: State, action: LoginAction) {
  const newState: State = {
    state: state.state,
  };

  switch (action.type) {
    case LoginActionEnum.SetPartner: {
      if (action.partner) {
        newState.state = LoginStateEnum.PartnerLoginInit;
      }
      break;
    }
    case LoginActionEnum.InitPartnerDataRead: {
      if (action.token) {
        newState.state = LoginStateEnum.PartnerDataReadInit;
      }
      break;
    }
    case LoginActionEnum.LoginGuiUser: {
      if (
        state.state == LoginStateEnum.MasterUserLoggedIn ||
        state.state == LoginStateEnum.PreLoggedIn
      ) {
        newState.state = LoginStateEnum.PreLoggedIn;
        // } if (state.state == LoginStateEnum.Init) {
        //   newState.state = LoginStateEnum.GuiUserLoggedIn
      } else {
        newState.state = LoginStateEnum.GuiUserLoggedIn;
      }
      break;
    }
    case LoginActionEnum.LoginMasterUser: {
      if (
        state.state == LoginStateEnum.GuiUserLoggedIn ||
        state.state == LoginStateEnum.PartnerDataReadInit
      ) {
        newState.state = LoginStateEnum.PreLoggedIn;
        // } else {
        //   newState.state = LoginStateEnum.MasterUserLoggedIn
      }
      break;
    }
    case LoginActionEnum.LoginPartnerUser: {
      newState.state = LoginStateEnum.PartnerLoggedIn;
      break;
    }
    case LoginActionEnum.LogoutMasterUser: {
      delete newState.masterUser;
      newState.state = LoginStateEnum.GuiUserLoginInit;
      break;
    }
    case LoginActionEnum.LogoutGuiUser: {
      delete newState.guiUser;
      newState.state = LoginStateEnum.GuiUserLoginInit;
      break;
    }
    case LoginActionEnum.LogoutPartnerUser: {
      delete newState.campaignUser;
      newState.state = LoginStateEnum.GuiUserLoginInit;
      break;
    }
    default: {
      break;
    }
  }
  return newState;
}
