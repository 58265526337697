import { Paths } from '../constants/paths';
import { CampaignSetupStatusEnumModel } from '../graphql/__generated__/graphql';

export function getPath(status: CampaignSetupStatusEnumModel, id: string) {
  switch (status) {
    case CampaignSetupStatusEnumModel.Initialized:
      return Paths.getUpsertTargetGroup(id);
    case CampaignSetupStatusEnumModel.WithTargetGroups:
      return Paths.getUpsertVariantTargeting(id);
    case CampaignSetupStatusEnumModel.WithVariants:
      return Paths.getUpsertBudgetSplit(id);
    case CampaignSetupStatusEnumModel.WithBudgetSplit:
      return Paths.getUpsertLisAdgs(id);
    default:
      return Paths.home;
  }
}
