import React, { FC, useState } from 'react';
import styles from './Sets.module.scss';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useFormState,
} from 'react-hook-form';
import cn from 'classnames';
import { ICreateCampaignForm } from '../../../../types/CreateCampaignForm';
import LimitFrequency from '../LimitFrequency/LimitFrequency';
import { FormControl } from 'react-bootstrap';
import { Error } from '../../../Error/Error';
import { UseFieldArrayAppend } from 'react-hook-form/dist/types/fieldArray';
import CopyRemoveIcons from '../../../CopyRemoveIcons/CopyRemoveIcons';

interface Props {
  register: UseFormRegister<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  setSelectedSet?: (index: number) => void;
  withMultiple?: boolean;
  withCopy?: boolean;
  sets: FieldArrayWithId<ICreateCampaignForm, 'sets'>[];
  append: UseFieldArrayAppend<ICreateCampaignForm, 'sets'>;
  remove: UseFieldArrayRemove;
}

const Sets: FC<Props> = ({
  control,
  getValues,
  setValue,
  register,
  setSelectedSet,
  withCopy = true,
  sets,
  append,
  remove,
}): JSX.Element => {
  const [chosenSet, setChosenSet] = useState<number | null>(null);

  const { errors } = useFormState({ control });

  const selectSet = (index: number): void => {
    if (chosenSet === index) {
      return;
    }
    setChosenSet(index);

    if (setSelectedSet) {
      setSelectedSet(index);
    }
  };

  const removeSet = (index: number): void => {
    if (chosenSet === index) {
      setChosenSet(null);
    }

    remove(index);
  };

  const copySet = (index: number): void => {
    const set = getValues(`sets.${index}`);
    append({ ...set, uniqId: undefined });
  };

  return (
    <div className="mt-3">
      <h5>Created sets of insertion orders</h5>
      {sets.map((item, index: number) => {
        return (
          <React.Fragment key={item.id}>
            <div
              className={cn(styles.set, 'd-flex mb-2 gap-3', {
                'bg-light-gray': chosenSet === index,
              })}>
              <div className={styles.setFields} onClick={() => selectSet(index)}>
                <p className={'row mr-0 border align-items-center'}>{item.goal}</p>
                <p className={'row mr-0 border align-items-center'}>
                  {item.touchpoints.map((el) => el.name).join(', ')}
                </p>
                <p className={'row mr-0 border align-items-center'}>{item.creatives.join(', ')}</p>
              </div>
              <div className={'my-auto'}>
                <FormControl
                  {...register(`sets.${index}.name`)}
                  disabled={!withCopy}
                  type={'text'}
                  aria-label="Set name"
                  placeholder={'Set name'}
                  isInvalid={errors.sets?.[index]?.name !== undefined}
                />
              </div>
              {withCopy && (
                <CopyRemoveIcons copy={() => copySet(index)} remove={() => removeSet(index)} />
              )}
            </div>
            {chosenSet === index && withCopy && (
              <div className={cn('mb-3')}>
                {item.touchpoints.map((touchpoint, tIndex) => (
                  <div
                    key={touchpoint.name}
                    className={cn(styles.roundedBorder, 'row align-items-center py-2 mx-2')}>
                    <div className={'col-md-3'}>{touchpoint.name}</div>
                    <div className={'col-md-9'}>
                      <LimitFrequency
                        isDisabled={!withCopy}
                        register={register}
                        setValue={setValue}
                        name={`sets.${index}.touchpoints.${tIndex}.frequency`}
                        isInvalid={{
                          limit:
                            errors.sets?.[index]?.touchpoints?.[tIndex]?.frequency?.limit !==
                            undefined,
                          per:
                            errors.sets?.[index]?.touchpoints?.[tIndex]?.frequency?.per !==
                            undefined,
                          period:
                            errors.sets?.[index]?.touchpoints?.[tIndex]?.frequency?.period !==
                            undefined,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
              errors.sets?.[index]?.message && <Error>{errors.sets?.[index]?.message!}</Error>
            }
            {errors.setsWithTabs?.[index]?.message && (
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
              <Error>{errors.setsWithTabs?.[index]?.message!}</Error>
            )}
            {errors.setsWithVariants?.[index]?.variants?.message && (
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
              <Error>{errors.setsWithVariants?.[index]?.variants?.message!}</Error>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Sets;
