import React, { FC } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { Control, useWatch } from 'react-hook-form';
import cn from 'classnames';
import styles from '../PlatformsBrandsProductsSetup.module.scss';
import { FormControl } from 'react-bootstrap';

interface Props {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  getValues: UseFormGetValues<any>;
  removeItem: (name: string, index: number) => void;
}

const GetTouchpointTypesAndFormats: FC<Props> = ({
  control,
  setValue,
  getValues,
  register,
  removeItem,
}): JSX.Element => {
  const touchpointTypes = useWatch({ control, name: `touchpoint_types` });
  const touchpointFormats = useWatch({ control, name: `touchpoint_formats` });
  const touchpointKpis = useWatch({ control, name: `touchpoint_kpis` });

  const removeFromTP = (
    name: string,
    index: number,
    paramName: string,
    currentParamName: string,
  ) => {
    const currentValue = JSON.parse(JSON.stringify(getValues(`${name}.${index}`)));
    const copyObject = JSON.parse(JSON.stringify(getValues('touchpoints')));

    for (let i = 0; i < copyObject?.length; i++) {
      if (
        copyObject[i] &&
        Object.prototype.hasOwnProperty.call(copyObject[i], paramName) &&
        copyObject[i][paramName] === currentValue[currentParamName]
      ) {
        copyObject[i][paramName] = 'default';
      }
    }

    setValue('touchpoints', copyObject);

    removeItem(name, index);
  };

  const getTouchpointTypes = (index: number) => {
    return (
      <React.Fragment key={`touchpoint_types_${index}`}>
        <div className={'row mx-2 mt-4'}>
          <div className={'col-5'}>
            <FormControl
              placeholder="Touchpoint type name"
              aria-label="Touchpoint type name"
              type={'text'}
              {...register(`touchpoint_types.${index}.touchpoint_type_name`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`touchpoint_types.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>

          <div className={cn('col-5', styles.number)}>
            <FormControl
              placeholder="nc_touchpoint_type_name"
              aria-label="nc_touchpoint_type_name"
              type={'text'}
              {...register(`touchpoint_types.${index}.nc_touchpoint_type_name`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`touchpoint_types.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>

          <div className={'col-2 d-flex align-items-center'}>
            {touchpointTypes?.length >= index && touchpointTypes[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() =>
                  removeFromTP(`touchpoint_types`, index, 'IO_Type', 'nc_touchpoint_type_name')
                }
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getTouchpointFormat = (index: number) => {
    return (
      <React.Fragment key={`touchpoint_formats_${index}`}>
        <div className={'row mx-2 mt-4'}>
          <div className={'col-5'}>
            <FormControl
              placeholder="Touchpoint format"
              aria-label="Touchpoint format"
              type={'text'}
              {...register(`touchpoint_formats.${index}.touchpoint_format`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`touchpoint_formats.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>

          <div className={cn('col-5', styles.number)}>
            <FormControl
              placeholder="nc_touchpoint_format"
              aria-label="nc_touchpoint_format"
              type={'text'}
              {...register(`touchpoint_formats.${index}.nc_touchpoint_format`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`touchpoint_formats.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>

          <div className={'col-2 d-flex align-items-center'}>
            {touchpointFormats?.length >= index && touchpointFormats[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() =>
                  removeFromTP(`touchpoint_formats`, index, 'item_format', 'nc_touchpoint_format')
                }
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getTouchpointKPIS = (index: number) => {
    return (
      <React.Fragment key={`touchpoint_kpis_${index}`}>
        <div className={'row mx-2 mt-4'}>
          <div className={'col-5'}>
            <FormControl
              placeholder="KPIs"
              aria-label="KPIs"
              type={'text'}
              {...register(`touchpoint_kpis.${index}.kpi_name`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`touchpoint_kpis.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>

          <div className={cn('col-5', styles.number)}>
            <FormControl
              placeholder="nc_kpi_name"
              aria-label="nc_kpi_name"
              type={'text'}
              {...register(`touchpoint_kpis.${index}.nc_kpi_name`)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value != '') {
                  setValue(`touchpoint_kpis.${index}.is_active`, true, { shouldValidate: true });
                }
              }}
            />
          </div>

          <div className={'col-2 d-flex align-items-center'}>
            {touchpointKpis?.length >= index && touchpointKpis[index]?.is_active ? (
              <img
                className={styles.stop}
                src={'/stop.png'}
                onClick={() => removeFromTP(`touchpoint_kpis`, index, 'KPI', 'nc_kpi_name')}
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className={'row'}>
      <div className={'col-4'}>
        <p className="mt-3 col-12">Campaign objectives</p>
        {touchpointTypes?.length
          ? touchpointTypes
              ?.filter((itemCampaignObjectives: any) => itemCampaignObjectives?.is_active)
              ?.map((item: any, index: number) => {
                return getTouchpointTypes(index);
              })
          : null}
        {getTouchpointTypes(
          touchpointTypes?.filter(
            (itemCampaignObjectives: any) =>
              itemCampaignObjectives &&
              Object.prototype.hasOwnProperty.call(itemCampaignObjectives, 'is_active') &&
              itemCampaignObjectives?.is_active,
          )?.length || 0,
        )}
      </div>

      <div className={'col-4'}>
        <p className="mt-3 col-12">Touchpoint formats</p>
        {touchpointFormats?.length
          ? touchpointFormats
              ?.filter((itemCampaignObjectives: any) => itemCampaignObjectives?.is_active)
              ?.map((item: any, index: number) => {
                return getTouchpointFormat(index);
              })
          : null}
        {getTouchpointFormat(
          touchpointFormats?.filter(
            (itemCampaignObjectives: any) =>
              itemCampaignObjectives &&
              Object.prototype.hasOwnProperty.call(itemCampaignObjectives, 'is_active') &&
              itemCampaignObjectives?.is_active,
          )?.length || 0,
        )}
      </div>

      <div className={'col-4'}>
        <p className="mt-3 col-12">KPIs</p>
        {touchpointKpis?.length
          ? touchpointKpis
              ?.filter((itemCampaignObjectives: any) => itemCampaignObjectives?.is_active)
              ?.map((item: any, index: number) => {
                return getTouchpointKPIS(index);
              })
          : null}
        {getTouchpointKPIS(
          touchpointKpis?.filter(
            (itemCampaignObjectives: any) =>
              itemCampaignObjectives &&
              Object.prototype.hasOwnProperty.call(itemCampaignObjectives, 'is_active') &&
              itemCampaignObjectives?.is_active,
          )?.length || 0,
        )}
      </div>
    </div>
  );
};

export default GetTouchpointTypesAndFormats;
