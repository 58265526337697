import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from '../PlatformsBrandsProductsSetup.module.scss';
import { Control, useWatch } from 'react-hook-form';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form/dist/types/form';
import { FormControl } from 'react-bootstrap';

interface GetTabsProps {
  register: UseFormRegister<any>;
  getValues: UseFormGetValues<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  removeItem: (name: string, index: number) => void;
}

const GetCreatives: FC<GetTabsProps> = ({
  register,
  getValues,
  watch,
  control,
  setValue,
  removeItem,
}): JSX.Element => {
  const [currentVariableType, setCurrentVariableType] = useState(null);
  const [isSelect, setIsSelect] = useState(false);
  const [nextName, setNextName] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentName, setCurrentName] = useState<string>('');
  const [isSelectParent, setIsSelectParent] = useState(false);
  const variableTypes = useWatch({ control, name: `creative.variable_types` });
  const variableValues = useWatch({ control, name: `creative.variable_values` });
  // const childrenVariableValues = useWatch({
  //   control,
  //   name: `creative.variable_values.${currentVariableType}`,
  // });

  useEffect(() => {
    if (nextName) {
      setTimeout(() => {
        document.getElementById(nextName)?.focus();
      }, 50);
    }
  }, [nextName]);

  const changeNcVariableType = (e: any, index: number) => {
    const value = e?.target?.value;
    const oldValue = getValues(`creative.variable_types.${index}.nc_variable_type`);
    const copyValues = getValues(`creative.variable_values`);
    let findIndex = null;
    let findIndexParent = null;

    copyValues?.find((item: any, index: number) => {
      if (item?.select_variable_value === oldValue) {
        findIndex = index;
      }
    });

    copyValues?.find((item: any, index: number) => {
      if (item?.parent_variable_type === oldValue) {
        findIndexParent = index;
      }
    });

    if (findIndex !== null) {
      // @ts-ignore
      setValue(`creative.variable_values.${findIndex}.select_variable_value`, value);
    }

    if (findIndexParent !== null) {
      // @ts-ignore
      setValue(`creative.variable_values.${findIndexParent}.parent_variable_type`, value);
    }
    setValue(`creative.variable_types.${index}.nc_variable_type`, value);
  };

  const deleteType = (index: number) => {
    const currentValue = getValues(`creative.variable_types.${index}`);
    const copyValues = getValues(`creative.variable_values`);
    let findIndex = null;
    copyValues?.find((item: any, index: number) => {
      if (item?.select_variable_value === currentValue?.nc_variable_type) {
        findIndex = index;
      }
    });

    if (findIndex !== null) {
      const copyValues = JSON.parse(JSON.stringify(getValues(`creative.variable_values`)));
      copyValues.splice(findIndex, 1);
      for (let i = 0; i < copyValues?.length; i++) {
        if (copyValues[i]?.parent_variable_type === currentValue?.nc_variable_type) {
          copyValues[i].parent_variable_type = 'default';
          delete copyValues[i]?.children_values;
        }
      }
      setValue(`creative.variable_values`, copyValues);
    }

    removeItem('creative.variable_types', index);
  };

  const getVariableType = (index: number) => {
    console.log(
      getValues(`creative.variable_types.${index}.variable_type`),
      getValues(`creative.variable_types.${index}.nc_variable_type`),
    );
    return (
      <React.Fragment key={`variable_types_${index}`}>
        <div className={'row mt-4'}>
          <div className={'col-5'}>
            <FormControl
              placeholder="Variable type"
              aria-label="Variable type"
              type={'text'}
              tabIndex={+`${index}1`}
              key={`variable_types_${index}_variable_type`}
              defaultValue={getValues(`creative.variable_types.${index}.variable_type`)}
              {...register(`creative.variable_types.${index}.variable_type`)}
              onKeyDown={(e: any) => {
                if (e?.keyCode === 9) {
                  setNextName(`creative.variable_types.${index}.nc_variable_type`);
                }
              }}
              onFocus={() => {
                setCurrentName(`creative.variable_types`);
              }}
              onChange={(e: any) => {
                const value = e?.target?.value;
                setValue(`creative.variable_types.${index}.variable_type`, value);
              }}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value !== '') {
                  setValue(`creative.variable_types.${index}.is_active`, true, {
                    shouldValidate: true,
                  });
                }
              }}
            />
          </div>
          <div className={'col-5'}>
            <FormControl
              placeholder="nc_variable_type"
              aria-label="nc_variable_type"
              type={'text'}
              key={`variable_types_${index}_nc_variable_type`}
              tabIndex={+`${index}2`}
              defaultValue={getValues(`creative.variable_types.${index}.nc_variable_type`)}
              value={getValues(`creative.variable_types.${index}.nc_variable_type`) || ''}
              id={`creative.variable_types.${index}.nc_variable_type`}
              onChange={(e: any) => changeNcVariableType(e, index)}
              onBlur={(e: any) => {
                const value = e?.target?.value;
                if (value && value !== '') {
                  setValue(`creative.variable_types.${index}.is_active`, true, {
                    shouldValidate: true,
                  });
                }
              }}
            />
          </div>

          <div className={'col-2 d-flex align-items-center'}>
            {variableTypes?.length >= index && variableTypes[index]?.is_active ? (
              <img className={styles.stop} src={'/stop.png'} onClick={() => deleteType(index)} />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const deleteVariable = (name: string, index: number) => {
    const currentValue = getValues(`${name}.variable_values.${index}.nc_variable_name`);
    const copyValues = getValues('creative.variable_values');
    copyValues?.map((item: any) => {
      item?.children_values?.map((item2: any) => {
        if (item2?.parent_values) {
          item2.parent_values = item2?.parent_values?.filter((parentValue: any) => {
            if (parentValue?.parentValue) {
              return parentValue?.parentValue !== currentValue;
            } else {
              return parentValue;
            }
          });
        }
      });
    });

    const copyTouchpoints = getValues('touchpoints');
    const copyCampaignObjectives = getValues('campaign_objectives');

    const selectVariable = getValues(name)?.select_variable_value;

    let touchpointVariableType: any = null;

    switch (selectVariable) {
      case 'nc_kpi_nc_io_goal':
        touchpointVariableType = 'KPI Naming IO goal';
        break;
      case 'nc_kpi_io_goal':
        touchpointVariableType = 'KPI IO goal';
        break;
      case 'nc_kpi_reporting_goal':
        touchpointVariableType = 'KPI Reporting goal';
        break;
      case 'nc_li_subtype':
        touchpointVariableType = 'LI Subtype';
        break;
      case 'nc_li_type':
        touchpointVariableType = 'LI Type';
        break;
      case 'nc_environment':
        touchpointVariableType = 'Environment';
        break;
      case 'nc_creative_format':
        touchpointVariableType = 'Creative format';
        break;
      case 'nc_publishers':
        touchpointVariableType = 'Publisher';
        break;
    }

    if (touchpointVariableType) {
      const newTp = copyTouchpoints?.map((item: any) => {
        if (
          touchpointVariableType === 'Creative format' ||
          touchpointVariableType === 'Publisher'
        ) {
          const creative_list = item?.creative_list?.map((creativeItem: any) => {
            if (
              creativeItem &&
              Object.prototype.hasOwnProperty.call(creativeItem, touchpointVariableType) &&
              creativeItem[touchpointVariableType] === currentValue
            ) {
              return {
                ...creativeItem,
                [touchpointVariableType]: 'default',
              };
            } else {
              return creativeItem;
            }
          });

          return {
            ...item,
            creative_list,
          };
        } else {
          if (
            item &&
            Object.prototype.hasOwnProperty.call(item, touchpointVariableType) &&
            item[touchpointVariableType] === currentValue
          ) {
            return {
              ...item,
              [touchpointVariableType]: 'default',
            };
          } else {
            return item;
          }
        }
      });
      setValue('touchpoints', newTp);
    }

    if (selectVariable === 'nc_campaign_objective') {
      const netCO = copyCampaignObjectives?.filter(
        (item: any) => item?.campaign_objective !== currentValue,
      );
      setValue('campaign_objectives', netCO);
    }

    setValue('creative.variable_values', copyValues);
    removeItem(`${name}.variable_values`, index);
  };

  const getVariableValue = (index: number) => {
    const getVariableTypes = () => {
      const types = JSON.parse(JSON.stringify(watch(`creative.variable_types`)))?.map(
        (item: any) => ({ name: item?.variable_type, id: item?.nc_variable_type }),
      );
      const sortTypes = types?.sort((a: any, b: any) =>
        a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1,
      );
      // const currentTypes = JSON.parse(JSON.stringify(getValues(`creative.variable_values`) || []))
      //   ?.map((item: any) => item?.select_variable_value)
      //   ?.filter((item: any, currentIndex: number) => currentIndex !== index);
      return sortTypes?.filter(
        (item: any) => item?.name !== '' && item?.name && item?.name !== 'default',
      );
    };

    const getParentVariableTypes = () => {
      const types = JSON.parse(JSON.stringify(watch(`creative.variable_types`)))?.map(
        (item: any) => ({ name: item?.variable_type, id: item?.nc_variable_type }),
      );
      const currentTypes = getValues(`creative.variable_values.${index}.select_variable_value`);
      const filterTypes = types?.filter(
        (item: any) =>
          item?.id &&
          item?.id !== currentTypes &&
          item?.id !== '' &&
          item &&
          item?.id !== 'default',
      );
      return filterTypes?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1));

      //
      // const types = JSON.parse(JSON.stringify(getValues(`creative.variable_types`)))?.map((item: any) => ({name: item?.variable_type, id: item?.nc_variable_type}));
      // const currentTypes = JSON.parse(JSON.stringify(getValues(`creative.variable_values`)))
      //   ?.map((item: any) => item?.select_variable_value)
      //   ?.filter((item: any, currentIndex: number) => currentIndex !== index);
      // return types?.filter((item: any, index2: number) => !currentTypes?.includes(item?.name) && item?.name !== '' && item?.name && item?.name !== 'default')
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getChildrenParentValues = (name: string, index: number, parentIndex: number) => {
      const removeItem = (name: string, index: number) => {
        const itemCopy = JSON.parse(JSON.stringify(getValues(name)));
        itemCopy.splice(index, 1);
        setValue(name, itemCopy);
      };

      const getParentTypes = (currentValue: any) => {
        const parentName = getValues(
          `creative.variable_values.${parentIndex}.parent_variable_type`,
        );
        const currentParentValues = getValues(`${name}.children_values.${index}.parent_values`)
          ?.map((item: any) => item?.parentValue)
          .filter((item: any) => item);

        return getValues(`creative.variable_values`)
          ?.find((item: any) => item?.select_variable_value === parentName)
          ?.variable_values?.filter(
            (item: any) =>
              item?.is_active &&
              item?.nc_variable_name &&
              (!currentParentValues?.includes(item?.nc_variable_name) ||
                currentParentValues?.includes(currentValue)),
          )
          ?.map((item: any) => ({
            id: item?.nc_variable_name,
            name: item?.variable_name,
          }));
      };

      const getCurrentParentTypes = (name: string, index: number) => {
        const currentValues = getValues(`${name}.parent_values`);

        return (
          <div key={`getCurrentParentTypes_${name}_${index}`} className={'row'}>
            <div className={cn(styles.leftRem, 'col-11')}>
              <select
                {...register(`${name}.parent_values.${index}.parentValue`)}
                className="form-select col-3 mt-2 gap-2"
                aria-label="Parent variable value"
                defaultValue="default"
                value={getValues(`${name}.parent_values.${index}.parentValue`)}
                onChange={(e: any) => {
                  setValue(`${name}.parent_values.${index}.parentValue`, e?.target?.value);
                  setValue(`${name}.parent_values.${index}.is_active`, true);
                }}>
                <option value="default">Parent variable value</option>
                {getParentTypes(getValues(`${name}.parent_values.${index}.parentValue`))
                  ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
                  ?.map((type: any, index2: number) => (
                    <option key={`cvalues_${index}_${name}_${index2}`} value={type?.id}>
                      {type?.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className={'col-1 mt-2 d-flex align-items-center'}>
              {currentValues?.length >= index && currentValues[index]?.is_active ? (
                <img
                  className={cn(styles.stop, styles.stopLeft)}
                  src={'/stop.png'}
                  onClick={() => removeItem(`${name}.parent_values`, index)}
                />
              ) : null}
            </div>
          </div>
        );
      };

      const parentValues = getValues(`${name}.children_values.${index}.parent_values`);

      return (
        <React.Fragment key={`creative.children_parent_variable_values_${name}.${index}`}>
          <div className={'row mt-2 mx-2'}>
            <div className={'col-6'}>
              <FormControl
                placeholder="variable_value"
                aria-label="variable_value"
                type={'text'}
                {...register(`${name}.children_values.${index}.variable_name`)}
                onBlur={(e: any) => {
                  const value = e?.target?.value;
                  if (value && value != '') {
                    setValue(`${name}.children_values.${index}.is_active`, true, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </div>
            <div className={'col-5'}>
              <FormControl
                placeholder="nc_variable_value"
                aria-label="nc_variable_value"
                type={'text'}
                {...register(`${name}.children_values.${index}.nc_variable_name`)}
                onBlur={(e: any) => {
                  const value = e?.target?.value;
                  if (value && value != '') {
                    setValue(`${name}.children_values.${index}.is_active`, true, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </div>
            <div className={'col-1 d-flex align-items-center'}>
              {variableValues?.length >= parentIndex &&
              variableValues[parentIndex]?.children_values?.length >= index &&
              variableValues[parentIndex]?.children_values[index]?.is_active ? (
                <img
                  className={cn(styles.stop)}
                  src={'/stop.png'}
                  onClick={() =>
                    deleteVariable(`creative.variable_values.${parentIndex}.children_values`, index)
                  }
                />
              ) : null}
            </div>
          </div>
          {isSelectParent ? (
            <div className={cn('row')}>
              {parentValues
                ?.filter((item: any) => item?.is_active)
                ?.map((item: any, index2: number) =>
                  getCurrentParentTypes(`${name}.children_values.${index}`, index2),
                )}
              {getCurrentParentTypes(
                `${name}.children_values.${index}`,
                parentValues?.length
                  ? parentValues?.filter(
                      (itemVariableType: any) =>
                        itemVariableType &&
                        Object.prototype.hasOwnProperty.call(itemVariableType, 'is_active') &&
                        itemVariableType.is_active,
                    )?.length
                  : 0,
              )}
            </div>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    };

    const getChildrenValues = (
      name: string,
      index: number,
      parentIndex: number,
      isDisabled?: boolean,
    ) => {
      const removeItem = (name: string, index: number) => {
        const itemCopy = JSON.parse(JSON.stringify(getValues(name)));
        itemCopy.splice(index, 1);
        setValue(name, itemCopy);
      };

      const parentValues = getValues(`${name}.variable_values.${index}.children_values`);

      const getParentTypes = (currentValue: any) => {
        const parentName = getValues(
          `creative.variable_values.${parentIndex}.parent_variable_type`,
        );
        const currentParentValues = getValues(`${name}.variable_values.${index}.children_values`)
          ?.map((item: any) => item?.parentValue)
          .filter((item: any) => item);

        return getValues(`creative.variable_values`)
          ?.find((item: any) => item?.select_variable_value === parentName)
          ?.variable_values?.filter((item: any) => {
            return (
              item?.is_active &&
              item?.nc_variable_name &&
              (!currentParentValues?.includes(item?.nc_variable_name) ||
                currentValue === item?.nc_variable_name)
            );
          })
          ?.map((item: any) => ({
            id: item?.nc_variable_name,
            name: item?.variable_name,
          }));
      };

      const getCurrentParentTypes = (name: string, index: number) => {
        const currentValues = getValues(`${name}`);

        return (
          <div key={`getCurrentParentTypes_${name}_${index}`} className={'row'}>
            <div className={cn(styles.leftRem, 'col-11')}>
              <select
                {...register(`${name}.${index}.parentValue`)}
                className="form-select col-3 mt-2 gap-2"
                aria-label="Parent variable value"
                defaultValue="default"
                value={getValues(`${name}.${index}.parentValue`)}
                onChange={(e: any) => {
                  const value = e?.target?.value;
                  if (value === 'default') {
                    setIsSelectParent(true);
                  }
                  setValue(`${name}.${index}.parentValue`, value);
                  setValue(`${name}.${index}.is_active`, true);
                }}>
                <option value="default">Parent variable value</option>
                {getParentTypes(getValues(`${name}.${index}.parentValue`))
                  ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
                  ?.map((type: any, index2: number) => (
                    <option key={`cvalues_${index}_${name}_${index2}`} value={type?.id}>
                      {type?.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className={'col-1 mt-2 d-flex align-items-center'}>
              {currentValues?.length >= index && currentValues[index]?.is_active ? (
                <img
                  className={cn(styles.stop, styles.stopLeft)}
                  src={'/stop.png'}
                  onClick={() => removeItem(`${name}.parent_values`, index)}
                />
              ) : null}
            </div>
          </div>
        );
      };

      return (
        <React.Fragment key={`creative.variable_values_${name}.${index}`}>
          {index >= 0 ? (
            <div className={'row mt-2 mx-2'}>
              <div className={'col-6'}>
                <FormControl
                  placeholder="variable_value"
                  aria-label="variable_value"
                  type={'text'}
                  disabled={isDisabled || !isSelect}
                  value={
                    isSelect ? getValues(`${name}.variable_values.${index}.variable_name`) : ''
                  }
                  {...register(`${name}.variable_values.${index}.variable_name`)}
                  onBlur={(e: any) => {
                    const value = e?.target?.value;
                    if (value && value != '') {
                      setValue(`${name}.variable_values.${index}.is_active`, true, {
                        shouldValidate: true,
                      });
                    }
                  }}
                />
              </div>
              <div className={'col-5'}>
                <FormControl
                  placeholder="nc_variable_value"
                  aria-label="nc_variable_value"
                  type={'text'}
                  disabled={isDisabled || !isSelect}
                  value={
                    isSelect ? getValues(`${name}.variable_values.${index}.nc_variable_name`) : ''
                  }
                  {...register(`${name}.variable_values.${index}.nc_variable_name`)}
                  onChange={(e: any) => {
                    const value = e?.target?.value;
                    const currentParent = getValues(name)?.select_variable_value;
                    const oldValue = getValues(`${name}.variable_values.${index}.nc_variable_name`);
                    const copyValues = getValues(`creative.variable_values`);
                    let findIndex = null;
                    const findValue = copyValues?.find((item: any, index: number) => {
                      if (item?.parent_variable_type === currentParent) {
                        findIndex = index;
                        return item;
                      }
                    });
                    // .sort((a:any, b:any) => a.variable_type.toLowerCase() > b.variable_type.toLowerCase() ? 1 : -1)
                    const newValue = {
                      ...findValue,
                      variable_values: findValue?.variable_values?.map((item: any) => {
                        return {
                          ...item,
                          children_values: item?.children_values?.map((item2: any) => {
                            if (item2?.parentValue === oldValue) {
                              return {
                                parentValue: value,
                                is_active: true,
                              };
                            }
                          }),
                        };
                      }),
                    };

                    setValue(`creative.variable_values.${findIndex}`, newValue);
                    setValue(`${name}.variable_values.${index}.nc_variable_name`, value);
                  }}
                  onBlur={(e: any) => {
                    const value = e?.target?.value;
                    if (value && value != '') {
                      setValue(`${name}.variable_values.${index}.is_active`, true, {
                        shouldValidate: true,
                      });
                    }
                  }}
                />
              </div>
              <div className={'col-1 d-flex align-items-center'}>
                {variableValues?.length >= parentIndex &&
                variableValues[parentIndex]?.variable_values?.length >= index &&
                variableValues[parentIndex]?.variable_values[index]?.is_active ? (
                  <img
                    className={cn(styles.stop)}
                    src={'/stop.png'}
                    onClick={() => deleteVariable(`creative.variable_values.${parentIndex}`, index)}
                  />
                ) : null}
              </div>
              {isSelectParent &&
              getValues(`creative.variable_values.${parentIndex}.parent_variable_type`) !==
                'default' &&
              getValues(`creative.variable_values.${parentIndex}.parent_variable_type`) ? (
                <div className={cn('row')}>
                  {parentValues
                    ?.filter((item: any) => item?.is_active)
                    ?.map((item: any, index2: number) =>
                      getCurrentParentTypes(
                        `${name}.variable_values.${index}.children_values`,
                        index2,
                      ),
                    )}
                  {getCurrentParentTypes(
                    `${name}.variable_values.${index}.children_values`,
                    parentValues?.length
                      ? parentValues?.filter(
                          (itemVariableType: any) =>
                            itemVariableType &&
                            Object.prototype.hasOwnProperty.call(itemVariableType, 'is_active') &&
                            itemVariableType.is_active,
                        )?.length
                      : 0,
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : null}
        </React.Fragment>
      );
    };

    const isDisabled = () => {
      const value = getValues(`creative.variable_values.${index}.select_variable_value`);
      return value && value !== '' && value !== 'default';
    };

    return (
      <React.Fragment key={`creative.variable_values_${index}`}>
        <div className={'row mt-4'}>
          <div className={'col-6'}>
            <select
              className="form-select col-3 gap-2"
              aria-label="Select variable"
              value={
                isSelect
                  ? getValues(`creative.variable_values.${index}.select_variable_value`)
                  : 'default'
              }
              defaultValue="default"
              onChange={(e: any) => {
                setIsSelect(true);
                setIsSelectParent(true);
                const value = e?.target?.value;
                setCurrentVariableType(e?.target?.value);
                const values = getValues(`creative.variable_values`);
                let check = false;
                for (let i = 0; i < values?.length; i++) {
                  if (values[i]?.select_variable_value === value) {
                    check = true;
                    setValue(
                      `creative.variable_values.${i}.select_variable_value`,
                      e?.target?.value,
                    );
                    setValue(`creative.variable_values.${i}.is_active`, true, {
                      shouldValidate: true,
                    });
                  }
                }

                if (!check) {
                  setValue(
                    `creative.variable_values.${index + 1}.select_variable_value`,
                    e?.target?.value,
                  );
                  setValue(`creative.variable_values.${index + 1}.is_active`, true, {
                    shouldValidate: true,
                  });
                }
              }}>
              <option value="default">Select variable</option>
              {getVariableTypes()?.map((vt: any, index2: number) => (
                <option
                  key={`creative.variable_values.select_variable_value.${index}.${index2}`}
                  value={`${vt?.id}`}>
                  {vt?.name}
                </option>
              ))}
            </select>
          </div>
          <div className={'col-5'}>
            <select
              className="form-select col-3 gap-2"
              aria-label="Parent variable type"
              value={
                isSelect
                  ? getValues(`creative.variable_values.${index}.parent_variable_type`) || 'default'
                  : 'default'
              }
              defaultValue="default"
              disabled={!isDisabled() || !isSelect}
              onChange={(e: any) => {
                setIsSelectParent(true);
                const value = e?.target?.value;
                const newVariableValues: any = [];
                const copyVariables = JSON.parse(
                  JSON.stringify(getValues(`creative.variable_values.${index}.variable_values`)),
                );

                copyVariables?.map((item: any) => {
                  delete item?.children_values;
                  newVariableValues.push(item);
                });

                setValue(`creative.variable_values.${index}.parent_variable_type`, value);
                setValue(`creative.variable_values.${index}.variable_values`, newVariableValues);
                setValue(`creative.variable_values.${index}.is_active`, true, {
                  shouldValidate: true,
                });
              }}>
              <option value="default">Parent variable type</option>
              {getParentVariableTypes()
                ?.sort((a: any, b: any) => (a?.name > b?.name ? 1 : -1))
                ?.map((vt: any, index2: number) => (
                  <option
                    key={`creative.variable_values.parentVariableType${index}.${index2}`}
                    value={vt?.id}>
                    {vt?.name}
                  </option>
                ))}
            </select>
          </div>
          {/*<div className={'col-1 d-flex align-items-center'}>*/}
          {/*  {*/}
          {/*    (variableValues?.length >= index && variableValues[index]?.is_active) ?*/}
          {/*      <img className={styles.stop} src={'/stop.png'} onClick={() => variableValues('creative.variable_values', index)}/>*/}
          {/*      : null*/}
          {/*  }*/}
          {/*</div>*/}
        </div>
        <div className={'row'}>
          {
            // (!isSelect || !getValues(`creative.variable_values.${index}.parent_variable_type`))
            // || getValues(`creative.variable_values.${index}.parent_variable_type`) === 'default')
            // ?
            <>
              {isSelect &&
              variableValues?.length &&
              variableValues?.length >= index &&
              variableValues[index]?.variable_values?.length
                ? variableValues[index]?.variable_values
                    ?.filter((itemVariableType: any) => itemVariableType?.is_active)
                    ?.map((item: any, index2: number) => {
                      return getChildrenValues(
                        `creative.variable_values.${index}`,
                        index2,
                        index,
                        !isDisabled(),
                      );
                    })
                : null}
              {getChildrenValues(
                `creative.variable_values.${index}`,
                variableValues?.length &&
                  variableValues?.length >= index &&
                  variableValues[index]?.variable_values?.length
                  ? variableValues[index]?.variable_values?.filter(
                      (itemVariableType: any) =>
                        itemVariableType &&
                        Object.prototype.hasOwnProperty.call(itemVariableType, 'is_active') &&
                        itemVariableType.is_active,
                    )?.length
                  : 0,
                index,
                !isDisabled(),
              )}
            </>
            // : null
          }
        </div>
      </React.Fragment>
    );
  };

  const getIndex = () => {
    let newIndex = -1;
    variableValues?.find((itemVariableValue: any, index: number) => {
      if (itemVariableValue?.select_variable_value === currentVariableType) {
        newIndex = index;
      }
    });

    if (!isSelect && !variableValues?.length) {
      return 0;
    } else {
      return newIndex !== -1
        ? newIndex
        : variableValues?.filter((item: any) => item?.is_active)?.length || 0;
    }
  };
  console.log('variableTypes', variableTypes);
  return (
    <div className={'row'}>
      <div className={'col-6'}>
        <p className="mt-3 col-12">Variable types</p>
        {variableTypes?.length
          ? variableTypes
              ?.sort(
                (a: any, b: any) =>
                  +!a?.variable_type?.toLowerCase() - +!b?.variable_type?.toLowerCase() ||
                  a?.variable_type?.toLowerCase().localeCompare(b?.variable_type?.toLowerCase()),
              )
              ?.filter((itemVariableType: any) => itemVariableType?.is_active)
              ?.map((item: any, index: number) => {
                return getVariableType(index);
              })
          : null}
        {getVariableType(
          variableTypes?.filter(
            (itemVariableType: any) =>
              itemVariableType &&
              Object.prototype.hasOwnProperty.call(itemVariableType, 'is_active') &&
              itemVariableType.is_active,
          )?.length || 0,
        )}
      </div>

      <div className={'col-6'}>
        <p className="mt-3 col-12">Variable values</p>
        {getVariableValue(getIndex())}
      </div>
    </div>
  );
};

export default GetCreatives;
