import React, { FC } from 'react';
import { UseFormGetValues, UseFormRegister, UseFormSetValue, useFormState } from 'react-hook-form';
import Header from '../Header/Header';
import { FormNavigationButtons } from '../FormNavigationButtons/FormNavigationButtons';
import { ICreateCampaignForm } from '../../types/CreateCampaignForm';
import { Control, UseFormClearErrors } from 'react-hook-form/dist/types/form';
import CampaignSetupHeader from '../CampaignGoals/CampaignSetupHeader/CampaignSetupHeader';
import BudgetSplitInfo from './BudgetSplitInfo/BudgetSplitInfo';
import { VariableNameFragment } from '../../graphql/__generated__/graphql';
import { Error } from '../Error/Error';

interface Props {
  handleNext: () => void;
  handlePrevious: () => void;
  handlerLoading: boolean;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  partners: VariableNameFragment[];
  clearErrors: UseFormClearErrors<ICreateCampaignForm>;
  campaignId: string;
}
const BudgetSplit: FC<Props> = ({
  partners,
  handleNext,
  handlePrevious,
  handlerLoading,
  setValue,
  getValues,
  register,
  control,
  clearErrors,
  campaignId,
}) => {
  const { errors } = useFormState({ control });

  const getError = (): JSX.Element | undefined => {
    if (Object.keys(errors).length === 0) {
      return undefined;
    }

    return <Error>Please fill in all of the required fields.</Error>;
  };

  return (
    <div>
      <Header campaignSetupText={'Budget split'} />
      <CampaignSetupHeader
        register={register}
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={true}
      />
      <BudgetSplitInfo
        clearErrors={clearErrors}
        partners={partners}
        setValue={setValue}
        getValues={getValues}
        register={register}
        control={control}
        campaignId={campaignId}
      />
      {getError()}
      <FormNavigationButtons
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        isLoading={handlerLoading}
      />
    </div>
  );
};

export default BudgetSplit;
