import { gql } from '../__generated__';

export const GOAL_DETAILS_FRAGMENT = gql(`
    fragment GoalDetails on Goal {
        id
        touchpoints {
            id
            creatives {
                creativeName
            }
            targetingCompatibility
        }
    }
`);
