import React, { FC, useRef } from 'react';
import cn from 'classnames';
import styles from './FilterNavigation.module.scss';

interface Props {
  activeTab: string;
  setActiveTab: any;
  isShowScrollButtons?: boolean;
  tabs?: Array<any>;
}

const FilterNavigation: FC<Props> = ({
  activeTab,
  setActiveTab,
  isShowScrollButtons = true,
  tabs = [],
}: Props) => {
  const navRef = useRef<HTMLUListElement | any>();

  const scroll = (scrollOffset: number) => {
    if (navRef) {
      navRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <div className="mt-4 bg-light " style={{ position: 'relative', padding: '10px 90px 0 10px' }}>
      <ul
        ref={navRef}
        className={cn(
          'nav nav-tabs overflow-auto position-relative text-nowrap',
          styles.filterNavWrapper,
        )}>
        {tabs.map((el: string, index: number) => {
          return el && el !== '' ? (
            <li className="nav-item font-size _12" key={index}>
              <button
                className={cn('nav-link active', { [styles.active]: el === activeTab })}
                onClick={() => {
                  setActiveTab(el);
                }}>
                {el}
              </button>
            </li>
          ) : null;
        })}
      </ul>

      <div
        className={cn(
          'd-flex position-absolute top-50 end-0',
          isShowScrollButtons ? 'py-2 px-3' : 'py-1 px-3',
          styles.triangleNavigation,
        )}>
        {
          isShowScrollButtons ? (
            <>
              <div
                onClick={() => {
                  scroll(-navRef.current?.clientWidth - 150);
                }}
                className={styles.arrowLeft}
              />
              <div
                onClick={() => {
                  scroll(navRef.current?.clientWidth - 150);
                }}
                className={styles.arrowRight}
              />
            </>
          ) : null
          // <Button>Suggest values</Button>
        }
      </div>
    </div>
  );
};

export default FilterNavigation;
