import React, { FC } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { ICreateCampaignForm } from '../../types/CreateCampaignForm';
import Header from '../../components/Header/Header';
import { FormNavigationButtons } from '../../components/FormNavigationButtons/FormNavigationButtons';
import TargetGroupSetup from '../../components/TargetGroup/TargetGroupSetup/TargetGroupSetup';
import CampaignSetupHeader from '../../components/CampaignGoals/CampaignSetupHeader/CampaignSetupHeader';
export interface Props {
  campaignId: string;
  handleNext: () => void;
  handlePrevious: () => void;
  handlerLoading: boolean;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  trigger: UseFormTrigger<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
}

const TargetGroup: FC<Props> = ({
  campaignId,
  handlePrevious,
  handleNext,
  handlerLoading,
  setValue,
  getValues,
  register,
  trigger,
  control,
}) => {
  return (
    <div>
      <Header campaignSetupText={'Target groups'} />
      {/*TODO: templates support will be added later*/}
      <CampaignSetupHeader
        disabled={true}
        control={control}
        setValue={setValue}
        getValues={getValues}
        register={register}
      />
      <TargetGroupSetup
        campaignId={campaignId}
        control={control}
        setValue={setValue}
        getValues={getValues}
        register={register}
        trigger={trigger}
      />
      <FormNavigationButtons
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        isLoading={handlerLoading}
      />
    </div>
  );
};

export default TargetGroup;
