import React, { FC, useEffect } from 'react';
import MultipleSelectBlock from '../MultipleSelectBlock/MultipleSelectBlock';
import Sets from '../Sets/Sets';
import {
  GoalDetailsFragment,
  InsertionFrequencyFragment,
} from '../../../../graphql/__generated__/graphql';
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../../../types/CreateCampaignForm';
import { useFieldArray, UseFormGetValues, useFormState } from 'react-hook-form';

type Props = {
  goals: GoalDetailsFragment[];
  control: Control<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  matchedInsertionFreqTemplates: InsertionFrequencyFragment[];
  register: UseFormRegister<ICreateCampaignForm>;
  trigger: UseFormTrigger<ICreateCampaignForm>;
};
export const CreateSets: FC<Props> = ({
  goals,
  control,
  setValue,
  getValues,
  matchedInsertionFreqTemplates,
  register,
  trigger,
}) => {
  const {
    fields: sets,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'sets',
  });

  const { isSubmitted } = useFormState({ control });

  useEffect(() => {
    // Triggering after we add first set (to remove 'You need to define at least one set' error)
    sets.length === 1 && isSubmitted && trigger('sets');
  }, [sets]);

  return (
    <>
      <MultipleSelectBlock
        goals={goals}
        append={append}
        matchedInsertionFreqTemplates={matchedInsertionFreqTemplates}
      />

      <Sets
        register={register}
        getValues={getValues}
        setValue={setValue}
        control={control}
        sets={sets}
        append={append}
        remove={remove}
      />
    </>
  );
};
