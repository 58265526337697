import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './Login.module.scss';

type Props = {
  setToken: (e: any) => void;
};
const Login = ({ setToken }: Props) => {
  const navigate = useNavigate();

  const initialFormData = Object.freeze({
    gui_token: '',
  });
  const [formData, updateFormData] = React.useState(initialFormData);

  const handleChange = (e: any) => {
    updateFormData({
      ...formData,

      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setToken(formData.gui_token);
    // toast.success('Login Success !');
    navigate('/');
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column gap-2 m-2">
          <label className="text-center font-size _18 fw-bold">Enter GUI Token </label>
          <input
            className={styles.inputText}
            type="text"
            name="gui_token"
            required
            onChange={handleChange}
          />
        </div>
        <div className="d-flex p-0 justify-content-center">
          <input
            className={cn('text-white mt-2 px-5 pt-2', styles.inputSubmit)}
            type="submit"
            value="Login"
          />
        </div>
      </form>
    </div>
  );
};

export default Login;
