import React, { FC, useEffect } from 'react';
import { Control, useFieldArray, UseFormGetValues } from 'react-hook-form';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { VariableNameFragment } from '../../../graphql/__generated__/graphql';
import AdgItem from '../AdgItem/AdgItem';
import { LisAdgsNaming } from '../../../containers/UpsertLisAdgs/UpsertLisAdgs';

interface Props {
  register: UseFormRegister<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  lisAdgsIndex: number;
  lineItemIndex: number;
  liSubtypes: VariableNameFragment[];
  lisAdgsNaming: LisAdgsNaming;
}

const AdgList: FC<Props> = ({
  lisAdgsNaming,
  liSubtypes,
  lisAdgsIndex,
  lineItemIndex,
  register,
  setValue,
  control,
  getValues,
}) => {
  const {
    fields: adgs,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs`,
  });

  useEffect(() => {
    if (adgs.length === 0) {
      const touchpointLiSubtype = getValues(`lisAdgs.${lisAdgsIndex}.liSubtype`);
      const defaultLiSubtype = liSubtypes.find(
        (subtype) => subtype.ncVariableName === touchpointLiSubtype,
      );
      append({
        name: '',
        liSubtype: defaultLiSubtype?.variableName ?? '',
      });
    }
  }, [adgs.length]);

  const copyAdg = (index: number) => {
    const adg = getValues(`lisAdgs.${lisAdgsIndex}.lineItems.${lineItemIndex}.adgs.${index}`);
    append({
      name: adg.name,
      liSubtype: adg.liSubtype,
    });
  };

  const removeAdg = (index: number) => {
    remove(index);
  };

  return (
    <>
      {adgs.map((adg, index) => (
        <AdgItem
          key={adg.id}
          lisAdgsNaming={lisAdgsNaming}
          indexAdg={index}
          copyAdg={copyAdg}
          removeAdg={removeAdg}
          liSubtypes={liSubtypes}
          lisAdgsIndex={lisAdgsIndex}
          lineItemIndex={lineItemIndex}
          register={register}
          setValue={setValue}
          control={control}
          getValues={getValues}
          removeAdgEnabled={adgs.length > 1}
        />
      ))}
    </>
  );
};

export default AdgList;
