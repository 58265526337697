import React, { FC } from 'react';
import Header from '../Header/Header';
import CampaignSetupHeader from '../CampaignGoals/CampaignSetupHeader/CampaignSetupHeader';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../types/CreateCampaignForm';
import { Control } from 'react-hook-form';
import VariantTargetingInfo from './VariantTargetingInfo/VariantTargetingInfo';
import { FormNavigationButtons } from '../FormNavigationButtons/FormNavigationButtons';

interface Props {
  handleNext: () => void;
  handlePrevious: () => void;
  handlerLoading: boolean;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  trigger: UseFormTrigger<ICreateCampaignForm>;
}

const VariantTargeting: FC<Props> = ({
  handleNext,
  handlePrevious,
  handlerLoading,
  setValue,
  getValues,
  register,
  control,
  trigger,
}: Props) => {
  return (
    <div>
      <Header campaignSetupText={'Target group variants'} />
      <CampaignSetupHeader
        disabled={true}
        setValue={setValue}
        getValues={getValues}
        register={register}
        control={control}
      />
      <VariantTargetingInfo
        getValues={getValues}
        register={register}
        setValue={setValue}
        control={control}
        trigger={trigger}
      />
      <FormNavigationButtons
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        isLoading={handlerLoading}
      />
    </div>
  );
};

export default VariantTargeting;
