import { gql } from '../__generated__';

export const TARGETING_CONTAINERS_QUERY = gql(`
    query TargetingContainers($categoryId: String!) {
        targetingContainers(categoryId: $categoryId) {
            containerName
            containerOrder
            id
            selectors {
                id
                searchable
                selectorName
                selectorOrder
                selectorType
            }
        }
    }
`);
