import React, { FC, useEffect, useMemo, useState } from 'react';
import AdsSetup from '../../components/AdsSetup/AdsSetup';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADS_SETUP_FORM_QUERY, adsSetupToForm } from '../../graphql/queries/AdsSetupFormQuery';
import { useGraphQLError } from '../../hooks/useGraphQLError';
import { useFragment } from '../../graphql/__generated__';
import {
  CAMPAIGN_SETUP_FRAGMENT,
  campaignSetupFragmentToForm,
} from '../../graphql/fragments/CampaignSetupFragment';
import { useForm } from 'react-hook-form';
import { ICreateCampaignForm } from '../../types/CreateCampaignForm';
import Loader from '../../components/UI/Loader/Loader';
import { Paths } from '../../constants/paths';
import { AdsSetupFormSchema, IAdsSetupForm } from '../../types/AdsSetupForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { CampaignSetupStatusEnumModel } from '../../graphql/__generated__/graphql';
import {
  getUpsertAdsSetupMutationVariables,
  UPSERT_ADS_SETUP_MUTATION,
} from '../../graphql/mutations/UpsertAdsSetupMutation';
import { getPath } from '../../helpers/getSetupStatus';
import { toastMissingUpdateDate } from '../../helpers/toastMissingUpdateDate';
import { Ad } from '../../types/Ad';

type AdsSetupParams = {
  id: string;
};

const AdsSetupForm: FC = () => {
  const { id } = useParams<AdsSetupParams>();
  const navigate = useNavigate();

  const [initialized, setInitialized] = useState(false);
  const [getAdsSetup, { data, loading, error }] = useLazyQuery(ADS_SETUP_FORM_QUERY);
  const [upsertAdsSetup, { data: upsertData, error: upsertError, loading: upsertLoading }] =
    useMutation(UPSERT_ADS_SETUP_MUTATION);

  function refresh() {
    setInitialized(false);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getAdsSetup({ variables: { campaignId: id! } });
  }

  useGraphQLError([error, upsertError]);

  const {
    register: campaignRegister,
    getValues: campaignGetValues,
    setValue: campaignSetValue,
    control: campaignControl,
    reset: campaignReset,
  } = useForm<ICreateCampaignForm>();

  const { register, getValues, setValue, control, reset, handleSubmit } = useForm<IAdsSetupForm>({
    resolver: zodResolver(AdsSetupFormSchema),
  });

  useEffect(() => {
    if (!id) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getAdsSetup({ variables: { campaignId: id! } });
  }, [id]);

  useEffect(() => {
    if (!data || loading) {
      return;
    }
    const campaignSetup = useFragment(CAMPAIGN_SETUP_FRAGMENT, data.campaign);

    const status = campaignSetup?.setupStatus || '';
    if (
      status === CampaignSetupStatusEnumModel.Initialized ||
      status === CampaignSetupStatusEnumModel.WithTargetGroups ||
      status === CampaignSetupStatusEnumModel.WithVariants ||
      status === CampaignSetupStatusEnumModel.WithBudgetSplit
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      navigate(getPath(status, id!));
      return;
    }

    campaignReset({
      setup: campaignSetupFragmentToForm(campaignSetup),
    });

    reset(adsSetupToForm(data));
    setInitialized(true);
  }, [data, loading]);

  const ads = useMemo(() => {
    return (
      data?.ads.map((ad: Ad) => ({
        id: ad.id,
        site: ad.site,
        clickTag: ad.clickTag,
        placementName: ad.placementName,
      })) ?? []
    );
  }, [data, loading]);

  const handleNext = () => {
    if (upsertLoading) {
      return;
    }
    if (!data?.campaign?.updatedAt) {
      toastMissingUpdateDate();
      return;
    }
    const updatedAt = data.campaign.updatedAt;

    handleSubmit(
      (data) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const variables = getUpsertAdsSetupMutationVariables(data, id!, updatedAt);
        upsertAdsSetup({ variables });
      },
      (e) => console.log(e),
    )();
  };

  useEffect(() => {
    if (!upsertData) {
      return;
    }

    navigate(Paths.home);
  }, [upsertData]);

  if (!initialized) {
    return <Loader />;
  }
  if (!id) {
    return <></>;
  }

  return (
    <AdsSetup
      handleNext={handleNext}
      handlerLoading={upsertLoading}
      ads={ads}
      campaignRegister={campaignRegister}
      refreshData={refresh}
      campaignControl={campaignControl}
      campaignSetValue={campaignSetValue}
      campaignGetValues={campaignGetValues}
      getValues={getValues}
      setValue={setValue}
      register={register}
      campaignId={id}
      control={control}
    />
  );
};

export default AdsSetupForm;
