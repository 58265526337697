import cn from 'classnames';
import styles from '../TargetGroupCheckboxes.module.scss';
import { FormCheck } from 'react-bootstrap';
import React, { FC, RefObject, useCallback, useEffect, useRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { ICreateCampaignForm } from '../../../../types/CreateCampaignForm';
import { TargetGroupForVariant } from '../../../../types/TargetGroup';
import { Coordinates } from '../../../../types/Coordinates';
import { UpdateCheckboxesFunction } from '../../VariantTargetingForm/VariantTargetingForm';

type Props = {
  register: UseFormRegister<ICreateCampaignForm>;
  selectedSet: number;
  variantIndex: number;
  targetGroup: TargetGroupForVariant;
  targetingIndex: number;
  coordinatesRef: RefObject<Coordinates>;
  wrapperRef: RefObject<HTMLDivElement>;
  clicked: boolean;
  updateCheckboxes: UpdateCheckboxesFunction;
};
export const VariantCheckbox: FC<Props> = ({
  register,
  selectedSet,
  variantIndex,
  targetGroup,
  targetingIndex,
  clicked,
  coordinatesRef,
  wrapperRef,
  updateCheckboxes,
}) => {
  const checkboxRef = useRef<HTMLDivElement | null>(null);
  const alreadyChanged = useRef<boolean>(false);

  const isInside = useCallback((): boolean => {
    if (!checkboxRef.current || !coordinatesRef.current || !wrapperRef.current) {
      return false;
    }

    const box = checkboxRef.current.getBoundingClientRect();
    const wrapperDiv = wrapperRef.current.getBoundingClientRect();

    const right = coordinatesRef.current.width + coordinatesRef.current.left;
    const bottom = coordinatesRef.current.height + coordinatesRef.current.top;

    const boxLeft = box.left - wrapperDiv.left;
    const boxRight = boxLeft + box.width;
    const boxTop = box.top - wrapperDiv.top;
    const boxBottom = boxTop + box.height;

    if (
      boxLeft > coordinatesRef.current.left &&
      boxRight < right &&
      boxTop > coordinatesRef.current.top &&
      boxBottom < bottom
    ) {
      return true;
    }

    return false;
  }, []);

  useEffect(() => {
    const mouseMoveHandler = () => {
      if (!checkboxRef.current || !coordinatesRef.current || !wrapperRef.current || !clicked) {
        return;
      }

      const inside = isInside();

      if (inside && !alreadyChanged.current) {
        alreadyChanged.current = true;
        updateCheckboxes(variantIndex, targetGroup.type, targetGroup.subType, targetingIndex);
      } else if (!inside && alreadyChanged.current) {
        alreadyChanged.current = false;
      }
    };
    if (clicked) {
      window.addEventListener('mousemove', mouseMoveHandler);
      return () => {
        window.removeEventListener('mousemove', mouseMoveHandler);
      };
    }
  }, [clicked, alreadyChanged]);

  const { ref, ...rest } = register(
    `setsWithVariants.${selectedSet}.variants.${variantIndex}.tabs.${targetGroup.type}.${targetGroup.subType}.${targetingIndex}.value`,
  );

  return (
    <div>
      <FormCheck
        {...rest}
        ref={(e: HTMLInputElement | null) => {
          ref(e);
          checkboxRef.current = e;
        }}
        type={'checkbox'}
        className={cn('d-flex justify-content-center', styles.h25px, styles.w100px)}
      />
    </div>
  );
};
