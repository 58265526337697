import React, { FC, useContext, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from './CustomDropdown.module.scss';
import cn from 'classnames';
import { Control, Controller } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { HeaderContext, IHeaderValue } from '../../context/HeaderContext';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';

interface IObjectItem {
  name: string;
  id: string;
}

interface Props {
  placeholder: string;
  name: string;
  options?: Array<IObjectItem>;
  control: Control<any>;
  getValues: UseFormGetValues<any>;
  disabled?: boolean;
  isRequired?: boolean;
  setSelectId?: ((val: string | null) => void) | null;
}

const BOLD_TEXT = ['categoryData.category', 'categoryData.brand'];

const CustomDropdown: FC<Props> = ({
  placeholder,
  name,
  options,
  control,
  getValues,
  disabled = false,
  isRequired = false,
  setSelectId,
}): JSX.Element => {
  const { id, token } = useParams();
  const { pathname } = useLocation();
  const { headerObjectValue, setHeaderObjectValue } = useContext(HeaderContext);

  const checkCategoryPathname: boolean = useMemo((): boolean => {
    return (
      BOLD_TEXT?.includes(name) &&
      pathname !== `/${token}` &&
      pathname !== `/templates-management/${id}` &&
      pathname !== `/ads-setup/${id}`
    );
  }, [id, token, pathname, name]);

  const getDefaultValue = (): string => {
    if (getValues && getValues(name)) {
      return getValues(name);
    } else {
      return placeholder;
    }
  };

  const setFormValue = (onChange: (val: string) => void, item: IObjectItem): void => {
    const selectVal = item?.name;
    if (setSelectId) {
      setSelectId(item?.id);
    }
    onChange(selectVal);

    const tempObject: IHeaderValue = { categoryData: {} };
    let container: any = tempObject;
    name?.split('.')?.map((k: string, i: number, values: Array<string>) => {
      container = container[k] = i === values.length - 1 ? selectVal : {};
      return k;
    });

    setHeaderObjectValue({
      ...headerObjectValue,
      categoryData: { ...headerObjectValue?.categoryData, ...tempObject?.categoryData },
    });
  };

  return (
    <Dropdown className="position-relative">
      <Dropdown.Toggle
        disabled={disabled}
        variant="success"
        id="dropdown-basic"
        className={cn('dark-gray border-0 bg-very-light-gray', styles.dropdownButton, {
          [styles.dropdownButtonBold]: checkCategoryPathname,
        })}>
        {getDefaultValue()}
      </Dropdown.Toggle>
      <Controller
        control={control}
        name={name}
        rules={{ required: isRequired }}
        render={({ field: { onChange } }) => (
          <Dropdown.Menu className="overflow-auto max-h _200">
            {options?.map((item: IObjectItem, index: number) => (
              <Dropdown.Item
                key={`${placeholder}_${index}`}
                onClick={() => {
                  setFormValue(onChange, item);
                }}>
                {item?.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      />
    </Dropdown>
  );
};

export default CustomDropdown;
