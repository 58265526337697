import React, { FC, useEffect, useMemo } from 'react';
import { Navbar } from 'react-bootstrap';
import styles from './CreateCampaignNavbar.module.scss';
import { Control, UseFormSetValue } from 'react-hook-form/dist/types/form';
import CreateCampaignDropdown from '../CreateCampaignDropdown/CreateCampaignDropdown';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { UseFormRegister, useFormState, useWatch } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { CATEGORY_DIVISION_QUERY } from '../../../graphql/queries/CategoryDivisonQuery';
import { useGraphQLError } from '../../../hooks/useGraphQLError';
import { BrandDetailsFragment } from '../../../graphql/__generated__/graphql';

interface Props {
  setValue: UseFormSetValue<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  brands?: BrandDetailsFragment[];
}

const CreateCampaignNavbar: FC<Props> = ({ setValue, register, control, brands }) => {
  const brandValue = useWatch({ control, name: 'categoryData.brand' });
  const categoryValue = useWatch({ control, name: 'categoryData.category' });
  const scenarioName = useWatch({ control, name: 'setup.scenarioName' });
  const { errors } = useFormState({ control, name: 'categoryData' });

  const [
    getCategoryDivision,
    { data: categoryDivisionData, loading: categoryDivisionLoading, error: categoryDivisionError },
  ] = useLazyQuery(CATEGORY_DIVISION_QUERY);

  const brandOptions = useMemo(() => {
    return (
      brands
        ?.filter((el): el is { id: string; Brand: string } => el.id != null && el.Brand != null)
        .map((el) => ({ id: el.id, name: el.Brand }))
        .sort((a, b) => a.name.localeCompare(b.name)) || []
    );
  }, [brands]);

  const categoryOptions = useMemo(() => {
    return categoryDivisionData?.categories
      ?.filter((el): el is { id: string; category: string } => el.id != null && el.category != null)
      .map((el) => ({ id: el.id, name: el.category }));
  }, [categoryDivisionData]);

  const divisionOptions = useMemo(() => {
    if (!categoryDivisionData) {
      return undefined;
    }
    return [
      {
        id: categoryDivisionData.division?.id || '',
        name: categoryDivisionData.division?.division || '',
      },
    ];
  }, [categoryDivisionData]);

  const scenarioOptions = useMemo(() => {
    if (!categoryDivisionData) {
      return undefined;
    }
    return categoryDivisionData.scenarioNames?.map((item) => {
      return {
        id: item,
        name: item,
      };
    });
  }, [categoryDivisionData]);

  const isCategoryDisabled = brandValue === '' || !categoryOptions;

  const isScenarioDisabled = brandValue === '' || !scenarioOptions;

  useEffect(() => {
    setValue('categoryData.category', '');
    setValue('categoryData.division', '');
    setValue('setup.scenarioName', '');
    if (!brandValue) {
      return;
    }

    if (!categoryDivisionLoading) {
      getCategoryDivision({ variables: { brandId: brandValue } });
    }
  }, [brandValue]);

  useEffect(() => {
    if (!brandValue) {
      return;
    }
    setValue('categoryData.category', categoryValue);
    setValue('setup.scenarioName', scenarioName);
  }, []);

  useEffect(() => {
    setValue('categoryData.division', divisionOptions?.[0]?.id || '');
  }, [divisionOptions]);

  useEffect(() => {
    //TODO: A product value might be copied from a categoryValue on backend
    setValue('categoryData.product', categoryValue);
  }, [categoryValue]);

  useGraphQLError([categoryDivisionError]);

  const updateBrandName = (e: React.ChangeEvent<HTMLOptionElement>) => {
    const brandId = e.target.value;
    const brand = brands?.find((brand) => brand.id === brandId);
    setValue('categoryData.brandName', brand?.ncBrand ?? '');
  };

  return (
    <Navbar bg="light" className={styles.navbar}>
      <CreateCampaignDropdown
        name={`categoryData.division`}
        placeholder={'Division'}
        options={divisionOptions}
        disabled={true}
        register={register}
        isInvalid={errors.categoryData?.division !== undefined}
      />
      <CreateCampaignDropdown
        disabled={categoryDivisionLoading}
        name={`categoryData.brand`}
        placeholder={'Brand'}
        onChange={updateBrandName}
        options={brandOptions}
        register={register}
        isInvalid={errors.categoryData?.brand !== undefined}
      />
      <CreateCampaignDropdown
        name={`categoryData.category`}
        placeholder={'Category'}
        value={categoryValue}
        options={categoryOptions}
        disabled={isCategoryDisabled || categoryDivisionLoading}
        register={register}
        isInvalid={errors.categoryData?.category !== undefined}
      />
      <CreateCampaignDropdown
        name={`setup.scenarioName`}
        placeholder="Scenario Name"
        value={scenarioName}
        options={scenarioOptions}
        register={register}
        disabled={isScenarioDisabled || categoryDivisionLoading}
        isInvalid={errors.setup?.scenarioName !== undefined}
      />
    </Navbar>
  );
};

export default CreateCampaignNavbar;
