import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import CampaignGoals from '../../components/CampaignGoals/CampaignGoals';
import {
  CampaignGoalsSchema,
  ICreateCampaignForm,
  ICreateCampaignFormPartial,
} from '../../types/CreateCampaignForm';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  getUpsertCampaignMutationVariables,
  UPSERT_CAMPAIGN_MUTATION,
} from '../../graphql/mutations/UpsertCampaignMutation';
import { useGraphQLError } from '../../hooks/useGraphQLError';
import { useNavigate, useParams } from 'react-router-dom';
import { Paths } from '../../constants/paths';
import { UPSERT_CAMPAIGN_GOALS_QUERY } from '../../graphql/queries/UpsertCampaignGoalsQuery';
import Loader from '../../components/UI/Loader/Loader';
import { useFragment } from '../../graphql/__generated__';
import { BRAND_DETAILS_FRAGMENT } from '../../graphql/fragments/BrandDetailsFragment';
import { GOAL_DETAILS_FRAGMENT } from '../../graphql/fragments/GoalDetailsFragment';
import { NAMING_DETAILS_FRAGMENT } from '../../graphql/fragments/NamingDetailsFragment';
import {
  CAMPAIGN_SETUP_FRAGMENT,
  campaignSetupFragmentToForm,
} from '../../graphql/fragments/CampaignSetupFragment';
import { zodResolver } from '@hookform/resolvers/zod';
import { NamingDetailsFragment } from '../../graphql/__generated__/graphql';
import {
  CAMPAIGN_CATEGORY_FRAGMENT,
  campaignCategoryFragmentToForm,
} from '../../graphql/fragments/CampaignCategoryFragment';
import { toastMissingUpdateDate } from '../../helpers/toastMissingUpdateDate';
import {
  CAMPAIGN_SET_WITH_FREQUENCY_FRAGMENT,
  campaignSetWithFrequencyFragmentToSets,
} from '../../graphql/fragments/CampaignSetWithFrequencyFragment';
import { CAMPAIGN_FREQUENCY_FRAGMENT } from '../../graphql/fragments/CampaignFrequencyFragment';
import { INSERTION_FREQUENCY_FRAGMENT } from '../../graphql/fragments/InsertionFrequencyFragment';

type UpsertCampaignGoals = {
  id: string;
};

const UpsertCampaignGoals: FC = () => {
  const { id } = useParams<UpsertCampaignGoals>();
  const [initialized, setInitialized] = useState(false);

  const [
    upsertCampaign,
    { loading: upsertCampaignLoading, error: upsertCampaignError, data: upsertCampaignData },
  ] = useMutation(UPSERT_CAMPAIGN_MUTATION);

  const [getUpsertCampaignGoals, { error: dataError, data }] = useLazyQuery(
    UPSERT_CAMPAIGN_GOALS_QUERY,
  );

  useEffect(() => {
    if (!id) {
      return;
    }

    getUpsertCampaignGoals({ variables: { campaignId: id } });
  }, [id]);

  const navigate = useNavigate();

  const { setValue, getValues, register, control, reset, handleSubmit, trigger } =
    useForm<ICreateCampaignForm>({
      resolver: zodResolver(CampaignGoalsSchema),
    });

  useEffect(() => {
    if (!data) {
      return;
    }

    const campaignSetup = useFragment(CAMPAIGN_SETUP_FRAGMENT, data.campaign);
    const categoryData = useFragment(CAMPAIGN_CATEGORY_FRAGMENT, data.campaign);
    const campaignSets =
      data.campaign?.sets.map((set) => useFragment(CAMPAIGN_SET_WITH_FREQUENCY_FRAGMENT, set)) ??
      [];

    const defaultValue: ICreateCampaignFormPartial = {
      categoryData: campaignCategoryFragmentToForm(categoryData),
      setup: {
        ...campaignSetupFragmentToForm(campaignSetup),
      },
      sets: campaignSetWithFrequencyFragmentToSets(campaignSets),
    };

    reset(defaultValue);
    setInitialized(true);
  }, [data]);

  useEffect(() => {
    if (!upsertCampaignData) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    navigate(Paths.getUpsertTargetGroup(id!));
  }, [upsertCampaignData]);

  useGraphQLError([upsertCampaignError, dataError]);

  const handleNextButton = () => {
    if (upsertCampaignLoading) {
      return;
    }
    if (!data?.campaign?.updatedAt) {
      toastMissingUpdateDate();
      return;
    }
    const updatedAt = data.campaign.updatedAt;
    handleSubmit((data) => {
      upsertCampaign({
        variables: getUpsertCampaignMutationVariables(data, updatedAt, id),
      });
    })();
  };

  const brands = useMemo(() => {
    return data?.brands?.map((el) => useFragment(BRAND_DETAILS_FRAGMENT, el)) ?? [];
  }, [data]);

  const goals = useMemo(() => {
    return data?.goals?.map((el) => useFragment(GOAL_DETAILS_FRAGMENT, el)) ?? [];
  }, [data]);

  const campaignFrequencyTemplates =
    data?.campaignFrequencyTemplates?.map((el) => useFragment(CAMPAIGN_FREQUENCY_FRAGMENT, el)) ??
    [];

  const insertionFrequencyTemplates =
    data?.insertionFrequencyTemplates?.map((el) => useFragment(INSERTION_FREQUENCY_FRAGMENT, el)) ??
    [];

  const namingConventions =
    data?.namingConventions?.conventions
      ?.map((el) => useFragment(NAMING_DETAILS_FRAGMENT, el))
      ?.filter((el): el is NamingDetailsFragment => el != null) ?? [];

  const namingSeparators = useMemo(() => {
    return {
      separator: data?.namingConventions?.separatorCharacter ?? '',
      subSeparator: data?.namingConventions?.separatorCharacterSubtitle ?? '',
    };
  }, [data]);

  if (!initialized) {
    return <Loader />;
  }

  return (
    <CampaignGoals
      goals={goals}
      namingConventions={namingConventions}
      namingConventionsSeparators={namingSeparators}
      brands={brands}
      campaignFrequencyTemplates={campaignFrequencyTemplates}
      insertionFrequencyTemplates={insertionFrequencyTemplates}
      setValue={setValue}
      getValues={getValues}
      register={register}
      control={control}
      trigger={trigger}
      handleNext={handleNextButton}
      handlerLoading={upsertCampaignLoading}
    />
  );
};

export default UpsertCampaignGoals;
