import { ApolloClient, ApolloLink, fromPromise, HttpLink, InMemoryCache } from '@apollo/client';
import { FirebaseApp } from 'firebase/app';
import { getHeaders } from '../helpers/getHeaders';

export const getApolloClient = (firebase: FirebaseApp) => {
  const tokenMiddleware = new ApolloLink((operation, forward) => {
    const updateHeaders = async () => {
      const headersFromPartner = await getHeaders(firebase);
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          ...headersFromPartner,
        },
      }));
    };

    return fromPromise(updateHeaders()).flatMap(() => forward(operation));
  });

  const httpLink = new HttpLink({ uri: process.env?.REACT_APP_BACKEND_URL || '' });
  return new ApolloClient({
    link: tokenMiddleware.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      mutate: {
        fetchPolicy: 'network-only',
      },
    },
  });
};
