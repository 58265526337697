import React, { FC } from 'react';
import styles from './SelectBlock.module.scss';
import cn from 'classnames';
import { Control, Controller, UseFormGetValues, UseFormSetValue, useWatch } from 'react-hook-form';

interface Props {
  children?: React.ReactNode;
  content: Array<string>;
  className?: {
    option?: string;
    selectWrap?: string;
  };
  title: string;
  name: string;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  control: Control<any>;
  withMultiple?: boolean;
  isRequired?: boolean;
  changeSelectedOptions?: any;
  disabledOptions?: string[];
  isShowChecked?: boolean;
}

const SelectBlock: FC<Props> = ({
  content,
  className,
  isRequired = false,
  changeSelectedOptions,
  withMultiple = true,
  isShowChecked = false,
  title,
  name,
  setValue,
  getValues,
  disabledOptions,
  control,
  children,
}): JSX.Element => {
  const watchName = useWatch({ control, name: name });

  const setCheckedValue = (value: string): void => {
    if (!withMultiple) {
      setValue(name, value);
      return;
    }

    if (getValues(name)?.includes(value)) {
      setValue(
        name,
        getValues(name)?.filter((item: string) => item !== value),
      );
      return;
    }

    setValue(name, getValues(name) ? [...getValues(name), value] : [value]);
  };

  return (
    <div
      className={cn(
        'd-grid flex-column mt-3 gap-2 max-w-imp _300',
        styles.selectWrap,
        className?.selectWrap,
      )}>
      <p>{title}</p>
      {children}
      {isShowChecked &&
        (withMultiple ? watchName : [watchName])?.map((option: string, index: number) => {
          return (
            <span
              key={`checked-${title}-${index}`}
              className={cn(styles.optionStyle, { [styles.active]: true })}
              onClick={() => {
                setCheckedValue(option);
              }}>
              {option}
            </span>
          );
        })}
      <Controller
        control={control}
        name={name}
        rules={{ required: isRequired }}
        render={() => {
          return (
            <div
              className={cn(
                'border rounded d-flex flex-column h-100 overflow-auto max-h _200 position-relative',
                styles.customFocus,
              )}
              id={'optionsWrapper'}>
              {content.map((item: string, index: number) => {
                const disabled: boolean = disabledOptions?.includes(item) ?? false;

                return (
                  <React.Fragment key={`${title}_${item}_${index}`}>
                    {' '}
                    <span
                      className={cn(styles.optionStyle, className?.option, {
                        [styles.active]: !withMultiple
                          ? item === watchName
                          : watchName?.includes(item),
                        [styles.disabled]: disabled,
                      })}
                      onClick={() => {
                        if (!disabled) {
                          setCheckedValue(item);
                          changeSelectedOptions && changeSelectedOptions(title, item);
                        }
                      }}
                      key={`${title}_${item}_${index}`}>
                      {item}
                    </span>
                  </React.Fragment>
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
};

export default SelectBlock;
