import React, { FC, useContext } from 'react';
import cn from 'classnames';
import styles from './Header.module.scss';
import { useNavigate } from 'react-router-dom';
import { IPartnerContext, PartnerContext } from '../../context/PartnerContext';

interface Props {
  withButtons?: boolean;
  campaignSetupText?: string;
}

const Header: FC<Props> = ({ withButtons = true, campaignSetupText = '' }): JSX.Element => {
  const navigate = useNavigate();
  const { partnerData } = useContext<IPartnerContext>(PartnerContext);

  const getTitle = (): string => {
    const text = 'Campaign Setup';
    if (campaignSetupText) {
      return text + ` - ${campaignSetupText}`;
    }
    return text;
  };

  const getButton = (): JSX.Element | null => {
    if (!withButtons) {
      return null;
    }

    return (
      <div className="d-flex gap-2">
        <button
          className={'btn btn-primary'}
          onClick={() => {
            navigate(`/checker`);
          }}>
          Checker
        </button>
        <button
          className={'btn btn-primary'}
          onClick={() => {
            navigate(`/platforms-brands-products`);
          }}>
          Client setup
        </button>
        <button
          className={'btn btn-primary'}
          onClick={() => {
            navigate(`/platforms-brands-products-platform`);
          }}>
          Naming conventions and branding definitions
        </button>
        <button
          className={'btn btn-primary'}
          onClick={() => {
            navigate(`/templates-management/${partnerData?.partner_id}`);
          }}>
          Templates
        </button>
        <button
          className={'btn btn-primary'}
          onClick={() => {
            navigate(`/optimisation-management`);
          }}>
          Optimisation
        </button>
      </div>
    );
  };

  return (
    <header className={cn('d-flex justify-content-between align-items-center pe-4', styles.header)}>
      <h2 className={styles.title}>{getTitle()}</h2>
      {getButton()}
    </header>
  );
};
export default Header;
