import { useMutation } from '@apollo/client';
import React, { SyntheticEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { VERIFY_CAMPAIGN_MUTATION } from '../../graphql/mutations/VerifyCampaignMutatation';
import { Error } from '../Error/Error';
import Loader from '../UI/Loader/Loader';

function handleInput(setValue: (prev: string) => void) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.trim());
  };
}

export interface PostData {
  advertiserId: string;
  campaignId: string;
  ioIds: string[];
}

function filterOutUndefinedAndNull<T>(arr: (T | null | undefined)[] | undefined): T[] {
  if (!arr) return [];
  return arr.filter((value) => value !== undefined && value !== null) as T[];
}

function CheckerForm() {
  const [verifyCampaign, { data, loading }] = useMutation(VERIFY_CAMPAIGN_MUTATION);
  const [errors, setErrors] = useState<string[]>([]);
  const [postData, setPostData] = useState<PostData>({
    advertiserId: '',
    campaignId: '',
    ioIds: [],
  });
  const setCampaignId = (campaignId: string) => setPostData((prev) => ({ ...prev, campaignId }));
  const setAdvertiserId = (advertiserId: string) =>
    setPostData((prev) => ({ ...prev, advertiserId }));

  const setInsertionOrderIds = (ioIds: string) =>
    setPostData((prev) => ({
      ...prev,
      ioIds: ioIds
        .split(',')
        .map((v) => v.trim())
        .filter((v, ix, arr) => (ix < arr.length - 1 ? v.length > 0 : true)),
    }));

  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    e.preventDefault();

    const { advertiserId, campaignId, ioIds } = postData;
    const { data: response } = await verifyCampaign({
      variables: { advertiserId, campaignId, insertionOrderIds: ioIds.filter((v) => v.length > 0) },
    });

    if (!response?.verifyCampaign.ok) {
      setErrors(filterOutUndefinedAndNull(response?.verifyCampaign.errors));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="col">
        <div className="row mb-3">
          <div className="col-4">
            <Form.Control
              type="text"
              value={postData.advertiserId}
              onChange={handleInput(setAdvertiserId)}
              placeholder="Advertiser ID"
              id="advertiser-id"
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <Form.Control
              type="text"
              value={postData.campaignId}
              onChange={handleInput(setCampaignId)}
              placeholder="Campaign ID"
              id="campaign-id"
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <Form.Control
              type="text"
              value={postData.ioIds.join()}
              onChange={handleInput(setInsertionOrderIds)}
              placeholder="Insertion Order IDs (Optional, comma-separated)"
              id="insertion-order-ids"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <button className="btn btn-primary" type="submit">
              Verify Campaign
            </button>
          </div>
        </div>
        {loading ? (
          <Loader></Loader>
        ) : data?.verifyCampaign.ok ? (
          <p className="text-success fw-bold mt-3 mb-3">Your campaign is set up correctly</p>
        ) : (
          errors.map((err) => <Error key={err}>{err}</Error>)
        )}
      </div>
    </form>
  );
}

export default CheckerForm;
