import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import CreateCampaign from '../../components/CreateCampaign/CreateCampaign';
import { useForm } from 'react-hook-form';
import { addDoc, collection, getDocs, orderBy, query, where } from 'firebase/firestore/lite';
import { useLocation } from 'react-router-dom';
import { FirebaseContext, IFirebaseContext } from '../../context/FirebaseContext';
import { CampaignContext, ICampaignContext } from '../../context/CampaignContext';
import {
  CollectionReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
} from '@firebase/firestore/lite';
import { ICampaignData, ICampaigns } from '../../types/Campaign';
import Loader from '../../components/UI/Loader/Loader';
import CampaignSetup from '../../components/CampaignSetup/CampaignSetup';
import { HeaderContext } from '../../context/HeaderContext';

interface IForm {
  brand: string;
  category: string;
  division: string;
  iab: string;
  product: Array<string>;
  status: string;
}

const Home: FC = (): JSX.Element => {
  const [loader, setLoader] = useState<boolean>(true);

  const { pathname }: any = useLocation();
  const [campaigns, setCampaigns] = useState<null | ICampaigns>(null);
  const { setValue, getValues, control } = useForm<IForm>();
  const { setHeaderObjectValue } = useContext(HeaderContext);
  const { campaignFirestore, partnerData } = useContext<IFirebaseContext>(FirebaseContext);
  const { setCampaignRef } = useContext<ICampaignContext>(CampaignContext);

  useEffect(() => {
    setHeaderObjectValue({ categoryData: {} });
  }, []);

  useEffect(() => {
    if (campaignFirestore) {
      (async (): Promise<void> => {
        if (partnerData && campaignFirestore) {
          const colSnapshot: CollectionReference = collection(campaignFirestore, 'campaigns');
          const stateQueryName: Query = query(
            colSnapshot,
            where('partner_id', '==', partnerData?.partner_id),
            orderBy('updated_at', 'desc'),
          );

          const docs: QuerySnapshot = await getDocs(stateQueryName);
          const rawCampaignsList: ICampaigns = [];
          docs.forEach((doc: DocumentSnapshot) => {
            rawCampaignsList.push({ data: doc?.data() as ICampaignData, id: doc.id });
          });
          setCampaigns(rawCampaignsList);
        }
      })()
        .catch((reason) => {
          console.log('Error: Problem reading campaigns:', reason);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [partnerData, pathname, campaignFirestore]);

  const onSubmitData = useCallback(async (): Promise<void> => {
    if (partnerData && campaignFirestore) {
      const defaultCampaign: ICampaignData = {
        status: 'In progress',
        partner_id: partnerData?.partner_id,
        setup: { name: '' },
      };

      const docRef = await addDoc(
        collection(campaignFirestore, 'campaigns') as CollectionReference<ICampaignData>,
        defaultCampaign,
      );
      if (setCampaignRef) {
        setCampaignRef(docRef);
      }
    }
  }, [partnerData?.partner_id, campaignFirestore]);

  return (
    <div>
      {loader || !campaigns ? (
        <Loader />
      ) : (
        <>
          <Header />
          <CreateCampaign
            control={control}
            setValue={setValue}
            getValues={getValues}
            onSubmit={onSubmitData}
            setMethod={setCampaigns}
          />
          <CampaignSetup setCampaigns={setCampaigns} campaigns={campaigns} />
        </>
      )}
    </div>
  );
};

export default Home;
