import React, { FC, useContext } from 'react';
import cn from 'classnames';
import styles from './TemplatesManagementSetup.module.scss';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { PartnerContext } from '../../context/PartnerContext';

const TemplatesManagementSetup: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { partnerData } = useContext(PartnerContext);

  return (
    <div className="d-flex align-items-stretch py-3 px-0 h-100">
      {/*<div className={`col-xl-3 col-md-5 col-xs-4 ${styles.SelectAdsSecondary}`}>*/}
      {/*  <select className={`form-select ${styles.MainSelect}`} aria-label="Default select example" defaultValue='default'>*/}
      {/*    <option value="default">Option 1</option>*/}
      {/*    <option value="1">Option 2</option>*/}
      {/*    <option value="2">Option 3</option>*/}
      {/*    <option value="3">Option 4</option>*/}
      {/*  </select>*/}
      {/*</div>*/}
      <Button
        variant="primary"
        size="lg"
        className={cn('d-flex align-items-center justify-content-center py-0 px-3', styles.Button)}
        onClick={() => {
          navigate(`/edit/campaign-goals/${partnerData?.partner_id}`, {
            state: { editTemplate: true, isNew: true },
          });
        }}>
        Create campaign template
      </Button>
      <Button
        onClick={() => {
          navigate(`/exclusions/${partnerData?.partner_id}`);
        }}
        variant="primary"
        size="lg"
        className={styles.Button}>
        Create Exclusions
      </Button>
    </div>
  );
};

export default TemplatesManagementSetup;
