import React from 'react';
import Header from '../Header/Header';
import CheckerForm from './Form';

function Flyps() {
  return (
    <>
      <Header campaignSetupText={'Verify Your Campaign'} withButtons={false} />
      <CheckerForm />
    </>
  );
}

export default Flyps;
