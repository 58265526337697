import { gql } from '../__generated__';
import {
  ICreateCampaignSetWithTabs,
  ICreateCampaignTargetGroup,
} from '../../types/CreateCampaignForm';
import { CampaignSetTabsForVariantsFragment } from '../__generated__/graphql';

export const CAMPAIGN_SET_TABS_FOR_VARIANTS_FRAGMENT = gql(`
    fragment CampaignSetTabsForVariants on CampaignSet {
        uniqId
        targetGroups {
            type
            subType
            values
        }
    }
`);

export const campaignSetsTabsFragmentToSetWithTabsForVariants = (
  campaignSets: readonly CampaignSetTabsForVariantsFragment[],
): ICreateCampaignSetWithTabs[] => {
  return (
    campaignSets.map((set) => {
      return campaignSetTabsFragmentToSetWithTabsForVariants(set);
    }) ?? []
  );
};

export const campaignSetTabsFragmentToSetWithTabsForVariants = (
  campaignSet: CampaignSetTabsForVariantsFragment,
): ICreateCampaignSetWithTabs => {
  const tabsResult: ICreateCampaignTargetGroup = {};
  campaignSet.targetGroups?.forEach((tab) => {
    if (tabsResult[tab.type] === undefined) {
      tabsResult[tab.type] = {};
    }

    tabsResult[tab.type][tab.subType] = tab.values;
  });

  return {
    setUniqId: campaignSet.uniqId,
    tabs: tabsResult,
  };
};
