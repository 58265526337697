import React, { FC, useRef } from 'react';
import cn from 'classnames';
import styles from './FilterNavigation.module.scss';
import { TabType } from '../GetTabs/GetTabs';

interface Props {
  activeTab: string | null;
  setActiveTab: (value: string) => void;
  tabs: TabType[];
}

const FilterNavigation: FC<Props> = ({ activeTab, setActiveTab, tabs }: Props) => {
  const navRef = useRef<HTMLUListElement>(null);

  const scroll = (scrollOffset: number) => {
    if (navRef.current) {
      navRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <div className="mt-4 bg-light " style={{ position: 'relative', padding: '10px 90px 0 10px' }}>
      <ul
        ref={navRef}
        className={cn(
          'nav nav-tabs overflow-auto position-relative text-nowrap',
          styles.filterNavWrapper,
        )}>
        {tabs.map((el, index) => {
          return (
            <li className="nav-item font-size _12" key={index}>
              <button
                className={cn('nav-link active', {
                  [styles.active]: el.id === activeTab,
                })}
                onClick={() => {
                  setActiveTab(el.id);
                }}>
                {el.tabName}
              </button>
            </li>
          );
        })}
      </ul>

      <div
        className={cn(
          'd-flex position-absolute top-50 end-0 py-2 px-3',
          styles.triangleNavigation,
        )}>
        <div
          onClick={() => {
            scroll(-(navRef.current?.clientWidth ?? 0) - 150);
          }}
          className={styles.arrowLeft}
        />
        <div
          onClick={() => {
            scroll((navRef.current?.clientWidth ?? 0) - 150);
          }}
          className={styles.arrowRight}
        />
      </div>
    </div>
  );
};

export default FilterNavigation;
