import React, { FC, useCallback, useMemo } from 'react';
import { FormCheck } from 'react-bootstrap';
import { Control, useWatch } from 'react-hook-form';
import { IAdItemAds, IAdsSetupForm } from '../../../../types/AdsSetupForm';
import { Ad } from '../../../../types/Ad';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { SelectedLineItems } from '../../AdsSetup';

interface Props {
  setValue: UseFormSetValue<IAdsSetupForm>;
  getValues: UseFormGetValues<IAdsSetupForm>;
  control: Control<IAdsSetupForm>;
  selectedLineItems: SelectedLineItems;
  filteredAds: Ad[];
}

const AdsTableHeader: FC<Props> = ({
  control,
  selectedLineItems,
  filteredAds,
  setValue,
  getValues,
}) => {
  const currentAds = useWatch({ control, name: 'currentAds' });

  const isChecked = useMemo(() => {
    return filteredAds.every((ad) => currentAds[ad.id].options.selected === true);
  }, [currentAds, filteredAds]);

  const onChange = useCallback(() => {
    const currentAds: IAdsSetupForm['currentAds'] = getValues('currentAds');
    const touchpointsAds: IAdItemAds = {};

    filteredAds.forEach((ad) => {
      currentAds[ad.id] = {
        options: {
          selected: !isChecked,
        },
        sharedOptions: {
          selected: true,
        },
      };

      touchpointsAds[ad.id] = {
        options: {
          selected: !isChecked,
        },
      };
    });
    setValue('currentAds', currentAds);

    const currentTouchpoints = getValues('touchpoints');
    selectedLineItems.forEach((selected) => {
      const prevAds = getValues(
        `touchpoints.${selected.touchpointIndex}.lineItems.${selected.lineItemIndex}.ads`,
      );
      currentTouchpoints[selected.touchpointIndex].lineItems[selected.lineItemIndex].ads = {
        ...prevAds,
        ...touchpointsAds,
      };
    });

    setValue('touchpoints', currentTouchpoints, { shouldDirty: true });
  }, [currentAds, isChecked, filteredAds, selectedLineItems, setValue, getValues]);

  return (
    <div className="row">
      <div className={'col-3'}>
        <FormCheck type={'checkbox'} inline={true} checked={isChecked} onChange={onChange} />
        Placement Name
      </div>
      <div className={'col-3'}>Click Tag</div>
      <div className={'col-3'}>Site</div>
      <div className={'col-3'}>Already assigned to:</div>
    </div>
  );
};

export default AdsTableHeader;
