import React, { FC, useMemo } from 'react';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import styles from './GetTabsContainer.module.scss';
import cn from 'classnames';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useQuery } from '@apollo/client';
import { TARGETING_CONTAINERS_QUERY } from '../../../graphql/queries/TargetingContainersQuery';
import { useGraphQLError } from '../../../hooks/useGraphQLError';
import {
  TargetingContainerSelector,
  TargetingContainerSelectorTypeEnumModel,
} from '../../../graphql/__generated__/graphql';
import { Selector } from '../Selector/Selector';
import { changeName } from '../../../helpers/helpers';

interface Props {
  activeTabId: string;
  selectedSetIndex: number;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  campaignId: string;
}

export interface ContainerType {
  id: string;
  name: string;
  containerOrder: number;
  selectors: SelectorType[];
}

export interface SelectorType {
  id: string;
  name: string;
  selectorType: TargetingContainerSelectorTypeEnumModel;
  isSearchable: boolean;
  selectorOrder: number;
}
const GetTabsContainer: FC<Props> = ({
  activeTabId,
  selectedSetIndex,
  setValue,
  getValues,
  control,
  register,
  campaignId,
}): JSX.Element | null => {
  const { error, data } = useQuery(TARGETING_CONTAINERS_QUERY, {
    variables: { categoryId: activeTabId },
  });

  useGraphQLError([error]);

  const tabContainers: ContainerType[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return (
      data.targetingContainers
        ?.map((container) => {
          return {
            id: container.id,
            name: container.containerName,
            containerOrder: container.containerOrder,
            selectors:
              container.selectors
                ?.filter((selector): selector is TargetingContainerSelector => selector !== null)
                .map((selector) => {
                  return {
                    id: selector.id,
                    isSearchable: selector.searchable,
                    name: selector.selectorName,
                    selectorType: selector.selectorType,
                    selectorOrder: selector.selectorOrder,
                  };
                })
                .sort((el1, el2) => el1.selectorOrder - el2.selectorOrder) ?? [],
          };
        })
        .sort((el1, el2) => el1.containerOrder - el2.containerOrder) ?? []
    );
  }, [data]);

  const getName = (selector: SelectorType): `setsWithTabs.${number}.tabs.${string}.${string}` => {
    const selectorName = selector.name;

    // TODO: find a better way to handle selector name
    const otherName = changeName(`${activeTabId}.${selectorName}`) as `${string}.${string}`;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return `setsWithTabs.${selectedSetIndex!}.tabs.${otherName}`;
  };

  const getTabData = (selector: SelectorType, container: ContainerType): JSX.Element => {
    const key = `${selector.id}-${container.id}`;

    if (selector.selectorType === TargetingContainerSelectorTypeEnumModel.MultilineBox) {
      return (
        <React.Fragment key={key}>
          <div className="col-4 mb-3">
            <p className={'mb-0'}>{selector.name}</p>
            <textarea className="w-0" {...register(getName(selector))} />
          </div>
        </React.Fragment>
      );
    }

    return (
      <Selector
        key={key}
        campaignId={campaignId}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        activeTab={activeTabId!}
        container={container}
        selector={selector}
        setValue={setValue}
        getValues={getValues}
        control={control}
        name={getName(selector)}
      />
    );
  };

  if (tabContainers.length === 0) {
    return null;
  }

  return (
    <div className={'row border rounded mt-3 pb-3 mx-1'}>
      <div className={'row'}>
        {tabContainers.map((container) => (
          <React.Fragment key={`${container.id}`}>
            <p className={cn('mt-3', styles.containerName)}>{container.name}</p>
            <div className={'row'}>
              <div className={cn(styles.containerWrap)}>
                <div className={styles.selectWrap}>
                  {container.selectors.map((selector) => getTabData(selector, container))}
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default GetTabsContainer;
