import React, { FC, useEffect, useMemo } from 'react';
import cn from 'classnames';
import styles from './CampaignSetupHeader.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { FormControl } from 'react-bootstrap';
import { Control, useFormState, useWatch } from 'react-hook-form';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import LimitFrequency from '../CampaignGoalsSetup/LimitFrequency/LimitFrequency';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import { NamingDetailsFragment } from '../../../graphql/__generated__/graphql';
import { namingConventionsVariables } from '../../../constants/namingConventionsVariables';
import { NamingConventionsSeparators } from '../../../types/NamingConventions';
import { handleFloatChange } from '../../../helpers/handleInput';

interface Props {
  namingConventions?: NamingDetailsFragment[];
  namingConventionsSeparators?: NamingConventionsSeparators;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  isShowLimit?: boolean;
  isShowFranshise?: boolean;
  disabled?: boolean;
  isShowNamingSection?: boolean;
}

const CampaignSetupHeader: FC<Props> = ({
  namingConventions,
  namingConventionsSeparators,
  setValue,
  getValues,
  register,
  control,
  isShowLimit = true,
  isShowFranshise = true,
  disabled = false,
  isShowNamingSection = false,
}): JSX.Element => {
  const startDate = useWatch({ control, name: 'setup.startDate' });
  const brand = useWatch({ control, name: 'categoryData.brand' });
  const category = useWatch({ control, name: 'categoryData.category' });
  const franchiseName = useWatch({ control, name: 'setup.franchiseName' });
  const scenarioName = useWatch({ control, name: 'setup.scenarioName' });
  const franchiseDescription = useWatch({ control, name: 'setup.franchiseDescription' });
  const po = useWatch({ control, name: 'setup.po' });
  const { errors } = useFormState({ control, name: 'setup' });

  const getMinEndDate = useMemo((): Date => {
    if (startDate === undefined) {
      return new Date();
    }

    const helpDate = new Date(startDate);
    helpDate.setDate(helpDate.getDate() + 1);
    return helpDate;
  }, [startDate?.getTime()]);

  useEffect(() => {
    if (startDate === undefined) {
      return;
    }

    const endDate = getValues('setup.endDate');
    const helpDate = new Date(startDate);
    helpDate.setDate(helpDate.getDate() + 1);

    if (endDate === undefined) {
      setValue('setup.endDate', helpDate);
      return;
    }

    if (startDate.getTime() >= endDate.getTime()) {
      setValue('setup.endDate', helpDate);
    }
  }, [startDate?.getTime()]);

  const getName = () => {
    let name = '';

    const separator = namingConventionsSeparators?.separator ?? '';
    const subSeparator = namingConventionsSeparators?.separator ?? '';

    const newOrder =
      namingConventions
        ?.filter((el) => el?.variable != null && el?.elementOrder != null && el?.isMerge != null)
        ?.map((el) => ({
          /* eslint-disable @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain */
          variable: el?.variable!,
          order: el?.elementOrder!,
          separator: el?.isMerge! ? subSeparator : separator,
          /* eslint-enable @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain */
        }))
        .sort((el1, el2) => el1.order - el2.order) || [];

    const categoryData = getValues('categoryData');
    const setup = getValues('setup');

    newOrder.forEach((item, index) => {
      switch (item.variable) {
        case namingConventionsVariables.brandId.name:
          name += categoryData.brandName || '[Brand_name]';
          break;
        case namingConventionsVariables.categoryId.name:
          name += categoryData.category || '[Category]';
          break;
        case namingConventionsVariables.franchiseName.name:
          name += setup.franchiseName || '[Line/Franchise name]';
          break;
        case namingConventionsVariables.franchiseDescription.name:
          name += setup.franchiseDescription || '[Campaign description]';
          break;
        case namingConventionsVariables.poNumber.name:
          name += setup.po || '[PO-number]';
          break;
        case namingConventionsVariables.scenarioName.name:
          name += setup.scenarioName || '[Scenario name]';
          break;
      }
      if (index !== newOrder.length - 1) {
        name += item.separator;
      }
    });
    return name;
  };

  useEffect(() => {
    if (disabled) {
      return;
    }
    setValue('setup.name', getName());
  }, [brand, category, franchiseName, franchiseDescription, po, scenarioName]);

  return (
    <>
      {isShowFranshise && (
        <div className="d-flex mb-4">
          <FormControl
            {...register('setup.franchiseName')}
            type={'text'}
            disabled={disabled}
            aria-label="franchiseName"
            placeholder={'Line/Franchise name'}
            className={styles.franchiseName}
            autoComplete={'off'}
            isInvalid={errors.setup?.['franchiseName'] !== undefined}
          />
          <FormControl
            {...register('setup.franchiseDescription')}
            type={'text'}
            disabled={disabled}
            aria-label="franchise-description"
            placeholder={'Campaign description'}
            className={styles.franchiseDecsr}
            autoComplete={'off'}
            isInvalid={errors.setup?.['franchiseDescription'] !== undefined}
          />
          <FormControl
            {...register('setup.po')}
            type={'text'}
            disabled={disabled}
            aria-label="po"
            placeholder={'PO number'}
            className={styles.po}
            autoComplete={'off'}
            isInvalid={errors.setup?.po !== undefined}
          />
        </div>
      )}
      <div className={cn('d-flex w-100 flex-wrap', styles.inputsWrapper)}>
        {isShowLimit && (
          <div>
            <LimitFrequency
              isDisabled={disabled}
              register={register}
              setValue={setValue}
              name={'setup.frequency'}
              isInvalid={{
                limit: errors.setup?.frequency?.limit !== undefined,
                per: errors.setup?.frequency?.per !== undefined,
                period: errors.setup?.frequency?.period !== undefined,
              }}
            />
          </div>
        )}
        <FormControl
          disabled={disabled}
          {...register('setup.budget', {
            valueAsNumber: true,
            onChange: (e) => {
              handleFloatChange(e, setValue, 'setup.budget');
            },
          })}
          type={'text'}
          inputMode={'decimal'}
          aria-label="budget"
          placeholder={'Budget'}
          className={styles.budget}
          autoComplete={'off'}
          isInvalid={errors.setup?.budget !== undefined}
        />
        <div className="d-flex gap-4">
          <CustomDatePicker
            control={control}
            name={'setup.startDate'}
            isDisabled={disabled}
            withIcon={true}
            placeholder={'Start date'}
            minDate={new Date()}
            isInvalid={errors.setup?.startDate !== undefined}
          />
          <CustomDatePicker
            control={control}
            name={'setup.endDate'}
            isDisabled={disabled}
            withIcon={true}
            placeholder={'End date'}
            minDate={getMinEndDate}
            isInvalid={errors.setup?.endDate !== undefined}
          />
        </div>
        <FormControl
          disabled={true}
          {...register('setup.name')}
          isInvalid={errors.setup?.name !== undefined}
          aria-label="campaignName"
          placeholder={
            'Campaign name [Brand_name]_[Category]_[Line/Franchise name]_[Campaign description]_[PO-number]_[Scenario name]'
          }
          className={cn(styles.campaignName, { [styles.bigWidth]: !isShowNamingSection })}
          autoComplete={'off'}
        />
      </div>
    </>
  );
};

export default CampaignSetupHeader;
