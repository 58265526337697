export const getNcTargetingObjectiveName = (
  tabs:
    | {
        [key: string]: { [key: string]: string[] | undefined };
      }
    | undefined,
) => {
  if (!tabs) {
    return 'prospecting';
  }

  let isFirstPartySelected = false;
  if (tabs['nc_first_third_party_custom_lists']) {
    const firstPartyExclude = tabs['nc_first_third_party_custom_lists']['first_party_exclude'];
    const firstPartyInclude = tabs['nc_first_third_party_custom_lists']['first_party_include'];
    isFirstPartySelected =
      (firstPartyInclude !== undefined && firstPartyInclude.length > 0) ||
      (firstPartyExclude !== undefined && firstPartyExclude.length > 0);
  }

  const excludeKeys = ['nc_geo_language', 'nc_devices_dv360'];
  const isAnyOtherOptionSelected = Object.keys(tabs)
    .filter((key) => !excludeKeys.includes(key.toString()))
    .some((key) =>
      Object.keys(tabs[key]).some((optionName) => {
        if (optionName === 'first_party_exclude' || optionName === 'first_party_include') {
          return false;
        }

        const value = tabs[key][optionName];
        return value !== undefined && value.length > 0;
      }),
    );

  if (isFirstPartySelected && isAnyOtherOptionSelected) {
    return 'pros-rem';
  }

  if (isFirstPartySelected) {
    return 'remarketing';
  }

  return 'prospecting';
};
