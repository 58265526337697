// Some dates used in the datepicker are provided by the backend in the format: 2023-12-19T00:00:00+00:00
// We need to convert it to the local timezone without change of the day and time.
// Otherwise, a previous day will be displayed in places with negative timezones.
export function getFromUTC(dateString: string): Date {
  const date = new Date(dateString);
  const offset = date.getTimezoneOffset();
  // offset < 0 matches positive timezones e.g., Fri Dec 08 2023 16:13:46 GMT+0100 (Central European Standard Time)
  const day = offset < 0 ? date.getDate() : date.getDate() + 1;
  date.setDate(day);
  date.setHours(0, 0, 0, 0);
  return date;
}

// Converts a date to UTC without changing the day.
// The time is set to 00:00:00.
//E.g., 2023-12-19T00:00:00+02:00 -> 2023-12-19T00:00:00Z
export function convertToUTC(date: Date): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}
