import { collection, Firestore, getDocs, query, where } from 'firebase/firestore/lite';
import { IPartner } from '../../types/Partner';

export async function readPartnerData(
  masterFirestore: Firestore,
  token: string,
): Promise<IPartner | null> {
  let partnerData: IPartner | null = null;
  const colSnapshot = collection(masterFirestore, 'clients');
  const stateQueryName = query(colSnapshot, where('gui_token', '==', token));
  const docs: any = await getDocs(stateQueryName);

  let data = null;
  docs.forEach((doc: any) => {
    data = doc.data();
    partnerData = {
      ...doc.data(),
      partner_id: data?.platforms?.DV360.API?.name,
      campaignName: doc?.id,
      firebaseConfig: data?.platforms?.Firebase?.API?.firebaseConfig,
    };
  });

  return partnerData;
}
