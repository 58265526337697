import { gql } from '../__generated__';
import { ICreateCampaignForm, ICreateCampaignLisAdgs } from '../../types/CreateCampaignForm';
import {
  UpsertCampaignLineItemsLiInput,
  UpsertLisAdgsMutationVariables,
} from '../__generated__/graphql';
import { getFrequencyInput, getViewFrequencyInput } from '../helpers/getFrequencyInput';

export const UPSERT_LIS_ADGS_MUTATION = gql(`
    mutation UpsertLisAdgs($input: UpsertCampaignLineItemsInput!) {
        upsertCampaignLineItems(input: $input) {
            campaign {
                id
            }
        }
    }
`);

export const getUpsertLisAdgsMutationVariables = (
  data: ICreateCampaignForm,
  id: string,
  updatedAt: string,
): UpsertLisAdgsMutationVariables => {
  const sets: { [key: string]: ICreateCampaignLisAdgs[] } = {};

  data.lisAdgs?.forEach((lisAdg) => {
    if (sets[lisAdg.setId] === undefined) {
      sets[lisAdg.setId] = [];
    }

    sets[lisAdg.setId].push(lisAdg);
  });

  return {
    input: {
      campaignId: id,
      sets: Object.keys(sets).map((key) => ({
        uniqId: key.toString(),
        lis: sets[key]
          .map((lisAdg) =>
            lisAdg.lineItems.map((lineItem) => {
              const result: UpsertCampaignLineItemsLiInput = {
                uniqId: lineItem.uniqId,
                partnerId: lisAdg.partnerId,
                touchpointId: lisAdg.touchpointId,
                variantName: lineItem.variantName,
                name: lineItem.name,
                tacticDetails: lineItem.tacticDetails,
                mainTactics: lineItem.mainTactics,
                adgs: lineItem.adgs.map((adg) => ({
                  name: adg.name,
                  liSubtype: adg.liSubtype,
                })),
                frequency: getFrequencyInput(lineItem.frequency),
                view: getViewFrequencyInput(lineItem.view),
              };
              return result;
            }),
          )
          .flat(),
      })),
      updatedAt: updatedAt,
    },
  };
};
