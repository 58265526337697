import * as z from 'zod';

const AdOptionsSchema = z.object({
  selected: z.boolean(),
});

const AdsItemSchema = z.object({
  uniqId: z.string(),
  name: z.string(),
  variantName: z.string(),
  adgs: z.array(z.string()),
  ads: z.object({}).catchall(
    z.object({
      options: AdOptionsSchema,
    }),
  ),
});

const AdSharedOptions = z.object({
  selected: z.boolean(),
});

const VariantsSchema = z.object({}).catchall(z.object({}).catchall(z.array(z.string()).optional()));

export const AdsSetupFormSchema = z.object({
  campaignDetails: z.object({
    brandName: z.string(),
    category: z.string(),
    franchiseName: z.string(),
    campaignDescription: z.string(),
    poNumber: z.string(),
  }),
  sets: z.object({}).catchall(
    z.object({
      name: z.string(),
      touchpoints: z.object({}).catchall(
        z.object({
          name: z.string(),
        }),
      ),
      goals: z.array(z.string()),
      variants: z.object({}).catchall(VariantsSchema),
    }),
  ),
  currentAds: z.object({}).catchall(
    z.object({
      options: AdOptionsSchema,
      sharedOptions: AdSharedOptions,
    }),
  ),
  touchpoints: z.array(
    z.object({
      setId: z.string(),
      touchpointId: z.string(),
      partnerId: z.string().optional(),
      lineItems: z.array(AdsItemSchema),
    }),
  ),
});

export type IAdsSetupForm = z.infer<typeof AdsSetupFormSchema>;
export type IAdOptions = z.infer<typeof AdOptionsSchema>;
export type IAdSharedOptions = z.infer<typeof AdSharedOptions>;
export type IAdItemAds = z.infer<typeof AdsItemSchema>['ads'];
