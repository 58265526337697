import { FC } from 'react';
import React, { Route, Routes } from 'react-router-dom';
import { firebaseContext } from './context/FirebaseContext';
import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';
import TemplatesM from './containers/TemplatesM/TemplatesM';
import OptimisationM from './containers/OptimisationM/OptimisationM';
import Exclusions from './containers/Exclusions/Exclusions';
import PlatformsBrandsProducts from './containers/PlatformsBrandsProducts/PlatformsBrandsProducts';
import PlatformsBrandsProductsConventions from './containers/PlatformsBrandsProductsPlatforms/PlatformsBrandsProductsPlatforms';
import Login from './components/Login/Login';
import { Paths } from './constants/paths';
import CreateCampaignGoals from './containers/CreateCampaignGoals/CreateCampaignGoals';
import UpsertTargetGroup from './containers/UpsertTargetGroup/UpsertTargetGroup';
import UpsertCampaignGoals from './containers/UpsertCampaignGoals/UpsertCampaignGoals';
import { UpsertVariantTargeting } from './containers/UpsertVariantTargeting/UpsertVariantTargeting';
import UpsertBudgetSplit from './containers/UpsertBudgetSplit/UpsertBudgetSplit';
import UpsertLisAdgs from './containers/UpsertLisAdgs/UpsertLisAdgs';
import { Authentication } from './components/Authentication/Authentication';
import AdsSetupForm from './containers/AdsSetupForm/AdsSetupForm';
import FlypsContainer from './containers/Flyps/FlypsContainer';

const App: FC = (): JSX.Element => {
  return (
    <Layout>
      <Authentication firebaseContext={firebaseContext}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/login"
            element={
              <Login
                setToken={(token) => {
                  firebaseContext.token = token;
                }}
              />
            }
          />
          <Route path="/:token" element={<Home />} />
          <Route path="/platforms-brands-products" element={<PlatformsBrandsProducts />} />
          <Route
            path="/platforms-brands-products-platform"
            element={<PlatformsBrandsProductsConventions />}
          />
          <Route path={Paths.createCampaignGoals} element={<CreateCampaignGoals />} />
          <Route path={Paths.upsertCampaignGoals} element={<UpsertCampaignGoals />} />
          <Route path={Paths.upsertTargetGroup} element={<UpsertTargetGroup />} />
          <Route path={Paths.upsertVariantTargeting} element={<UpsertVariantTargeting />} />
          <Route path={Paths.upsertBudgetSplit} element={<UpsertBudgetSplit />} />
          <Route path={Paths.upsertLisAdgs} element={<UpsertLisAdgs />} />
          <Route path={Paths.adsSetupForm} element={<AdsSetupForm />} />
          <Route path={'/checker'} element={<FlypsContainer />} />
          <Route path={'/templates-management/:id'} element={<TemplatesM />} />
          <Route path={'/optimisation-management'} element={<OptimisationM />} />
          <Route path="/exclusions/:id" element={<Exclusions />} />
          <Route path="/404" element={<h1>NOT FOUND PAGE</h1>} />
        </Routes>
      </Authentication>
    </Layout>
  );
};

export default App;
