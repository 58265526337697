import React, { FC } from 'react';
import Row from 'react-bootstrap/Row';
import { Control, FieldArrayWithId, useFieldArray, UseFormGetValues } from 'react-hook-form';
import { UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../../types/CreateCampaignForm';
import { VariableNameFragment } from '../../../graphql/__generated__/graphql';
import { LisAdgsNaming } from '../../../containers/UpsertLisAdgs/UpsertLisAdgs';
import LineItem from '../LineItem/LineItem';

interface Props {
  getValues: UseFormGetValues<ICreateCampaignForm>;
  lisAdgsIndex: number;
  lisAdgs: FieldArrayWithId<ICreateCampaignForm, 'lisAdgs'>;
  register: UseFormRegister<ICreateCampaignForm>;
  setValue: UseFormSetValue<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  mainTactics: VariableNameFragment[];
  liSubtypes: VariableNameFragment[];
  lisAdgsNaming: LisAdgsNaming;
  trigger: UseFormTrigger<ICreateCampaignForm>;
}

const OneTpObject: FC<Props> = ({
  lisAdgsNaming,
  liSubtypes,
  mainTactics,
  lisAdgsIndex,
  lisAdgs,
  getValues,
  register,
  control,
  trigger,
  setValue,
}): JSX.Element => {
  const { fields: lineItems } = useFieldArray({
    control,
    name: `lisAdgs.${lisAdgsIndex}.lineItems`,
  });

  return (
    <Row className={'mb-5'}>
      <Row>
        <span className={'col-4 col-xl-2 mx-3'}>{lisAdgs.name} </span>
        <span className={'col-7 col-xl-9 fw-bold'}>{lisAdgs.ncTouchpointName}</span>
      </Row>
      <>
        {lineItems.map((lineItem, lineItemIndex) => (
          <LineItem
            key={lineItem.id}
            lisAdgsNaming={lisAdgsNaming}
            liSubtypes={liSubtypes}
            mainTactics={mainTactics}
            lineItemIndex={lineItemIndex}
            lisAdgsIndex={lisAdgsIndex}
            register={register}
            setValue={setValue}
            getValues={getValues}
            control={control}
            trigger={trigger}
          />
        ))}
      </>
    </Row>
  );
};

export default OneTpObject;
