import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Header from '../../components/Header/Header';
import styles from './AdsSetup.module.scss';
import { Control } from 'react-hook-form';
import cn from 'classnames';
import CampaignSetupHeader from '../CampaignGoals/CampaignSetupHeader/CampaignSetupHeader';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../types/CreateCampaignForm';
import AdsTable from './AdsTable/AdsTable';
import SelectTouchpoint from './SelectTouchpoint/SelectTouchpoint';
import { IAdOptions, IAdSharedOptions, IAdsSetupForm } from '../../types/AdsSetupForm';
import { Ad } from '../../types/Ad';
import { FirebaseContext } from '../../context/FirebaseContext';
import UploadAdsBlock from '../UploadAdsBlock/UploadAdsBlock';
import Loader360 from '../UI/Loader/Loader360';

export type SelectedLineItems = { touchpointIndex: number; lineItemIndex: number }[];

interface Props {
  handleNext: () => void;
  handlerLoading: boolean;
  campaignRegister: UseFormRegister<ICreateCampaignForm>;
  campaignGetValues: UseFormGetValues<ICreateCampaignForm>;
  campaignSetValue: UseFormSetValue<ICreateCampaignForm>;
  campaignControl: Control<ICreateCampaignForm>;
  campaignId: string;
  ads: Ad[];
  refreshData: () => void;
  getValues: UseFormGetValues<IAdsSetupForm>;
  setValue: UseFormSetValue<IAdsSetupForm>;
  register: UseFormRegister<IAdsSetupForm>;
  control: Control<IAdsSetupForm>;
}

const AdsSetup: FC<Props> = ({
  handleNext,
  handlerLoading,
  campaignControl,
  campaignRegister,
  campaignGetValues,
  campaignSetValue,
  ads,
  refreshData,
  campaignId,
  setValue,
  getValues,
  register,
  control,
}) => {
  const [selectedLineItems, setSelectedLineItems] = useState<SelectedLineItems>([]);
  const { firebaseGui } = useContext(FirebaseContext);

  const calculateCurrentAds = useCallback(() => {
    ads.forEach((ad) => {
      const defaultOptions: IAdOptions = { selected: false };
      const sharedOptions: IAdSharedOptions = {
        selected: false,
      };

      const values = selectedLineItems.map((selected) =>
        getValues(
          `touchpoints.${selected.touchpointIndex}.lineItems.${selected.lineItemIndex}.ads.${ad.id}.options.selected` as const,
        ),
      );
      const set = new Set(values);

      // all values are identical
      if (set.size === 1 || set.size === 0) {
        sharedOptions.selected = true;

        if (set.size === 1) {
          defaultOptions.selected = set.values().next().value as boolean;
        }
      }

      setValue(`currentAds.${ad.id}.options`, defaultOptions);
      setValue(`currentAds.${ad.id}.sharedOptions`, sharedOptions);
    });
  }, [ads, selectedLineItems, setValue, getValues]);

  useEffect(() => {
    calculateCurrentAds();
  }, [calculateCurrentAds]);

  const touchpoints = useMemo(() => getValues('touchpoints'), [getValues]);
  const disable: boolean = ads.length === 0 || touchpoints.length === 0;

  return (
    <div>
      <Header campaignSetupText={'Ads Setup'} withButtons={false} />
      <CampaignSetupHeader
        register={campaignRegister}
        control={campaignControl}
        setValue={campaignSetValue}
        getValues={campaignGetValues}
        disabled={true}
      />
      <div className={'row p-4'}>
        <div className={'col-9'}>
          <SelectTouchpoint
            getValues={getValues}
            selectedLineItems={selectedLineItems}
            setSelectedLineItems={setSelectedLineItems}
            control={control}
          />
        </div>
        <UploadAdsBlock
          campaignId={campaignId}
          firebaseGui={firebaseGui}
          control={control}
          refreshData={refreshData}
        />
      </div>
      <AdsTable
        selectedLineItems={selectedLineItems}
        register={register}
        ads={ads}
        setValue={setValue}
        control={control}
        getValues={getValues}
      />
      <div className="row justify-content-end">
        <button
          className={cn(
            'btn btn-primary col-2 d-flex justify-content-center align-items-center',
            styles.btnAssignAds,
          )}
          onClick={handleNext}
          disabled={disable || handlerLoading}>
          {!handlerLoading ? 'Push to DV360' : <Loader360 isOrange={true} />}
        </button>
      </div>
    </div>
  );
};

export default AdsSetup;
