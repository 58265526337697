import React, { FC } from 'react';
import { FormNavigationButtons } from '../../components/FormNavigationButtons/FormNavigationButtons';
import { Control, useFieldArray } from 'react-hook-form';
import Header from '../../components/Header/Header';
import CampaignSetupHeader from '../../components/CampaignGoals/CampaignSetupHeader/CampaignSetupHeader';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form/dist/types/form';
import { ICreateCampaignForm } from '../../types/CreateCampaignForm';
import styles from './LisAdgs.module.scss';
import OneTpObject from '../../components/LisAdgs/OneTpObject/OneTpObject';
import { VariableNameFragment } from '../../graphql/__generated__/graphql';
import { LisAdgsNaming } from '../../containers/UpsertLisAdgs/UpsertLisAdgs';

interface Props {
  setValue: UseFormSetValue<ICreateCampaignForm>;
  getValues: UseFormGetValues<ICreateCampaignForm>;
  register: UseFormRegister<ICreateCampaignForm>;
  control: Control<ICreateCampaignForm>;
  handleNext: () => void;
  handlePrevious: () => void;
  handlerLoading: boolean;
  liSubtypes: VariableNameFragment[];
  mainTactics: VariableNameFragment[];
  lisAdgsNaming: LisAdgsNaming;
  trigger: UseFormTrigger<ICreateCampaignForm>;
}

const LisAdgs: FC<Props> = ({
  lisAdgsNaming,
  liSubtypes,
  mainTactics,
  handleNext,
  handlePrevious,
  handlerLoading,
  setValue,
  getValues,
  register,
  control,
  trigger,
}) => {
  const { fields: lisAdgs } = useFieldArray({ control, name: 'lisAdgs' });

  return (
    <div>
      <Header campaignSetupText={'LIs & ADGs'} />
      <CampaignSetupHeader
        control={control}
        setValue={setValue}
        getValues={getValues}
        register={register}
        disabled={true}
        isShowFranshise={false}
        isShowLimit={false}
      />
      {lisAdgs.map((lisadg, index) => (
        <OneTpObject
          key={lisadg.id}
          lisAdgsNaming={lisAdgsNaming}
          liSubtypes={liSubtypes}
          mainTactics={mainTactics}
          lisAdgs={lisadg}
          lisAdgsIndex={index}
          getValues={getValues}
          control={control}
          register={register}
          setValue={setValue}
          trigger={trigger}
        />
      ))}
      <FormNavigationButtons
        className={{ next: styles.nextButton }}
        isOrange={true}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        isLoading={handlerLoading}
      />
    </div>
  );
};

export default LisAdgs;
